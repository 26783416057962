<template>
  <div class="gwd-table nested h-full" v-if="taskEquipmentLoaded">
    <div class="gwd-table-header">Planeeritud tehnika</div>
    <div class="gwd-table-header equipment-assignments-table">
      <span>Tehnika nimi</span>
      <span>Vastutav töötaja</span>
      <span>Kogus</span>
      <span>Kulu</span>
      <span>Hind</span>
      <span>Teenuse makse tüüp</span>
    </div>
    <div class="gwd-table-row-wrapper">
      <equipment-assignment
        v-for="equipmentAssignment in taskEquipment"
        :key="equipmentAssignment.id"
        :equipment-assignment="equipmentAssignment"
        @enableDelete="enableDelete"
        @editAssignment="editAssignment"
        @convertToCost="convertToCost"
      />
      <span v-if="taskEquipment.length === 0" class="p-2">
        Määratud varustust pole.
      </span>
      <div class="gwd-table-header mt-2">Tehnika kulud</div>
      <div class="gwd-table-header grid-cols-6">
        <span>Tehnika</span>
        <span>Kogus</span>
        <span>Kulu</span>
        <span>Müügihind</span>
        <span>Kogukulu</span>
        <span>Kogu müügihind</span>
      </div>
      <div
        class="gwd-table-row grid-cols-6"
        v-for="cost in equipmentCosts"
        :key="`${cost.type}${cost.id}`"
      >
        <span>{{ cost.description }}</span>
        <span>{{ cost.amount }} {{ cost.unit }}</span>
        <span>{{ cost.cost }} €</span>
        <span>{{ cost.price }} €</span>
        <span>{{ round2(cost.amount * cost.cost) }}€</span>
        <span>{{ round2(cost.price * cost.amount) }} €</span>
      </div>
      <div v-if="equipmentCosts.length === 0" class="p-1">Kulud puuduvad</div>
    </div>
    <div class="gwd-table-bottom-bar">
      <button
        class="btn-primary mr-3"
        v-if="['R0', 'R1', 'R2'].includes(userPermissions)"
        @click="newEquipmentAssignment = true"
      >
        <span class="label">Määra tehnika</span>
      </button>
      <button
        class="btn-primary"
        v-if="['R0', 'R1', 'R2'].includes(userPermissions)"
        @click="addProjectEquipmentCost = true"
      >
        <span class="label">Lisa kulu</span>
      </button>
    </div>
    <add-equipment-assignment-modal
      v-if="newEquipmentAssignment"
      :task="taskId"
      @equipmentAssigned="loadTaskEquipment"
      @closeModal="newEquipmentAssignment = false"
    />
    <add-project-equipment-cost-modal
      v-if="addProjectEquipmentCost"
      :task="taskId"
      :equipment-assignment="equipmentToConvert"
      @costAdded="loadTaskEquipmentCosts"
      @closeModal="addProjectEquipmentCost = false"
    />
    <confirm-modal
      key="equipment-assignment-delete-modal"
      v-if="showDeleteAssignmentModal"
      text="Oled kindel, et soovid tehnika töölt kustutada?"
      @confirm="deleteEquipmentAssignment"
      @closeModal="
        () => {
          this.deleteObject = null;
          this.showDeleteAssignmentModal = false;
        }
      "
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AddEquipmentAssignmentModal from "@/components/tasks/modals/AddEquipmentAssignmentModal.vue";
import ConfirmModal from "@/components/reusable/ConfirmModal.vue";
import AddProjectEquipmentCostModal from "@/components/project/costs/AddProjectEquipmentCostModal.vue";
import EquipmentAssignment from "@/components/tasks/costs/EquipmentAssignment.vue";
import { round2 } from "@/assets/utils/commonMath";

export default {
  name: "TaskEquipmentAssignments",
  components: {
    EquipmentAssignment,
    AddProjectEquipmentCostModal,
    ConfirmModal,
    AddEquipmentAssignmentModal,
  },
  props: {
    taskId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      taskEquipment: [],
      taskEquipmentLoaded: false,
      newEquipmentAssignment: false,
      showDeleteAssignmentModal: false,
      deleteObject: null,
      addProjectEquipmentCost: false,
      equipmentCosts: [],
      equipmentToConvert: null,
    };
  },
  methods: {
    round2,
    loadTaskEquipment() {
      this.apiRequest(
        `template/${this.companyId}/equipment/assignments/?task=${this.taskId}`,
        "get",
        true
      ).then((res) => {
        this.taskEquipment = res.data;
        this.taskEquipmentLoaded = true;
      });
    },
    loadTaskEquipmentCosts() {
      this.apiRequest(
        `costs/${this.companyId}/task/${this.taskId}/equipment/`,
        "get",
        true
      ).then((res) => {
        this.equipmentCosts = res.data;
      });
    },
    enableDelete(e) {
      this.showDeleteAssignmentModal = true;
      this.deleteObject = e;
    },
    deleteEquipmentAssignment() {
      this.apiRequest(
        `template/${this.companyId}/equipment/${this.deleteObject.equipment.id}/assignment/${this.deleteObject.id}/delete/`,
        "delete",
        true
      ).then((res) => {
        if (res.status === 200) {
          this.loadTaskEquipment();
        }
      });
    },
    editAssignment(e) {
      this.apiRequest(
        `template/${this.companyId}/equipment/${e.equipment.id}/${e.id}/update/`,
        "patch",
        true,
        e
      )
        .then((res) => {
          if (res.status === 200) this.loadTaskEquipment();
        })
        .catch((err) => {
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            error: err,
            text: "Midagi läks valesti",
            ttl: 5,
          });
        });
    },
    convertToCost(equipment) {
      this.equipmentToConvert = equipment;
      this.addProjectEquipmentCost = true;
    },
  },
  mounted() {
    this.loadTaskEquipment();
    this.loadTaskEquipmentCosts();
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      userPermissions: "companyData/userPermission",
      isAdmin: "companyData/isAdmin",
      isManager: "companyData/isManager",
    }),
  },
};
</script>

<style lang="scss">
.equipment-assignments-table {
  //                     name worker amount cost price type  delete
  grid-template-columns: 0.5fr 0.5fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr;
}
</style>
