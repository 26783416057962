<template>
  <div class="flex h-full">
    <chat-overlord :url-suffix="`task/${this.modalData.task}`" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChatOverlord from "@/components/chat/ChatOverlord.vue";

export default {
  name: "TaskChat",
  components: { ChatOverlord },
  data() {
    return {
      messages: [],
      messagesLoaded: false,
      socket: null,
      content: "",
      chatParty: null,
      notificationTarget: false,
      lockInput: false,
    };
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      modalData: "modals/taskModal/data",
    }),
  },
};
</script>

<style scoped>
#chatWindow {
  .comment-window {
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      @apply rounded-none;
    }
  }
}
</style>
