var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal text-sm"},[_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway:mousedown",value:(_vm.closeModal),expression:"closeModal",arg:"mousedown"}],staticClass:"modal-body modal-medium modal-vwide"},[(_vm.itemInited)?_c('div',{staticClass:"modal-content flex-col"},[_c('div',{staticClass:"gwd-table nested"},[_vm._m(0),_vm._l((_vm.costs),function(cost){return _c('div',{key:cost.id,staticClass:"gwd-table-row template-generator-modal-rows"},[_c('span'),_c('span',[_vm._v(_vm._s(cost.description))]),_c('span',[_vm._v(_vm._s(cost.amount)+" "+_vm._s(cost.unit))]),_c('span',[_vm._v(_vm._s(cost.cost))]),_c('span',[_vm._v(_vm._s(cost.price))]),_c('span',[_vm._v(_vm._s(_vm.round2(cost.amount * cost.cost)))]),_c('span',[_vm._v(_vm._s(_vm.round2(cost.amount * cost.price)))]),_c('span'),_c('span')])}),_c('div',{staticClass:"gwd-table-row template-generator-modal-rows"},[_c('div',{staticClass:"pr-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.serviceObj),expression:"item.serviceObj"}],staticClass:"w-full",class:_vm.showInvalid && !_vm.item.serviceObj
                  ? 'ring-danger ring-opacity-50 ring-2'
                  : '',on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.item, "serviceObj", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.item.service_name = _vm.item.serviceObj.service_name}]}},_vm._l((_vm.serviceObjects),function(serviceObj){return _c('option',{key:serviceObj.id,attrs:{"disabled":serviceObj.service_code.length === 2},domProps:{"value":serviceObj}},[_vm._v(" "+_vm._s(((serviceObj.service_code) + " - " + (serviceObj.service_name)))+" ")])}),0)]),_c('div',{staticClass:"no-hover pr-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.description),expression:"item.description"}],staticClass:"w-full",attrs:{"type":"text"},domProps:{"value":(_vm.item.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.item, "description", $event.target.value)}}})]),_c('div',{staticClass:"no-hover pr-2"},[_c('decimal-input',{staticClass:"w-full",class:_vm.showInvalid && !_vm.item.amount
                  ? 'ring-danger ring-opacity-50 ring-2'
                  : '',model:{value:(_vm.item.amount),callback:function ($$v) {_vm.$set(_vm.item, "amount", _vm._n($$v))},expression:"item.amount"}})],1),_c('div'),_c('div',{staticClass:"no-hover pr-2"},[_c('decimal-input',{staticClass:"w-full",class:_vm.showInvalid && !_vm.item.price
                  ? 'ring-danger ring-opacity-50 ring-2'
                  : '',attrs:{"step":"0.01"},model:{value:(_vm.item.price),callback:function ($$v) {_vm.$set(_vm.item, "price", $$v)},expression:"item.price"}})],1),_c('div',{staticClass:"no-hover pr-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.unit),expression:"item.unit"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.item, "unit", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":'tk'}},[_vm._v("tk")]),_c('option',{domProps:{"value":'h'}},[_vm._v("h")]),_c('option',{domProps:{"value":'m'}},[_vm._v("m")]),_c('option',{domProps:{"value":'km'}},[_vm._v("km")]),_c('option',{domProps:{"value":'l'}},[_vm._v("l")]),_c('option',{domProps:{"value":'m²'}},[_vm._v("m²")]),_c('option',{domProps:{"value":'m³'}},[_vm._v("m³")]),_c('option',{domProps:{"value":'kg'}},[_vm._v("kg")]),_c('option',{domProps:{"value":'t'}},[_vm._v("t")]),_c('option',{domProps:{"value":'paev'}},[_vm._v("päev")]),_c('option',{domProps:{"value":'kuu'}},[_vm._v("kuu")])])]),_c('div',{staticClass:"no-hover pr-2"},[_c('span',[_vm._v(" "+_vm._s(_vm.round2(_vm.item.amount * _vm.item.price)))])]),_c('div',{staticClass:"no-hover pr-2"},[(_vm.taxTypes)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.taxType),expression:"item.taxType"}],staticClass:"max-w-full",class:_vm.showInvalid && !_vm.item.taxType
                  ? 'ring-danger ring-opacity-50 ring-2'
                  : '',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.item, "taxType", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.taxTypes.filter(function (x) { return x.outgoing_tax_code; })),function(taxType){return _c('option',{key:taxType.id,domProps:{"value":taxType}},[_vm._v(" "+_vm._s(taxType ? ((taxType.description) + " (" + (taxType.tax_percentage ? taxType.tax_percentage : "0") + "%)") : "-")+" ")])}),0):_vm._e()]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.item.total.toFixed(2))+" "+_vm._s(_vm.currency ? _vm.currency.sign : "€"))])])]),_c('div',{staticClass:"gwd-table-row template-generator-modal-rows"},[_c('span'),_c('div',{staticClass:"flex items-center"},[_c('button',{staticClass:"btn-primary mr-3",on:{"click":_vm.addDate}},[_vm._v(" Lisa kuupäev "),_c('span',{staticClass:"label ml-1"},[_vm._v(" ("+_vm._s(_vm.task ? _vm.moment(_vm.task.start_time).format("DD.MM.YYYY") : _vm.moment(_vm.ee).format("DD.MM.YYYY"))+") ")])]),(_vm.task)?_c('button',{class:_vm.task.address ? 'btn-primary' : 'btn-disabled',on:{"click":_vm.addStartAddress}},[_vm._v(" Algaadress ")]):_vm._e(),(_vm.task)?_c('button',{staticClass:"mx-3",class:_vm.task.end_address ? 'btn-primary' : 'btn-disabled',on:{"click":_vm.addEndAddress}},[_vm._v(" Lõppaadress ")]):_vm._e(),(_vm.task)?_c('button',{class:_vm.task.address && _vm.task.end_address
                  ? 'btn-primary'
                  : 'btn-disabled',on:{"click":_vm.addBothAddress}},[_vm._v(" Alg - Lõpp ")]):_vm._e()]),(!_vm.amountAdded)?_c('div',{staticClass:"flex items-center text-sm"},[_c('button',{staticClass:"btn-primary",on:{"click":_vm.calculateAverageAmount}},[_c('span',{staticClass:"label"},[_vm._v("kesk.")])]),_c('button',{staticClass:"btn-primary mx-auto",on:{"click":_vm.calculateSumAmount}},[_c('span',{staticClass:"label"},[_vm._v("sum")])]),_c('button',{staticClass:"btn-primary",on:{"click":_vm.calculateMaxAmount}},[_c('span',{staticClass:"label"},[_vm._v("max")])])]):_c('span'),_c('span'),(!_vm.priceAdded)?_c('div',{staticClass:"flex items-center text-sm"},[_c('button',{staticClass:"btn-primary",on:{"click":_vm.calculateAveragePrice}},[_c('span',{staticClass:"label"},[_vm._v("kesk.")])]),_c('button',{staticClass:"btn-primary mx-auto",on:{"click":_vm.calculateSumPrice}},[_c('span',{staticClass:"label"},[_vm._v("sum")])]),_c('button',{staticClass:"btn-primary",on:{"click":_vm.calculateMaxPrice}},[_c('span',{staticClass:"label"},[_vm._v("max")])])]):_c('span'),_c('span'),(!_vm.priceAdded && !_vm.amountAdded)?_c('div',{staticClass:"flex items-center text-sm"},[_c('button',{staticClass:"btn-primary",on:{"click":_vm.calculateSumOfEach}},[_c('span',{staticClass:"lable"},[_vm._v("Summa")])])]):_c('span'),_c('span',{staticClass:"col-span-2"})]),(_vm.task && (_vm.task.address || _vm.task.end_address))?_c('div',{staticClass:"gwd-table-row template-generator-modal-rows"},[_c('div'),_c('div',{staticClass:"col-span-8"},[_vm._v(" "+_vm._s(_vm.task.address)+" - "+_vm._s(_vm.task.end_address)+" ")])]):_vm._e()],2)]):_vm._e(),_c('div',{staticClass:"modal-bottom-bar"},[_c('button',{staticClass:"btn-primary mr-3",on:{"click":_vm.saveRevenue}},[_vm._v("Salvesta")]),_c('button',{staticClass:"btn-danger",on:{"click":_vm.closeModal}},[_vm._v("Sulge")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gwd-table-row template-generator-modal-rows"},[_c('span',[_vm._v("Programmikood")]),_c('span',[_vm._v("Kirjeldus")]),_c('span',[_vm._v("Kogus")]),_c('span',[_vm._v("Kulu")]),_c('span',[_vm._v("Müügihind")]),_c('span',[_vm._v("Kogukulu")]),_c('span',[_vm._v("Koguhind")]),_c('span'),_c('span')])}]

export { render, staticRenderFns }