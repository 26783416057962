<template>
  <div class="flex flex-col z-50">
    <div class="data-container">
      <div class="data-container-header">
        <h3>Failid</h3>
      </div>
      <div class="data-container-content">
        <file-display
          :files="files"
          ref="fileDisplay"
          @uploadFiles="saveTaskFiles"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RequestHandler } from "@/assets/mixins/GewodoMixins";
import { mapGetters } from "vuex";
import FileDisplay from "../../reusable/FileDisplay.vue";
import { EventBus } from "../../../assets/mixins/GewodoMixins";
export default {
  name: "TaskFiles",
  components: { FileDisplay },
  props: {
    task: { type: Number, default: null },
    draft: {
      type: Object,
      default: null,
    },
    company: { type: String, default: "" },
    taskStatus: { type: Number, default: null },
  },
  data() {
    return {
      files: [],
      fileList: [],
      expanded: false,
      extraFiles: [],
    };
  },
  mounted() {
    this.retrieveTaskFiles();
    EventBus.$on("fileDeleted", () => {
      this.retrieveTaskFiles();
    });
  },
  beforeDestroy() {
    EventBus.$off("fileDeleted");
  },
  mixins: [RequestHandler],
  methods: {
    retrieveTaskFiles() {
      this.filesLoaded = false;
      let url;
      if (this.$store.state.companyData.isClient) {
        url = "tasks/" + this.modalData.task + "/client/files/";
      } else {
        url = "tasks/" + this.modalData.task + "/files/";
      }
      this.apiRequest(url, "get", true).then((res) => {
        if (res.status === 200) {
          this.files = res.data;
          this.filesLoaded = true;
        }
      });
    },
    fileDeleted() {
      this.$emit("fileDeleted");
    },
    selectFile() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
      // Do something with chosen file
    },
    updateFiles(e) {
      this.fileList = e;
    },
    saveTaskFiles() {
      const data = new FormData();
      const fileList = this.$refs.fileDisplay.getFilesToUpload();
      this.$store.state.loading = true;
      fileList.map((x) => {
        const file = new File([x], x.name, { type: x.type });
        data.append("files", file, file.name);
      });
      this.apiRequest(
        "tasks/" + this.modalData.task + "/files/add/",
        "post",
        true,
        data
      )
        .then((res) => {
          if (res.status === 201) {
            this.$store.state.loading = false;
            this.retrieveTaskFiles();
            this.$refs.fileDisplay.reset();
          } else this.$store.state.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            text: "Failide salvestamine ebaõnnestus",
          });
          this.$store.state.loading = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      modalData: "modals/taskModal/data",
    }),
    canClick() {
      return (
        (this.files.length === 0 &&
          !this.$store.state.companyData.isClient &&
          ["R0", "R1", "R2", "R3", "R5"].includes(
            this.$store.state.companyData.activeCompany.worker_permission
          ) &&
          this.taskStatus !== 6) ||
        this.files.length > 0
      );
    },
  },
};
</script>
