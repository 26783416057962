<template>
  <div class="chat-controller w-full justify-end">
    <chat-party-modal
      v-for="chatParty in $store.state.chat.activeChatParties"
      :key="chatParty.id"
      :chat-party="chatParty"
      class="mx-2"
    ></chat-party-modal>
  </div>
</template>

<script>
import ChatPartyModal from "@/components/chat/ChatPartyModal";
export default {
  name: "ChatController",
  components: { ChatPartyModal },
};
</script>

<style lang="scss">
.chat-controller {
  @apply flex flex-row p-2 items-center shadow;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100vw;
}
</style>
