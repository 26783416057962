import StoreRequestHandler from "@/assets/mixins/StoreRequestHandler";

export const equipmentUnavailabilityModal = {
  namespaced: true,
  state: {
    show: false,
    data: null,
    retrieving: false,
  },
  mutations: {
    open(state, data) {
      state.data = data;
      state.show = true;
      state.retrieving = false;
    },
    close(state) {
      state.show = false;
      state.data = null;
    },
    setRetrieving(state, data) {
      state.retrieving = data;
    },
  },
  actions: {
    open({ commit, rootGetters }, data) {
      commit("setRetrieving", true);
      StoreRequestHandler.apiRequest(
        `template/${rootGetters["companyData/activeCompanyUuid"]}/equipment/${data.equipmentId}/unavailability/${data.unavailabilityId}/`,
        "get",
        true
      )
        .then((res) => {
          commit("open", { reqData: res.data, equipmentId: data.equipmentId });
        })
        .catch((err) => console.error(err));
    },
    close({ commit }) {
      commit("close");
    },
    setRetrieving({ commit }, data) {
      commit("setRetrieving", data);
    },
  },
  getters: {
    data: (state) => state.data,
    retrieving: (state) => state.retrieving,
  },
};
