<template>
  <div class="modal">
    <div class="modal-body modal-medium" v-on-clickaway:mousedown="closeModal">
      <div class="modal-header p-3">
        <h3>Vali eksisteerivad arved</h3>
      </div>
      <div class="modal-content flex-col">
        <div class="gwd-table">
          <div class="gwd-table-header grid-cols-6">
            <span>Arve jrk nr</span>
            <span class="col-span-2">Klient/maksja (kui erineb)</span>
            <span class="col-span-2">Kuupäev</span>
            <span>Summa</span>
          </div>
          <div
            class="gwd-table-row grid-cols-6 cursor-pointer"
            :class="selectedInvoices.includes(invoice) ? 'bg-offwhite' : ''"
            v-for="invoice in invoices"
            :key="invoice.id"
            @click="toggleInvoice(invoice)"
          >
            <span>{{ invoice.id }}</span>
            <span class="col-span-2">{{
              invoice.payer ? invoice.payer : invoice.client
            }}</span>
            <span class="col-span-2">{{ invoice.invoice_date }}</span>
            <span>{{ invoice.sum }}</span>
          </div>
        </div>
      </div>
      <div class="modal-bottom-bar flex">
        <button class="btn-primary mr-3" @click="confirm">
          <span class="label">Kinnita valik</span>
        </button>
        <button class="btn-danger mr-3" @click="closeModal">
          <span class="label">Tühista</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExistingInvoiceRevenueListSelector",
  props: {
    invoices: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedInvoices: [],
    };
  },
  methods: {
    toggleInvoice(invoice) {
      if (this.selectedInvoices.find((x) => x.id === invoice.id)) {
        this.selectedInvoices.splice(
          this.selectedInvoices.indexOf(
            this.selectedInvoices.find((x) => x.id === invoice.id)
          ),
          1
        );
      } else {
        this.selectedInvoices.push(invoice);
      }
    },
    confirm() {
      this.$emit("activateSelectedExistingInvoices", this.selectedInvoices);
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss"></style>
