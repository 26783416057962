<template>
  <div class="modal">
    <div class="modal-body modal-medium" v-on-clickaway:mousedown="closeModal">
      <div class="modal-content flex-col" v-if="itemInited">
        <div class="gwd-table">
          <div class="gwd-table-header grid-cols-9">
            <span>Programmikood</span>
            <span class="col-span-2">Kirjeldus</span>
            <span>Kogus</span>
            <span>Hind</span>
            <span>Ühik</span>
            <span>Summa (-km)</span>
            <span>KM tüüp</span>
            <span>Summa</span>
          </div>
        </div>
        <div
          v-for="cost in combinedCosts"
          :key="cost.id"
          class="grid grid-cols-9 w-full p-2 items-center"
        >
          <span></span>
          <span class="col-span-2 flex justify-between items-center">
            <span>{{ cost.description }}</span>
            <button
              @click="toggleNameSelected(cost)"
              v-if="!nameUsed"
              class="pr-2"
            >
              <span class="label typcn typcn-arrow-down"></span>
            </button>
          </span>
          <span>{{ cost.amount }}</span>
          <span>{{ cost.price }}</span>
          <span>{{ cost.unit }}</span>
          <span>{{ round2(cost.amount * cost.price) }}</span>
          <span></span>
          <span>{{ round2(cost.amount * cost.price) }}</span>
        </div>
        <div class="grid grid-cols-9 w-full p-2 items-center hover:bg-offwhite">
          <div class="pr-2">
            <select
              v-model="item.serviceObj"
              class="w-full"
              :class="
                showInvalid && !item.serviceObj
                  ? 'ring-danger ring-opacity-50 ring-2'
                  : ''
              "
              @change="
                item.service_name
                  ? ''
                  : (item.service_name = item.serviceObj.service_name)
              "
            >
              <option
                v-for="serviceObj in serviceObjects"
                :key="serviceObj.id"
                :value="serviceObj"
              >
                {{ `${serviceObj.service_code} - ${serviceObj.service_name}` }}
              </option>
            </select>
          </div>
          <div class="no-hover col-span-2 pr-2">
            <input type="text" v-model="item.service_name" class="w-full" />
          </div>
          <div class="no-hover pr-2">
            <decimal-input
              v-model.number="item.amount"
              class="w-full"
              :class="
                showInvalid && !item.amount
                  ? 'ring-danger ring-opacity-50 ring-2'
                  : ''
              "
            />
          </div>
          <div class="no-hover pr-2">
            <decimal-input
              v-model="item.price"
              class="w-full"
              :class="
                showInvalid && !item.price
                  ? 'ring-danger ring-opacity-50 ring-2'
                  : ''
              "
            />
          </div>
          <div class="no-hover pr-2">
            <select v-model="item.unit">
              <option :value="'tk'">tk</option>
              <option :value="'h'">h</option>
              <option :value="'m'">m</option>
              <option :value="'km'">km</option>
              <option :value="'l'">l</option>
              <option :value="'m²'">m²</option>
              <option :value="'m³'">m³</option>
              <option :value="'kg'">kg</option>
              <option :value="'t'">t</option>
              <option :value="'paev'">päev</option>
              <option :value="'kuu'">kuu</option>
            </select>
          </div>
          <div class="no-hover pr-2">
            <span> {{ round2(item.amount * item.price) }}</span>
          </div>
          <div class="no-hover pr-2">
            <select
              v-if="taxTypes"
              v-model="item.taxType"
              class="max-w-full"
              :class="
                showInvalid && !item.taxType
                  ? 'ring-danger ring-opacity-50 ring-2'
                  : ''
              "
            >
              <option
                v-for="taxType in taxTypes.filter((x) => x.outgoing_tax_code)"
                :key="taxType.id"
                :value="taxType"
              >
                {{
                  taxType
                    ? `${taxType.description} (${
                        taxType.tax_percentage ? taxType.tax_percentage : "0"
                      }%)`
                    : "-"
                }}
              </option>
            </select>
          </div>
          <div>
            <span
              >{{ round2(item.total) }}
              {{ currency ? currency.sign : "€" }}</span
            >
          </div>
        </div>
        <div class="grid grid-cols-9 w-full p-2 items-center">
          <div class="col-span-3"></div>
          <div
            v-if="!amountTotalUsed && item.amount === 0"
            class="flex items-center text-sm"
          >
            <button class="btn-primary mx-auto" @click="sumAmount">
              <span class="label">sum</span>
            </button>
            <button class="btn-primary" @click="averageAmount">
              <span class="label">kesk.</span>
            </button>
            <button class="btn-primary mx-auto" @click="maxAmount">
              <span class="label">max</span>
            </button>
          </div>
          <div v-else></div>
          <div
            v-if="!priceTotalUsed && item.price === 0"
            class="flex items-center text-sm"
          >
            <button class="btn-primary mx-auto" @click="sumPrice">
              <span class="label">sum</span>
            </button>
            <button class="btn-primary" @click="averagePrice">
              <span class="label">kesk.</span>
            </button>
            <button class="btn-primary mx-auto" @click="maxPrice">
              <span class="label">max</span>
            </button>
          </div>
          <div v-else></div>
          <div class="col-span-4"></div>
        </div>
        <div
          class="flex flex-col"
          v-if="revenueListsCopy.length > 1 && splitPayments"
          :key="componentKey"
        >
          <div
            v-for="(sepItem, index) in revenueListsCopy"
            :key="index"
            class="flex flex-col"
          >
            <div class="flex mb-1">
              <h3>
                {{ sepItem.payer ? sepItem.payer.name : sepItem.client.name }}
              </h3>
            </div>
            <div class="grid grid-cols-9 w-full items-center p-1">
              <decimal-input
                v-model.number="sepItem.itemObj.percentage"
                class="pr-2"
              />
              <span class="col-span-2">{{ item.service_name }}</span>
              <span>
                {{ round2((sepItem.itemObj.percentage * item.amount) / 100) }}
              </span>
              <span> {{ item.price }} </span>
              <span>{{ item.unit }}</span>
              <span>
                {{
                  round2(
                    ((sepItem.itemObj.percentage * item.amount) / 100) *
                      item.price
                  )
                }}</span
              >
              <span>{{
                item.taxType
                  ? `${
                      item.taxType.tax_percentage
                        ? item.taxType.tax_percentage
                        : "0"
                    }%`
                  : "-"
              }}</span>
              <span>{{
                item.taxType
                  ? round2(
                      ((sepItem.itemObj.percentage * item.amount) / 100) *
                        item.price +
                        (((sepItem.itemObj.percentage * item.amount) / 100) *
                          item.price *
                          item.taxType.tax_percentage) /
                          100
                    )
                  : round2(
                      ((sepItem.itemObj.percentage * item.amount) / 100) *
                        item.price
                    )
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-bottom-bar flex">
        <div class="flex" v-if="!splitPayments && revenueListsCopy.length > 1">
          <button class="btn-primary" @click="splitPayments = true">
            <span class="label">Jaga kulud</span>
          </button>
        </div>
        <div v-if="splitPayments && revenueListsCopy.length > 1" class="flex">
          <button class="btn-primary" @click="splitEven" v-if="!splitUsed">
            <span class="label">Jaga võrdselt</span>
          </button>
        </div>
        <button
          class="mr-3 ml-auto"
          :class="
            !splitPayments
              ? 'btn-primary'
              : percentageHundredCheck
              ? 'btn-primary'
              : 'btn-disabled'
          "
          :key="`second${componentKey}`"
          @click="saveRevenue"
        >
          Salvesta
        </button>
        <button class="btn-danger" @click="closeModal">Sulge</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import { Revenue } from "@/assets/types/Revenue";
import { mapGetters } from "vuex";
import { round2 } from "@/assets/utils/commonMath";
import DecimalInput from "../reusable/DecimalInput.vue";
export default {
  name: "CombinedCostModal",
  props: {
    combinedCosts: {
      type: Array,
      default: () => {
        return [];
      },
    },
    revenueLists: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      item: null,
      itemInited: false,
      showInvalid: false,
      currency: null,
      moment: moment,
      priceTotalUsed: false,
      amountTotalUsed: false,
      splitUsed: false,
      nameUsed: false,
      splitPayments: false,
      revenueListsCopy: [],
      componentKey: 0,
      percentageHundredCheck: false,
    };
  },
  components: { DecimalInput },
  methods: {
    round2,
    closeModal() {
      this.$emit("closeModal");
    },
    formatItem(e) {
      e.amount = round2((this.item.amount * e.percentage) / 100);
      e.price = this.item.price;
      e.taxType = this.item.taxType;
      e.type = this.item.type;
      e.serviceObj = this.item.serviceObj;
      e.service_name = this.item.service_name;
      e.description = this.item.service_name;
      return e;
    },
    saveRevenue() {
      if (this.revenueListsCopy.length > 0 && this.splitPayments) {
        this.revenueListsCopy.map((x) => {
          x.itemObj = this.formatItem(x.itemObj);
        });
        this.$emit("splitRevenue", this.revenueListsCopy);
      } else {
        this.item.description = this.item.service_name;
        this.$emit("revenueSaved", this.item);
      }
      this.closeModal();
    },
    splitEven() {
      const evenPercentage = round2(100 / this.revenueListsCopy.length);
      this.revenueListsCopy.forEach((x) =>
        this.$set(x.itemObj, "percentage", evenPercentage)
      );
      this.componentKey++;
    },
    toggleNameSelected(e) {
      this.item.service_name = e.description;
      this.nameUsed = true;
    },
    sumPrice() {
      if (!this.combinedCosts) return 0;
      this.priceTotalUsed = true;

      this.item.price = round2(
        this.combinedCosts.reduce(
          (prev, curr) =>
            parseFloat(prev) + parseFloat(curr.price ? curr.price : 0),
          0
        )
      );
    },
    averagePrice() {
      if (!this.combinedCosts) return 0;
      this.item.price = round2(
        this.combinedCosts
          .map((x) => parseFloat(x.price))
          .reduce((x, y) => x + y, 0) / this.combinedCosts.length
      );
      this.priceTotalUsed = true;
    },
    maxPrice() {
      if (!this.combinedCosts) return 0;
      this.item.price = round2(
        Math.max(...this.combinedCosts.map((x) => x.price))
      );
      this.priceTotalUsed = true;
    },
    averageAmount() {
      if (!this.combinedCosts) return 0;
      this.item.amount = round2(
        this.combinedCosts
          .map((x) => parseFloat(x.amount))
          .reduce((x, y) => x + y, 0) / this.combinedCosts.length
      );
      this.amountTotalUsed = true;
    },
    sumAmount() {
      if (!this.combinedCosts) return 0;
      this.item.amount = round2(
        this.combinedCosts.reduce(
          (prev, curr) =>
            parseFloat(prev) + parseFloat(curr.amount ? curr.amount : 0),
          0
        )
      );
      this.amountTotalUsed = true;
    },
    maxAmount() {
      if (!this.combinedCosts) return 0;
      this.item.amount = round2(
        Math.max(...this.combinedCosts.map((x) => x.amount))
      );
      this.amountTotalUsed = true;
    },
  },
  mounted() {
    document.body.classList.add("modal-open");
    this.revenueListsCopy = JSON.parse(JSON.stringify(this.revenueLists));
    let vat = this.taxTypes.find((x) => x.accounting_code === 1);
    const serObj = this.serviceObjects.find((x) => x.service_code === "201");
    this.item = new Revenue(
      null,
      serObj,
      0,
      1,
      vat,
      0,
      this.combinedCosts.map((x) => `${x.type}${x.id}`),
      ""
    );
    this.item.service_name = serObj.service_name;
    if (this.revenueListsCopy.length > 1) {
      this.revenueListsCopy.forEach((x) => {
        this.$set(
          x,
          "itemObj",
          new Revenue(
            null,
            serObj,
            0,
            this.item.unit,
            this.item.taxType,
            0,
            this.combinedCosts.map((x) => `${x.type}${x.id}`),
            "",
            0
          )
        );
      });
    }

    this.itemInited = true;
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
  watch: {
    revenueListsCopy: {
      deep: true,
      handler() {
        this.percentageHundredCheck =
          round2(
            this.revenueListsCopy.reduce(
              (prev, curr) => parseFloat(prev) + curr.itemObj.percentage,
              0
            )
          ) -
            100 <
          Number.EPSILON;
      },
    },
  },
  computed: {
    ...mapGetters({
      taxTypes: "companyVariables/taxTypes",
      companyId: "companyData/activeCompanyUuid",
      currencyList: "companyVariables/currencyList",
      serviceObjects: "companyVariables/serviceObjects",
    }),
  },
};
</script>
<style scoped lang="scss"></style>
