var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closeTray),expression:"closeTray"}],staticClass:"navbar-dropdown mobile:w-full",class:_vm.active ? 'navbar-dropdown-open wide ' : 'navbar-dropdown-closed',style:(_vm.active ? { height: '24rem', width: '20rem' } : null)},[_c('transition',{attrs:{"name":"fade","duration":300}},[(_vm.active)?_c('div',{staticClass:"notification-card-container"},[_c('div',{staticClass:"flex gap-x-2 justify-between px-4 py-2 bg-offwhite"},[_c('h4',[_vm._v(_vm._s(_vm.addChat ? "Uus vestlus" : "Vestlused"))]),_c('div',{staticClass:"flex gap-x-2"},[(!_vm.addChat)?_c('button',{staticClass:"btn-primary",on:{"click":_vm.goToChatView}},[_vm._v(" Vaata kõiki ")]):_vm._e(),(_vm.addChat)?_c('button',{class:_vm.selectedWorkers.length > 0 ? 'btn-primary' : 'btn-disabled',on:{"click":_vm.saveNewChat}},[_c('span',{staticClass:"label"},[_vm._v("Loo")])]):_vm._e(),_c('button',{class:_vm.addChat ? 'btn-danger' : 'btn-primary',on:{"click":_vm.toggleAddChat}},[(!_vm.addChat)?_c('span',{staticClass:"typcn typcn-plus icon"}):_c('span',[_vm._v("Tühista")])])])]),(!_vm.parties && !_vm.addChat)?_c('clip-loader'):_vm._e(),_c('div',{staticClass:"flex w-full px-4 pb-2 bg-offwhite"},[_c('search-input',{attrs:{"placeholder":"Otsi","background-color":"white"},model:{value:(_vm.searchBox),callback:function ($$v) {_vm.searchBox=$$v},expression:"searchBox"}})],1),(_vm.parties && !_vm.addChat)?_c('div',{staticClass:"flex flex-col overflow-y-auto"},_vm._l((!_vm.searchBox
            ? _vm.parties
            : _vm.parties.filter(function (x) { return x.title
                  .replace(/\s/g, '')
                  .toLowerCase()
                  .includes(_vm.searchBox.replace(/\s/g, '').toLowerCase()); }
              )),function(party){return _c('chat-card',{key:party.id,ref:"cards",refInFor:true,attrs:{"party":party,"notification-target":_vm.notifications &&
            _vm.notifications.map(function (x) { return Number(x.object_id); }).includes(party.id)},on:{"toggleChatTray":_vm.toggleChatTray,"markRead":_vm.markRead}})}),1):_vm._e(),(_vm.addChat)?_c('div',{staticClass:"w-full flex flex-col justify-start items-center flex-grow bg-white rounded-b gap-y-2"},[(!_vm.companyWorkers)?_c('clip-loader'):_vm._e(),(
            _vm.companyWorkers &&
            _vm.newChatSearchResults &&
            _vm.newChatSearchResults.length === 0
          )?_c('span',[_vm._v(" Ei leidnud töötajaid ")]):_vm._e(),_vm._l((_vm.newChatSearchResults),function(worker){return _c('div',{key:worker.id,staticClass:"flex justify-between items-center w-full px-4 py-2"},[_c('span',{staticClass:"font-medium"},[_vm._v(_vm._s(worker.worker_name))]),_c('button',{class:_vm.selectedWorkers.includes(worker)
                ? 'new-button-danger'
                : 'btn-primary',on:{"click":function($event){return _vm.toggleWorker(worker)}}},[_c('div',{staticClass:"transform duration-150 rounded-gwdfull icon items-center justify-center flex",class:_vm.selectedWorkers.includes(worker) ? 'rotate-45' : ''},[_c('span',{staticClass:"typcn typcn-plus text-xl leading-none transform text-white"})])])])})],2):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }