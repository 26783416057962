<template>
  <div>
    <div class="flex-col">
      <div class="flex flex-col">
        <div class="flex flex-row mb-3 justify-between">
          <div class="flex w-1/2">
            <div
              class="flex flex-col w-full"
              v-if="taskAct.client && Object.keys(taskAct.client).length > 0"
            >
              <h4>{{ taskAct.client.name }}</h4>
              <span class="mb-4">{{
                taskAct.client.is_company ? "Äriklient" : "Eraklient"
              }}</span>
              <client-info-card
                class="flex flex-col mb-4"
                :client="taskAct.client"
              >
              </client-info-card>
              <div class="flex mb-4">
                <button class="new-button-danger" @click="taskAct.client = {}">
                  <span class="typcn typcn-times icon"></span>
                  <span class="label">Tühista valik</span>
                </button>
              </div>
            </div>
            <existing-client-selector
              class="w-full flex"
              v-if="
                !taskAct.client ||
                (taskAct.client && Object.keys(taskAct.client).length === 0)
              "
              @clientUpdated="handleClientUpdate"
            ></existing-client-selector>
          </div>
          <div class="flex flex-col w-1/2 ml-3">
            <div class="flex flex-row">
              <gwd-formlabel title="Kuupäev" class="mr-2"></gwd-formlabel>
              <span>{{ taskAct.date.format("DD.MM.yyyy") }}</span>
            </div>
            <div class="flex flex-row justify-between">
              <gwd-formlabel title="Lähteaadress"></gwd-formlabel>
              <span class="self-end flex">{{ taskAct.start_address }}</span>
            </div>
            <div class="flex flex-row justify-between">
              <gwd-formlabel title="Sihtaadress"></gwd-formlabel>
              <span class="self-end flex">{{ taskAct.end_address }}</span>
            </div>
          </div>
        </div>
        <div class="flex flex-col mb-3">
          <gwd-formlabel title="Teostatud tööd"></gwd-formlabel>
          <div class="table">
            <div class="table-header grid-cols-8">
              <span>Kirjeldus</span>
              <span>Kogus</span>
              <span>Ühikuhind</span>
              <span>Ühik</span>
              <span>Summa</span>
              <span>KM%</span>
              <span>KM</span>
              <span>Kokku</span>
            </div>
            <div
              class="table-row grid-cols-8"
              v-for="cost in taskActCosts"
              :key="`${cost.type}${cost.id}`"
            >
              <span>{{ cost.name }}</span>
              <span>{{ cost.amount }}</span>
              <span>{{ cost.price }}</span>
              <span>{{ cost.unit }}</span>
              <span>{{ cost.amount * cost.price }}</span>
              <span>{{ cost.vat }}</span>
              <span>{{ cost.total_price }}</span>
              <span>{{ cost.total_price }}</span>
            </div>
          </div>
        </div>
        <div class="flex flex-col mb-3">
          <gwd-formlabel title="Lisainfo"></gwd-formlabel>
          <gwd-textarea v-model="taskAct.info"></gwd-textarea>
        </div>
        <div class="flex flex-col">
          <button class="btn-primary mr-3" @click="signatureActive = true">
            Kinnitan et andmed on õiged
          </button>
          <div class="flex flex-col" v-if="signatureActive">
            <div class="flex w-full">
              <vue-drawing-canvas
                ref="canvas"
                class="signatureCanvas"
                :image.sync="actSignature"
                :save-as="'png'"
                :line-width="3"
                :height="512"
                :width="1024"
              ></vue-drawing-canvas>
            </div>
            <div class="flex flex-row">
              <button class="btn-primary mr-3" @click="$refs.canvas.reset()">
                Tühjenda
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-bottom-bar">
      <button @click="saveTaskAct" class="btn-primary mr-3">Salvesta</button>
      <button class="btn-danger" @click="closeModal">Sulge</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ExistingClientSelector from "@/components/project/ExistingClientSelector";
import ClientInfoCard from "@/components/reusable/ClientInfoCard";
import moment from "moment";
import VueDrawingCanvas from "vue-drawing-canvas";

export default {
  name: "AddTaskAct",
  components: { ClientInfoCard, ExistingClientSelector, VueDrawingCanvas },
  data() {
    return {
      taskCostsData: [],
      costs: [],
      costsLoaded: false,
      taskAct: {
        client: null,
        info: "",
        date: null,
        start_address: "",
        end_address: "",
      },
      taskActCosts: [],
      actSignature: null,
      signatureActive: false,
    };
  },
  methods: {
    retrieveTaskCosts() {
      this.apiRequest(
        `costs/${this.task.company}/object/costs/?task=${this.task.task}`,
        "get",
        true
      ).then((res) => {
        this.initActVariables(res.data);
      });
    },
    initActVariables(data) {
      this.taskCostsData = data;
      this.costs = [
        ...data.times,
        ...data.equipment_costs,
        ...data.resource_costs,
        ...data.supplier_costs,
        ...data.extra_worker_payment_costs,
      ];
      this.taskActCosts = [
        ...data.times,
        ...data.equipment_costs,
        ...data.resource_costs,
        ...data.supplier_costs,
        ...data.extra_worker_payment_costs,
      ];
      this.taskAct.info = this.taskData.description;
      this.taskAct.start_address = this.taskData.location
        ? this.taskData.location.long_address
        : "";
      this.taskAct.end_address = this.taskData.end_location
        ? this.taskData.end_location.long_address
        : "";
      this.taskAct.date = moment(this.taskData.start_time);
      if (this.taskData.related_object) {
        this.retrieveClientData(this.taskData.related_object.client_id);
      }
    },
    retrieveClientData(clientId) {
      this.apiRequest(
        `company/${this.task.company}/clients/${clientId}/?compact=true`,
        "get",
        true
      ).then((res) => {
        this.taskAct.client = res.data;
      });
    },
    closeModal() {
      this.$emit("closeModal");
    },
    processData() {
      let data = JSON.parse(JSON.stringify(this.taskAct));
      data.client = this.taskAct.client.id;
      data.date = this.taskAct.date.format("yyyy-MM-DD");
      data.signature = this.actSignature;
      return data;
    },
    saveTaskAct() {
      this.$emit("save", this.processData());
      this.closeModal();
    },
    handleClientUpdate(e) {
      this.taskAct.client = e;
    },
  },
  mounted() {
    this.retrieveTaskCosts();
  },
  computed: {
    ...mapGetters({
      task: "modals/taskModal/data",
      taskData: "modals/taskModal/taskData",
    }),
  },
};
</script>

<style scoped lang="scss">
.signatureCanvas {
  @apply flex;
  border: 1px solid black;
}
</style>
