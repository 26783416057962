var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center gap-x-2 box-border max-w-full cursor-pointer",class:[
    _vm.isFocused ? 'shadow-outline mobile:shadow-none' : '',
    _vm.showAddresses ? 'rounded-t' : 'rounded',
    _vm.showError ? 'shadow-dangeroutline' : '' ]},[(!_vm.selectedLocation)?_c('span',[_c('img',{staticClass:"h-4 w-4",attrs:{"src":"/bc21/search.svg","alt":"Search icon"}}),_c('h4',{staticClass:"hidden mobile:flex"},[_vm._v("Otsi asukohta")])]):_vm._e(),(
      !_vm.selectedLocation ||
      (!_vm.selectedLocation.long_address && !_vm.selectedLocation.ipikkaadress)
    )?_c('div',{staticClass:"flex relative box-border w-full"},[_c('gwd-textarea',{staticClass:"flex-grow",class:[
        _vm.isWhite ? 'bg-white' : '',
        _vm.showAddresses ? 'option-display' : '' ],attrs:{"type":"text","placeholder":_vm.text},on:{"focus":function($event){_vm.isFocused = true},"blur":function($event){_vm.isFocused = false}},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),(_vm.showAddresses)?_c('div',{staticClass:"address-list absolute z-50"},[_vm._l((_vm.foundAddresses),function(address,index){return _c('div',{key:address.tunnus,staticClass:"flex flex-col"},[_c('div',{staticClass:"cursor-pointer px-2 py-3 justify-between flex flex-row items-center hover:bg-offwhite hover:bg-opacity-50",class:index === _vm.foundAddresses.length - 1 ? 'rounded-b-xl' : '',on:{"click":function($event){return _vm.selectLocation(address)}}},[_c('span',{staticClass:"flex w-full"},[_vm._v(_vm._s(address.ipikkaadress))])]),(index !== _vm.foundAddresses.length - 1)?_c('div',{staticClass:"h-1 w-full bg-offwhite rounded-full"}):_vm._e()])}),(_vm.foundAddresses.length === 0)?_c('div',{staticClass:"flex p-2"},[_c('span',[_vm._v(" Selle nimega asukohta ei leitud ")])]):_vm._e()],2):_vm._e()],1):_c('div',{staticClass:"flex hover:bg-offwhite rounded duration-200 py-2 hover:px-2 truncate box-border overflow-x-hidden",on:{"click":function($event){_vm.selectedLocation ? _vm.clearSelectedLocation() : _vm.clearSelection()}}},[_c('span',{staticClass:"box-border flex"},[_vm._v(" "+_vm._s(_vm.selectedLocation ? _vm.selectedLocation.long_address : "-")+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }