<template>
  <div class="modal">
    <div
      class="modal-body modal-medium add-task-modal relative"
      v-on-clickaway:mousedown="closeModal"
    >
      <div class="modal-header p-3">
        <h2>
          {{ planningCost ? "Planeeri hankija kulu" : "Lisa hankija kulu" }}
        </h2>
      </div>

      <div class="modal-content flex-col">
        <div
          class="flex flex-col justify-between items-center mobile:flex-col mb-4"
        >
          <add-supplier-cost
            ref="addSupplierCost"
            @close="closeModal"
            @supplierCostAdded="handleSaveSupplierCost"
            :project="project"
            :planning-cost="planningCost"
          />
        </div>
      </div>
      <div class="modal-bottom-bar">
        <button @click="saveSupplierCost" class="btn-primary mr-3">
          <span class="icon typcn typcn-tick"></span>
          <span class="label">Salvesta</span>
        </button>
        <button class="new-button-danger" @click="closeModal">
          <span class="typcn typcn-times icon"></span>
          <span class="label">Sulge</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import AddSupplierCost from "@/components/company/suppliers/AddSupplierCost";

export default {
  name: "AddProjectSupplierCostModal",
  components: { AddSupplierCost },
  props: {
    project: {
      type: String,
      default: null,
    },
    planningCost: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    saveSupplierCost() {
      this.$refs.addSupplierCost.saveSupplierCost();
    },
    handleSaveSupplierCost() {
      this.$emit("supplierCostAdded");
      this.closeModal();
    },
  },
  mounted() {
    document.body.classList.add("modal-open");
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
};
</script>
