<template>
  <div class="flex flex-col">
    <div class="flex">
      <h4>Varustus ({{ equipment.length }})</h4>
      <spa
        v-if="equipment.length > 0"
        @click="showEquipmentList = !showEquipmentList"
        >ar-d</spa
      >
    </div>
    <div class="flex flex-col" v-if="showEquipmentList && equipment.length > 0">
      <div class="flex" v-for="item in equipment" :key="item.id">
        {{ item }}
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    equipment: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showEquipmentList: false,
    };
  },
};
</script>
<style lang="scss"></style>
