<template>
  <div class="gwd-table">
    <div class="gwd-table-header narrow-cost-list">
      <span>Tüüp</span>
      <span>Kirjeldus</span>
      <span>Kogus</span>
      <span>Hind</span>
      <span class="flex justify-end">
        <button
          class="btn-primary"
          @click="toggleShowAdded"
          v-if="!markAsPaid && !combineCostsActive"
        >
          {{ showAdded ? "Peida" : "Näita" }}
        </button>
      </span>
    </div>
    <div v-for="task in totalCosts" :key="task.id" class="mb-3 border rounded">
      <div
        class="gwd-table-row narrow-task-list items-center cursor-pointer"
        @click="showTaskPlannedActualCosts(task)"
      >
        <span> {{ task.title }}</span>
        <span> {{ moment(task.start_time).format("DD.MM.YYYY") }}</span>
        <div class="flex items-center w-full" v-if="task.payer">
          <span class="flex ml-auto"> Maksja: {{ task.payer.name }} </span>
          <div
            class="flex ml-2"
            v-if="!task.checked"
            @click="addAnotherInvoice(task)"
          >
            <button class="btn-attention">
              <span class="label">Eraldi arve</span>
            </button>
          </div>
        </div>
      </div>
      <template-cost
        v-for="templateType in generateTemplateTypes(task)"
        :key="templateType.id"
        :id="templateType.id"
        :template="templates.find((x) => x.type === templateType.type)"
        :costs="templateType.costs"
        :name-prefix="templateType.name"
        :added-cost-ids="addedCostIds"
        :show-added="showAdded"
        :task="task"
        :combine-costs-active="combineCostsActive"
        :combined-costs="combinedCosts"
        :mark-as-paid="markAsPaid"
        :status="status"
        @addItem="addItem"
        @selectTemplate="selectTemplate"
        @addToCombinedCosts="addToCombinedCosts"
        :type="templateType.type"
        class="flex"
      />
      <div class="flex gwd-table-row narrow-cost-list items-center">
        <span class="col-span-4" />
        <span class="col-span-2 ml-auto pr-2">Töö koguhind:</span>
        <span class="col-span-2">{{ generateTaskTotalCost(task) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import TemplateCost from "@/components/invoices/TemplateCost.vue";
import { round2 } from "@/assets/utils/commonMath";
import set from "lodash/set";

export default {
  name: "TaskCosts",
  components: { TemplateCost },
  props: {
    totalCosts: {
      type: Array,
      default: () => {
        return [];
      },
    },
    templates: {
      type: Array,
      default: () => {
        return [];
      },
    },
    addedCostIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
    combinedCosts: {
      type: Array,
      default: () => {
        return [];
      },
    },
    combineCostsActive: {
      type: Boolean,
      default: false,
    },
    showAdded: {
      type: Boolean,
      default: false,
    },
    markedAsPaid: {
      type: Array,
      default: () => [],
    },
    markAsPaid: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      moment: moment,
    };
  },
  methods: {
    showTaskPlannedActualCosts(e) {
      this.$emit("toggleTaskPlannedActual", e);
    },
    changeTaskToNotChecked(e) {
      let task = this.totalCosts.find((x) => x.id === e);
      console.log(e);
      set(task, "checked", false);
    },
    addAnotherInvoice(e) {
      e.checked = true;
      this.$emit("addAnotherInvoice", {
        payer: e.payer,
        task: e.id,
      });
    },
    toggleShowAdded() {
      this.$emit("toggleShowAdded");
    },
    addItem(e) {
      this.$emit("addItem", e);
    },
    selectTemplate(template, task) {
      this.$emit("selectTemplate", template, task);
    },
    addToCombinedCosts(e) {
      this.$emit("addToCombinedCosts", e);
    },
    addToMarkedAsPaid(e) {
      this.$emit("addToMarkedAsPaid", e);
    },
    generateTaskTotalCost(task) {
      return round2(
        [...task.costs].reduce(
          (prev, curr) => parseFloat(prev) + curr.amount * curr.price,
          0
        )
      );
    },
    generateTemplateTypes(task) {
      let types = [
        {
          id: "resourcecosts",
          costs: task.costs.filter((x) => x.type === "resourceCost"),
          type: 3,
          name: "Ressurss",
        },
        {
          id: "suppliercosts",
          costs: task.costs.filter((x) => x.type === "supplierCost"),
          type: 5,
          name: "Hankija",
        },
        {
          id: "extraCosts",
          costs: task.costs.filter((x) => x.type === "extraCost"),
          type: 6,
          name: "Lisatöö",
        },
      ];
      if (this.templates.find((x) => x.type === 1) !== undefined) {
        types.push({
          id: "axl",
          costs: task.costs.filter(
            (x) => x.type === "workerCost" || x.type === "equipmentCost"
          ),
          type: 1,
          name: "Tehnika + töötaja",
        });
      } else {
        types.push(
          {
            id: "workercosts",
            costs: task.costs.filter((x) => x.type === "workerCost"),
            type: 2,
            name: "Töötaja",
          },
          {
            id: "equipmentcosts",
            costs: task.costs.filter((x) => x.type === "equipmentCost"),
            type: 4,
            name: "Töötaja",
          }
        );
      }
      return types;
    },
  },
};
</script>

<style scoped lang="scss"></style>
