<template>
  <div
    class="flex gwd-formlabel"
    :class="col ? 'flex-col items-start' : 'items-center'"
  >
    <span
      class="p-2 rounded-full shadow mr-2"
      v-if="icon"
      :class="[`text-${iconTextColor}`, `bg-${iconBgColor}`]"
    >
      <img :src="icon" class="h-4 w-4 filter-to-white" alt="Icon" />
    </span>
    <div
      class="header"
      v-html="generateHeader"
      :class="required || error ? 'mr-2' : ''"
    ></div>
    <div class="flex items-center" v-if="required || error">
      <span
        key="a"
        class="required-label"
        :class="
          this.valid
            ? 'bg-primary text-white rounded-full w-6'
            : this.error
            ? 'bg-danger text-white rounded-xl py-1 px-2'
            : 'bg-attention text-black rounded-xl py-1 px-2'
        "
      >
        <span
          class="typcn"
          :class="valid ? 'typcn-tick' : error ? 'typcn-times mr-2' : ''"
        ></span>
        <span v-if="!valid">{{ errorText }}</span>
        <span class="loader" v-if="this.loading"></span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    iconTextColor: {
      type: String,
      default: "white",
    },
    iconBgColor: {
      type: String,
      default: "grey-dark",
    },
    title: {
      type: String,
      default: "",
    },
    required: { type: Boolean, default: false },
    headingLevel: { type: Number, default: 4 },
    valid: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorText: { type: String, default: "Kohustuslik" },
    loading: { type: Boolean, default: false },
    col: { type: Boolean, default: false },
  },
  methods: {},
  computed: {
    generateHeader() {
      return `<h${this.headingLevel}>${this.title}</h${this.headingLevel}>`;
    },
  },
};
</script>

<style lang="scss" scoped>
.required-label {
  @apply h-6 text-xs font-semibold items-center justify-center flex transition duration-150;
}
</style>
