import RevenueGeneratorView from "@/views/company/RevenueGeneratorView";
import SentOutgoingInvoiceView from "@/views/ava/SentOutgoingInvoiceView.vue";

const CalendarView = () => import("@/views/company/CalendarView");

const InvoicesView = () => import("@/views/ava/InvoicesView");
const NewInvoiceView = () => import("@/views/ava/NewInvoiceView");
const InvoiceView = () => import("@/views/ava/InvoiceView");
const InvoiceTemplatesView = () => import("@/views/ava/InvoiceTemplatesView");
const NewInvoiceTemplateView = () =>
  import("@/views/ava/NewInvoiceTemplateView");
const InvoiceTemplateView = () => import("@/views/ava/InvoiceTemplateView");
const NewEInvoicesView = () => import("@/views/ava/NewEInvoicesView");
const NewEInvoiceView = () => import("@/views/ava/NewEInvoiceView");

const WorkersView = () => import("@/views/company/WorkersView");
const WorkerView = () => import("@/views/company/WorkerView");
const NewWorkerView = () => import("@/views/company/NewWorkerView");
const WorkerBrigadesView = () => import("@/views/company/WorkerBrigadesView");
const WorkerAttributesView = () => import("@/views/workers/AttributesView");

const ResourcesView = () => import("@/views/company/ResourcesView");
const ResourceView = () => import("@/views/company/ResourceView");
const NewResourceView = () => import("@/views/company/NewResourceView");

const ProjectsView = () => import("@/views/company/ProjectsView");
const NewProjectView = () => import("@/views/company/NewProjectView");

const SuppliersView = () => import("@/views/company/SuppliersView");
const SupplierView = () => import("@/views/company/SupplierView");
const NewSupplierView = () => import("@/views/company/NewSupplierView");

const CostsView = () => import("@/views/company/CostsView");

const EquipmentListView = () => import("@/views/company/EquipmentListView");
const EquipmentDetailView = () => import("@/views/company/EquipmentDetailView");
const NewEquipmentView = () => import("@/views/company/NewEquipmentView");

const CompanySettingsView = () => import("@/views/company/CompanySettingsView");

const FilesView = () => import("@/views/company/FilesView");
const ClientsView = () => import("@/views/company/ClientsView");
const ClientView = () => import("@/views/company/ClientView");
const NewClient = () => import("@/views/company/NewClientView");
const ChatsView = () => import("@/views/company/ChatsView");

const OutgoingInvoicesView = () =>
  import("@/views/company/OutgoingInvoicesView");
const NewOutgoingInvoiceView = () =>
  import("@/views/company/NewOutgoingInvoiceView");
const NewMultipleOutgoingInvoicesView = () =>
  import("@/views/company/NewMultipleOutgoingInvoicesView.vue");
const OutgoingInvoiceView = () => import("@/views/ava/OutgoingInvoiceView");

const SupplierGroupsView = () => import("@/views/ava/SupplierGroupsView");
const NewSupplierGroupView = () => import("@/views/ava/NewSupplierGroupView");
const SupplierGroupView = () => import("@/views/ava/SupplierGroupView");

const TasksView = () => import("@/views/company/TasksView");

const companyRoutes = [
  {
    name: "Calendar",
    path: "/calendar",
    component: CalendarView,
    meta: {
      public: false,
      sidebar: true,
      title: "Kalender | AVA kolimisrakendus",
    },
  },
  {
    name: "Invoices",
    path: "/invoices/incoming",
    component: InvoicesView,
    meta: {
      public: false,
      sidebar: true,
      title: "Arved | AVA kolimisrakendus",
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "AddInvoice",
    path: "/invoices/incoming/add",
    component: NewInvoiceView,
    meta: {
      public: false,
      sidebar: true,
      title: "Lisa arve | AVA kolimisrakendus",
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "AddOutgoingInvoice",
    path: "/invoices/outgoing/add/",
    component: NewOutgoingInvoiceView,
    meta: {
      public: false,
      sidebar: true,
      title: "Lisa arve | AVA kolimisrakendus",
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "NewMultipleOutgoingInvoicesView",
    path: "/invoices/outgoing/multiple/add/",
    component: NewMultipleOutgoingInvoicesView,
    meta: {
      public: false,
      sidebar: true,
      title: "Lisa arved | AVA kolimisrakendus",
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "InvoiceTemplates",
    path: "/invoices/incoming-templates",
    component: InvoiceTemplatesView,
    meta: {
      public: false,
      sidebar: true,
      title: "Ostuarvete mallid | AVA kolimisrakendus",
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "NewInvoiceTemplate`",
    path: "/invoices/incoming-templates/new",
    component: NewInvoiceTemplateView,
    meta: {
      public: false,
      sidebar: true,
      title: "Ostuarvete mallid | AVA kolimisrakendus",
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "NewEInvoices",
    path: "/Invoices/einvoices",
    component: NewEInvoicesView,
    meta: {
      public: false,
      sidebar: true,
      title: "Uued e-arved | AVA kolimisrakendus",
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "NewEInvoice",
    path: "/Invoices/einvoices/:invoiceId",
    component: NewEInvoiceView,
    meta: {
      public: false,
      sidebar: true,
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "OutgoingInvoicesView",
    path: "/invoices/outgoing",
    component: OutgoingInvoicesView,
    meta: {
      public: false,
      title: "Väljaminevad arved | AVA kolimisrakendus",
      sidebar: true,
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "Invoice",
    path: "/invoices/incoming/:invoiceId",
    component: InvoiceView,
    meta: {
      public: false,
      sidebar: true,
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "SentOutgoingInvoice",
    path: "/invoices/outgoing/confirmed/:invoiceId",
    component: SentOutgoingInvoiceView,
    meta: {
      public: false,
      sidebar: true,
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "InvoiceTemplate",
    path: "/invoices/incoming-templates/:templateId",
    component: InvoiceTemplateView,
    meta: {
      public: false,
      sidebar: true,
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    path: "/workers",
    name: "Workers",
    component: WorkersView,
    meta: {
      public: false,
      title: "Töötajad | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/workers/new",
    name: "NewWorker",
    component: NewWorkerView,
    meta: {
      public: false,
      title: "Lisa uus töötaja | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/workers/brigades",
    name: "WorkerBrigades",
    component: WorkerBrigadesView,
    meta: {
      public: false,
      title: "Brigaadid | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/workers/attributes",
    name: "WorkerAttributes",
    component: WorkerAttributesView,
    meta: {
      public: false,
      title: "Oskused | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/workers/:id",
    name: "Worker",
    component: WorkerView,
    meta: {
      public: false,
      title: "Töötaja | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/resources",
    name: "Resources",
    component: ResourcesView,
    meta: {
      public: false,
      title: "Ressurrsid | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/resources/new",
    name: "NewResource",
    component: NewResourceView,
    meta: {
      public: false,
      title: "Lisa uus ressurss | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/resources/:id",
    name: "Resource",
    component: ResourceView,
    meta: {
      public: false,
      title: "Ressurss | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/projects",
    name: "Projects",
    component: ProjectsView,
    meta: {
      public: false,
      title: "Tellimused | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/projects/new",
    name: "NewProject",
    component: NewProjectView,
    meta: {
      public: false,
      title: "Uus tellimus | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/suppliers",
    name: "Suppliers",
    component: SuppliersView,
    meta: {
      public: false,
      title: "Hankijad | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/suppliers/new",
    name: "NewSupplier",
    component: NewSupplierView,
    meta: {
      public: false,
      title: "Uus hankija | AVA kolimisrakendus",
      sidebar: true,
    },
  },

  {
    path: "/suppliers/groups/",
    name: "SupplierGroups",
    component: SupplierGroupsView,
    meta: {
      public: false,
      title: "Hankija grupid | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/suppliers/groups/new/",
    name: "NewSupplierGroup",
    component: NewSupplierGroupView,
    meta: {
      public: false,
      title: "Uus hankijate grupp | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/suppliers/groups/:groupId",
    name: "SupplierGroupView",
    component: SupplierGroupView,
    meta: {
      public: false,
      title: "Hankijate grupp | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/suppliers/:id",
    name: "Supplier",
    component: SupplierView,
    meta: {
      public: false,
      title: "Hankija | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/costs",
    name: "Costs",
    component: CostsView,
    meta: {
      public: false,
      title: "Kulud | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/equipment",
    name: "EquipmentList",
    component: EquipmentListView,
    meta: {
      public: false,
      title: "Tehnika | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/equipment/new",
    name: "NewEquipment",
    component: NewEquipmentView,
    meta: {
      public: false,
      title: "Uus tehnika | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/equipment/:id",
    name: "EquipmentDetail",
    component: EquipmentDetailView,
    meta: {
      public: false,
      title: "Tehnika | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/settings",
    name: "CompanySettings",
    component: CompanySettingsView,
    meta: {
      public: false,
      title: "Seaded | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/files",
    name: "Documents",
    component: FilesView,
    meta: {
      public: false,
      title: "Failid | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/clients",
    name: "Clients",
    component: ClientsView,
    meta: {
      public: false,
      title: "Kliendid | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/clients/new",
    name: "NewClient",
    component: NewClient,
    meta: {
      public: false,
      title: "Lisa klient | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/clients/:clientId",
    name: "Client",
    component: ClientView,
    meta: {
      public: false,
      title: "Klient | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/invoices/outgoing/:invoiceId",
    name: "OutgoingInvoice",
    component: OutgoingInvoiceView,
    meta: {
      public: false,
      title: "Väljaminev arve | AVA kolimisrakendus",
      sidebar: true,
      accountingPermissions: ["R0", "R1", "R2", "R0"],
    },
  },
  {
    path: "/invoices/generator/:projectId",
    name: "RevenueGeneratorView",
    component: RevenueGeneratorView,
    meta: {
      public: false,
      title: "Väljaminev arve | AVA kolimisrakendus",
      sidebar: false,
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    path: "/chats",
    name: "ChatsView",
    component: ChatsView,
    meta: {
      public: false,
      title: "Vestlused | AVA Kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/tasks",
    name: "TasksView",
    component: TasksView,
    meta: {
      public: false,
      title: "Tööd | AVA Kolimisrakendus",
      sidebar: true,
    },
  },
];

export default companyRoutes;
