<template>
  <div class="flex flex-col h-full">
    <div class="data-container h-full">
      <div class="data-container-header">
        <h4>Kulud</h4>
      </div>
      <div class="data-container-content nopad h-full">
        <div class="flex w-full h-full mobile:flex-col">
          <div class="assignment-sidebar">
            <div
              v-if="canSeeFull"
              class="assignment-sidebar-item"
              :class="activeView === 1 ? 'active' : ''"
              @click="activeView = 1"
            >
              <h4>Koondvaade</h4>
            </div>
            <div
              class="assignment-sidebar-item"
              :class="activeView === 2 ? 'active' : ''"
              @click="activeView = 2"
            >
              <h4>Ressursid</h4>
            </div>
            <div
              v-if="canSeeFull"
              class="assignment-sidebar-item"
              :class="activeView === 3 ? 'active' : ''"
              @click="activeView = 3"
            >
              <h4>Tehnika</h4>
            </div>
            <div
              class="assignment-sidebar-item"
              :class="activeView === 4 ? 'active' : ''"
              @click="activeView = 4"
            >
              <h4>Lisatööd</h4>
            </div>
            <div
              v-if="canSeeFull"
              class="assignment-sidebar-item"
              :class="activeView === 5 ? 'active' : ''"
              @click="activeView = 5"
            >
              <h4>Hankijakulud</h4>
            </div>
            <div
              class="assignment-sidebar-item hidden"
              :class="activeView === 6 ? 'active' : ''"
              @click="activeView = 6"
            >
              <h4>Aktid</h4>
            </div>
          </div>
          <div class="flex flex-col rest-of-fucking-modal">
            <task-cost-overview v-if="activeView === 1" :task-id="task.id" />
            <task-resource-assignments
              v-if="activeView === 2"
              :task-id="parseFloat(task.id)"
            />
            <task-equipment-assignments
              v-if="activeView === 3"
              :task-id="task.id"
            />
            <task-extra-cost-assignments v-if="activeView === 4" :task="task" />
            <task-supplier-assignments
              v-if="activeView === 5"
              :task-id="task.id"
            />
            <task-acts v-if="activeView === 6" class="flex" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskActs from "@/components/tasks/TaskActs";
import TaskResourceAssignments from "@/components/tasks/costs/TaskResourceAssignments.vue";
import TaskEquipmentAssignments from "@/components/tasks/costs/TaskEquipmentAssignments.vue";
import TaskExtraCostAssignments from "@/components/tasks/costs/TaskExtraCostAssignments.vue";
import TaskSupplierAssignments from "@/components/tasks/costs/TaskSupplierAssignments.vue";
import TaskCostOverview from "@/components/tasks/costs/TaskCostOverview.vue";
import { mapGetters } from "vuex";

export default {
  name: "TaskAssignments",
  components: {
    TaskCostOverview,
    TaskExtraCostAssignments,
    TaskResourceAssignments,
    TaskEquipmentAssignments,
    TaskSupplierAssignments,

    TaskActs,
  },
  props: {
    task: {
      type: Object,
      default: () => {
        return {};
      },
    },
    seeItems: { type: Boolean, default: true },
  },
  data() {
    return {
      activeView: null,
    };
  },
  mounted() {
    if (this.canSeeFull) this.activeView = 1;
    else this.activeView = 2;
  },
  computed: {
    ...mapGetters({
      userPermission: "companyData/userPermission",
    }),
    canSeeFull() {
      if (!this.userPermission) return false;
      return ["R0", "R1", "R2", "R3"].includes(this.userPermission);
    },
  },
};
</script>

<style lang="scss" scoped>
.assignment-sidebar {
  @apply flex flex-col w-48 border-r border-offwhite-dark;
  .assignment-sidebar-item {
    @apply p-2;
    &:last-child {
      @apply rounded-bl;
    }
    &.active {
      @apply bg-primary text-white;
    }
  }
}
.rest-of-fucking-modal {
  width: calc(100% - 10%);
  @media (max-width: 992px) {
    width: 100%;
  }
}
@media (max-width: 992px) {
  .assignment-sidebar {
    @apply w-full border-none;
  }
}
</style>
