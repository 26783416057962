var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal"},[_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway:mousedown",value:(_vm.closeModal),expression:"closeModal",arg:"mousedown"}],staticClass:"modal-body"},[_c('div',{staticClass:"flex modal-content"},[_c('l-map',{ref:"map",staticClass:"rounded-xl flex flex-grow h-64",attrs:{"options":_vm.mapOptions,"interactive":false}},[(_vm.mapInited)?_c('l-tile-layer',{attrs:{"url":_vm.tileUrl,"attribution":'© OpenStreetMap contributors',"position":'bottomleft'}}):_vm._e(),(
            _vm.mapInited &&
            _vm.startLocation &&
            Object.keys(_vm.startLocation).length > 0
          )?_c('l-circle',{attrs:{"lat-lng":[_vm.startLocation.latitude, _vm.startLocation.longitude],"radius":Number(_vm.startLocation.accuracy),"color":'#23c7ac'}},[_c('l-tooltip',{attrs:{"content":'Algus',"options":{ permanent: true, direction: 'left' }}})],1):_vm._e(),(
            _vm.mapInited && _vm.endLocation && Object.keys(_vm.endLocation).length > 0
          )?_c('l-circle',{attrs:{"lat-lng":[_vm.endLocation.latitude, _vm.endLocation.longitude],"radius":Number(_vm.endLocation.accuracy),"color":'#3600cd'}},[_c('l-tooltip',{attrs:{"content":'Lõpp',"options":{ permanent: true, direction: 'right' }}})],1):_vm._e(),(
            _vm.mapInited && _vm.taskLocation && Object.keys(_vm.taskLocation).length > 0
          )?_c('l-marker',{attrs:{"lat-lng":[_vm.taskLocation.latitude, _vm.taskLocation.longitude]}},[_c('l-tooltip',{attrs:{"content":'Tellimuse asukoht',"options":{ permanent: true }}}),_c('l-icon',{attrs:{"icon-url":"/bc21/placeholder.svg"}})],1):_vm._e()],1)],1),_c('div',{staticClass:"modal-bottom-bar"},[_c('button',{staticClass:"new-button-danger",on:{"click":_vm.closeModal}},[_c('span',{staticClass:"typcn typcn-times icon"}),_c('span',{staticClass:"label"},[_vm._v("Sulge")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }