import Axios from "axios";
import store from "../../store";
import router from "../../router";

export default {
  apiRequest(endpoint, method, isAuthed, data) {
    let instance = Axios.create({
      baseURL: process.env.VUE_APP_API_URL,
    });

    instance.interceptors.response.use(
      (res) => {
        return res;
      },
      (err) => {
        if (processError(err).response?.status === 401) {
          if (store.state.auth.status.refreshQueue.length === 0) {
            return new Promise((resolve, reject) => {
              const handleError = (error) => {
                logout();
                reject(error);
              };

              store
                .dispatch("auth/refreshToken")
                .then(
                  (res) => {
                    err.config.headers[
                      "Authorization"
                    ] = `Bearer ${res.data.access}`;
                    resolve(instance.request(err.config));
                  },
                  (error) => {
                    handleError(error);
                  }
                )
                .catch((error) => {
                  handleError(error);
                });
            });
          } else {
            return new Promise((resolve, reject) => {
              const promise = store.state.auth.status.refreshQueue[0];

              promise
                .then((res) => {
                  err.config.headers[
                    "Authorization"
                  ] = `Bearer ${res.data.access}`;
                  resolve(instance.request(err.config));
                })
                .catch((err) => {
                  if (store.state.auth.status.loggedIn) {
                    logout();
                  }
                  reject(err);
                });
            });
          }
        } else {
          return Promise.reject(err);
        }
      }
    );
    if (isAuthed === true) {
      instance.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("access");
    }

    let options = {
      method: method,
      url: endpoint,
    };

    if (data) {
      options.data = data;
    }

    return instance(options);
  },
};

const logout = () => {
  store.dispatch("auth/logout");
  router.push("/login");
};

const processError = (error) => {
  if (error.response) return error;
  return { error };
};
