<template>
  <div class="skeleton-content">
    <div class="pane">
      <div class="loading-shine"></div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.skeleton-content {
  @apply w-full;
  .pane {
    @apply h-8 bg-grey rounded;
    .loading-shine {
      @apply h-full w-4 relative rounded;
      background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 0) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(0, 212, 255, 0) 100%
      );
      animation: skeleton-loading 1s;
      animation-iteration-count: infinite;
    }
  }
}

@keyframes skeleton-loading {
  0% {
    left: 0px;
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 0) 0%,
      rgba(255, 255, 255, 100) 50%,
      rgba(0, 212, 255, 0) 100%
    );
  }
  80% {
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 0) 0%,
      rgba(255, 255, 255, 100) 50%,
      rgba(0, 212, 255, 0) 100%
    );
  }
  100% {
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 0) 0%,
      rgba(255, 255, 255, 0) 50%,
      rgba(0, 212, 255, 0) 100%
    );
    left: 100%;
  }
}
</style>
