<template>
  <div class="flex items-center">
    <span
      class="p-2 rounded-full shadow mr-2"
      :class="[`text-${textColor}`, `bg-${bgColor}`]"
    >
      <img :src="icon" class="h-4 w-4 filter-to-white" alt="Icon" />
    </span>
    <h4><slot></slot></h4>
  </div>
</template>
<script>
export default {
  props: {
    textColor: {
      type: String,
      default: "white",
    },
    bgColor: {
      type: String,
      default: "grey-dark",
    },
    icon: {
      type: String,
      default: "/bc21/project.svg",
    },
  },
  computed: {},
};
</script>
