<template>
  <div
    class="flex items-center bg-offwhite rounded px-2 py-1 gap-x-2"
    v-if="timerInited && !$store.state.timetracking.loading"
  >
    <div class="flex flex-col">
      <h4>Taimer</h4>
    </div>
    <div class="flex flex-col">
      <span
        @click="openTaskModal"
        class="hover:text-primary cursor-pointer rounded"
        >{{ timerObject.title }}</span
      >
    </div>
    <div
      class="bg-primary rounded flex font-medium items-center gap-x-2 px-2 w-32 py-1 justify-center"
    >
      <img
        src="/new/time.svg"
        class="h-5 w-5 filter-to-white"
        alt="Timer logo"
      />
      <span class="text-white"> {{ timer }}</span>
    </div>
    <div>
      <button
        class="btn-danger"
        @click="$store.dispatch('timetracking/stopTimer')"
      >
        <span>Lõpeta</span>
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      timer: null,
      timerInterval: null,
      timerObject: null,
      timerInited: false,
    };
  },
  mounted() {
    if (this.$store.state.timetracking.hasActiveTimer) {
      this.initTimer();
    }
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
    this.timerInterval = null;
    this.timer = null;
    this.timerInited = false;
  },
  methods: {
    initTimer() {
      this.timerObject = this.$store.state.timetracking.timer;
      this.timer = moment
        .utc(
          moment
            .duration(
              moment().diff(
                moment(this.$store.state.timetracking.timer.stopper_start)
              )
            )
            .asMilliseconds()
        )
        .format("HH:mm:ss");
      this.timerInterval = setInterval(() => {
        this.timer = moment
          .utc(
            moment
              .duration(
                moment().diff(
                  moment(this.$store.state.timetracking.timer.stopper_start)
                )
              )
              .asMilliseconds()
          )
          .format("HH:mm:ss");
      }, 1000);
      this.timerInited = true;
    },
    openTaskModal() {
      this.$store.dispatch("modals/taskModal/open", {
        task: this.timerObject.task_id,
      });
    },
  },
  watch: {
    "$store.state.timetracking.hasActiveTimer": {
      handler(val) {
        if (val && !this.timerInterval) {
          this.initTimer();
        } else {
          clearInterval(this.timerInterval);
          this.timerInterval = null;
          this.timer = null;
          this.timerInited = false;
        }
      },
    },
  },
};
</script>
