<template>
  <div class="flex flex-col gap-y-4">
    <div class="flex flex-col gap-y-2">
      <gwd-formlabel
        title="Algus"
        :required="true"
        :valid="!$v.timeObj.startDate.$invalid"
        :error="$v.timeObj.startDate.$error"
      />
      <div class="flex">
        <date-selector
          class="add-task-date-selector w-full"
          :class="[$v.$error && $v.timeObj.startDate.$invalid ? 'error' : '']"
          v-model="timeObj.startDate"
          background-color="offwhite"
          :shadow="true"
          :padded="true"
          pos="r"
        />
      </div>
      <div class="flex">
        <time-selector v-model="timeObj.startTime" pos="r" />
      </div>
    </div>
    <div class="flex flex-col gap-y-2">
      <gwd-formlabel
        title="Lõpp"
        :required="true"
        :valid="!$v.timeObj.endDate.$invalid"
        :error="$v.timeObj.endDate.$error"
        class="mr-2 w-1/3"
      />
      <div class="flex">
        <date-selector
          class="add-task-date-selector w-full"
          :class="[$v.$error && $v.timeObj.endDate.$invalid ? 'error' : '']"
          v-model="timeObj.endDate"
          background-color="offwhite"
          :shadow="true"
          :padded="true"
          pos="r"
        />
      </div>
      <div class="flex">
        <time-selector class="justify-end" v-model="timeObj.endTime" pos="r" />
      </div>
    </div>
    <div class="flex flex-col gap-y-2">
      <gwd-formlabel title="Põhjus"></gwd-formlabel>
      <gwd-textarea v-model="reason"></gwd-textarea>
    </div>
  </div>
</template>

<script>
import { ee } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import DateSelector from "@/components/globals/DateSelector.vue";
import { mapGetters } from "vuex";

import { required } from "vuelidate/lib/validators";
import TimeSelector from "@/components/reusable/TimeSelector";
export default {
  name: "AddEquipmentUnavailabilityForm",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: { TimeSelector, DateSelector },
  data() {
    return {
      ee: ee,
      moment: moment,
      timesInited: false,
      timeObj: {
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        isAllDay: false,
      },
      reason: "",
    };
  },
  methods: {
    initTimes() {
      if (this.data.date) {
        this.timeObj.startDate = moment(this.data.date);
        this.timeObj.endDate = moment(this.data.date).add(1, "day");
      }
      this.timeObj.startTime = {
        hour: moment().add(1, "hours").hour(),
        minute: moment().add(1, "hours").minute(),
      };
      this.timeObj.endTime = {
        hour: moment().add(2, "hours").hour(),
        minute: moment().add(2, "hours").minute(),
      };
      this.timesInited = true;
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    handleStartTimeChange(e) {
      this.timeObj.startTime = e;
    },
    handleEndTimeChange(e) {
      this.timeObj.endTime = e;
    },
    saveUnavailability() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = {
          start_time: this.startTimeFormatted,
          end_time: this.endTimeFormatted,
          reason: this.reason,
        };
        this.apiRequest(
          "template/" +
            this.companyId +
            "/equipment/" +
            this.data.equipment.id +
            "/unavailability/create/",
          "post",
          true,
          data
        ).then((res) => {
          if (res.status === 201) {
            this.$emit("unavailabilityAdded");
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
    }),
    disabledDates() {
      const threshold = new Date();
      threshold.setDate(threshold.getDate() - 1);
      return { to: threshold };
    },
    startTimeFormatted() {
      return moment(this.timeObj.startDate)
        .hour(this.timeObj.startTime.hour)
        .minute(this.timeObj.startTime.minute)
        .format("YYYY-MM-DD[T]HH:mm:ssZ");
    },
    endTimeFormatted() {
      return moment(this.timeObj.endDate)
        .hour(this.timeObj.endTime.hour)
        .minute(this.timeObj.endTime.minute)
        .format("YYYY-MM-DD[T]HH:mm:ssZ");
    },
  },
  mounted() {
    this.initTimes();
    this.$nextTick(() => {
      if (this.startDate) this.timeObj.startDate = this.startDate;
    });
  },
  validations: {
    timeObj: {
      startDate: { required },
      endDate: { required },
    },
  },
};
</script>
