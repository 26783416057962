<template>
  <div class="gwd-form">
    <div class="mb-3" v-if="success" data-cy="success-message">
      Registreerimine õnnestus!
    </div>
    <h3 class="self-center">Ühenda töötaja konto</h3>
    <div class="gwd-form-group w-full">
      <gwd-formlabel
        title="Meiliaadress"
        :required="true"
        :valid="!$v.user.email.$invalid"
        :error="!$v.user.email.email"
        :error-text="
          !$v.user.email.required
            ? 'Kohustuslik'
            : !$v.user.email.email
            ? 'Ei vasta nõuetele'
            : ''
        "
      />
      <input type="email" class="w-full" v-model="user.email" data-cy="email" />
    </div>
    <div class="gwd-form-hor-group w-full gap-x-2">
      <div
        class="flex flex-col items-start flex-1 mobile:w-full mobile:mr-0 mobile:mb-5"
      >
        <gwd-formlabel
          title="Eesnimi"
          :required="true"
          :valid="!$v.user.first_name.$invalid"
        />
        <input
          type="text"
          class="w-full"
          v-model="user.first_name"
          data-cy="first-name"
        />
      </div>
      <div class="flex flex-col items-start flex-1 mobile:w-full mobile:ml-0">
        <gwd-formlabel
          title="Perenimi"
          :required="true"
          :valid="!$v.user.last_name.$invalid"
        />
        <input
          type="text"
          class="w-full"
          v-model="user.last_name"
          data-cy="last-name"
        />
      </div>
    </div>
    <div class="gwd-form-group w-full">
      <gwd-formlabel
        title="Salasõna"
        :required="true"
        :valid="!$v.user.password.$invalid"
        :error-text="
          !$v.user.password.required
            ? 'Kohustuslik'
            : !$v.user.password.minLength
            ? 'Salasõna peab olema vähemalt 8 tähemärki pikk'
            : ''
        "
      />
      <input
        type="password"
        class="w-full"
        v-model="user.password"
        data-cy="password"
      />
    </div>
    <div class="gwd-form-group w-full">
      <gwd-formlabel
        title="Kinnita salasõna"
        :required="true"
        :valid="!$v.user.password2.$invalid"
        :error="$v.user.password2.required && !$v.user.password2.sameAsPassword"
        :error-text="
          !$v.user.password2.required
            ? 'Kohustuslik'
            : !$v.user.password2.sameAsPassword
            ? 'Salasõnad ei klapi'
            : ''
        "
      />
      <input
        type="password"
        class="w-full"
        v-model="user.password2"
        data-cy="password"
      />
    </div>
    <button
      v-if="success === false"
      @click="createUser(user)"
      data-cy="submit"
      class="self-center h-8 w-32"
      :class="$v.$invalid ? 'btn-disabled' : 'btn-primary'"
      :disabled="$v.$invalid"
    >
      Registreeri
    </button>
  </div>
</template>

<script>
import { RequestHandler } from "@/assets/mixins/GewodoMixins";
import jwtdecode from "jwt-decode";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
export default {
  name: "RegistrationFormWorkerJoin",
  mixins: [RequestHandler],
  data() {
    return {
      user: {
        email: "",
        first_name: "",
        last_name: "",
        password: "",
        password2: "",
        token: "",
      },
      success: false,
      error: false,
      errorText: "",
    };
  },
  methods: {
    createUser(user) {
      if (!this.$route.query.token) {
        this.$store.dispatch("messageHandler/throwMessage", {
          type: "error",
          text: "Registreerimistoken puudub",
          ttl: 10,
        });
        return;
      }
      this.$v.$touch();
      if (this.$v.$error) {
        if (!this.$v.user.email.email)
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            text: "Meiliaadress ei vasta nõuetele",
            ttl: 10,
          });
        if (!this.$v.user.password.minLength)
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            text: "Salasõna ei ole piisavalt pikk",
            ttl: 10,
          });
        return;
      }
      if (this.user.password !== this.user.password2) {
        this.$store.dispatch("messageHandler/throwMessage", {
          type: "error",
          text: "Salasõnad ei klapi",
          ttl: 10,
        });
        return;
      }

      this.$store.state.loading = true;
      user.email = user.email.toLowerCase();
      user.token = this.$route.query.token;
      this.$store.dispatch("auth/createUser", { user }).then(
        (res) => {
          if (res.status === 201) {
            this.$store.state.loading = false;
            this.apiRequest(
              `/company/worker/merge/${
                jwtdecode(this.$store.state.auth.user).user_id
              }/`,
              "post",
              true,
              { token: this.$route.query.token }
            ).then((res) => {
              if (res.status === 200) this.$router.push("/calendar");
            });
          }
        },
        (err) => {
          this.error = true;
          if (err.response)
            switch (err.response.status) {
              case 400:
                this.$store.dispatch("messageHandler/throwMessage", {
                  type: "error",
                  text: "E-posti aadress on juba kasutusel",
                  ttl: 10,
                });
                break;
              case 403:
                this.$store.dispatch("messageHandler/throwMessage", {
                  type: "error",
                  text: "Kasutaja loomise kood on juba kasutatud!",
                  ttl: 10,
                });
                break;
              case 406:
                this.$store.dispatch("messageHandler/throwMessage", {
                  type: "error",
                  text: "Parool on nimega liiga sarnane",
                  ttl: 10,
                });
                break;
              case 409:
                this.$store.dispatch("messageHandler/throwMessage", {
                  type: "error",
                  text: "E-posti aadress on juba kasutusel",
                  ttl: 10,
                });
                break;
              case 500:
                this.$store.dispatch("messageHandler/throwMessage", {
                  type: "error",
                  text: "Serverierror, sa ei tohiks siin olla",
                  ttl: 10,
                });
                break;
              default:
                this.$store.dispatch("messageHandler/throwMessage", {
                  type: "error",
                  text: "Midagi läks valesti",
                  ttl: 10,
                });
            }
          else
            this.$store.dispatch("messageHandler/throwMessage", {
              type: "error",
              text: "Midagi läks valesti",
              ttl: 10,
            });

          this.$store.state.loading = false;
        }
      );
    },
  },
  validations: {
    user: {
      email: { required, email },
      first_name: { required },
      last_name: { required },
      password: { required, minLength: minLength(8) },
      password2: { required, sameAsPassword: sameAs("password") },
    },
  },
};
</script>
