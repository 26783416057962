var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"wrapper",staticClass:"flex justify-start items-center relative w-auto flex-grow max-w-full",class:("w-" + _vm.width),attrs:{"data-is-time-input":_vm.key}},[((_vm.hour || _vm.hour === 0) && (_vm.minute || _vm.minute === 0))?_c('div',{staticClass:"flex items-center w-full max-w-full bg-offwhite rounded h-8 shadow focus-within:ring-2 ring-primary ring-opacity-25 duration-150",attrs:{"data-is-time-input":_vm.key}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.timeInput),expression:"timeInput"},{name:"maska",rawName:"v-maska"}],ref:"timeInput",staticClass:"h-full focus:ring-0 duration-100 shadow-none p-0 w-full max-w-full time-selector-input px-1",attrs:{"data-maska":"SS:TS","data-maska-tokens":"S:[0-9]|T:[0-5]","data-is-time-input":_vm.key},domProps:{"value":(_vm.timeInput)},on:{"focus":function () {
          _vm.showMenu = true;
          _vm.selectMinute = false;
        },"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.showMenu = false},"input":function($event){if($event.target.composing){ return; }_vm.timeInput=$event.target.value}}})]):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in","duration":150}},[(_vm.showMenu)?_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.handleClickAway),expression:"handleClickAway"}],staticClass:"time-selector-menu",style:(_vm.posComputed),attrs:{"data-is-time-input":_vm.key}},[_c('div',{staticClass:"flex"},[_c('button',{staticClass:"btn-danger",on:{"click":function () {
              _vm.showMenu = false;
              _vm.selectMinute = false;
            }}},[_vm._v(" x ")])]),(_vm.selectMinute)?_c('div',{key:"minute",staticClass:"time-selector-element mb-4"},[_c('div',{staticClass:"time-selector-foreground"},_vm._l((_vm.minuteSelector),function(item){return _c('div',{key:("minute-" + item),staticClass:"outer",class:_vm.minute === item ? 'selected' : '',on:{"click":function () {
                _vm.setTimeFromProp(_vm.hour, item);
                _vm.showMenu = false;
              }}},[_vm._v(" "+_vm._s(_vm.formatTimeUnit(item))+" ")])}),0),_c('div',{staticClass:"time-selector-background"},[_c('div',{staticClass:"center"})])]):_c('div',{key:"hour",staticClass:"time-selector-element mb-4"},[_c('div',{staticClass:"time-selector-foreground"},[_vm._l((_vm.hourSelectorOuter),function(item){return _c('div',{key:("hour-" + item),staticClass:"outer",class:_vm.hour === item ? 'selected' : '',on:{"click":function () {
                _vm.blockCloseMenu = true;
                _vm.setTimeFromProp(item, _vm.minute);
                _vm.selectMinute = true;
              }}},[_vm._v(" "+_vm._s(_vm.formatTimeUnit(item))+" ")])}),_vm._l((_vm.hourSelectorInner),function(item){return _c('div',{key:("hour-" + item),staticClass:"inner",class:_vm.hour === item ? 'selected' : '',on:{"click":function () {
                _vm.blockCloseMenu = true;
                _vm.setTimeFromProp(item, _vm.minute);
                _vm.selectMinute = true;
              }}},[_vm._v(" "+_vm._s(_vm.formatTimeUnit(item))+" ")])})],2),_c('div',{staticClass:"time-selector-background"},[_c('div',{staticClass:"center"})])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }