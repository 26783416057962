<template>
  <div>
    <div class="flex flex-row gap-x-2">
      <div class="data-container col-3">
        <div class="data-container-header">
          <h3>Üldinfo</h3>
        </div>
        <div class="data-container-content gwd-form">
          <div class="gwd-form-hor-group w-full">
            <gwd-formlabel
              :title="client.is_company ? 'Ettevõtte nimi' : 'Nimi'"
              :required="true"
              :valid="!$v.client.name.$invalid"
              :error="$v.client.name.$error"
              class="w-5/6"
            />
            <h4 class="w-1/6 ml-auto justify-end flex">Ettevõte</h4>
          </div>
          <div class="gwd-form-hor-group w-full">
            <div class="w-5/6">
              <input
                class="add-project-input"
                :class="[
                  $v.$error && $v.client.name.$invalid
                    ? 'shadow-dangeroutline'
                    : '',
                  Object.keys(existingClient).length > 0 ? 'disabled' : '',
                ]"
                :disabled="Object.keys(existingClient).length > 0"
                v-model="client.name"
                @input="debounceSearch"
              />
            </div>
            <div class="w-1/6 ml-auto justify-end">
              <gwd-checkbox
                class="flex ml-auto"
                v-model="client.is_company"
                @input="handleIsCompanyInput"
              />
            </div>
          </div>
          <div class="gwd-form-group" v-if="client.is_company">
            <gwd-formlabel
              title="Registrikood"
              :required="['EST', 'Eesti', null, ''].includes(client.country)"
              :valid="
                ['EST', 'Eesti', null, ''].includes(client.country) &&
                client.register_code.length > 0
              "
              :error="
                (['EST', 'Eesti', null, ''].includes(client.country) &&
                  !client.register_code) ||
                (['EST', 'Eesti', null, ''].includes(client.country) &&
                  client.register_code.length === 0)
              "
              :error-text="'Kohustuslik'"
            />
            <input
              v-model="client.register_code"
              :class="
                $v.client.register_code.$invalid ? 'shadow-dangeroutline' : ''
              "
              @input="debounceSearch"
            />
          </div>
          <div
            class="gwd-form-group"
            v-if="
              (companyRegistrySearchPending || foundCompanies.length > 0) &&
              client.is_company
            "
          >
            <div class="gwd-table">
              <div class="gwd-table-header">Otsingutulemused</div>
              <div v-if="companyRegistrySearchPending">
                <SkeletonContent />
              </div>
              <div class="gwd-table-row-wrapper max-h-80">
                <div
                  v-for="company in foundCompanies"
                  :key="company.ariregistri_kood"
                  class="p-1 gwd-table-row grid-cols-4 cursor-pointer"
                  @click="selectCompany(company)"
                >
                  <div>{{ company.nimi }}</div>
                  <div>{{ company.ariregistri_kood }}</div>
                  <div>{{ company.kmkr_nr }}</div>
                  <div>{{ company.ads_normaliseeritud_taisaadress }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="gwd-form-group">
            <gwd-formlabel title="Alias" />
            <input type="text" v-model="client.alias" />
          </div>

          <div class="gwd-form-group" v-if="client.is_company">
            <gwd-formlabel title="KMRK kood" />
            <input
              class="add-project-input"
              :class="Object.keys(existingClient).length > 0 ? 'disabled' : ''"
              v-model="client.tax_code"
              :disabled="Object.keys(existingClient).length > 0"
            />
          </div>
          <div class="gwd-form-group">
            <gwd-formlabel title="Telefoni number" />
            <input class="mb-4" v-model="phoneNumberInput" />
          </div>
          <div class="gwd-form-group">
            <gwd-formlabel title="Meiliaadress (semikoolon ';' mitme jaoks)" />
            <input class="mb-4" v-model="client.email" />
          </div>
          <div class="gwd-form-group">
            <gwd-formlabel title="Aadress" />
            <div
              class="flex flex-row items-center w-full"
              v-if="!client.location && project && project.location"
            >
              <div class="ml-2 items-center flex">
                <span class="flex mr-2">Sama, mis tellimusel</span>
                <input
                  type="checkbox"
                  class="flex ml-2 shadow-none focus:shadow-none"
                  @click="client.location = project.location"
                />
              </div>
            </div>
            <location-inaddress
              v-if="!client.location"
              v-model="client.location"
              :text="
                client.is_company
                  ? 'Otsi ettevõtte aadressi'
                  : 'Otsi kliendi aadressi'
              "
              :emit-standardized-object="true"
            />
            <div v-if="client.location" class="flex items-center w-full">
              <span class="flex-grow">{{ client.location.long_address }}</span>
              <span
                class="bg-danger p-2 rounded-full"
                @click="client.location = null"
              >
                <img
                  src="/bc21/trash.svg"
                  alt="Delete icon"
                  class="h-5 filter-to-white"
                />
              </span>
            </div>
            <div class="gwd-form-group">
              <gwd-formlabel title="Riik" />
              <select-search
                :dataset="countryList"
                :filter-func="filterCountries"
                :value-to-select="'code'"
                v-model="client.country"
                :label="(item) => item.name"
                v-if="!client.country"
                class="w-full"
              />
              <div class="flex w-full items-center justify-between" v-else>
                <span>{{
                  selectedCountry
                    ? `${selectedCountry.name} (${selectedCountry.code})`
                    : "-"
                }}</span>
                <button class="btn-primary" @click="client.country = null">
                  Muuda
                </button>
              </div>
            </div>
            <div class="gwd-form-group">
              <gwd-formlabel title="Keel" />
              <input v-model="client.language" />
            </div>
            <div class="gwd-form-group">
              <gwd-formlabel title="Kommentaar" />
              <input v-model="client.comment" />
            </div>
            <div class="gwd-form-group">
              <gwd-formlabel title="Hoiatus" />
              <input v-model="client.warning" />
            </div>
          </div>
        </div>
      </div>
      <div class="data-container col-3">
        <div class="data-container-header">
          <h3>Arvelduse info</h3>
        </div>
        <div class="data-container-content gwd-form">
          <div class="gwd-form-hor-group">
            <gwd-formlabel title="Saab arveid esitada" class="flex-1" />
            <div class="flex-1">
              <gwd-checkbox v-model="client.can_pay" />
            </div>
          </div>
          <div class="gwd-form-group">
            <gwd-formlabel title="Eraldi maksja" class="flex-1" />
            <div class="flex flex-1" v-if="client.payer">
              <span>{{ client.payer.name }}</span>
              <button class="btn-danger ml-auto" @click="client.payer = null">
                <span class="label">Muuda</span>
              </button>
            </div>
            <div v-else class="flex-1">
              <button class="btn-primary" @click="selectSeparatePayer = true">
                <span class="label">Vali maksja</span>
              </button>
            </div>
          </div>
          <div class="gwd-form-group">
            <gwd-formlabel title="Raamatupidamise info" />
            <gwd-textarea v-model="client.contact_name" :background="true" />
          </div>
          <div class="gwd-form-hor-group">
            <div class="flex-1">
              <gwd-formlabel title="Eksisteeriv AVA ID" />
            </div>
            <div class="flex-1">
              <input v-model="client.client_id" />
            </div>
          </div>
          <div class="gwd-form-hor-group">
            <div class="flex-1">
              <gwd-formlabel title="Valuuta" />
            </div>
            <div class="flex-1">
              <select v-model="client.currency">
                <option
                  v-for="currency in currencies"
                  :key="currency.id"
                  :value="currency.code"
                >
                  {{ `${currency.code} (${currency.sign})` }}
                </option>
              </select>
            </div>
          </div>
          <div class="gwd-form-hor-group">
            <div class="flex-1">
              <gwd-formlabel title="Vaikimisi maksetähtaeg" />
            </div>
            <div class="flex-1">
              <input type="number" step="1" v-model="client.payment_term" />
            </div>
          </div>
          <div class="gwd-form-hor-group">
            <div class="flex-1">
              <gwd-formlabel title="Viitenumber" />
            </div>
            <div class="flex-1">
              <input
                type="number"
                v-model.number="client.reference_number"
                @keydown="isNumber"
              />
            </div>
          </div>
          <div class="gwd-form-hor-group">
            <div class="flex-1">
              <gwd-formlabel title="Info arve koostamisel" />
            </div>
            <div class="flex-1">
              <gwd-textarea
                v-model="client.invoice_information_text"
                :max-length="512"
                :background="true"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="data-container col-3">
        <div class="data-container-header">
          <h3>Kontaktid</h3>
        </div>
        <div class="data-container-content">
          <new-client-contact-form
            v-if="contactDetails"
            @close="contactDetails = false"
            @save="saveClientContact"
          />
          <div v-if="clientContacts.length > 0" class="flex flex-col w-full">
            <div class="flex flex-col mb-2" v-if="!contactDetails">
              <div
                v-for="(contact, index) in clientContacts"
                :key="contact.name + contact.email"
                class="flex"
              >
                <div class="flex w-5/12 flex-col">
                  <span class="flex"> Nimi: {{ contact.name }}</span>
                  <span class="flex"> Nimetus: {{ contact.role }}</span>
                </div>
                <div class="flex w-5/12 flex-col">
                  <span v-if="contact.telephone_number.length > 0" class="flex">
                    {{ countryCode }}
                    {{ contact.telephone_number.substring(4) }}</span
                  >
                  <span class="flex"> Meiliaadress: {{ contact.email }}</span>
                </div>
                <div class="flex w-2/12 flex-col items-center">
                  <button class="btn-danger" @click="removeContact(index)">
                    <span class="label">Eemalda</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="flex" v-if="!contactDetails">
            <button class="btn-primary" @click="contactDetails = true">
              <span class="label">Lisa</span>
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-col" v-if="client.can_pay && false">
        <div class="flex flex-col">
          <gwd-formlabel title="Maksja nimi" />
          <input
            class="add-project-input"
            :class="[Object.keys(existingClient).length > 0 ? 'disabled' : '']"
            :disabled="Object.keys(existingClient).length > 0"
            v-model="client.pay_to_name"
          />
        </div>
        <div class="flex flex-col">
          <gwd-formlabel title="Pangakonto andmed" />
          <input
            class="add-project-input"
            :class="[Object.keys(existingClient).length > 0 ? 'disabled' : '']"
            :disabled="Object.keys(existingClient).length > 0"
            v-model="client.iban_code"
          />
        </div>
      </div>
    </div>
    <client-selector-modal
      v-if="selectSeparatePayer"
      :select-client="false"
      @closeModal="selectSeparatePayer = false"
      @clientSelected="changePayer"
    />
  </div>
</template>

<script>
import NewClientContactForm from "@/components/project/NewClientContactForm.vue";
import LocationInaddress from "@/components/reusable/LocationInaddress";
import { email, required, requiredIf } from "vuelidate/lib/validators";
import SelectSearch from "@/components/reusable/SelectSearch.vue";
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import SkeletonContent from "../../reusable/SkeletonContent.vue";
import ClientSelectorModal from "@/components/project/ClientSelectorModal.vue";
import Axios from "axios";
import { normalizeLocationObject } from "@/assets/utils/commonTransforms";

export default {
  components: {
    ClientSelectorModal,
    SelectSearch,
    NewClientContactForm,
    LocationInaddress,
    SkeletonContent,
  },
  props: {
    existingClient: {
      type: Object,
      default: () => {
        return {};
      },
    },
    project: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      client: {
        name: null,
        alias: null,
        email: null,
        telephone_number: null,
        location: null,
        register_code: "",
        tax_code: null,
        is_company: false,
        existing: false,
        can_pay: true,
        country: null,
        payer: null,
        contact_name: null,
        payment_term: null,
        currency: null,
        comment: null,
        warning: null,
        language: null,
        reference_number: null,
      },
      phoneNumberInput: "",
      contactDetails: false,
      companyRegistrySearchPending: false,
      clientContacts: [],
      isCompany: false,
      countryCode: "+372",
      foundCompanies: [],
      selectSeparatePayer: false,
    };
  },
  mounted() {
    this.$store.dispatch("companyVariables/retrieveCurrencies");
  },
  methods: {
    isNumber(e) {
      if (
        e.ctrlKey &&
        [65, 67, 86, 88, 35, 36, 37, 38, 39].includes(e.keyCode)
      ) {
        return;
      }
      const allowedKeys = [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "0",
        "Backspace",
        " ",
      ];
      if (!allowedKeys.includes(e.key)) {
        e.preventDefault();
      }
    },
    changePayer(e) {
      this.client.payer = e;
      this.selectSeparatePayer = false;
    },
    selectCompany(e) {
      this.client.name = e.nimi;
      this.client.register_code = e.ariregistri_kood.toString();
      this.client.tax_code = e.kmkr_nr;
      this.client.country = "EST";
      if (e.ads_adr_id && this.hasInAadressScript)
        this.retrieveAddressById(e.ads_adr_id);
      this.foundCompanies = [];
    },
    retrieveAddressById(addressId) {
      let axiosInstance = Axios.create({
        baseURL: process.env.VUE_APP_API_URL,
      });
      let options = {
        method: "get",
        url: `https://inaadress.maaamet.ee/inaadress/gazetteer?adrid=${addressId}`,
      };
      axiosInstance(options)
        .then((res) => {
          if (Array.isArray(res.data.addresses)) {
            this.client.location = normalizeLocationObject(
              res.data.addresses[0]
            );
          }
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("messageHandler/throwMessage", {
            text: "Aadressipäring ebaõnnestus",
            type: "error",
            ttl: 15,
          });
        });
    },
    assignPhoneNumber() {
      this.client.telephone_number = this.fullPhoneNumber;
    },
    saveClientContact(contact) {
      this.clientContacts.push(contact);
      this.$emit("clientContactsUpdated", this.clientContacts);
    },
    removeContact(index) {
      this.clientContacts.splice(index, 1);
      this.$emit("clientContactsUpdated", this.clientContacts);
    },
    filterCountries(country, query) {
      return (
        country.name.toLowerCase().includes(query.toLowerCase()) ||
        country.code.toLowerCase().includes(query.toLowerCase())
      );
    },
    searchCompany() {
      this.companyRegistrySearchPending = true;
      let type = 18;
      let search = this.client.name;
      if (this.client.register_code && this.client.register_code.length > 0) {
        type = 2;
        search = this.client.register_code;
      }
      this.apiRequest(
        `core/companies/search/?name=${search}&type=${type}`,
        "get",
        true
      )
        .then((res) => {
          this.companyRegistrySearchPending = false;
          this.foundCompanies = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("messageHandler/throwMessage", {
            text: "Ettevõtte otsing ebaõnnestus",
            type: "error",
            ttl: 15,
          });
        });
    },
    debounceSearch: debounce(function () {
      if (this.client.is_company) {
        this.searchCompany();
      }
    }, 500),
    handleIsCompanyInput(e) {
      if (e && this.client.name) this.searchCompany();
    },
  },
  validations() {
    let validationObj = {
      client: {
        name: { required },
        register_code: {
          required: requiredIf(() => {
            return ["EST", "Eesti", null].includes(this.client.country);
          }),
        },
      },
    };

    if (this.client.email) {
      validationObj.client.email = { email };
    }

    if (
      this.client.telephone_number !== null &&
      this.client.telephone_number.length > 0
    ) {
      validationObj.client.telephone_number = { required };
    }

    return validationObj;
  },
  computed: {
    ...mapGetters({
      countryList: "companyVariables/countryList",
      currencies: "companyVariables/currencyList",
    }),
    fullPhoneNumber() {
      return this.countryCode + this.phoneNumberInput.replace(" ", "");
    },
    selectedCountry() {
      if (this.client.country)
        return this.countryList.find((x) => x.code === this.client.country);
      return null;
    },
    hasInAadressScript() {
      return !!document.head.querySelector(
        "script[src='https://inaadress.maaamet.ee/inaadress/js/inaadress.min.js']"
      );
    },
  },
  watch: {
    client: {
      deep: true,
      handler() {
        this.$v.$touch();
        this.$emit("clientUpdated", this.client);
      },
    },
    phoneNumberInput() {
      this.client.telephone_number = this.phoneNumberInput;
    },
    contactDetails() {
      this.$emit("editingContact", this.contactDetails);
    },
  },
};
</script>
