export const invoice = {
  state: {
    revenueList: [],
    addedCostsList: [],
    projectId: null,
    payer: null,
    client: null,
    invoice: null,
    revenuesArray: [],
    invoiceData: null,
  },
  namespaced: true,
  mutations: {
    setInvoiceCopyData(state, data) {
      state.revenueList = data.revenues ?? [];
      state.projectId = data.projectId ?? null;
      state.payer = data.payer ?? null;
      state.client = data.client ?? null;
      state.invoiceData = data.invoiceData ?? null;
    },
    setData(state, data) {
      state.revenueList = data.revenues ?? [];
      state.addedCostsList = data.addedCosts ?? [];
      state.projectId = data.projectId ?? null;
      state.payer = data.payer ?? null;
      state.client = data.client ?? null;
      state.invoice = data.invoice ?? null;
    },
    setMultipleData(state, data) {
      state.projectId = data.projectId ?? null;
      state.revenuesArray = data.revenuesArray ?? [];
    },
    clear(state) {
      state.revenueList = [];
      state.addedCostsList = [];
      state.projectId = null;
      state.payer = null;
      state.client = null;
      state.invoice = null;
      state.revenuesArray = [];
      state.invoiceData = null;
    },
  },
  actions: {
    setData({ commit }, data) {
      commit("setData", data);
    },
    setInvoiceCopyData({ commit }, data) {
      commit("setInvoiceCopyData", data);
    },
    setMultipleData({ commit }, data) {
      commit("setMultipleData", data);
    },
    clear({ commit }) {
      commit("clear");
    },
  },
};
