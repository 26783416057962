<template>
  <div
    class="flex bg-offwhite rounded shadow py-1 px-2 mobile:flex-wrap"
    :class="stacked ? 'flex-col' : ' gap-x-4'"
  >
    <div
      class="flex items-center mobile:w-full gap-x-2"
      :class="stacked ? (dateFilter ? 'mb-2' : '') : ''"
    >
      <div class="flex mobile:w-1/2">
        <h4 class="mr-3">Ajafilter</h4>
        <gwd-checkbox v-model="dateFilter" size="6" />
      </div>
      <div class="flex mobile:w-1/2">
        <dropdown-menu>
          <template #title> Vali periood </template>
          <template #menu-content>
            <div
              @click="
                setFilter(moment().startOf('week'), moment().endOf('week'))
              "
              class="px-2 py-1 hover:bg-offwhite duration-100 cursor-pointer"
            >
              See nädal
            </div>
            <div
              @click="
                setFilter(moment().startOf('month'), moment().endOf('month'))
              "
              class="px-2 py-1 hover:bg-offwhite duration-100 cursor-pointer"
            >
              See kuu
            </div>
            <div
              @click="
                setFilter(
                  moment().subtract(1, 'month').startOf('month'),
                  moment().subtract(1, 'month').endOf('month')
                )
              "
              class="px-2 py-1 hover:bg-offwhite duration-100 cursor-pointer"
            >
              Eelmine kuu
            </div>
            <div
              @click="setFilter(moment().subtract(30, 'days'), moment())"
              class="px-2 py-1 hover:bg-offwhite duration-100 cursor-pointer"
            >
              30p
            </div>
            <div
              @click="setFilter(moment().subtract(90, 'days'), moment())"
              class="px-2 py-1 hover:bg-offwhite duration-100 cursor-pointer"
            >
              90p
            </div>
            <div
              @click="
                setFilter(moment().startOf('year'), moment().endOf('year'))
              "
              class="px-2 py-1 hover:bg-offwhite duration-100 cursor-pointer"
            >
              See aasta
            </div>
          </template>
        </dropdown-menu>
      </div>
    </div>
    <div
      class="flex py-1 mobile:w-full gap-x-2 gap-y-1"
      :class="stacked ? 'border-t border-offwhite-dark' : ''"
      v-if="dateFilter"
    >
      <div
        class="flex items-center mobile:items-start mobile:pr-0 mobile:justify-start mobile:flex-col mobile:w-full"
      >
        <span class="mobile:mb-1 flex">Algusaeg</span>
        <date-picker
          class="date-picker-white flex mobile:w-full"
          :language="ee"
          :format="formatDate"
          :monday-first="true"
          v-model="startDate"
        />
      </div>
      <div
        class="flex items-center mobile:items-start mobile:pr-0 mobile:justify-start mobile:flex-col mobile:mb-4 mobile:w-full"
      >
        <span class="mobile:mb-1 flex">Lõpp</span>
        <date-picker
          class="date-picker-white flex mobile:w-full"
          :language="ee"
          :format="formatDate"
          :monday-first="true"
          v-model="endDate"
        />
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { ee } from "vuejs-datepicker/dist/locale";
import DatePicker from "vuejs-datepicker";
import DropdownMenu from "./DropdownMenu";
export default {
  components: { DatePicker, DropdownMenu },
  props: {
    showCurrentMonth: {
      type: Boolean,
      default: false,
    },
    stacked: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      startDate: moment().toDate(),
      endDate: moment().add(1, "month").toDate(),
      dateFilter: false,
      moment: moment,
      ee: ee,
    };
  },
  mounted() {
    if (this.showCurrentMonth) {
      this.setFilter(moment().startOf("month"), moment().endOf("month"));
      this.$emit("stateChanged", this.state);
    } else {
      this.$emit("stateChanged", this.state);
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    setFilter(start, end) {
      if (end && !start) this.startDate = end.subtract(1, "week").toDate();
      else this.startDate = start.toDate();
      if (!end) this.endDate = start.add(1, "week");
      else this.endDate = end.toDate();
      this.dateFilter = true;
      this.$emit("rangeChanged", { start: this.startDate, end: this.endDate });
    },
  },
  computed: {
    state() {
      return {
        filter: this.dateFilter,
        startDate: this.startDate,
        endDate: this.endDate,
      };
    },
  },
  watch: {
    state() {
      this.$emit("stateChanged", this.state);
    },
  },
};
</script>
