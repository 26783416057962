<template>
  <div
    class="flex border-2 justify-center items-center rounded duration-100 cursor-pointer"
    :class="[
      value && !hovered ? 'bg-primary border-primary' : '',
      value && hovered ? 'bg-danger border-danger' : '',
      !value && hovered ? 'bg-offwhite border-primary mobile:bg-white' : '',
      !value && !hovered ? 'border-primary' : '',
      `h-${size} w-${size}`,
    ]"
    @click="toggle"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
  >
    <span
      class="typcn text-xl leading-none"
      :class="
        value
          ? 'text-white typcn-tick'
          : hovered
          ? 'text-primary typcn-tick'
          : 'text-white'
      "
    ></span>
  </div>
</template>

<script>
export default {
  name: "CustomCheckbox",
  props: {
    value: Boolean,
    size: {
      type: String,
      default: "8",
    },
  },
  data() {
    return {
      hovered: false,
    };
  },
  methods: {
    toggle() {
      this.$emit("input", !this.value);
    },
  },
};
</script>
