<template>
  <div class="progress-bar" :class="[`bg-${background}`]" ref="progbar">
    <div
      class="progress-line"
      v-if="percentage > 0"
      :class="[`bg-${bar}`]"
      :style="{ width: `${percentage}%` }"
    />
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      default: 0,
    },
    background: {
      type: String,
      default: "white",
    },
    bar: {
      type: String,
      default: "primary",
    },
  },
  data() {
    return {
      lineWidth: 0,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.progress-bar {
  @apply flex rounded border border-offwhite-dark;
  .progress-line {
    @apply h-full;
    border-radius: calc(0.25rem - 1px);
  }
}
</style>
