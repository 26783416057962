var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col w-full mb-2 bg-offwhite rounded shadow"},[_c('div',{staticClass:"flex w-full items-center hover:bg-offwhite-dark p-1 cursor-pointer rounded-t bg-offwhite-dark"},[_vm._l((_vm.comb.workers),function(worker){return _c('div',{key:worker.id,staticClass:"mr-2 flex bg-white rounded shadow"},[_vm._m(0,true),_c('div',{staticClass:"px-2 py-1"},[_c('h4',[_vm._v(_vm._s(worker.worker_name))])])])}),(_vm.selectedSlots.length > 0)?_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex bg-white shadow rounded mr-2 items-center"},[_vm._m(1),_c('div',{staticClass:"py-1 px-2"},[_vm._v(" "+_vm._s(((_vm.moment(_vm.selectedSlots[0].start).format("HH:mm")) + " - " + (_vm.moment( _vm.selectedSlots[_vm.selectedSlots.length - 1].end ).format("HH:mm"))))+" ")])]),_c('button',{staticClass:"btn-primary",on:{"click":_vm.confirmSelectedTime}},[_vm._v("Vali")])]):_vm._e()],2),_c('div',{staticClass:"flex flex-col p-2"},[_c('div',{staticClass:"flex mb-1"},[_c('div',{staticClass:"flex w-full items-center"},[_c('div',{staticClass:"flex flex-grow bg-offwhite h-6"},_vm._l((_vm.dayMap),function(slot){return _c('div',{key:slot.i,staticClass:"time-slot group",class:[
              slot.busy
                ? 'busy'
                : slot.selected
                ? 'selected'
                : slot.unavailable
                ? 'unavailable'
                : '',
              slot.hovered && !slot.busy ? 'hovered' : '' ],on:{"click":function($event){!slot.busy && !slot.unavailable ? _vm.selectSlot(slot) : function () {}},"mouseenter":function($event){return _vm.handleSlotHover(slot.i)},"mouseleave":function($event){return _vm.handleSlotLeave(slot.i)}}},[_c('div',{staticClass:"time-slot-tooltip hidden group-hover:flex"},[_c('span',[_vm._v(_vm._s(slot.start.format("HH:mm")))])])])}),0)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-primary text-white rounded-l flex items-center justify-center p-1"},[_c('span',{staticClass:"typcn typcn-user h-6 w-6 text-center leading-none text-xl"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex bg-primary rounded-l h-full p-1"},[_c('img',{staticClass:"h-6 w-6 filter-to-white",attrs:{"src":"/new/time.svg","alt":"Clock icon"}})])}]

export { render, staticRenderFns }