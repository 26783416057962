import moment from "moment";

const monthNames = [
  {
    loc: "ee",
    names: [
      "Jaanuar",
      "Veebruar",
      "Märts",
      "Aprill",
      "Mai",
      "Juuni",
      "Juuli",
      "August",
      "September",
      "Oktoober",
      "November",
      "Detsember",
    ],
  },
];

export default {
  methods: {
    generateMonth(year, month) {
      let monthArray = [];
      let offset = moment().year(year).month(month).date(1).weekday();
      let lastMonthNumber = month === 0 ? 11 : month - 1;
      let nextMonthNumber = month === 11 ? 0 : month + 1;

      const lastMonthLength = moment()
        .year(year)
        .month(lastMonthNumber)
        .date(1)
        .daysInMonth();

      for (let i = 0; i < offset; i++) {
        monthArray.push(
          this.generateDay(
            monthArray.length,
            lastMonthLength - (offset - (i + 1)),
            lastMonthNumber,
            month === 0 ? year - 1 : year,
            false,
            true
          )
        );
      }

      for (let i = 0; i < moment().year(year).month(month).daysInMonth(); i++) {
        monthArray.push(
          this.generateDay(monthArray.length, i + 1, month, year)
        );
      }

      let cap = monthArray.length > 35 ? 42 : 35;
      if (monthArray.length > cap - 7 && monthArray.length < cap) {
        let offsetRem = cap - monthArray.length;
        for (let i = 0; i < offsetRem; i++) {
          monthArray.push(
            this.generateDay(
              monthArray.length,
              i + 1,
              nextMonthNumber,
              month === 11 ? year + 1 : year,
              false,
              true
            )
          );
        }
      }

      return monthArray;
    },
    generateDay(
      index,
      date,
      month,
      year,
      disabled = false,
      isOffset = false,
      tasks = true
    ) {
      const day = {};
      day.id = index;
      day.date = date;
      day.month = month;
      day.year = year;
      day.fullDate = moment().year(year).month(month).date(date);
      day.dayOfWeek = moment(day.fullDate).weekday();
      if (tasks) day.tasks = [];
      day.isOffset = isOffset;
      day.disabled = disabled;
      return day;
    },

    getMonthName(index) {
      return monthNames.find((x) => x.loc === "ee").names[index];
    },
  },
};
