<template>
  <div class="flex flex-col">
    <div
      class="flex items-center mb-2 gap-x-2 bg-offwhite rounded px-2 py-1 shadow"
    >
      <div class="flex">
        <h3 class="mr-3">Tulemused</h3>
        <button class="btn-danger" @click="reset">Tagasi</button>
      </div>
      <div class="flex">
        <span class="bg-white rounded p-2 shadow">{{
          moment(parameters.startDate).format("DD.MM.YYYY")
        }}</span>
        <span class="bg-white rounded p-2 ml-2 shadow">
          {{ parameters.duration }}h
        </span>
        <span
          class="bg-white rounded p-2 ml-2 shadow"
          v-if="
            parameters.startLocation && parameters.startLocation.long_address
          "
          >{{ parameters.startLocation.long_address }}</span
        >
        <span
          class="bg-white rounded p-2 ml-2 shadow"
          v-if="parameters.endLocation && parameters.endLocation.long_address"
          >{{ parameters.endLocation.long_address }}</span
        >
        <span class="bg-white rounded p-2 ml-2 shadow">{{
          `${parameters.worker_amount} töötaja${
            parameters.worker_amount > 0 && "t"
          }`
        }}</span>
        <span
          v-for="(item, index) in parameters.attributes.filter(
            (x) => x.length > 0
          )"
          :key="index"
          class="bg-offwhite ml-2 p-2 rounded shadow"
        >
          <span v-for="attribute in item" :key="attribute.id">
            {{ attribute.name }}
          </span>
        </span>
      </div>
    </div>
    <div class="flex flex-col">
      <span v-if="results && results.length === 0">
        Tulemusi vastavale päringule pole
      </span>
      <div class="flex flex-col" v-if="results && results.length > 0">
        <planning-card
          v-for="(comb, index) in results"
          :key="index"
          ref="combinations"
          :comb="comb"
          :duration="parameters.duration"
        />
      </div>
    </div>
  </div>
</template>
<script>
import PlanningCard from "@/components/tasks/planning/PlanningCard";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      moment: moment,
    };
  },
  components: {
    PlanningCard,
  },
  methods: {
    reset() {
      this.$store.dispatch("modals/taskPlanningModal/resetAvailabilityData");
    },
  },
  computed: {
    ...mapGetters({
      results: "modals/taskPlanningModal/results",
      parameters: "modals/taskPlanningModal/parameters",
    }),
  },
};
</script>
