<template>
  <div class="flex flex-col gap-y-1">
    <div
      v-for="equipment in equipmentList.results"
      :key="equipment.id"
      class="flex w-full bg-offwhite rounded shadow p-1"
    >
      <div class="flex flex-wrap flex-1 gap-x-2">
        <span>{{ equipment.name }}</span>
      </div>
      <button
        :class="
          selectedEquipment.map((x) => x.id).includes(equipment.id)
            ? 'btn-disabled'
            : 'btn-primary'
        "
        @click="selectEquipment(equipment)"
      >
        Vali
      </button>
    </div>
    <page-changer
      :page="page"
      :count="equipmentList.count"
      v-if="equipmentList.count > 1"
      @pageChange="handlePageChange"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PageChanger from "@/components/reusable/PageChanger.vue";
import { generateParamString } from "@/assets/utils/commonTransforms";

export default {
  name: "TaskEquipmentSelector",
  components: { PageChanger },
  props: {
    selectedEquipment: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      page: 1,
      equipmentList: [],
    };
  },
  methods: {
    loadEquipment() {
      this.apiRequest(
        `template/${this.companyId}/equipment/${generateParamString({
          page: this.page,
        })}`,
        "get",
        true
      ).then((res) => {
        this.equipmentList = res.data;
      });
    },
    selectEquipment(equipment) {
      this.$emit("selectEquipment", equipment);
    },
    handlePageChange(e) {
      this.page = e;
      this.loadEquipment();
    },
  },
  mounted() {
    this.loadEquipment();
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      internalEquipmentList: "modals/addTaskModal/equipmentList",
    }),
  },
};
</script>
