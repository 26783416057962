var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex relative rounded"},[_c('textarea',{ref:"textarea",staticClass:"gwd-textarea",class:[
      _vm.error || !_vm.maxLengthCheck
        ? 'border-2 !border-danger border-opacity-50 px-1 focus:border-opacity-0'
        : '',
      _vm.heading ? 'text-2xl' : '',
      _vm.borderless ? 'borderless' : null,
      _vm.background
        ? 'bg-offwhite px-1 shadow'
        : 'bg-transparent hover:bg-white focus:bg-white' ],attrs:{"placeholder":_vm.placeholder,"rows":"1","maxlength":_vm.maxLength},domProps:{"value":_vm.value},on:{"input":_vm.handleInput}}),_vm._v(" "),(_vm.maxLength)?_c('span',{staticClass:"max-length-text",class:[_vm.maxLengthTextColor]},[_vm._v(_vm._s(_vm.value.length)+"/"+_vm._s(_vm.maxLength))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }