<template>
  <div
    class="timer-bar"
    v-if="timerInited && !$store.state.timetracking.loading"
  >
    <div class="flex flex-col items-center" @click="openTaskModal">
      <div class="flex">
        <h4>{{ timerObject.title }}</h4>
      </div>
      <div class="flex">
        <div class="flex flex-col">
          {{ timer }}
        </div>
      </div>
    </div>
    <div class="flex ml-auto justify-center px-1">
      <button
        class="h-12 w-12 bg-danger text-white flex items-center justify-center"
        @click="$store.dispatch('timetracking/stopTimer')"
      >
        <img src="/icons/stop.svg" class="h-6 w-6" alt="Stop icon" />
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      timer: null,
      timerInterval: null,
      timerObject: null,
      timerInited: false,
    };
  },
  mounted() {
    if (this.$store.state.timetracking.hasActiveTimer) {
      this.initTimer();
    }
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
    this.timerInterval = null;
    this.timer = null;
    this.timerInited = false;
  },
  methods: {
    initTimer() {
      this.timerObject = this.$store.state.timetracking.timer;
      this.timer = moment
        .utc(
          moment
            .duration(
              moment().diff(
                moment(this.$store.state.timetracking.timer.stopper_start)
              )
            )
            .asMilliseconds()
        )
        .format("HH:mm:ss");
      this.timerInterval = setInterval(() => {
        this.timer = moment
          .utc(
            moment
              .duration(
                moment().diff(
                  moment(this.$store.state.timetracking.timer.stopper_start)
                )
              )
              .asMilliseconds()
          )
          .format("HH:mm:ss");
      }, 1000);
      this.timerInited = true;
    },
    openTaskModal() {
      this.$store.dispatch("modals/taskModal/open", {
        task: this.timerObject.task_id,
      });
    },
  },
  watch: {
    "$store.state.timetracking.hasActiveTimer": {
      handler(val) {
        if (val && !this.timerInterval) {
          this.initTimer();
        } else {
          clearInterval(this.timerInterval);
          this.timerInterval = null;
          this.timer = null;
          this.timerInited = false;
        }
      },
    },
  },
};
</script>

<style lang="scss">
.timer-bar {
  @apply bg-offwhite flex h-20 p-2 items-center shadow;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
}
</style>
