export const countryList = [
  { name: "Afghanistan", code: "AFG" },
  { name: "Ahvenamaa", code: "ALA" },
  { name: "Albaania", code: "ALB" },
  { name: "Alžeeria", code: "DZA" },
  { name: "Ameerika Samoa", code: "ASM" },
  { name: "Andorra", code: "AND" },
  { name: "Angola", code: "AGO" },
  { name: "Anguilla", code: "AIA" },
  { name: "Antarktis", code: "ATA" },
  { name: "Antigua ja Barbuda", code: "ATG" },
  { name: "Argentina", code: "ARG" },
  { name: "Armeenia", code: "ARM" },
  { name: "Aruba", code: "ABW" },
  { name: "Austraalia", code: "AUS" },
  { name: "Austria", code: "AUT" },
  { name: "Aserbaidžaan", code: "AZE" },
  { name: "Bahama", code: "BHS" },
  { name: "Bahrein", code: "BHR" },
  { name: "Bangladesh", code: "BGD" },
  { name: "Barbados", code: "BRB" },
  { name: "Valgevene", code: "BLR" },
  { name: "Belgia", code: "BEL" },
  { name: "Belize", code: "BLZ" },
  { name: "Benin", code: "BEN" },
  { name: "Bermuda", code: "BMU" },
  { name: "Bhutan", code: "BTN" },
  {
    name: "Boliivia",
    code: "BOL",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    code: "BES",
  },
  { name: "Bosnia ja Hertsegoviina", code: "BIH" },
  { name: "Botswana", code: "BWA" },
  { name: "Bouvet", code: "BVT" },
  { name: "Brasiilia", code: "BRA" },
  {
    name: "Briti India ookeani ala",
    code: "IOT",
  },
  { name: "Brunei", code: "BRN" },
  { name: "Bulgaaria", code: "BGR" },
  { name: "Burkina Faso", code: "BFA" },
  { name: "Burundi", code: "BDI" },
  { name: "Roheneemesaared", code: "CPV" },
  { name: "Kambodža", code: "KHM" },
  { name: "Kamerun", code: "CMR" },
  { name: "Kanada", code: "CAN" },
  { name: "Kaimanisaared", code: "CYM" },
  { name: "Kesk-Aafrika Vabariik", code: "CAF" },
  { name: "Tšaad", code: "TCD" },
  { name: "Tšiili", code: "CHL" },
  { name: "Hiina", code: "CHN" },
  { name: "Jõulusaar", code: "CXR" },
  { name: "Kookossaared", code: "CCK" },
  { name: "Colombia", code: "COL" },
  { name: "Comoros", code: "COM" },
  { name: "Kongo Vabariik", code: "COG" },
  {
    name: "Kongo Demokraatlik Vabariik",
    code: "COD",
  },
  { name: "Cooki saared", code: "COK" },
  { name: "Costa Rica", code: "CRI" },
  { name: "Elevandiluurannik", code: "CIV" },
  { name: "Horvaatia", code: "HRV" },
  { name: "Kuuba", code: "CUB" },
  { name: "Curaçao", code: "CUW" },
  { name: "Küpros", code: "CYP" },
  { name: "Tšehhi", code: "CZE" },
  { name: "Taani", code: "DNK" },
  { name: "Djibouti", code: "DJI" },
  { name: "Dominica", code: "DMA" },
  { name: "Dominikaani Vabariik", code: "DOM" },
  { name: "Ecuador", code: "ECU" },
  { name: "Egiptus", code: "EGY" },
  { name: "El Salvador", code: "SLV" },
  { name: "Ekvatoriaal-Guinea", code: "GNQ" },
  { name: "Eritrea", code: "ERI" },
  { name: "Eesti", code: "EST" },
  { name: "Svaasimaa", code: "SWZ" },
  { name: "Etioopia", code: "ETH" },
  {
    name: "Falklandi saared",
    code: "FLK",
  },
  { name: "Fääri saared", code: "FRO" },
  { name: "Fidži", code: "FJI" },
  { name: "Soome", code: "FIN" },
  { name: "Prantsusmaa", code: "FRA" },
  { name: "Prantsuse Guajaana", code: "GUF" },
  { name: "Prantsuse Polüneesia", code: "PYF" },
  {
    name: "Prantsuse Lõunaalad",
    code: "ATF",
  },
  { name: "Gabon", code: "GAB" },
  { name: "Gambia", code: "GMB" },
  { name: "Gruusia", code: "GEO" },
  { name: "Saksama", code: "DEU" },
  { name: "Ghana", code: "GHA" },
  { name: "Gibraltar", code: "GIB" },
  { name: "Kreeka", code: "GRC" },
  { name: "Gröönimaa", code: "GRL" },
  { name: "Grenada", code: "GRD" },
  { name: "Guadeloupe", code: "GLP" },
  { name: "Guam", code: "GUM" },
  { name: "Guatemala", code: "GTM" },
  { name: "Guernsey", code: "GGY" },
  { name: "Guinea", code: "GIN" },
  { name: "Guinea-Bissau", code: "GNB" },
  { name: "Guyana", code: "GUY" },
  { name: "Haiti", code: "HTI" },
  {
    name: "Heard ja McDonald",
    code: "HMD",
  },
  { name: "Vatikan", code: "VAT" },
  { name: "Honduras", code: "HND" },
  { name: "Hong Kong", code: "HKG" },
  { name: "Ungari", code: "HUN" },
  { name: "Island", code: "ISL" },
  { name: "India", code: "IND" },
  { name: "Indonesia", code: "IDN" },
  {
    name: "Iraan",
    code: "IRN",
  },
  { name: "Iraak", code: "IRQ" },
  { name: "Iirimaa", code: "IRL" },
  { name: "Man", code: "IMN" },
  { name: "Iisrael", code: "ISR" },
  { name: "Itaalia", code: "ITA" },
  { name: "Jamaica", code: "JAM" },
  { name: "Jaapan", code: "JPN" },
  { name: "Jersey", code: "JEY" },
  { name: "Jordaania", code: "JOR" },
  { name: "Kasahstan", code: "KAZ" },
  { name: "Keenia", code: "KEN" },
  { name: "Kiribati", code: "KIR" },
  { name: "Kosovo", code: "XXK" },
  {
    name: "Põhja-Korea",
    code: "PRK",
  },
  { name: "Lõuna-Korea", code: "KOR" },
  { name: "Kuveit", code: "KWT" },
  { name: "Kõrgõzstan", code: "KGZ" },
  {
    name: "Laos",
    code: "LAO",
  },
  { name: "Läti", code: "LVA" },
  { name: "Liibanon", code: "LBN" },
  { name: "Lesotho", code: "LSO" },
  { name: "Libeeria", code: "LBR" },
  { name: "Liibüa", code: "LBY" },
  { name: "Liechtenstein", code: "LIE" },
  { name: "Leedu", code: "LTU" },
  { name: "Luksemburg", code: "LUX" },
  { name: "Macau", code: "MAC" },
  { name: "Madagaskar", code: "MDG" },
  { name: "Malawi", code: "MWI" },
  { name: "Malaisia", code: "MYS" },
  { name: "Maldiivid", code: "MDV" },
  { name: "Mali", code: "MLI" },
  { name: "Malta", code: "MLT" },
  { name: "Marshalli Saared", code: "MHL" },
  { name: "Martinique", code: "MTQ" },
  { name: "Mauritaania", code: "MRT" },
  { name: "Mauritius", code: "MUS" },
  { name: "Mayotte", code: "MYT" },
  { name: "Mehhiko", code: "MEX" },
  {
    name: "Mikroneesia Liiduriigid",
    code: "FSM",
  },
  { name: "Moldova", code: "MDA" },
  { name: "Monaco", code: "MCO" },
  { name: "Mongoolia", code: "MNG" },
  { name: "Montenegro", code: "MNE" },
  { name: "Montserrat", code: "MSR" },
  { name: "Maroko", code: "MAR" },
  { name: "Mosambiik", code: "MOZ" },
  { name: "Myanmar", code: "MMR" },
  { name: "Namiibia", code: "NAM" },
  { name: "Nauru", code: "NRU" },
  { name: "Nepal", code: "NPL" },
  { name: "Holland", code: "NLD" },
  { name: "Uus-Kaledoonia", code: "NCL" },
  { name: "Uus-Meremaa", code: "NZL" },
  { name: "Nicaragua", code: "NIC" },
  { name: "Niger", code: "NER" },
  { name: "Nigeria", code: "NGA" },
  { name: "Niue", code: "NIU" },
  { name: "Norfolk", code: "NFK" },
  { name: "Põhja-Makedoonia", code: "MKD" },
  { name: "Põhja-Mariaanid", code: "MNP" },
  { name: "Norra", code: "NOR" },
  { name: "Omaan", code: "OMN" },
  { name: "Pakistan", code: "PAK" },
  { name: "Belau", code: "PLW" },
  { name: "Palestiina", code: "PSE" },
  { name: "Panama", code: "PAN" },
  { name: "Paapua Uus-Guinea", code: "PNG" },
  { name: "Paraguay", code: "PRY" },
  { name: "Peruu", code: "PER" },
  { name: "Filipiinid", code: "PHL" },
  { name: "Pitcairn", code: "PCN" },
  { name: "Poland", code: "POL" },
  { name: "Portugal", code: "PRT" },
  { name: "Puerto Rico", code: "PRI" },
  { name: "Katar", code: "QAT" },
  { name: "Réunion", code: "REU" },
  { name: "Rumeenia", code: "ROU" },
  { name: "Venemaa", code: "RUS" },
  { name: "Rwanda", code: "RWA" },
  { name: "Saint-Barthélemy", code: "BLM" },
  {
    name: "Saint Helena",
    code: "SHN",
  },
  { name: "Saint Kitts ja Nevis", code: "KNA" },
  { name: "Saint Lucia", code: "LCA" },
  {
    name: "Saint-Martin",
    code: "MAF",
  },
  {
    name: "Saint-Pierre ja Miquelon",
    code: "SPM",
  },
  {
    name: "Saint Vincent ja Grenadiinid",
    code: "VCT",
  },
  { name: "Samoa", code: "WSM" },
  { name: "San Marino", code: "SMR" },
  { name: "Sao Tome ja Principe", code: "STP" },
  { name: "Saudi Araabia", code: "SAU" },
  { name: "Senegal", code: "SEN" },
  { name: "Serbia", code: "SRB" },
  { name: "Seišellid", code: "SYC" },
  { name: "Sierra Leone", code: "SLE" },
  { name: "Singapur", code: "SGP" },
  {
    name: "Sint Maarten (Dutch part)",
    code: "SXM",
  },
  { name: "Slovakia", code: "SVK" },
  { name: "Slovenia", code: "SVN" },
  { name: "Solomon Islands", code: "SLB" },
  { name: "Somalia", code: "SOM" },
  { name: "South Africa", code: "ZAF" },
  {
    name: "Lõuna-Georgia and Lõuna-Sandwichi saared",
    code: "SGS",
  },
  { name: "Lõuna-Sudaan", code: "SSD" },
  { name: "Hispaania", code: "ESP" },
  { name: "Sri Lanka", code: "LKA" },
  { name: "Sudaan", code: "SDN" },
  { name: "Suriname", code: "SUR" },
  { name: "Svalbard ja Jan Mayen", code: "SJM" },
  { name: "Rootsi", code: "SWE" },
  { name: "Šveits", code: "CHE" },
  { name: "Süüria", code: "SYR" },
  {
    name: "Taiwan",
    code: "TWN",
  },
  { name: "Tadžikistan", code: "TJK" },
  {
    name: "Tansaania",
    code: "TZA",
  },
  { name: "Tai", code: "THA" },
  { name: "Ida-Timor", code: "TLS" },
  { name: "Togo", code: "TGO" },
  { name: "Tokelau", code: "TKL" },
  { name: "Tonga", code: "TON" },
  { name: "Trinidad ja Tobago", code: "TTO" },
  { name: "Tuneesia", code: "TUN" },
  { name: "Türgi", code: "TUR" },
  { name: "Türkmenistaan", code: "TKM" },
  { name: "Turks ja Caicos", code: "TCA" },
  { name: "Tuvalu", code: "TUV" },
  { name: "Uganda", code: "UGA" },
  { name: "Ukraina", code: "UKR" },
  { name: "Araabia Ühendemiraadid", code: "ARE" },
  {
    name: "Suurbritannia",
    code: "GBR",
  },
  { name: "Ameerika Ühendriigid", code: "USA" },
  {
    name: "Ühendriikide hajasaared",
    code: "UMI",
  },
  { name: "Uruguay", code: "URY" },
  { name: "Usbekistan", code: "UZB" },
  { name: "Vanuatu", code: "VUT" },
  {
    name: "Venezuela",
    code: "VEN",
  },
  { name: "Vietnam", code: "VNM" },
  { name: "Virgin Islands (British)", code: "VGB" },
  { name: "USA Neitsisaared", code: "VIR" },
  { name: "Wallis ja Futuna", code: "WLF" },
  { name: "Lääne-Sahara", code: "ESH" },
  { name: "Jeemen", code: "YEM" },
  { name: "Sambia", code: "ZMB" },
  { name: "Zimbabwe", code: "ZWE" },
];
