<template>
  <div
    @click="toggleSelectedProject"
    class="gwd-table-row grid-cols-4 cursor-pointer"
    :class="
      selectedProject === this.project.uuid ? 'bg-primary bg-opacity-10' : ''
    "
  >
    <h4 class="truncate">{{ project.title }}</h4>
    <span class="truncate">{{ project.client_name }}</span>
    <span class="text-sm truncate col-span-2">{{
      project.location ? project.location.long_address : "-"
    }}</span>
  </div>
</template>

<script>
export default {
  name: "TaskProject",
  props: {
    project: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isFirst: Boolean,
    selectedProject: { type: String, default: "" },
  },
  methods: {
    toggleSelectedProject() {
      this.$emit("projectSelected", this.project);
    },
  },
};
</script>
