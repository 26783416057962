<template>
  <div class="wrapper register-wrapper">
    <div
      class="container items-center"
      :class="
        $store.state.auth.loggedIn &&
        !$store.state.userData.user.is_email_verified
          ? 'mt-12'
          : ''
      "
    >
      <div
        class="flex flex-col w-7/12 p-6 mx-auto bg-white justify-center items-center shadow rounded mb-24"
        v-if="!$store.state.auth.status.loggedIn"
      >
        <registration-form-worker-join />
      </div>
      <div
        v-else
        class="flex flex-col w-7/12 p-6 mx-auto bg-white justify-center items-center shadow rounded mb-24"
      >
        <span class="mb-2"
          >Et luua lingiga uus kasutaja, palun logige välja.</span
        >
        <button class="btn-danger" @click="$store.dispatch('auth/logout')">
          Logi välja
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RegistrationFormWorkerJoin from "@/components/registration/RegistrationFormWorkerJoin";

export default {
  data() {
    return {
      asProvider: false,
      typeSelected: false,
    };
  },
  components: {
    RegistrationFormWorkerJoin,
  },
  methods: {
    handleTypeSelection(e) {
      this.asProvider = e;
      this.typeSelected = true;
    },
  },
  mounted() {
    if (!this.$route.query.token) this.$router.push("/login");
  },
  computed: {
    headerText() {
      if (!this.typeSelected) {
        return "Registreeri konto";
      } else if (this.typeSelected) {
        if (this.asProvider) {
          return "Registreeru teenusepakkujana";
        } else {
          return "Registreeru tellijana";
        }
      } else {
        return "Registreeri konto";
      }
    },
  },
};
</script>

<style lang="scss">
.register-wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.register-cancel-button {
  position: absolute;
  top: 9%;
  left: 6%;
}
</style>
