var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gwd-form"},[(_vm.success)?_c('div',{staticClass:"mb-3",attrs:{"data-cy":"success-message"}},[_vm._v(" Registreerimine õnnestus! ")]):_vm._e(),_c('h3',{staticClass:"self-center"},[_vm._v("Ühenda töötaja konto")]),_c('div',{staticClass:"gwd-form-group w-full"},[_c('gwd-formlabel',{attrs:{"title":"Meiliaadress","required":true,"valid":!_vm.$v.user.email.$invalid,"error":!_vm.$v.user.email.email,"error-text":!_vm.$v.user.email.required
          ? 'Kohustuslik'
          : !_vm.$v.user.email.email
          ? 'Ei vasta nõuetele'
          : ''}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"w-full",attrs:{"type":"email","data-cy":"email"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}})],1),_c('div',{staticClass:"gwd-form-hor-group w-full gap-x-2"},[_c('div',{staticClass:"flex flex-col items-start flex-1 mobile:w-full mobile:mr-0 mobile:mb-5"},[_c('gwd-formlabel',{attrs:{"title":"Eesnimi","required":true,"valid":!_vm.$v.user.first_name.$invalid}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.first_name),expression:"user.first_name"}],staticClass:"w-full",attrs:{"type":"text","data-cy":"first-name"},domProps:{"value":(_vm.user.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "first_name", $event.target.value)}}})],1),_c('div',{staticClass:"flex flex-col items-start flex-1 mobile:w-full mobile:ml-0"},[_c('gwd-formlabel',{attrs:{"title":"Perenimi","required":true,"valid":!_vm.$v.user.last_name.$invalid}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.last_name),expression:"user.last_name"}],staticClass:"w-full",attrs:{"type":"text","data-cy":"last-name"},domProps:{"value":(_vm.user.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "last_name", $event.target.value)}}})],1)]),_c('div',{staticClass:"gwd-form-group w-full"},[_c('gwd-formlabel',{attrs:{"title":"Salasõna","required":true,"valid":!_vm.$v.user.password.$invalid,"error-text":!_vm.$v.user.password.required
          ? 'Kohustuslik'
          : !_vm.$v.user.password.minLength
          ? 'Salasõna peab olema vähemalt 8 tähemärki pikk'
          : ''}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"w-full",attrs:{"type":"password","data-cy":"password"},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "password", $event.target.value)}}})],1),_c('div',{staticClass:"gwd-form-group w-full"},[_c('gwd-formlabel',{attrs:{"title":"Kinnita salasõna","required":true,"valid":!_vm.$v.user.password2.$invalid,"error":_vm.$v.user.password2.required && !_vm.$v.user.password2.sameAsPassword,"error-text":!_vm.$v.user.password2.required
          ? 'Kohustuslik'
          : !_vm.$v.user.password2.sameAsPassword
          ? 'Salasõnad ei klapi'
          : ''}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password2),expression:"user.password2"}],staticClass:"w-full",attrs:{"type":"password","data-cy":"password"},domProps:{"value":(_vm.user.password2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "password2", $event.target.value)}}})],1),(_vm.success === false)?_c('button',{staticClass:"self-center h-8 w-32",class:_vm.$v.$invalid ? 'btn-disabled' : 'btn-primary',attrs:{"data-cy":"submit","disabled":_vm.$v.$invalid},on:{"click":function($event){return _vm.createUser(_vm.user)}}},[_vm._v(" Registreeri ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }