<template>
  <div class="flex relative rounded">
    <textarea
      @input="handleInput"
      :value="value"
      class="gwd-textarea"
      :class="[
        error || !maxLengthCheck
          ? 'border-2 !border-danger border-opacity-50 px-1 focus:border-opacity-0'
          : '',
        heading ? 'text-2xl' : '',
        borderless ? 'borderless' : null,
        background
          ? 'bg-offwhite px-1 shadow'
          : 'bg-transparent hover:bg-white focus:bg-white',
      ]"
      ref="textarea"
      :placeholder="placeholder"
      rows="1"
      :maxlength="maxLength"
    />
    <span class="max-length-text" :class="[maxLengthTextColor]" v-if="maxLength"
      >{{ value.length }}/{{ maxLength }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    error: Boolean,
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "...",
    },
    heading: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    background: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.value) this.resize();
  },
  methods: {
    handleInput() {
      this.resize();
      this.$emit("input", this.$refs.textarea.value);
    },
    resize() {
      this.$refs.textarea.style.height = "auto";
      this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight}px`;
    },
  },
  computed: {
    maxLengthCheck() {
      return !(this.maxLength && this.value.length > this.maxLength);
    },
    maxLengthTextColor() {
      if (!this.maxLength) return null;
      if (this.value.length === this.maxLength) return "text-danger";
      if (this.value.length > this.maxLength * 0.8) return "text-attention";
      if (this.value.length > this.maxLength / 2) return "text-black";
      return "text-offwhite-dark";
    },
  },
};
</script>

<style lang="scss">
.gwd-textarea {
  @apply shadow-none p-1 cursor-pointer ring-2 ring-opacity-0 rounded ring-primary flex-grow duration-100 box-border;
  overflow-y: hidden;
  resize: none;
  &:hover {
    @apply ring-opacity-10 px-1;
  }
  &:focus {
    @apply shadow-none ring-2 ring-opacity-25 px-1;
    outline: none;
  }
  &.option-display {
    @apply rounded-none rounded-t;
  }
}
.max-length-text {
  @apply absolute right-0 -bottom-5 text-sm;
}
</style>
