<template>
  <div class="flex flex-col gap-y-2">
    <SearchInput v-model="searchBox" />
    <ul
      v-if="equipmentLoaded && equipmentList.results"
      class="flex flex-col gap-y-1"
    >
      <li
        class="flex cursor-pointer rounded p-1 hover:bg-offwhite-dark duration-100 gap-x-5"
        :class="
          selectedEquipment && selectedEquipment.id === equipment.id
            ? 'bg-offwhite-dark'
            : 'bg-offwhite'
        "
        v-for="equipment in equipmentList.results"
        :key="equipment.id"
        @click="equipment.conflicts ? () => {} : toggleEquipment(equipment)"
      >
        <span>
          {{ equipment.name }}
        </span>
        <span> {{ equipment.worker ? equipment.worker.worker_name : "" }}</span>
        <div
          v-if="equipment.conflicts"
          title="Tehnika on määratud teisele tööle või hõivatud!"
          class="text-white bg-danger rounded-xl leading-none w-6 h-6 flex justify-center items-center font-extrabold cursor-default ml-auto"
        >
          !
        </div>
      </li>
      <li
        v-if="equipmentList.results.length === 0"
        :key="'noresult'"
        class="font-medium flex items-center"
      >
        <span
          class="typcn typcn-times bg-attention rounded-full p-1 flex items-center justify-center leading-none mr-2"
        />
        Vastet ei leitud
      </li>
    </ul>
    <SkeletonContent v-if="!equipmentLoaded" />
    <page-changer
      class="self-center"
      v-if="equipmentList.count > 1"
      :page="page"
      :count="equipmentList.count"
      @pageChange="pageChange"
    />
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import PageChanger from "@/components/reusable/PageChanger";
import { mapGetters } from "vuex";
import SearchInput from "../../reusable/SearchInput.vue";
import SkeletonContent from "../../reusable/SkeletonContent.vue";
import { generateParamString } from "@/assets/utils/commonTransforms";

export default {
  name: "EquipmentSelector",
  components: { PageChanger, SearchInput, SkeletonContent },
  props: {
    task: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      menuActive: false,
      searchBox: "",
      equipmentList: [],
      equipmentLoaded: false,
      selectedEquipment: null,
      page: 1,
    };
  },
  methods: {
    toggleEquipment(e) {
      this.$emit("equipmentSelected", e);
    },
    pageChange(e) {
      this.page = e;
      this.loadCompanyEquipment();
    },
    closeDropdown() {
      this.menuActive = false;
      this.searchBox = "";
    },
    loadCompanyEquipment() {
      let url;
      let params = {
        task: this.task,
        show_prices: true,
        page: this.page,
      };
      if (this.searchBox && this.searchBox.length > 0)
        params.name = this.searchBox;
      if (this.task) {
        url = `template/${this.companyId}/equipment/${generateParamString(
          params
        )}`;
      } else {
        url = `template/${this.companyId}/equipment/${generateParamString(
          params
        )}`;
      }
      this.apiRequest(url, "get", true).then((res) => {
        this.equipmentList = res.data;
        this.equipmentLoaded = true;
      });
    },
    debounceSearchQuery: debounce(function () {
      this.loadCompanyEquipment();
    }, 500),
    clearSelection() {
      this.selectedResource = null;
      this.searchBox = "";
    },
  },
  mounted() {
    this.loadCompanyEquipment();
  },
  watch: {
    searchBox() {
      this.debounceSearchQuery();
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
    }),
  },
};
</script>
