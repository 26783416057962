<template>
  <div class="modal text-sm">
    <div
      class="modal-body modal-medium modal-vwide"
      v-on-clickaway:mousedown="closeModal"
    >
      <div class="modal-content flex-col" v-if="itemInited">
        <div class="gwd-table nested">
          <div class="gwd-table-row template-generator-modal-rows">
            <span>Programmikood</span>
            <span>Kirjeldus</span>
            <span>Kogus</span>
            <span>Kulu</span>
            <span>Müügihind</span>
            <span>Kogukulu</span>
            <span>Koguhind</span>
            <span />
            <span />
          </div>
          <div
            v-for="cost in costs"
            :key="cost.id"
            class="gwd-table-row template-generator-modal-rows"
          >
            <span />
            <span>{{ cost.description }}</span>
            <span>{{ cost.amount }} {{ cost.unit }}</span>
            <span>{{ cost.cost }}</span>
            <span>{{ cost.price }}</span>
            <span>{{ round2(cost.amount * cost.cost) }}</span>
            <span>{{ round2(cost.amount * cost.price) }}</span>
            <span />
            <span />
          </div>
          <div class="gwd-table-row template-generator-modal-rows">
            <div class="pr-2">
              <select
                v-model="item.serviceObj"
                class="w-full"
                :class="
                  showInvalid && !item.serviceObj
                    ? 'ring-danger ring-opacity-50 ring-2'
                    : ''
                "
                @change="item.service_name = item.serviceObj.service_name"
              >
                <option
                  v-for="serviceObj in serviceObjects"
                  :key="serviceObj.id"
                  :value="serviceObj"
                  :disabled="serviceObj.service_code.length === 2"
                >
                  {{
                    `${serviceObj.service_code} - ${serviceObj.service_name}`
                  }}
                </option>
              </select>
            </div>
            <div class="no-hover pr-2">
              <input type="text" v-model="item.description" class="w-full" />
            </div>
            <div class="no-hover pr-2">
              <decimal-input
                v-model.number="item.amount"
                class="w-full"
                :class="
                  showInvalid && !item.amount
                    ? 'ring-danger ring-opacity-50 ring-2'
                    : ''
                "
              />
            </div>
            <div></div>
            <div class="no-hover pr-2">
              <decimal-input
                step="0.01"
                v-model="item.price"
                class="w-full"
                :class="
                  showInvalid && !item.price
                    ? 'ring-danger ring-opacity-50 ring-2'
                    : ''
                "
              />
            </div>
            <div class="no-hover pr-2">
              <select v-model="item.unit">
                <option :value="'tk'">tk</option>
                <option :value="'h'">h</option>
                <option :value="'m'">m</option>
                <option :value="'km'">km</option>
                <option :value="'l'">l</option>
                <option :value="'m²'">m²</option>
                <option :value="'m³'">m³</option>
                <option :value="'kg'">kg</option>
                <option :value="'t'">t</option>
                <option :value="'paev'">päev</option>
                <option :value="'kuu'">kuu</option>
              </select>
            </div>
            <div class="no-hover pr-2">
              <span> {{ round2(item.amount * item.price) }}</span>
            </div>
            <div class="no-hover pr-2">
              <select
                v-if="taxTypes"
                v-model="item.taxType"
                class="max-w-full"
                :class="
                  showInvalid && !item.taxType
                    ? 'ring-danger ring-opacity-50 ring-2'
                    : ''
                "
              >
                <option
                  v-for="taxType in taxTypes.filter((x) => x.outgoing_tax_code)"
                  :key="taxType.id"
                  :value="taxType"
                >
                  {{
                    taxType
                      ? `${taxType.description} (${
                          taxType.tax_percentage ? taxType.tax_percentage : "0"
                        }%)`
                      : "-"
                  }}
                </option>
              </select>
            </div>
            <div>
              <span
                >{{ item.total.toFixed(2) }}
                {{ currency ? currency.sign : "€" }}</span
              >
            </div>
          </div>
          <div class="gwd-table-row template-generator-modal-rows">
            <span></span>
            <div class="flex items-center">
              <button class="btn-primary mr-3" @click="addDate">
                Lisa kuupäev
                <span class="label ml-1">
                  ({{
                    task
                      ? moment(task.start_time).format("DD.MM.YYYY")
                      : moment(ee).format("DD.MM.YYYY")
                  }})
                </span>
              </button>
              <button
                v-if="task"
                :class="task.address ? 'btn-primary' : 'btn-disabled'"
                @click="addStartAddress"
              >
                Algaadress
              </button>
              <button
                v-if="task"
                class="mx-3"
                :class="task.end_address ? 'btn-primary' : 'btn-disabled'"
                @click="addEndAddress"
              >
                Lõppaadress
              </button>
              <button
                v-if="task"
                :class="
                  task.address && task.end_address
                    ? 'btn-primary'
                    : 'btn-disabled'
                "
                @click="addBothAddress"
              >
                Alg - Lõpp
              </button>
            </div>
            <div class="flex items-center text-sm" v-if="!amountAdded">
              <button class="btn-primary" @click="calculateAverageAmount">
                <span class="label">kesk.</span>
              </button>
              <button class="btn-primary mx-auto" @click="calculateSumAmount">
                <span class="label">sum</span>
              </button>
              <button class="btn-primary" @click="calculateMaxAmount">
                <span class="label">max</span>
              </button>
            </div>
            <span v-else />
            <span />
            <div class="flex items-center text-sm" v-if="!priceAdded">
              <button class="btn-primary" @click="calculateAveragePrice">
                <span class="label">kesk.</span>
              </button>
              <button class="btn-primary mx-auto" @click="calculateSumPrice">
                <span class="label">sum</span>
              </button>
              <button class="btn-primary" @click="calculateMaxPrice">
                <span class="label">max</span>
              </button>
            </div>
            <span v-else />
            <span />
            <div
              class="flex items-center text-sm"
              v-if="!priceAdded && !amountAdded"
            >
              <button class="btn-primary" @click="calculateSumOfEach">
                <span class="lable">Summa</span>
              </button>
            </div>
            <span v-else />
            <span class="col-span-2"></span>
          </div>
          <div
            class="gwd-table-row template-generator-modal-rows"
            v-if="task && (task.address || task.end_address)"
          >
            <div />
            <div class="col-span-8">
              {{ task.address }} - {{ task.end_address }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-bottom-bar">
        <button class="btn-primary mr-3" @click="saveRevenue">Salvesta</button>
        <button class="btn-danger" @click="closeModal">Sulge</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Revenue } from "@/assets/types/Revenue";
import moment from "moment";
import { round2 } from "@/assets/utils/commonMath";
import DecimalInput from "../reusable/DecimalInput.vue";
import { ee } from "vuejs-datepicker/dist/locale";
export default {
  name: "CostTemplateRevenueGeneratorModal",
  props: {
    template: {
      type: Object,
      default: null,
    },
    costs: {
      type: Array,
      default: () => {
        return [];
      },
    },
    task: {
      type: Object,
      default: null,
    },
  },
  components: { DecimalInput },
  data() {
    return {
      item: null,
      itemInited: false,
      showInvalid: false,
      currency: null,
      moment: moment,
      amountAdded: false,
      priceAdded: false,
      ee: ee,
    };
  },
  methods: {
    round2,
    calculateAverageAmount() {
      this.item.amount = round2(
        this.costs.map((x) => parseFloat(x.amount)).reduce((x, y) => x + y, 0) /
          this.costs.length
      );
      this.amountAdded = true;
    },
    calculateSumAmount() {
      this.item.amount = round2(
        this.costs.map((x) => parseFloat(x.amount)).reduce((x, y) => x + y, 0)
      );
      this.amountAdded = true;
    },
    calculateMaxAmount() {
      this.item.amount = round2(
        Math.max(...this.costs.map((x) => parseFloat(x.amount)))
      );
      this.amountAdded = true;
    },
    calculateAveragePrice() {
      this.item.price = round2(
        this.costs.map((x) => parseFloat(x.price)).reduce((x, y) => x + y, 0) /
          this.costs.length
      );
      this.priceAdded = true;
    },
    calculateSumPrice() {
      this.item.price = round2(
        this.costs.map((x) => parseFloat(x.price)).reduce((x, y) => x + y, 0)
      );
      this.priceAdded = true;
    },
    calculateMaxPrice() {
      this.item.price = round2(
        Math.max(...this.costs.map((x) => parseFloat(x.price)))
      );
      this.priceAdded = true;
    },
    calculateSumOfEach() {
      this.item.amount = 1;
      this.item.price = round2(
        this.costs
          .map((x) => parseFloat(x.price) * parseFloat(x.amount))
          .reduce((x, y) => x + y, 0)
      );
      this.priceAdded = true;
      this.amountAdded = true;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    saveRevenue() {
      this.$emit("revenueSaved", this.item);
      this.closeModal();
    },
    addDate() {
      if (this.task) {
        this.item.description = `${this.item.description} ${moment(
          this.task.start_time
        ).format("DD.MM.YYYY")}`;
      } else {
        this.item.description = `${this.item.description} (${moment(ee).format(
          "DD.MM.YYYY"
        )})`;
      }
    },
    addStartAddress() {
      if (!this.task.address) return;
      const start = this.task.address.split(",");
      let addr;
      if (start.length > 2) {
        addr = start[0];
      } else {
        addr = `${start[0]}, ${start[1]}`;
      }
      this.item.description = `${this.item.description} ${addr}`;
    },
    addEndAddress() {
      if (!this.task.end_address) return;
      const end = this.task.end_address.split(",");
      let endAddr;
      if (end.length > 2) {
        endAddr = end[0];
      } else {
        endAddr = `${end[0]}, ${end[1]}`;
      }
      this.item.description = `${this.item.description} ${endAddr}`;
    },
    addBothAddress() {
      if (!this.task.address) return;
      if (!this.task.end_address) return;
      const start = this.task.address.split(",");
      let addr;
      if (start.length > 2) {
        addr = start[0];
      } else {
        addr = `${start[0]}, ${start[1]}`;
      }
      const end = this.task.end_address.split(",");
      let endAddr;
      if (end.length > 2) {
        endAddr = end[0];
      } else {
        endAddr = `${end[0]}, ${end[1]}`;
      }
      this.item.description = `${this.item.description} ${addr} - ${endAddr}`;
    },
    removeCostFromTemplateCosts(e) {
      this.$emit("removeCostFromTemplateCosts", e);
    },
  },
  mounted() {
    document.body.classList.add("modal-open");
    let vat = this.taxTypes.find(
      (x) => x.accounting_code === this.template.vat
    );
    const types = [
      {
        type: 1,
        code: "201",
      },
      {
        type: 5,
        code: "202",
      },
    ];
    let typeToCode = types.find((x) => x.type === this.template.type);
    if (typeToCode === undefined) {
      typeToCode = "201";
    } else {
      typeToCode = typeToCode.code;
    }
    const serObj = this.serviceObjects.find(
      (x) => x.service_code === typeToCode
    );
    this.item = new Revenue(
      null,
      serObj,
      0,
      this.template.unit,
      vat,
      this.template.price,
      this.costs.map((x) => `${x.type}${x.id}`),
      this.template.description
    );
    this.itemInited = true;
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
  computed: {
    ...mapGetters({
      taxTypes: "companyVariables/taxTypes",
      companyId: "companyData/activeCompanyUuid",
      currencyList: "companyVariables/currencyList",
      serviceObjects: "companyVariables/serviceObjects",
    }),
  },
};
</script>
<style lang="scss" scoped>
.template-generator-modal-rows {
  grid-template-columns: 0.4fr 1.2fr 0.3fr 0.2fr 0.3fr 0.2fr 0.2fr 0.4fr 0.2fr;
}
</style>
