var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal"},[_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closeModal),expression:"closeModal"}],staticClass:"modal-body modal-large"},[_c('div',{staticClass:"modal-header px-2 py-1"},[_c('h3',[_vm._v(" "+_vm._s(_vm.timerMapModal.data.length === 1 ? ("Töötaja \"" + (_vm.timerMapModal.data[0].worker_name) + "\" töö alustamise/lõpetamise kohad") : ("Töö \"" + (_vm.taskData.title) + "\" asukoha ja töötajate kaart"))+" ")])]),(_vm.mapInited)?_c('div',{staticClass:"flex h-full w-full shadow rounded-b-xl mobile:hidden"},[_c('l-map',{ref:"map",staticClass:"h-full w-full z-10 rounded-b-xl",attrs:{"options":_vm.mapOptions,"center":_vm.mapOptions.center,"interactive":false},on:{"update:zoom":_vm.resizeCircles}},[_c('l-tile-layer',{staticClass:"h-full w-full justify-start flex",attrs:{"url":_vm.tileUrl,"attribution":'© OpenStreetMap contributors',"position":'bottomleft'}}),_vm._l((_vm.timerMapModal.data),function(timerData,index){return _c('div',{key:index},[(timerData.start_location && _vm.zoomLevel >= 14)?_c('l-circle',{attrs:{"lat-lng":[
              timerData.start_location.latitude,
              timerData.start_location.longitude ],"radius":parseFloat(timerData.start_location.accuracy),"color":"#550055"}},[_c('l-tooltip',[_vm._v(" Töötaja "+_vm._s(timerData.worker_name)+" algus koht ")])],1):_vm._e(),(timerData.start_location && _vm.zoomLevel < 14)?_c('l-marker',{attrs:{"lat-lng":[
              timerData.start_location.latitude,
              timerData.start_location.longitude ],"color":"#550055"}},[_c('l-icon',{attrs:{"icon-url":"/bc21/placeholder.svg","icon-size":_vm.dynamicSize}}),_c('l-tooltip',[_vm._v(" Töötaja "+_vm._s(timerData.worker_name)+" algus koht ")])],1):_vm._e(),(timerData.end_location && _vm.zoomLevel >= 14)?_c('l-circle',{attrs:{"lat-lng":[
              timerData.end_location.latitude,
              timerData.end_location.longitude ],"radius":parseFloat(timerData.end_location.accuracy)}},[_c('l-tooltip',[_vm._v(" Töötaja "+_vm._s(timerData.worker_name)+" lõppkoht ")])],1):_vm._e(),(timerData.end_location && _vm.zoomLevel < 14)?_c('l-marker',{attrs:{"lat-lng":[
              timerData.end_location.latitude,
              timerData.end_location.longitude ],"color":"#550055"}},[_c('l-icon',{attrs:{"icon-url":"/bc21/placeholder.svg","icon-size":_vm.dynamicSize}}),_c('l-tooltip',[_vm._v(" Töötaja "+_vm._s(timerData.worker_name)+" lõppkoht ")])],1):_vm._e()],1)}),(
            _vm.taskData &&
            _vm.taskData.location &&
            _vm.taskData.location.latitude &&
            _vm.taskData.location.longitude
          )?_c('l-marker',{attrs:{"lat-lng":[_vm.taskData.location.latitude, _vm.taskData.location.longitude]}},[_c('l-icon',{attrs:{"icon-url":"/bc21/placeholder.svg","icon-size":_vm.dynamicSize}}),_c('l-tooltip',[_vm._v(" Töö alguskoht ")])],1):_vm._e(),_vm._l((_vm.taskData
            ? _vm.taskData.extra_locations.filter(
                function (x) { return x.location && x.location.latitude && x.location.longitude; }
              )
            : []),function(extraLocation,index){return _c('l-marker',{key:index,attrs:{"lat-lng":[
            extraLocation.location.latitude,
            extraLocation.location.longitude ]}},[_c('l-icon',{attrs:{"icon-url":"/bc21/placeholder.svg","icon-size":_vm.dynamicSize}}),_c('l-tooltip',[_vm._v(" Vahekoht "+_vm._s(index)+" ")])],1)}),(
            _vm.taskData &&
            _vm.taskData.end_location &&
            _vm.taskData.end_location.latitude &&
            _vm.taskData.end_location.longitude
          )?_c('l-marker',{attrs:{"lat-lng":[
            _vm.taskData.end_location.latitude,
            _vm.taskData.end_location.longitude ]}},[_c('l-icon',{attrs:{"icon-url":"/bc21/placeholder.svg","icon-size":_vm.dynamicSize}}),_c('l-tooltip',[_vm._v(" Töö lõppkoht ")])],1):_vm._e()],2)],1):_vm._e(),_c('div',{staticClass:"modal-bottom-bar"},[_c('button',{staticClass:"btn-danger",on:{"click":_vm.closeModal}},[_vm._v("Sulge")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }