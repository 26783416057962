<template>
  <div
    class="flex items-center rounded duration-100 ring-primary flex-grow hover:ring-2 hover:ring-opacity-15"
    :class="[
      focusSearch ? 'ring-2 ring-opacity-25 ring-primary' : 'shadow',
      `bg-${backgroundColor}`,
    ]"
  >
    <div
      class="w-6 items-center justify-center flex border-r border-offwhite-dark"
    >
      <img src="/bc21/search.svg" class="h-4 w-4" alt="Search icon" />
    </div>
    <input
      type="text"
      @input="$emit('input', $event.target.value)"
      :value="value"
      :placeholder="placeholder"
      class="shadow-none focus:ring-0 hover:shadow-none rounded w-auto px-1 flex-grow hover:ring-0"
      :class="`bg-${backgroundColor}`"
      @focus="focusSearch = true"
      @blur="focusSearch = false"
    />
    <span
      class="typcn typcn-times w-6 h-6 duration-150 bg-danger rounded flex justify-center items-center text-white leading-none mr-1 hover:bg-danger-dark"
      :class="value.length > 0 ? 'bg-danger' : 'bg-offwhite-dark'"
      @click="$emit('input', '')"
    />
  </div>
</template>
<script>
import { debounce } from "lodash";

export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    backgroundColor: {
      type: String,
      default: "offwhite",
    },
    placeholder: {
      type: String,
      default: "Otsi",
    },
  },
  data() {
    return {
      focusSearch: false,
    };
  },
  methods: {
    debounceSearch: debounce(function () {
      this.$emit("triggerSearch");
    }, 500),
  },
};
</script>
