const ProjectView = () => import("@/views/company/ProjectView");
const LoginView = () => import("@/views/core/LoginView");
const NewCompanyView = () => import("@/views/company/NewCompanyView");
const EInvoiceTestPanelView = () => import("@/views/ava/EInvoiceTestPanelView");
const PasswordResetView = () => import("@/views/core/PasswordResetView");
const PasswordResetConfirmView = () =>
  import("@/views/core/PasswordResetConfirmView");
const RegisterView = () => import("@/views/core/RegisterView");
const coreRoutes = [
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    alias: "/",
    meta: {
      public: true,
      sidebar: false,
      title: "Login | AVA kolimisrakendus",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterView,
    meta: {
      disabled: true,
      public: true,
      sidebar: false,
      title: "Registreeri | AVA kolimisrakendus",
    },
  },
  {
    path: "/projects/:projectId",
    name: "ProjectView",
    component: ProjectView,
    meta: {
      public: false,
      title: "Tellimus | AVA kolimisrakendus",
      sidebar: true,
    },
  },
  {
    path: "/company/new",
    name: "NewCompany",
    component: NewCompanyView,
    meta: {
      public: false,
      sidebar: true,
    },
  },
  {
    path: "/einvoicetest",
    name: "EInvoiceTest",
    component: EInvoiceTestPanelView,
    meta: {
      public: false,
      sidebar: true,
    },
  },
  {
    path: "/reset",
    name: "PasswordReset",
    component: PasswordResetView,
    meta: {
      public: true,
      sidebar: false,
    },
  },
  {
    path: "/reset/confirm/:token",
    name: "PasswordResetConfirm",
    component: PasswordResetConfirmView,
    meta: {
      public: true,
      sidebar: false,
    },
  },
];

export default coreRoutes;
