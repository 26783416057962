<template>
  <div
    class="gwd-table-row"
    :class="
      isAdmin || isManager ? 'extra-cost-table' : 'extra-cost-table-worker'
    "
  >
    <span class="mobile:hidden">{{
      extraCost.worker
        ? extraCost.worker.worker_name
        : !isManager
        ? "(Mina)"
        : "-"
    }}</span>
    <span>{{ extraCost.name }}</span>
    <div v-if="isAdmin || isManager" class="flex">
      <span v-if="extraCost.confirmed || !edit">{{ extraCost.cost }} €</span>
      <decimal-input
        v-if="!extraCost.confirmed && edit"
        v-model="editables.cost"
      />
    </div>
    <div v-if="isAdmin || isManager" class="flex mobile:hidden">
      <span v-if="extraCost.confirmed || !edit">{{ extraCost.price }} €</span>
      <decimal-input
        v-if="!extraCost.confirmed && edit"
        v-model="editables.price"
      />
    </div>
    <span v-else> {{ extraCost.price }} € </span>
    <div v-if="isAdmin || isManager">
      <div class="flex justify-end items-center gap-x-1" v-if="edit">
        <decimal-input v-model.number="editables.amount" class="ml-auto" />
        <select class="add-offer-input" v-model="editables.unit">
          <option v-for="unit in units" :value="unit" :key="unit">
            {{ unit }}
          </option>
        </select>
      </div>
      <span class="mobile:hidden" v-else>
        {{ extraCost.amount }} {{ extraCost.unit }}
      </span>
    </div>
    <span v-else> {{ extraCost.amount }} {{ extraCost.unit }} </span>
    <span>{{ round2(extraCost.price * extraCost.amount) }} €</span>
    <span>{{ extraCost.info }}</span>
    <div class="flex">
      <div v-if="isManager" class="flex items-center gap-x-1">
        <div v-if="!extraCost.confirmed && !edit">
          <button
            class="btn-primary mr-2"
            @click="$emit('confirmExtraCost', extraCost)"
          >
            Kinnita
          </button>
        </div>
        <button
          v-if="!edit && !extraCost.confirmed"
          class="btn-primary"
          @click="edit = true"
        >
          <span class="typcn typcn-edit" />
        </button>
        <button v-if="edit" class="btn-primary" @click="editCost">
          <span class="typcn typcn-tick" />
        </button>
        <button v-if="edit" class="btn-danger" @click="resetCost">
          <span class="typcn typcn-times" />
        </button>
        <button v-if="edit" class="btn-danger" @click="deleteCostActive = true">
          <span class="typcn typcn-trash" />
        </button>
        <span v-if="extraCost.confirmed && !edit" class="typcn typcn-tick">
          Kinnitatud
        </span>
      </div>
      <div v-else>
        <span
          class="typcn"
          :class="extraCost.confirmed ? 'typcn-tick' : 'typcn-times'"
        />
      </div>
    </div>
    <delete-confirm-modal
      v-if="deleteCostActive"
      @confirmDelete="deleteCost"
      @closeModal="deleteCostActive = false"
    />
  </div>
</template>
<script>
import DecimalInput from "@/components/reusable/DecimalInput.vue";
import { round2 } from "@/assets/utils/commonMath";
import { mapGetters } from "vuex";
import { isEqual } from "lodash";
import DeleteConfirmModal from "@/components/reusable/DeleteConfirmModal.vue";

export default {
  name: "ExtraCost",
  components: { DeleteConfirmModal, DecimalInput },
  props: {
    extraCost: {
      type: Object,
      default: null,
    },
    isAdmin: { type: Boolean, default: false },
    isManager: { type: Boolean, default: false },
  },
  data() {
    return {
      edit: false,
      editables: null,
      deleteCostActive: false,
    };
  },
  mounted() {
    this.editables = JSON.parse(JSON.stringify(this.extraCost));
  },
  methods: {
    round2,
    editCost() {
      if (!isEqual(this.editables, this.extraCost)) {
        this.$emit("editCost", this.editables);
      }
      this.edit = false;
    },
    deleteCost() {
      this.$emit("deleteCost", this.editables);
      this.edit = false;
    },
    resetCost() {
      this.editables = JSON.parse(JSON.stringify(this.extraCost));
      this.edit = false;
    },
  },
  computed: {
    ...mapGetters({
      units: "companyVariables/units",
    }),
  },
};
</script>
<style scoped lang="scss">
input {
  width: 95%;
}
select {
  width: 75%;
  option {
    width: 75%;
  }
}
</style>
