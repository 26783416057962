var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-container"},[_c('div',{staticClass:"data-container-header gap-x-3"},[_c('h3',[_vm._v("Kuupäev")]),_c('button',{class:_vm.moment(_vm.startDate).isSame(_vm.moment(), 'week')
          ? 'btn-disabled'
          : 'btn-primary',attrs:{"disabled":_vm.moment(_vm.startDate).isSame(_vm.moment(), 'week')},on:{"click":_vm.prevWeek}},[_vm._v(" Tagasi ")]),_c('button',{staticClass:"btn-primary",on:{"click":_vm.nextWeek}},[_vm._v("Edasi")]),_c('span',[_vm._v("Nädal "+_vm._s(_vm.moment(_vm.startDate).week())+" ("+_vm._s(_vm.moment(_vm.startDate).year())+")")])]),_c('div',{staticClass:"data-container-content overflow-x-scroll"},[_c('div',{staticClass:"date-grid"},_vm._l((_vm.dateArray),function(date,index){return _c('div',{key:index,staticClass:"date-container",class:[
          _vm.selectedDate &&
          _vm.moment(_vm.selectedDate).isSame(_vm.moment(date.date), 'day')
            ? 'selected'
            : '' ],on:{"click":function($event){return _vm.selectDate(date.date)}}},[_c('div',{staticClass:"date-container-text"},[_c('h4',{staticClass:"date-text"},[_vm._v(" "+_vm._s(date.date.format("dd"))+" "+_vm._s(date.date.format("DD.MM.YYYY"))+" ")]),_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"job-count-text mr-2"},[_vm._v(_vm._s(date.taskCount)+" "+_vm._s(date.taskCount === 1 ? "töö" : "tööd"))]),_c('span',{staticClass:"mr-2"},[_vm._v("/")]),_c('progress-bar',{staticClass:"flex-grow h-4",attrs:{"percentage":date.percentage,"bar":date.percentage <= 60
                  ? 'green'
                  : date.percentage <= 90
                  ? 'attention'
                  : 'danger'}})],1)])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }