var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-container"},[_c('div',{staticClass:"data-container-header gap-x-2"},[_c('h3',[_vm._v("Töötajad")]),_c('span',{staticClass:"rounded text-white font-medium px-2 cursor-default",class:_vm.assignments.length < _vm.taskData.worker_amount
          ? 'bg-danger'
          : 'bg-primary'},[_vm._v(" "+_vm._s(_vm.assignments.length)+"/"+_vm._s(_vm.taskData.worker_amount)+" ")]),_c('button',{staticClass:"btn-primary",on:{"click":function($event){return _vm.$emit('showWorkerSelector')}}},[_vm._v(" Lisa ")]),_c('button',{staticClass:"btn-danger",on:{"click":_vm.clearWorkers}},[_vm._v("Tühjenda")])]),_c('div',{staticClass:"data-container-content gap-y-1"},_vm._l((_vm.assignments),function(assignment){return _c('div',{key:assignment.worker.id,staticClass:"flex items-center w-full gap-x-2"},[_c('div',{staticClass:"w-40 font-medium"},[_vm._v(_vm._s(assignment.worker.worker_name))]),_c('div',{staticClass:"w-24 flex gap-x-1"},[(assignment.start_time)?_c('time-selector',{attrs:{"pos":"t"},model:{value:(assignment.start_time),callback:function ($$v) {_vm.$set(assignment, "start_time", $$v)},expression:"assignment.start_time"}}):_c('span',{staticClass:"w-full h-full hover:bg-offwhite hover:p-1 rounded duration-200 cursor-pointer",on:{"click":function () { return (assignment.start_time = _vm.timeObj.startTime); }}},[_vm._v(_vm._s(((_vm.formatTimeUnit(_vm.timeObj.startTime.hour)) + ":" + (_vm.formatTimeUnit( _vm.timeObj.startTime.minute )))))]),(assignment.start_time)?_c('button',{staticClass:"btn-danger leading-none h-8 w-5 self-center",on:{"click":function($event){assignment.start_time = null}}},[_c('span',{staticClass:"typcn typcn-times leading-none"})]):_vm._e()],1),_c('div',{staticClass:"w-24 flex gap-x-1"},[(assignment.end_time)?_c('time-selector',{attrs:{"pos":"t"},on:{"input":_vm.broadcastWorkerChange},model:{value:(assignment.end_time),callback:function ($$v) {_vm.$set(assignment, "end_time", $$v)},expression:"assignment.end_time"}}):_c('span',{staticClass:"w-full h-full hover:bg-offwhite hover:p-1 rounded duration-200 cursor-pointer",on:{"click":function () { return (assignment.end_time = {
                hour: _vm.moment(_vm.timeObj.endTime).hour(),
                minute: _vm.moment(_vm.timeObj.endTime).minute(),
              }); }}},[_vm._v(" "+_vm._s(("" + (_vm.moment(_vm.timeObj.endTime).format("HH:mm"))))+" ")]),(assignment.end_time)?_c('button',{staticClass:"btn-danger leading-none h-8 w-5 self-center",on:{"click":function($event){assignment.end_time = null}}},[_c('span',{staticClass:"typcn typcn-times"})]):_vm._e()],1),_c('div',{staticClass:"flex items-center flex-grow gap-x-2"},[_c('div',{staticClass:"w-1/3 flex gap-x-1"},[(assignment.location)?_c('location-inaddress',{staticClass:"flex-grow py-0",model:{value:(assignment.location),callback:function ($$v) {_vm.$set(assignment, "location", $$v)},expression:"assignment.location"}}):_c('span',{staticClass:"w-full truncate px-2 hover:bg-offwhite hover:py-1 duration-200 rounded h-full cursor-pointer",on:{"click":function () { return (assignment.location = _vm.taskData.location
                  ? _vm.taskData.location
                  : {}); }}},[_vm._v(" "+_vm._s(_vm.taskData.location ? _vm.taskData.location.long_address : "-")+" ")]),(assignment.location)?_c('button',{staticClass:"btn-danger btn-danger leading-none h-8 w-5 self-center",on:{"click":function($event){assignment.location = null}}},[_c('span',{staticClass:"typcn typcn-times leading-none"})]):_vm._e()],1),_c('div',{staticClass:"w-1/3 flex gap-x-1"},[(assignment.end_location)?_c('location-inaddress',{staticClass:"flex-grow py-0",model:{value:(assignment.end_location),callback:function ($$v) {_vm.$set(assignment, "end_location", $$v)},expression:"assignment.end_location"}}):_c('span',{staticClass:"w-full truncate px-2 hover:bg-offwhite hover:py-1 duration-200 rounded h-full cursor-pointer",on:{"click":function () { return (assignment.end_location = _vm.taskData.endLocation
                  ? _vm.taskData.endLocation
                  : {}); }}},[_vm._v(_vm._s(_vm.taskData.endLocation ? _vm.taskData.endLocation.long_address : "-"))]),(assignment.end_location)?_c('button',{staticClass:"btn-danger leading-none h-8 w-5 self-center",on:{"click":function($event){assignment.end_location = null}}},[_c('span',{staticClass:"typcn typcn-times"})]):_vm._e()],1),_c('div',{staticClass:"flex w-1/3 border-box"},[_c('gwd-textarea',{staticClass:"w-full",attrs:{"placeholder":"Kirjeldus"},model:{value:(assignment.description),callback:function ($$v) {_vm.$set(assignment, "description", $$v)},expression:"assignment.description"}})],1)]),_c('button',{staticClass:"btn-danger",on:{"click":function($event){return _vm.removeWorker(assignment.worker.id)}}},[_vm._v(" Eemalda ")])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }