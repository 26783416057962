<template>
  <div class="flex w-full">
    <div class="file-input duration-75 w-full items-start">
      <input
        type="file"
        multiple
        class="bg-white"
        ref="files"
        :name="`fields[${this.identifier}][]`"
        :id="`${this.identifier}`"
        @input="updateFiles"
      />

      <label
        v-if="singleFile ? fileList.length < 1 : true"
        class="hover:bg-primary hover:text-white rounded p-2 font-semibold text-sm cursor-pointer flex items-center justify-center w-full h-full border-2 border-primary border-dotted"
        @drop="handleDrop"
        @dragover="dragover"
        @dragleave="dragleave"
        :for="`${this.identifier}`"
      >
        <span class="typcn typcn-upload text-xl leading-none mr-3"></span>
      </label>
      <div class="flex flex-col w-full">
        <div
          v-for="(item, index) in fileList"
          :key="index"
          class="flex bg-offwhite p-2 rounded shadow w-full justify-between items-center flex-col"
        >
          <div class="flex w-full">
            <span class="truncate flex mobile:pr-0 max-w-full font-medium">{{
              item.name
            }}</span>
          </div>
          <div class="flex items-center justify-between w-full">
            <span class="flex text-sm">{{ humanizeSize(item.size) }}</span>
            <button class="btn-danger ml-2" @click="removeItem(index)">
              <span class="label">Eemalda</span>
            </button>
          </div>
        </div>
      </div>
      <button
        class="btn-danger"
        v-if="fileList.length > 0 && !singleFile"
        @click="reset"
      >
        <span class="icon typcn typcn-times"></span>
        <span class="label">Tühjenda</span>
      </button>
    </div>
  </div>
</template>

<script>
import { FileSizeHumanizer } from "@/assets/mixins/GewodoMixins";
export default {
  props: {
    singleFile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileList: [],
    };
  },
  mixins: [FileSizeHumanizer],
  methods: {
    updateFiles() {
      if (this.singleFile) {
        this.fileList = [];
        this.fileList.push(this.$refs.files.files[0]);
      } else {
        for (let file of this.$refs.files.files) {
          this.fileList.push(file);
        }
      }

      this.$emit("filesUpdated", this.fileList);
    },
    removeItem(item) {
      this.fileList.splice(item, 1);
      this.$refs.files.value = null;
      this.$emit("filesUpdated", this.fileList);
    },
    handleDrop(e) {
      e.preventDefault();
      this.$refs.files.files = e.dataTransfer.files;
      this.updateFiles();
      e.currentTarget.classList.remove("bg-primary-dark");
      e.currentTarget.classList.remove("text-white");
    },
    dragover(e) {
      e.preventDefault();
      e.currentTarget.classList.add("bg-primary-dark");
      e.currentTarget.classList.add("text-white");
    },
    dragleave(e) {
      if (e.currentTarget.classList.contains("bg-primary-dark")) {
        e.currentTarget.classList.remove("bg-primary-dark");
        e.currentTarget.classList.remove("text-white");
      }
    },
    reset() {
      this.fileList = [];
      this.$refs.files.value = "";
      this.$emit("filesUpdated", this.fileList);
    },
  },
  computed: {
    identifier() {
      return this.$vnode.key ?? "assetsFieldHandle";
    },
  },
};
</script>

<style lang="scss">
.file-input {
  @apply rounded flex flex-col items-center h-auto relative;
  input {
    @apply p-0 absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 0.1px;
    width: 0.1px;
  }
}
</style>
