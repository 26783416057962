<template>
  <div class="relative flex">
    <div
      class="flex bg-primary rounded text-white font-medium p-1 cursor-pointer hover:bg-primary-dark duration-100"
      @click="showMenu = !showMenu"
    >
      <slot name="title" />
    </div>

    <div
      class="absolute flex flex-col z-50 top-full bg-white rounded min-w-max mt-1 shadow-xl font-medium"
      v-if="showMenu"
    >
      <slot name="menu-content" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showMenu: false,
    };
  },
};
</script>
<style lang="scss"></style>
