<template>
  <div class="data-container-content">
    <div class="flex flex-wrap gap-x-2 gap-y-2">
      <div
        class="w-1/3 bg-white rounded shadow flex flex-col gap-y-2 p-2"
        v-for="assignment in taskData.sub_tasks"
        :key="assignment.worker.id"
      >
        <div
          class="flex items-center justify-between bg-offwhite rounded-t -mx-2 -mt-2 p-2"
        >
          <h3>{{ assignment.worker.worker_name }}</h3>
          <div class="flex items-center gap-x-2">
            <span
              class="h-4 w-4 bg-green rounded-full"
              title="Töötajal on aktiivne taimer"
              v-if="workerHasActiveTimer(assignment.worker.profile)"
            ></span>
            <span>{{ getWorkerTimeTotal(assignment.worker.profile) }}</span>
          </div>
        </div>
        <div class="flex items-center gap-x-2">
          <div class="flex flex-1 items-center gap-x-2">
            <h4>Algus</h4>
            <time-selector
              v-model="assignment.start_time"
              v-if="assignment.start_time"
              width="8"
            />
            <span
              class="w-full h-full hover:bg-offwhite hover:p-1 rounded duration-200 cursor-pointer"
              v-else
              @click="
                () =>
                  (assignment.start_time = {
                    hour: moment(taskData.start_time).hour(),
                    minute: moment(taskData.start_time).minute(),
                  })
              "
            >
              {{ `${moment(taskData.start_time).format("HH:mm")}` }}
            </span>
            <button
              @click="assignment.start_time = null"
              class="btn-danger w-5"
              v-if="assignment.start_time"
            >
              <span class="typcn typcn-times leading-none" />
            </button>
          </div>
          <div class="flex flex-1 items-center gap-x-2">
            <h4>Lõpp</h4>
            <time-selector
              v-model="assignment.end_time"
              v-if="assignment.end_time"
              width="10"
            />
            <span
              v-else
              class="w-full h-full hover:bg-offwhite p-1 rounded duration-200 cursor-pointer"
              @click="
                () =>
                  (assignment.end_time = {
                    hour: moment(taskData.end_time).hour(),
                    minute: moment(taskData.end_time).minute(),
                  })
              "
            >
              {{ `${moment(taskData.end_time).format("HH:mm")}` }}
            </span>
            <button
              @click="assignment.end_time = null"
              class="btn-danger w-5"
              v-if="assignment.end_time"
            >
              <span class="typcn typcn-times leading-none" />
            </button>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex gap-x-2">
            <h4>Lähteaadress</h4>
            <button
              class="btn-danger"
              @click="assignment.location = null"
              v-if="assignment.location"
            >
              x
            </button>
          </div>
          <div class="flex">
            <location-inaddress
              v-if="assignment.location"
              v-model="assignment.location"
              :emit-standardized-object="true"
              class="w-full"
            />
            <span
              v-else
              class="flex-grow flex truncate py-1 hover:px-2 hover:bg-offwhite duration-200 rounded h-full cursor-pointer"
              @click="() => (assignment.location = {})"
            >
              {{ taskData.location ? taskData.location.long_address : "-" }}
            </span>
          </div>
        </div>

        <div class="flex flex-col">
          <div class="flex gap-x-2">
            <h4>Sihtaadress</h4>
            <button
              class="btn-danger"
              @click="assignment.end_location = null"
              v-if="assignment.end_location"
            >
              x
            </button>
          </div>
          <div class="flex">
            <location-inaddress
              v-if="assignment.end_location"
              v-model="assignment.end_location"
              :emit-standardized-object="true"
              class="w-full"
            />
            <span
              class="w-full truncate py-1 hover:bg-offwhite hover:px-2 duration-200 rounded h-full cursor-pointer"
              v-else
              @click="
                () =>
                  (assignment.end_location = taskData.end_location
                    ? taskData.end_location
                    : {})
              "
            >
              {{
                taskData.end_location ? taskData.end_location.long_address : "-"
              }}
            </span>
          </div>
        </div>

        <div class="flex">
          <gwd-textarea
            v-model="assignment.description"
            placeholder="Kirjeldus"
          />
        </div>
        <card-view-assignment-equipment
          class="flex flex-col"
          :equipment="
            assignment.worker && assignment.worker.equipment
              ? assignment.worker.equipment
              : []
          "
        />
      </div>
    </div>
  </div>
</template>
<script>
import LocationInaddress from "@/components/reusable/LocationInaddress.vue";
import TimeSelector from "@/components/reusable/TimeSelector.vue";
import CardViewAssignmentEquipment from "./CardViewAssignmentEquipment.vue";
import moment from "moment";
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    LocationInaddress,
    TimeSelector,
    CardViewAssignmentEquipment,
  },
  data() {
    return {
      moment: moment,
    };
  },
  methods: {
    workerHasActiveTimer(workerUUID) {
      return this.timerData
        .filter((x) => x.worker === workerUUID)
        .some((x) => !x.stopper_end);
    },
    getWorkerTimeTotal(workerUUID) {
      return moment
        .utc(
          this.timerData
            .filter((x) => x.worker === workerUUID)
            .map((x) =>
              moment
                .duration(
                  moment(x.stopper_end ? moment(x.stopper_end) : moment()).diff(
                    moment(x.stopper_start)
                  )
                )
                .as("milliseconds")
            )
            .reduce((len, sum) => len + sum, 0)
        )
        .format("HH:mm:ss");
    },
  },
  computed: {
    ...mapState({
      taskData: (state) => state.modals.taskModal.taskData,
    }),
    ...mapGetters({
      timerData: "modals/taskModal/timerData",
      taskWorkers: "modals/taskModal/workers",
    }),
  },
};
</script>
<style lang="scss"></style>
