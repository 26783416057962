import StoreRequestHandler from "../../../assets/mixins/StoreRequestHandler";

const initialState = {
  show: false,
  data: null,
  parameters: {
    startLocation: null,
    endLocation: null,
    duration: 1,
    worker_amount: 2,
    attributes: [],
    startDate: null,
  },
  loading: false,
  selectedSlot: null,
  results: null,
};

const processParams = (params) => {
  let processedParams = {};

  processedParams.attributes = params.attributes.map((x) => x.map((y) => y.id));
  processedParams.duration = params.duration * 60;
  processedParams.start_location = params.startLocation;
  processedParams.end_location = params.endLocation;
  processedParams.worker_amount = params.worker_amount;
  processedParams.start_date = params.startDate.format(
    "YYYY-MM-DD[T]HH:mm:ssZ"
  );
  return processedParams;
};

export const taskPlanningModal = {
  state: {
    ...initialState,
  },
  namespaced: true,
  mutations: {
    open(state, data) {
      state.show = true;
      state.data = data;
    },
    close(state) {
      state.data = null;
      state.parameters = {
        startLocation: null,
        endLocation: null,
        duration: 1,
        worker_amount: 2,
        attributes: [],
        startDate: null,
      };
      state.selectedSlot = null;
      state.results = null;
      state.show = false;
    },
    selectSlot(state, data) {
      state.selectedSlot = data;
    },
    setResults(state, data) {
      state.results = data;
    },
    setDate(state, data) {
      state.parameters.startDate = data;
    },
  },
  actions: {
    open({ commit }, data) {
      commit("open", data);
    },
    close({ commit }) {
      commit("setResults", null);
      commit("close");
    },
    retrieveAvailabilityData({ commit, dispatch, state, rootState }) {
      state.loading = true;
      StoreRequestHandler.apiRequest(
        `calendar/${rootState.companyData.activeCompany.uuid}/suitable/task/`,
        "post",
        true,
        processParams(state.parameters)
      )
        .then((res) => {
          state.loading = false;
          commit("setResults", res.data);
        })
        .catch((err) => {
          state.loading = false;

          dispatch(
            "messageHandler/throwMessage",
            {
              type: "error",
              error: err,
              text: "Midagi läks valesti",
              ttl: 10,
            },
            {
              root: true,
            }
          );
        });
    },
    resetAvailabilityData({ commit }) {
      commit("setResults", null);
    },
    resetSelectedSlot({ commit }) {
      commit("selectSlot", null);
    },
    selectSlot({ commit }, data) {
      commit("selectSlot", data);
    },
    setDate({ commit }, data) {
      commit("setDate", data);
    },
  },
  getters: {
    selectedSlot: (state) => state.selectedSlot,
    results: (state) => state.results,
    parameters: (state) => state.parameters,
    selectedDate: (state) => state.parameters.startDate,
    loading: (state) => state.loading,
  },
};
