let banks = [
  { bank: "Coop Pank aktsiaselts", swift_code: "EKRDEE22", id: "42" },
  { bank: "Eesti Pank", swift_code: "EPBEEE2X", id: "16" },
  { bank: "AS SEB Pank", swift_code: "EEUHEE2X", id: "10" },
  { bank: "Swedbank AS", swift_code: "HABAEE2X", id: "22" },
  { bank: "Luminor Bank AS", swift_code: "RIKOEE22", id: "96" },
  { bank: "Luminor Bank AS", swift_code: "RIKOEE22", id: "17" },
  { bank: "AS Citadele banka Eesti filiaal", swift_code: "PARXEE22", id: "12" },
  {
    bank: "Svenska Handelsbanken AB Eesti filiaal",
    swift_code: "HANDEE22",
    id: "83",
  },
  { bank: "AS TBB pank", swift_code: "TABUEE22", id: "00" },
  {
    bank: "OP Corporate Bank plc Eesti filiaal",
    swift_code: "OKOYEE2X",
    id: "51",
  },
  { bank: "AS LHV Pank", swift_code: "LHVBEE22", id: "77" },
  { bank: "Bigbank AS", swift_code: "BIGKEE2B", id: "75" },
  { bank: "AS Pocopay", swift_code: "AKELEE21", id: "99" },
  { bank: "Ühisarveldused AS", swift_code: "UHISEE21", id: "02" },
];
export default {
  methods: {
    parseIban(iban) {
      let bankCode = iban.substring(4, 6).replace(/^0+/, "");
      return banks.find((d) => {
        return d.id === bankCode;
      });
    },
  },
};
