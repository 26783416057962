const fileTypes = [
  { key: "application/pdf", icon: "/icons/filetypes/pdf.svg" },
  {
    key: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    icon: "/icons/filetypes/xls.svg",
  },
  {
    key: "application/json",
    icon: "/icons/filetypes/json-file.svg",
  },
  {
    key: "image/png",
    icon: "/icons/filetypes/png.svg",
  },
  {
    key: "application/zip",
    icon: "/icons/filetypes/zip.svg",
  },
  {
    key: "text/html",
    icon: "/icons/filetypes/html.svg",
  },
  {
    key: "image/jpeg",
    icon: "/icons/filetypes/jpg.svg",
  },
  {
    key: "text/csv",
    icon: "/icons/filetypes/csv.svg",
  },
  {
    key: "application/gzip",
    icon: "/icons/filetypes/zip.svg",
  },
  {
    key: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    icon: "/icons/filetypes/ppt.svg",
  },
  {
    key: "application/x-msdos-program",
    icon: "/icons/filetypes/exe.svg",
  },
  {
    key: "application/vnd.oasis.opendocument.text",
    icon: "/icons/filetypes/odt.svg",
  },
  {
    key: "application/octet-stream",
    icon: "/icons/filetypes/e_id_logo.svg",
  },
  {
    key: "video/mp4",
    icon: "/icons/filetypes/mp4.svg",
  },
  {
    key: "video/webm",
    icon: "/icons/filetypes/webm.svg",
  },
  {
    key: "image/svg+xml",
    icon: "/icons/filetypes/svg.svg",
  },
  {
    key: "text/plain",
    icon: "/icons/filetypes/txt.svg",
  },
  {
    key: "application/vnd.oasis.opendocument.spreadsheet",
    icon: "/icons/filetypes/ods.svg",
  },
  {
    key: "text/xml",
    icon: "/icons/filetypes/xml.svg",
  },
];

export default {
  methods: {
    parseFileType(file) {
      if (fileTypes.find((item) => item.key === file.file_type) !== undefined) {
        return fileTypes.find((item) => item.key === file.file_type).icon;
      } else {
        return "/icons/filetypes/file.svg";
      }
    },
    parseUploadedFileType(file) {
      if (fileTypes.find((item) => item.key === file.type) !== undefined) {
        return fileTypes.find((item) => item.key === file.type).icon;
      } else {
        return "/icons/filetypes/file.svg";
      }
    },
  },
};
