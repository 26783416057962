<template>
  <div class="data-container">
    <div class="data-container-header gap-x-3">
      <h3>Kuupäev</h3>
      <button
        @click="prevWeek"
        :class="
          moment(startDate).isSame(moment(), 'week')
            ? 'btn-disabled'
            : 'btn-primary'
        "
        :disabled="moment(startDate).isSame(moment(), 'week')"
      >
        Tagasi
      </button>
      <button class="btn-primary" @click="nextWeek">Edasi</button>
      <span
        >Nädal {{ moment(startDate).week() }} ({{
          moment(startDate).year()
        }})</span
      >
    </div>
    <div class="data-container-content overflow-x-scroll">
      <div class="date-grid">
        <div
          class="date-container"
          v-for="(date, index) in dateArray"
          :key="index"
          :class="[
            selectedDate &&
            moment(selectedDate).isSame(moment(date.date), 'day')
              ? 'selected'
              : '',
          ]"
          @click="selectDate(date.date)"
        >
          <div class="date-container-text">
            <h4 class="date-text">
              {{ date.date.format("dd") }}
              {{ date.date.format("DD.MM.YYYY") }}
            </h4>
            <div class="flex items-center">
              <span class="job-count-text mr-2"
                >{{ date.taskCount }}
                {{ date.taskCount === 1 ? "töö" : "tööd" }}</span
              >
              <span class="mr-2">/</span>
              <progress-bar
                :percentage="date.percentage"
                :bar="
                  date.percentage <= 60
                    ? 'green'
                    : date.percentage <= 90
                    ? 'attention'
                    : 'danger'
                "
                class="flex-grow h-4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import ProgressBar from "@/components/reusable/ProgressBar";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dateArray: null,
      dateAmount: 14,
      startDate: null,
      moment: moment,
    };
  },
  components: {
    ProgressBar,
  },
  mounted() {
    this.startDate = moment();
    this.selectDate(this.startDate);
    this.generateDateArray();
  },
  methods: {
    generateDateArray() {
      this.dateArray = [];
      this.apiRequest(
        `calendar/${this.companyId}/tasks/occupation/`,
        "get",
        true,
        { start_date: this.startDate }
      ).then((res) => {
        for (let i = 0; i < this.dateAmount; i++) {
          const dayData = res.data[i];
          this.dateArray.push({
            date: moment(this.startDate).add(i, "days"),
            taskCount: dayData.count,
            percentage: dayData.percentage,
          });
        }
      });
    },
    selectDate(date) {
      this.$store.dispatch("modals/taskPlanningModal/setDate", date);
    },
    nextWeek() {
      this.startDate.add(1, "week");
      this.generateDateArray();
    },
    prevWeek() {
      if (
        moment(this.startDate)
          .subtract(1, "week")
          .isSameOrBefore(moment(), "week")
      )
        this.startDate = moment();
      else this.startDate.subtract(1, "week");
      this.generateDateArray();
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      selectedDate: "modals/taskPlanningModal/selectedDate",
    }),
  },
};
</script>
<style scoped lang="scss">
.date-grid {
  @apply grid grid-cols-7 bg-offwhite rounded;
  .date-container {
    @apply flex items-center p-2 cursor-pointer hover:bg-primary hover:bg-opacity-90 hover:text-offwhite duration-100 border-r border-offwhite-dark gap-x-2;
    .date-container-text {
      @apply flex flex-col flex-grow;
      .job-count-text {
        @apply text-xs;
      }
    }
    &:nth-child(1) {
      @apply rounded-tl;
    }
    &:nth-child(7) {
      @apply rounded-tr border-none;
    }
    &:nth-child(8) {
      @apply rounded-bl;
    }
    &:nth-child(14) {
      @apply rounded-br border-none;
    }
    &:nth-child(n-7) {
      @apply border-b;
    }
    &.selected {
      @apply bg-primary text-white;
    }
  }
}
</style>
