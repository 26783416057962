export const convertCurrency = (value, rate) => {
  return Math.round((parseFloat(value) / parseFloat(rate)) * 100) / 100;
};

export const round2 = (value) => {
  return DecimalPrecision2.round(value, 2);
};

export const generateModalKey = () => {
  return String(Math.random() * 10000000);
};
export const DecimalPrecision2 = (function () {
  if (Number.EPSILON === undefined) {
    Number.EPSILON = Math.pow(2, -52);
  }
  if (Math.sign === undefined) {
    Math.sign = function (x) {
      return (x > 0) - (x < 0) || +x;
    };
  }
  return {
    // Decimal round (half away from zero)
    round: function (num, decimalPlaces = 2) {
      const p = Math.pow(10, decimalPlaces || 0);
      return Math.round(num * p * (1 + Number.EPSILON)) / p;
    },
    // Decimal ceil
    ceil: function (num, decimalPlaces) {
      const p = Math.pow(10, decimalPlaces || 0);
      return Math.ceil(num * p * (1 - Math.sign(num) * Number.EPSILON)) / p;
    },
    // Decimal floor
    floor: function (num, decimalPlaces) {
      const p = Math.pow(10, decimalPlaces || 0);
      return Math.floor(num * p * (1 + Math.sign(num) * Number.EPSILON)) / p;
    },
    // Decimal trunc
    trunc: function (num, decimalPlaces) {
      return (num < 0 ? this.ceil : this.floor)(num, decimalPlaces);
    },
    // Format using fixed-point notation
    toFixed: function (num, decimalPlaces) {
      return this.round(num, decimalPlaces).toFixed(decimalPlaces);
    },
  };
})();
