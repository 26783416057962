import { isEqual } from "lodash";
import StoreRequestHandler from "@/assets/mixins/StoreRequestHandler";
import set from "lodash/set";

export const project = {
  namespaced: true,
  state: {
    project: null,
    projectClone: null,
  },
  mutations: {
    setProject(state, data) {
      state.project = JSON.parse(JSON.stringify(data));
      state.projectClone = JSON.parse(JSON.stringify(data));
    },
    clearState(state) {
      state.project = null;
      state.projectClone = null;
    },
    resetProject(state) {
      state.project = JSON.parse(JSON.stringify(state.projectClone));
    },
  },
  actions: {
    resetProject({ commit }) {
      commit("resetProject");
    },
    retrieveProject({ commit }, data) {
      return new Promise((resolve, reject) => {
        let url = data.isClient
          ? `notes/draft/${data.id}/client/`
          : `company/drafts/${data.id}/`;
        StoreRequestHandler.apiRequest(url, "get", true)
          .then((res) => {
            commit("setProject", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    clearProject({ commit }) {
      commit("clearState");
    },
    saveProject({ state }) {
      let data = { ...state.projectClone };

      Object.keys(data).forEach((k) => {
        if (isEqual(data[k], state.project[k])) delete data[k];
        else if (k === "location") {
          if (data[k] === null && state.project[k]) {
            StoreRequestHandler.apiRequest(
              `company/draft/${state.project.uuid}/location/delete/`,
              "delete",
              true
            );
          }
        } else if (k === "client") {
          let updatedValues = {};
          Object.keys(data[k]).forEach((j) => {
            if (!isEqual(data[k][j], state.project.client[j])) {
              set(updatedValues, j, data[k][j]);
            }
          });
          if (Object.keys(updatedValues).length > 0) {
            StoreRequestHandler.apiRequest(
              `company/${state.projectClone.company.uuid}/clients/${data[k]["id"]}/edit/`,
              "patch",
              true,
              updatedValues
            );
          }

          delete data[k];
        } else if (k === "payer") {
          let updatedValues = {};
          Object.keys(data[k]).forEach((j) => {
            if (!isEqual(data[k][j], state.project.payer[j])) {
              set(updatedValues, j, data[k][j]);
            }
          });
          if (Object.keys(updatedValues).length > 0) {
            StoreRequestHandler.apiRequest(
              `company/${state.projectClone.company.uuid}/clients/${data[k]["id"]}/edit/`,
              "patch",
              true,
              updatedValues
            );
          }

          delete data[k];
        }
      });
      return StoreRequestHandler.apiRequest(
        `company/draft/${state.projectClone.uuid}/edit/`,
        "patch",
        true,
        data
      );
    },
    updateProjectClient({ state }, inputData) {
      let data;
      if (inputData.selectClient) {
        data = { client: inputData.clientId };
      } else {
        data = { payer: inputData.clientId };
      }
      return StoreRequestHandler.apiRequest(
        `company/draft/${state.projectClone.uuid}/edit/`,
        "patch",
        true,
        data
      );
    },
    deleteProject({ state }) {
      return StoreRequestHandler.apiRequest(
        `company/draft/${state.project.uuid}/delete/`,
        "delete",
        true
      );
    },
  },
  getters: {
    projectEdited: (state) => {
      let compObject = JSON.parse(JSON.stringify(state.project));
      let compObjectClone = JSON.parse(JSON.stringify(state.projectClone));
      delete compObject.location;
      delete compObjectClone.location;
      return !isEqual(compObject, compObjectClone);
    },
    data: (state) => state.project,
    projectId: (state) => state.project.uuid,
  },
};
