<template>
  <div class="flex rounded-md gap-x-1 items-center">
    <div class="flex">
      <div
        @click="changePage(page - 1)"
        class="h-6 w-6 rounded-md leading-none font-semibold cursor-pointer items-center justify-center flex bg-offwhite hover:bg-offwhite-dark duration-100 shadow"
      >
        <img
          src="/icons/chevron-up.svg"
          class="filter-to-black transform -rotate-90 h-3 w-3"
          alt="Decrement page icon"
        />
      </div>
    </div>
    <div v-for="i in pagesToShow" :key="i">
      <div
        class="h-6 w-6 rounded-md leading-none font-semibold cursor-pointer flex items-center justify-center duration-100 shadow text-sm"
        :class="
          page === i
            ? 'bg-primary text-white'
            : 'bg-offwhite hover:bg-offwhite-dark'
        "
        @click="changePage(i)"
      >
        {{ i }}
      </div>
    </div>
    <div>
      <div
        @click="changePage(page + 1)"
        class="h-6 w-6 rounded-md leading-none font-semibold cursor-pointer items-center justify-center flex bg-offwhite hover:bg-offwhite-dark duration-100 shadow"
      >
        <img
          src="/icons/chevron-up.svg"
          class="filter-to-black transform rotate-90 h-3 w-3"
          alt="Increment page icon"
        />
      </div>
    </div>
    <div class="flex gap-x-2" v-if="this.count > 6">
      <input
        v-model.number="manualPageNumber"
        @focus="attachKeyboardHandler"
        @blur="detachKeyboardHandler"
        class="w-12 text-sm h-6"
        placeholder="Leht"
      />
      <button
        class="btn-primary text-sm h-6"
        @click="changePage(manualPageNumber)"
      >
        Mine
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    page: { type: Number, default: null },
    count: { type: Number, default: null },
    range: { type: Number, default: 3 },
  },
  data() {
    return {
      manualPageNumber: null,
    };
  },
  methods: {
    changePage(index) {
      if (index > 0 && index <= this.count) this.$emit("pageChange", index);
    },
    keyboardHandler(e) {
      if (e.key === "Enter") {
        if (this.manualPageNumber < 1) return this.changePage(1);
        if (this.manualPageNumber > this.count)
          return this.changePage(this.count);
        return this.changePage(this.manualPageNumber);
      }
    },
    attachKeyboardHandler() {
      window.addEventListener("keydown", this.keyboardHandler);
    },
    detachKeyboardHandler() {
      window.removeEventListener("keydown", this.keyboardHandler);
    },
  },
  computed: {
    pagesToShow() {
      let pageArr = [...Array(this.count).keys()].map((x) => x + 1);
      let center = this.page;
      let start = this.page - this.range;
      let end = this.page + this.range;
      if (this.page - this.range < 1) {
        center = Math.abs(this.page - this.range) + this.page;
        start = center - this.range;
        end = center + this.range;
      }
      if (this.page + this.range > this.count) {
        center = this.count - this.range;
        start = center - this.range;
        end = center + this.range;
      }
      pageArr = pageArr.slice(start, end);
      if (!pageArr.includes(1)) pageArr = [1, ...pageArr];
      if (!pageArr.includes(this.count)) pageArr.push(this.count);
      return pageArr;
    },
  },
};
</script>
