<template>
  <div class="status-menu">
    <span
      class="status-menu-item"
      v-if="taskData.status !== 1 && taskData.status === 2 && isManager"
      v-html="parseTaskStatus(1)"
      @click="changeTaskStatus(1)"
    />
    <span
      class="status-menu-item"
      v-if="taskData.status !== 2 && isManager"
      v-html="parseTaskStatus(2)"
      @click="changeTaskStatus(2)"
    />
    <span
      class="status-menu-item"
      v-if="
        (taskData.status !== 3 && isManager) ||
        (!isManager && taskData.status === 4)
      "
      v-html="parseTaskStatus(3)"
      @click="changeTaskStatus(3)"
    />
    <span
      class="status-menu-item"
      v-if="taskData.status !== 4"
      v-html="parseTaskStatus(4)"
      @click="changeTaskStatus(4)"
    />
    <span
      class="status-menu-item"
      v-if="taskData.status !== 5"
      v-html="parseTaskStatus(5)"
      @click="changeTaskStatus(5)"
    />
    <span
      class="status-menu-item"
      :class="taskData.status !== 6 ? 'rounded-b' : ''"
      v-if="taskData.status !== 6 && isManager"
      v-html="parseTaskStatus(6)"
      @click="endTask"
    />
  </div>
</template>

<script>
import { StatusParser } from "@/assets/mixins/GewodoMixins";
import { mapGetters } from "vuex";
export default {
  props: {
    taskData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    userRole: { type: String, default: "" },
  },
  mixins: [StatusParser],
  methods: {
    changeTaskStatus(status) {
      this.$emit("changeStatus", status);
    },
    endTask() {
      this.$emit("endTask");
    },
  },
  computed: {
    ...mapGetters({
      isManager: "companyData/isManager",
    }),
  },
};
</script>

<style lang="scss" scoped>
.status-menu-item {
  @apply pl-2 py-1;
}
</style>
