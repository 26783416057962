<template>
  <div
    class="flex items-center gap-x-2 box-border max-w-full cursor-pointer"
    :class="[
      isFocused ? 'shadow-outline mobile:shadow-none' : '',
      showAddresses ? 'rounded-t' : 'rounded',
      showError ? 'shadow-dangeroutline' : '',
    ]"
  >
    <span v-if="!selectedLocation">
      <img src="/bc21/search.svg" class="h-4 w-4" alt="Search icon" />
      <h4 class="hidden mobile:flex">Otsi asukohta</h4>
    </span>
    <div
      class="flex relative box-border w-full"
      v-if="
        !selectedLocation ||
        (!selectedLocation.long_address && !selectedLocation.ipikkaadress)
      "
    >
      <gwd-textarea
        v-model="searchString"
        type="text"
        class="flex-grow"
        :class="[
          isWhite ? 'bg-white' : '',
          showAddresses ? 'option-display' : '',
        ]"
        :placeholder="text"
        @focus="isFocused = true"
        @blur="isFocused = false"
      />
      <div class="address-list absolute z-50" v-if="showAddresses">
        <div
          class="flex flex-col"
          v-for="(address, index) in foundAddresses"
          :key="address.tunnus"
        >
          <div
            @click="selectLocation(address)"
            :class="index === foundAddresses.length - 1 ? 'rounded-b-xl' : ''"
            class="cursor-pointer px-2 py-3 justify-between flex flex-row items-center hover:bg-offwhite hover:bg-opacity-50"
          >
            <span class="flex w-full">{{ address.ipikkaadress }}</span>
          </div>
          <div
            class="h-1 w-full bg-offwhite rounded-full"
            v-if="index !== foundAddresses.length - 1"
          ></div>
        </div>
        <div class="flex p-2" v-if="foundAddresses.length === 0">
          <span> Selle nimega asukohta ei leitud </span>
        </div>
      </div>
    </div>
    <div
      class="flex hover:bg-offwhite rounded duration-200 py-2 hover:px-2 truncate box-border overflow-x-hidden"
      v-else
      @click="selectedLocation ? clearSelectedLocation() : clearSelection()"
    >
      <span class="box-border flex">
        {{ selectedLocation ? selectedLocation.long_address : "-" }}
      </span>
    </div>
  </div>
</template>

<script>
import { RequestHandler } from "@/assets/mixins/GewodoMixins";
import { normalizeLocationObject } from "@/assets/utils/commonTransforms";
import debounce from "lodash/debounce";

export default {
  name: "LocationInaddress",
  mixins: [RequestHandler],
  props: {
    text: {
      type: String,
      default: "Sisestage asukoha aadress",
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    location: {
      type: String,
      default: "",
    },
    showError: {
      type: Boolean,
      default: false,
    },
    showDeleteText: {
      type: Boolean,
      default: true,
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    emitStandardizedObject: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      searchString: "",
      foundAddresses: [],
      showAddresses: false,
      isFocused: false,
      presetLocationExists: false,
      selectedLocation: null,
    };
  },
  mounted() {
    if (this.value) {
      this.selectedLocation = this.value;
    } else if (this.location?.length > 0) {
      this.searchString = this.location;
      this.presetLocationExists = true;
    }
  },
  methods: {
    clearSelection() {
      this.searchString = "";
      this.foundAddresses = [];
      this.showAddresses = false;
      this.$emit("clearLocation");
    },
    clearSelectedLocation() {
      this.clearSelection();
      this.selectedLocation = null;
    },
    loadAddresses() {
      this.foundAddresses = [];
      this.showAddresses = false;
      if (this.searchString.length > 3) {
        this.apiRequest(
          "https://inaadress.maaamet.ee/inaadress/gazetteer?address=" +
            this.searchString.replace(" ", "+"),
          "get"
        ).then((res) => {
          if (Array.isArray(res.data.addresses)) {
            this.foundAddresses = res.data.addresses;
          }
          this.showAddresses = true;
        });
      }
    },
    selectLocation(input) {
      let locationObj;
      if (input.long_address) locationObj = input;
      else locationObj = normalizeLocationObject(input);

      this.$emit("input", locationObj);
      this.selectedLocation = locationObj;
    },
    debounceSearchQuery: debounce(function () {
      this.loadAddresses();
    }, 500),
  },
  watch: {
    searchString() {
      if (!this.presetLocationExists) {
        this.debounceSearchQuery();
      } else {
        this.presetLocationExists = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.address-list {
  @apply w-full flex flex-col shadow rounded-b bg-white overflow-y-auto;
  z-index: 9999;
  max-height: 300px;
  top: 100%;
}
.address-field {
  width: calc(100% - 2rem);
}
</style>
