var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[(
      _vm.template !== undefined &&
      _vm.filteredCosts.filter(function (y) { return !_vm.addedCostIds.includes(("" + (y.type) + (y.id))); })
        .length > 0
    )?_c('div',{staticClass:"gwd-table-row narrow-template-list border-b border-t",style:(("background-color: " + _vm.typeColor))},[_c('span',[_vm._v(_vm._s(_vm.namePrefix))]),_c('span',[_vm._v(_vm._s(_vm.template.description))]),_c('span',[_vm._v(_vm._s(_vm.template.price)+" € / "+_vm._s(_vm.template.unit))]),(!_vm.combineCostsActive && !_vm.markAsPaid)?_c('div',{staticClass:"flex justify-end"},[_c('button',{staticClass:"btn-primary",on:{"click":_vm.selectTemplate}},[_c('span',{staticClass:"typcn typcn-plus icon"}),_c('span',{staticClass:"label"},[_vm._v("lisa")])])]):_vm._e()]):_vm._e(),_vm._l((_vm.showAdded
      ? _vm.filteredCosts
      : _vm.filteredCosts.filter(
          function (y) { return !_vm.addedCostIds.includes(("" + (y.type) + (y.id))); }
        )),function(cost){return _c('div',{key:cost.id,staticClass:"gwd-table-row nopad narrow-cost-list items-center"},[_c('div',{staticClass:"flex h-full items-center gap-x-1",staticStyle:{"min-width":"7px"},domProps:{"innerHTML":_vm._s(_vm.parseCostColorAndName(cost.type))}}),_c('span',{staticClass:"py-2"},[_vm._v(_vm._s(cost.description))]),_c('div',{staticClass:"flex justify-center py-2"},[_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(cost.amount))]),_c('span',[_vm._v(" "+_vm._s(cost.unit))])]),_c('span',{staticClass:"py-2"},[_vm._v(_vm._s(cost.price))]),_c('div',{staticClass:"flex justify-end py-2"},[(_vm.markAsPaid || _vm.combineCostsActive)?_c('gwd-checkbox',{attrs:{"value":_vm.combineCostsActive
            ? _vm.combinedCosts.includes(cost)
            : _vm.markedAsPaid.includes(cost)},on:{"input":function($event){_vm.combineCostsActive
            ? _vm.addToCombinedCosts(cost)
            : _vm.addToMarkedAsPaid(cost)}}}):_vm._e(),(
          !(_vm.combineCostsActive || _vm.markAsPaid) &&
          (!_vm.showAdded ||
            (_vm.showAdded && !_vm.addedCostIds.includes(("" + (cost.type) + (cost.id)))))
        )?_c('button',{staticClass:"btn-primary",on:{"click":function($event){return _vm.addItem(cost)}}},[_c('span',{staticClass:"label"},[_vm._v("Lisa ")])]):_vm._e()],1)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }