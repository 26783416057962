<template>
  <div class="location-coordinate-selector">
    <input type="text" v-model="location.address" placeholder="Aadress" />
    <input type="text" v-model="location.latitude" placeholder="Laius" />
    <input type="text" v-model="location.longitude" placeholder="Pikkus" />
    <div class="btn-primary" @click="showMap = true">
      <span class="typcn typcn-map icon" />
    </div>
    <div
      class="btn-danger"
      @click="
        () => {
          location.address = '';
          location.latitude = '';
          location.longitude = '';
          location.info = '';
        }
      "
    >
      <span class="typcn typcn-trash icon" />
    </div>
    <map-location-selector
      v-if="showMap"
      @locationSelected="
        (e) => {
          location.latitude = e.lat;
          location.longitude = e.lng;
          showMap = false;
        }
      "
      @close="() => (showMap = false)"
    />
  </div>
</template>
<script>
import { generateCoordinateLocationObject } from "../../assets/utils/commonTransforms";
import MapLocationSelector from "./MapLocationSelector.vue";
export default {
  props: {
    existingObject: {
      type: Object,
      default: null,
    },
    showMapOnMount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      location: {
        address: "",
        latitude: "",
        longitude: "",
      },
      showMap: false,
    };
  },
  mounted() {
    if (this.existingObject) {
      this.location.address = this.existingObject.long_address;
      this.location.latitude = this.existingObject.latitude;
      this.location.longitude = this.existingObject.longitude;
    } else if (this.showMapOnMount) this.$nextTick(() => (this.showMap = true));
  },
  components: {
    MapLocationSelector,
  },
  methods: {
    setAddressString(string) {
      this.location.address = string;
    },
  },
  computed: {
    locationObject() {
      return generateCoordinateLocationObject(
        this.location.address,
        this.location.latitude,
        this.location.longitude
      );
    },
  },
  watch: {
    locationObject: {
      deep: true,
      handler() {
        this.$emit("locationChanged", this.locationObject);
      },
    },
  },
};
</script>
<style lang="scss">
.location-coordinate-selector {
  @apply grid grid-cols-4 gap-x-2;
  grid-template-columns: 1fr 0.4fr 0.4fr 0.1fr 0.1fr;
}
</style>
