<template>
  <div class="flex flex-col w-full mb-2 bg-offwhite rounded shadow">
    <div
      class="flex w-full items-center hover:bg-offwhite-dark p-1 cursor-pointer rounded-t bg-offwhite-dark"
    >
      <div
        v-for="worker in comb.workers"
        :key="worker.id"
        class="mr-2 flex bg-white rounded shadow"
      >
        <div
          class="bg-primary text-white rounded-l flex items-center justify-center p-1"
        >
          <span
            class="typcn typcn-user h-6 w-6 text-center leading-none text-xl"
          ></span>
        </div>
        <div class="px-2 py-1">
          <h4>{{ worker.worker_name }}</h4>
        </div>
      </div>
      <div class="flex items-center" v-if="selectedSlots.length > 0">
        <div class="flex bg-white shadow rounded mr-2 items-center">
          <div class="flex bg-primary rounded-l h-full p-1">
            <img
              src="/new/time.svg"
              class="h-6 w-6 filter-to-white"
              alt="Clock icon"
            />
          </div>
          <div class="py-1 px-2">
            {{
              `${moment(selectedSlots[0].start).format("HH:mm")} - ${moment(
                selectedSlots[selectedSlots.length - 1].end
              ).format("HH:mm")}`
            }}
          </div>
        </div>
        <button class="btn-primary" @click="confirmSelectedTime">Vali</button>
      </div>
    </div>
    <div class="flex flex-col p-2">
      <div class="flex mb-1">
        <div class="flex w-full items-center">
          <div class="flex flex-grow bg-offwhite h-6">
            <div
              class="time-slot group"
              v-for="slot in dayMap"
              :key="slot.i"
              :class="[
                slot.busy
                  ? 'busy'
                  : slot.selected
                  ? 'selected'
                  : slot.unavailable
                  ? 'unavailable'
                  : '',
                slot.hovered && !slot.busy ? 'hovered' : '',
              ]"
              @click="
                !slot.busy && !slot.unavailable ? selectSlot(slot) : () => {}
              "
              @mouseenter="handleSlotHover(slot.i)"
              @mouseleave="handleSlotLeave(slot.i)"
            >
              <div class="time-slot-tooltip hidden group-hover:flex">
                <span>{{ slot.start.format("HH:mm") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapState } from "vuex";

export default {
  name: "PlanningCard",
  props: {
    comb: {
      type: Object,
      default: null,
    },
    duration: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      moment: moment,
      expanded: false,
      times: [],
      dayStart: null,
      dayEnd: null,
      dayMap: [],
      busySegments: [],
      unavailableSegments: [],
      selectedSegments: [],
    };
  },
  mounted() {
    this.dayMap = this.initDayMap();
  },
  methods: {
    initDayMap() {
      this.dayStart = this.selectedDate ? moment(this.selectedDate) : moment();
      this.dayEnd = this.selectedDate ? moment(this.selectedDate) : moment();
      this.dayStart.set({ h: 6, m: 0, s: 0 });
      this.dayEnd.set({ h: 22, m: 0, s: 0 });
      let dayDur = this.dayEnd.diff(this.dayStart, "hours");
      let segCount = dayDur * 4;

      let dayArr = Array.from(Array(segCount).keys()).map((x) => {
        return {
          i: x,
          start: moment(this.dayStart).add(15 * x, "minutes"),
          end: moment(this.dayStart)
            .add(15 * x, "minutes")
            .add(15, "minutes"),
          busy: true,
          unavailable: false,
          hovered: false,
          selected: false,
        };
      });
      dayArr.forEach((x) => {
        x.busy = this.comb.suitable_time_ranges
          .map(
            (y) =>
              !moment(x.start).isBetween(y.start_time, y.end_time, null, [])
          )
          .every((x) => x === true);
      });
      dayArr.forEach(
        (x, i) =>
          (x.unavailable = !dayArr
            .slice(i, i + this.duration * 4)
            .every((x) => x.busy === false))
      );

      dayArr
        .slice(dayArr.length - this.duration * 4 + 1, dayArr.length)
        .forEach((x) => (x.unavailable = true));

      return dayArr;
    },
    selectTimeSlot() {
      this.$emit("selectSlot", this.comb);
    },
    getStartTime() {
      return this.dayMap.find((x) => x.i === this.selectedSegments[0]).start;
    },
    getEndTime() {
      return this.dayMap.find(
        (x) => x.i === this.selectedSegments[this.selectedSegments.length - 1]
      ).start;
    },
    confirmSelectedTime() {
      const start = this.selectedSlots[0].start;
      let data = {
        workers: this.comb.workers,
        date: start.format("YYYY-MM-DD"),
        startTime: {
          hour: start.hour(),
          minute: start.minute(),
        },
        duration: {
          hours: this.duration,
          minutes: 0,
        },
        location: this.parameters.startLocation,
        end_location: this.parameters.endLocation,
      };
      if (this.presetData && this.presetData.project) {
        data.project = this.presetData.project;
      }
      this.$store.dispatch("modals/taskPlanningModal/close");
      this.$store.dispatch("modals/addTaskModal/open", data);
    },
    handleSlotHover(index) {
      const duration = 4 * this.duration - 1;
      for (let i = index + 1; i < index + 1 + duration; i++) {
        if (
          this.dayMap[i] &&
          !this.dayMap[i].busy &&
          !this.dayMap[index].unavailable
        ) {
          this.dayMap[i].hovered = true;
        }
      }
    },
    handleSlotLeave(index) {
      const duration = 4 * this.duration - 1;
      for (let i = index + 1; i < index + 1 + duration; i++) {
        if (this.dayMap[i]) this.dayMap[i].hovered = false;
      }
      return index;
    },
    resetSelection() {
      this.selectedSegments = [];
    },
    selectSlot(slot) {
      this.dayMap.forEach((x) => (x.selected = false));
      slot.selected = true;
      for (let i = slot.i + 1; i < slot.i + this.duration * 4; i++) {
        this.dayMap[i].selected = true;
      }
    },
  },
  watch: {
    duration() {
      this.initDayMap();
    },
  },
  computed: {
    ...mapState({
      presetData: (state) => state.modals.taskPlanningModal.data,
    }),
    ...mapGetters({
      selectedDate: "modals/taskPlanningModal/selectedDate",
      parameters: "modals/taskPlanningModal/parameters",
    }),
    selectedSlots() {
      return this.dayMap
        .filter((x) => x.selected)
        .sort((a, b) => moment(a.start).unix() - moment(b.start).unix());
    },
  },
};
</script>

<style scoped lang="scss">
.time-slot {
  @apply border-r border-offwhite-dark relative;
  width: calc(100% / 44);

  &.busy {
    @apply bg-danger;
    &:hover {
      @apply bg-danger;
    }
    .time-slot-tooltip {
      @apply hidden;
    }
  }
  &.unavailable {
    @apply bg-attention;
    &:hover {
      @apply bg-attention;
    }
    .time-slot-tooltip {
      @apply hidden;
    }
  }
  &:not(.busy, .unavailable, .selected) {
    @apply bg-green;
    &:hover {
      @apply bg-primary-dark;
    }
  }
  &.selected {
    @apply bg-primary;
  }
  &.hovered:not(.busy) {
    @apply bg-primary-dark;
  }
  &:not(.busy, .unavailable) {
    .time-slot-tooltip {
      @apply absolute bg-offwhite rounded h-8 items-center justify-center w-auto p-2;
      bottom: 100%;
      right: 0;
    }
  }
}
</style>
