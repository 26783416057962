<template>
  <div
    class="navbar-dropdown mobile:w-full"
    :class="active ? 'navbar-dropdown-open wide ' : 'navbar-dropdown-closed'"
    :style="active ? { height: '24rem', width: '20rem' } : null"
    v-on-clickaway="closeTray"
  >
    <transition name="fade" :duration="300">
      <div class="notification-card-container" v-if="active">
        <div class="flex gap-x-2 justify-between px-4 py-2 bg-offwhite">
          <h4>{{ addChat ? "Uus vestlus" : "Vestlused" }}</h4>
          <div class="flex gap-x-2">
            <button v-if="!addChat" class="btn-primary" @click="goToChatView">
              Vaata kõiki
            </button>
            <button
              v-if="addChat"
              :class="
                selectedWorkers.length > 0 ? 'btn-primary' : 'btn-disabled'
              "
              @click="saveNewChat"
            >
              <span class="label">Loo</span>
            </button>
            <button
              :class="addChat ? 'btn-danger' : 'btn-primary'"
              @click="toggleAddChat"
            >
              <span v-if="!addChat" class="typcn typcn-plus icon"></span>
              <span v-else>Tühista</span>
            </button>
          </div>
        </div>
        <clip-loader v-if="!parties && !addChat" />
        <div class="flex w-full px-4 pb-2 bg-offwhite">
          <search-input
            v-model="searchBox"
            placeholder="Otsi"
            background-color="white"
          />
        </div>
        <div class="flex flex-col overflow-y-auto" v-if="parties && !addChat">
          <chat-card
            v-for="party in !searchBox
              ? parties
              : parties.filter((x) =>
                  x.title
                    .replace(/\s/g, '')
                    .toLowerCase()
                    .includes(searchBox.replace(/\s/g, '').toLowerCase())
                )"
            :key="party.id"
            :party="party"
            :notification-target="
              notifications &&
              notifications.map((x) => Number(x.object_id)).includes(party.id)
            "
            @toggleChatTray="toggleChatTray"
            @markRead="markRead"
            ref="cards"
          />
        </div>
        <div
          v-if="addChat"
          class="w-full flex flex-col justify-start items-center flex-grow bg-white rounded-b gap-y-2"
        >
          <clip-loader v-if="!companyWorkers" />
          <span
            v-if="
              companyWorkers &&
              newChatSearchResults &&
              newChatSearchResults.length === 0
            "
          >
            Ei leidnud töötajaid
          </span>
          <div
            v-for="worker in newChatSearchResults"
            :key="worker.id"
            class="flex justify-between items-center w-full px-4 py-2"
          >
            <span class="font-medium">{{ worker.worker_name }}</span>
            <button
              :class="
                selectedWorkers.includes(worker)
                  ? 'new-button-danger'
                  : 'btn-primary'
              "
              @click="toggleWorker(worker)"
            >
              <div
                class="transform duration-150 rounded-gwdfull icon items-center justify-center flex"
                :class="selectedWorkers.includes(worker) ? 'rotate-45' : ''"
              >
                <span
                  class="typcn typcn-plus text-xl leading-none transform text-white"
                ></span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ChatCard from "@/components/navbar/ChatCard";
import SearchInput from "../reusable/SearchInput.vue";
import { mapGetters } from "vuex";
import { directive as onClickaway } from "vue-clickaway2";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  name: "ChatTray",
  components: { ChatCard, SearchInput, ClipLoader },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchBox: "",
      addChat: false,
      filteredParties: [],
      companyWorkers: null,
      selectedWorkers: [],
    };
  },
  directives: {
    onClickaway,
  },
  methods: {
    saveNewChat() {
      this.apiRequest(`chat/${this.companyId}/new/`, "post", true, {
        profiles: this.selectedWorkers.map((x) => x.profile),
      }).then((res) => {
        if (res.status === 201) {
          this.selectedWorkers = [];
          this.companyWorkers = [];
          this.$store.dispatch("chat/retrieveParties");
          this.addChat = false;
        }
      });
    },
    toggleWorker(worker) {
      if (
        this.selectedWorkers.find((item) => item.id === worker.id) !== undefined
      )
        this.selectedWorkers.splice(
          this.selectedWorkers.findIndex((item) => item.id === worker.id),
          1
        );
      else this.selectedWorkers.push(worker);
    },
    toggleAddChat() {
      this.companyWorkers = null;
      this.addChat = !this.addChat;

      this.apiRequest(
        `company/${this.companyId}/workers/?all=True`,
        "get",
        true
      ).then((res) => {
        this.companyWorkers = res.data;
      });
    },
    markRead(partyId) {
      this.apiRequest(`chat/${partyId}/read/`, "post", true).then((res) => {
        if (res.status === 200) {
          this.$store.dispatch("chat/markPartyNotificationsAsRead", partyId);
        }
      });
    },
    async toggleChatTray(chatParty) {
      await this.$store.dispatch("chat/markChatPartyAsRead", chatParty.id);
      await this.$store.dispatch(
        "chat/markPartyNotificationsAsRead",
        chatParty.id
      );
      if (chatParty.company && chatParty.company !== this.companyId) {
        await this.$store.dispatch(
          "companyData/activateCompany",
          chatParty.company
        );
      }
      if (chatParty.task) {
        await this.$store.dispatch("modals/taskModal/open", {
          task: chatParty.task,
          activeView: 6,
        });
      } else if (chatParty.worker) {
        if (this.$route.name !== "Worker") {
          await this.$router.push(`/workers/${chatParty.worker}/?page=6`);
        }
      } else if (chatParty.client) {
        if (this.$route.name !== "Client") {
          await this.$router.push(`/clients/${chatParty.client}/?page=5`);
        }
      } else if (chatParty.project) {
        if (this.$route.name !== "Project") {
          await this.$router.push(`/projects/${chatParty.project}/?page=5`);
        }
      } else {
        await this.$store.dispatch("chat/activateChatParty", chatParty);
      }
      this.$emit("closeTray");
    },
    closeTray() {
      this.addChat = false;
      this.$emit("closeTray");
    },
    getFilters() {
      if (this.searchBox) {
        this.filteredParties = JSON.parse(JSON.stringify(this.parties)).filter(
          (x) =>
            x.profiles
              .map((y) => y.display_username.toLowerCase())
              .filter((z) => z.includes(this.searchBox.toLowerCase())) ||
            x.title.toLowerCase().includes(this.searchBox.toLowerCase())
        );
      }
      return this.filteredParties;
    },
    goToChatView() {
      this.closeTray();
      this.$router.push("/chats");
    },
  },
  computed: {
    ...mapGetters({
      parties: "chat/parties",
      notifications: "chat/notifications",
      companyId: "companyData/activeCompanyUuid",
      permissions: "companyData/userPermission",
      userId: "userData/userId",
    }),
    newChatSearchResults() {
      if (!this.companyWorkers) return null;
      return this.companyWorkers.results
        .filter((y) => !!y.profile)
        .filter((z) => z.profile !== this.userId)
        .filter((x) =>
          x.worker_name
            .replace(/\s/g, "")
            .toLowerCase()
            .includes(this.searchBox.replace(/\s/g, "").toLowerCase())
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled-worker {
  background-color: rgba(169, 21, 39, 0.6);
}
.wide {
  @apply w-80;
  height: 25rem;
}
</style>
