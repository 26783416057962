<template>
  <div class="flex flex-col shadow chat-modal bg-white z-5 rounded-lg">
    <div
      class="flex w-full items-center justify-between bg-offwhite-dark p-2 rounded-t-lg"
    >
      <div class="flex items-center">
        <div
          class="mr-2 bg-offwhite-dark h-10 w-10 rounded-full flex items-center justify-center hover:text-white hover:bg-primary"
        >
          <span class="font-medium">{{ chatParty.id }}</span>
        </div>
        <span>{{ chatParty.title }}</span>
      </div>
      <div class="flex">
        <span
          class="typcn typcn-times icon cursor-pointer text-xl text-white bg-danger rounded-full leading-none items-center justify-center flex h-6 w-6"
          @click="closeParty"
        ></span>
      </div>
    </div>
    <div
      class="flex flex-col overflow-y-scroll p-2 bg-offwhite gap-y-2 flex-grow"
    >
      <div v-if="next" class="flex w-full justify-center">
        <button
          @click="loadMoreMessages"
          class="flex self-center bg-primary text-white w-full justify-center rounded lg-"
        >
          <span class="typcn typcn-arrow-up icon"></span>
          <span>Lae rohkem sõnumeid</span>
        </button>
      </div>
      <chat-comment
        v-for="(message, index) in messages"
        :key="message.uuid"
        :show-header="
          index > 0
            ? messages[index - 1].author.id !== message.author.id ||
              Date.parse(message.created_at) -
                Date.parse(messages[index - 1].created_at) <
                300
            : true
        "
        class="flex w-full rounded"
        :class="
          message.author.id === $store.state.userData.user.id
            ? 'justify-end'
            : 'justify-start'
        "
        :comment="message"
        :id="`comment-${message.uuid}`"
      >
      </chat-comment>
    </div>
    <div
      class="flex bg-offwhite-dark justify-center items-center relative w-full p-1.5 rounded-b-lg gap-x-2"
    >
      <div class="flex h-full relative flex-grow">
        <textarea
          v-model="content"
          @keydown.enter.prevent="
            () => {
              if (!sending) saveComment();
            }
          "
          data-cy="commentField"
          class="w-full h-full relative p-2 focus:shadow-outline bg-white rounded-sm"
          rows="1"
        />
      </div>
      <div class="flex w-10 h-full justify-center items-center">
        <span
          v-if="!sending"
          class="icon typcn typcn-arrow-right-thick text-white w-full h-full rounded justify-center items-center flex leading-none duration-150"
          :class="content.length > 0 ? 'bg-primary' : 'bg-offwhite'"
          @click="saveComment"
          @keydown.enter.prevent="
            () => {
              if (!sending) saveComment();
            }
          "
        ></span>
        <ClipLoader v-else size="24px" />
      </div>
    </div>
  </div>
</template>

<script>
import ChatComment from "@/components/chat/ChatComment";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
export default {
  name: "ChatPartyModal",
  components: { ChatComment, ClipLoader },
  props: {
    chatParty: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      messages: [],
      next: null,
      messagesLoaded: false,
      content: "",
      socket: null,
      sending: false,
    };
  },
  mounted() {
    this.loadChatMessages();
  },
  beforeDestroy() {
    this.destroySocket();
  },
  methods: {
    saveComment() {
      if (this.sending || this.content.length <= 0) return;
      this.sending = true;
      this.apiRequest(`chat/send/`, "post", true, {
        content: this.content,
        party: this.chatParty.id,
      })
        .then(() => {
          this.content = "";
          this.sending = false;
        })
        .catch((err) => {
          this.sending = false;
          console.log(err);
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            text: "Sõnumi saatmine ebaõnnestus",
            ttl: 5,
          });
        });
    },
    closeParty() {
      this.$store.dispatch("chat/closeChatParty", this.chatParty);
    },
    initChatPos() {
      this.$nextTick(() => {
        const lastComment = this.messages[this.messages.length - 1];
        document.getElementById(`comment-${lastComment.uuid}`).scrollIntoView();
      });
    },
    initLoadMoreChatPos() {
      this.$nextTick(() => {
        const lastComment = this.messages[9];
        document.getElementById(`comment-${lastComment.uuid}`).scrollIntoView();
      });
    },
    initChatWebsocket() {
      this.apiRequest(`chat/${this.chatParty.id}/token/`, "get", true).then(
        (res) => {
          const socket = new WebSocket(
            `${process.env.VUE_APP_WS_URL}chat/${res.data.token}/`
          );
          this.socket = socket;
          socket.addEventListener("message", (e) => {
            const data = JSON.parse(e.data);
            this.messages.push(data.message);
            this.initChatPos();
          });
        }
      );
    },
    destroySocket() {
      this.socket.close();
    },
    async loadMoreMessages() {
      if (this.next) {
        await this.apiRequest(this.next, "get", true).then((res) => {
          this.messages = res.data.results.reverse().concat(this.messages);
          this.next = res.data.next;
          this.messagesLoaded = true;
          this.initLoadMoreChatPos();
        });
      }
    },
    async loadChatMessages() {
      await this.apiRequest(
        `chat/parties/${this.chatParty.id}/?all=False`,
        "get",
        true
      ).then((res) => {
        this.messages = res.data.results.reverse();
        this.next = res.data.next;
        this.initChatPos();
        this.messagesLoaded = true;
        this.initChatWebsocket();
      });
      // const ws = new WebSocket("")
    },
  },
};
</script>

<style lang="scss">
.chat-modal {
  width: 22vw;
  height: 60vh;
  @media screen and (max-width: 992px) {
    width: 50vw;
  }
  @media screen and (max-width: 640px) {
    width: 90vw;
    height: 55vh;
  }
}
</style>
