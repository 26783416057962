<template>
  <div class="flex flex-col gwd-form">
    <div class="gwd-form-group">
      <gwd-formlabel
        title="Kirjeldus"
        :required="true"
        :valid="!$v.resource.name.$invalid"
        :error="$v.resource.name.$error"
        :error-text="
          $v.$error && !$v.resource.name.maxLength
            ? 'Kirjeldus on liiga pikk'
            : 'Kohustuslik'
        "
      />
      <input
        v-model="resource.name"
        :class="[
          $v.$error && !$v.resource.name.maxLength
            ? 'shadow-dangeroutline'
            : '',
        ]"
      />
    </div>
    <div class="gwd-form-group">
      <gwd-formlabel
        title="Kogus"
        :required="true"
        :valid="!$v.resource.amount.$invalid"
        :error="$v.resource.amount.$error"
      />
      <decimal-input
        v-model="resource.amount"
        :class="[
          $v.$error && !$v.resource.amount.required
            ? 'shadow-dangeroutline'
            : '',
        ]"
      />
    </div>
    <div class="gwd-form-group">
      <gwd-formlabel
        title="Ühik"
        :required="true"
        :valid="!$v.resource.unit.$invalid"
        :error="$v.resource.unit.$error"
      />
      <select v-model="resource.unit">
        <option v-for="unit in units" :key="unit" :value="unit">
          {{ unit }}
        </option>
      </select>
    </div>
    <div class="gwd-form-group">
      <gwd-formlabel
        title="Ostuhind"
        :required="true"
        :valid="!$v.resource.cost.$invalid"
        :error="$v.resource.cost.$error"
      />
      <decimal-input
        v-model.number="resource.cost"
        :class="[
          $v.$error && $v.resource.cost.$invalid ? 'shadow-dangeroutline' : '',
        ]"
      />
    </div>
    <div class="gwd-form-group">
      <gwd-formlabel
        title="Müügihind"
        :required="true"
        :valid="!$v.resource.price.$invalid"
        :error="$v.resource.price.$error"
      />
      <decimal-input
        v-model.number="resource.price"
        :class="[
          $v.$error && $v.resource.price.$invalid ? 'shadow-dangeroutline' : '',
        ]"
      />
    </div>
    <div class="gwd-form-group">
      <gwd-formlabel title="Ressursi kood" />
      <input type="text" v-model="resource.code" />
    </div>

    <div class="gwd-form-group" v-on-clickaway="() => (showGroups = false)">
      <gwd-formlabel title="Kategooria" />
      <input v-model="resource.group" type="text" @focus="showGroups = true" />
      <div class="flex flex-col" v-if="showGroups">
        <span
          v-for="group in resourceGroups"
          :key="group"
          class="p-1 cursor-pointer border border-1 bg-offwhite"
          @click.stop.prevent="
            () => {
              resource.group = group;
              showGroups = false;
            }
          "
        >
          {{ group }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { maxLength, decimal, required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import DecimalInput from "../reusable/DecimalInput.vue";
export default {
  name: "AddResourceForm",
  data() {
    return {
      resource: {
        name: "",
        amount: 0,
        unit: "",
        cost: 0.0,
        price: 0.0,
        code: "",
        group: "",
      },
      resourceGroups: [],
      showGroups: false,
    };
  },
  components: {
    DecimalInput,
  },
  methods: {
    getResource() {
      this.$v.$touch();
      if (this.$v.$invalid) return null;
      return this.resource;
    },
    loadResourceGroups() {
      this.apiRequest(
        `template/${this.companyId}/resources/groups/`,
        "get",
        true
      ).then((res) => {
        this.resourceGroups = res.data;
      });
    },
    reset() {
      this.resource = {
        name: "",
        amount: 0,
        unit: "",
        cost: 0.0,
        price: 0.0,
        code: "",
        group: "",
      };
    },
  },
  mounted() {
    this.loadResourceGroups();
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      units: "companyVariables/units",
    }),
  },
  validations: {
    resource: {
      name: {
        required,
        maxLength: maxLength(128),
      },
      amount: {
        required,
        decimal,
      },
      unit: {
        required,
        maxLength: maxLength(8),
      },
      cost: {
        decimal,
      },
      price: {
        decimal,
      },
    },
  },
};
</script>
