import Axios from "axios";
export default {
  data() {
    return {
      axiosInstance: null,
    };
  },
  created() {
    this.axiosInstance = Axios.create({
      baseURL: process.env.VUE_APP_API_URL,
    });
  },
  methods: {
    apiRequest(endpoint, method, isAuthed, data) {
      const store = this.$store;
      const router = this.$router;
      this.axiosInstance.interceptors.response.use(
        (res) => {
          return res;
        },
        (err) => {
          if (processError(err).response.status === 401) {
            if (this.$store.state.auth.status.refreshQueue.length === 0) {
              return new Promise((resolve, reject) => {
                const handleError = (error) => {
                  logout(store, router);
                  reject(error);
                };

                this.$store
                  .dispatch("auth/refreshToken")
                  .then(
                    (res) => {
                      err.config.headers[
                        "Authorization"
                      ] = `Bearer ${res.data.access}`;
                      resolve(this.axiosInstance.request(err.config));
                    },
                    (error) => {
                      handleError(error);
                    }
                  )
                  .catch((error) => {
                    handleError(error);
                  });
              });
            } else {
              return new Promise((resolve, reject) => {
                const promise = this.$store.state.auth.status.refreshQueue[0];

                promise
                  .then((res) => {
                    err.config.headers[
                      "Authorization"
                    ] = `Bearer ${res.data.access}`;
                    resolve(this.axiosInstance.request(err.config));
                  })
                  .catch((err) => {
                    if (this.$store.state.auth.status.loggedIn) {
                      logout(store, router);
                    }
                    reject(err);
                  });
              });
            }
          } else {
            return Promise.reject(err);
          }
        }
      );
      if (isAuthed === true) {
        this.axiosInstance.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.getItem("access");
      }

      let options = {
        method: method,
        url: endpoint,
      };

      if (data) {
        options.data = data;
      }

      return this.axiosInstance(options);
    },
  },
};

const logout = (store, router) => {
  store.dispatch("auth/logout");
  router.push("/login");
};

const processError = (error) => {
  if (error.response) return error;
  return { error };
};
