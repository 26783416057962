import StoreRequestHandler from "../../../assets/mixins/StoreRequestHandler";

export const clients = {
  namespaced: true,
  state: {
    clients: null,
  },
  mutations: {
    setClients(state, data) {
      state.clients = data;
    },
  },
  actions: {
    retrieveClients({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        StoreRequestHandler.apiRequest(
          `company/${rootState.companyData.activeCompany.uuid}/clients/?all=True`,
          "get",
          true
        )
          .then((res) => {
            commit("setClients", res.data);
            resolve();
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
    },
  },
  getters: {
    clients: (state) => state.clients,
  },
};
