<template>
  <div class="gwd-table nested h-full" v-if="taskResourcesLoaded">
    <div class="gwd-table-header">Planeeritud ressurssid</div>
    <div
      class="gwd-table-header gap-x-2"
      :class="
        isManager || isAdmin
          ? 'resource-assignment-table'
          : 'resource-assignment-table-worker'
      "
    >
      <span>Ressurssi nimi</span>
      <span>Kogus</span>
      <span>Tagastatav kogus</span>
      <span>Info</span>
      <span />
    </div>
    <div class="gwd-table-row-wrapper">
      <div
        v-for="resourceAssignment in taskResources.filter((x) => x.converted)"
        :key="resourceAssignment.id"
        class="gwd-table-row gap-x-2"
        :class="
          isManager || isAdmin
            ? 'resource-assignment-table'
            : 'resource-assignment-table-worker'
        "
      >
        <span> {{ resourceAssignment.resource.name }}</span>
        <span>
          {{ resourceAssignment.assigned_amount }}
          {{ resourceAssignment.resource.unit }}</span
        >
        <span>{{ resourceAssignment.returned_amount }}</span>
        <span type="text">{{ resourceAssignment.info }}</span>
      </div>
      <div
        v-for="resourceAssignment in taskResources.filter((x) => !x.converted)"
        :key="resourceAssignment.id"
        class="gwd-table-row gap-x-2"
        :class="
          isManager || isAdmin
            ? 'resource-assignment-table'
            : 'resource-assignment-table-worker'
        "
      >
        <span> {{ resourceAssignment.resource.name }}</span>
        <span>
          {{ resourceAssignment.assigned_amount }}
          {{ resourceAssignment.resource.unit }}</span
        >
        <input
          type="number"
          v-model.number="resourceAssignment.returned_amount"
          v-if="!resourceAssignment.converted"
        />
        <span v-else>{{ resourceAssignment.returned_amount }} </span>
        <input
          v-if="!resourceAssignment.converted"
          type="text"
          v-model="resourceAssignment.info"
        />
        <span v-else type="text">{{ resourceAssignment.info }}</span>
        <div
          class="flex w-full justify-end"
          v-if="!resourceAssignment.converted"
        >
          <button
            v-if="isAdmin || isManager"
            @click="toggleResource(resourceAssignment)"
            class="btn-primary"
          >
            <span
              v-if="
                selectedResources
                  .map((x) => x.id)
                  .includes(resourceAssignment.id)
              "
            >
              Eemalda valikust
            </span>
            <span v-else> Muuda kuluks </span>
          </button>
        </div>
      </div>
      <span v-if="taskResources.length === 0" class="p-2">
        Määratud ressursse pole.
      </span>

      <div
        class="gwd-table-header mt-2"
        v-if="selectedResources && selectedResources.length > 0 && isManager"
      >
        Valitud ressurssid
      </div>
      <div
        class="gwd-table-header grid-cols-4"
        v-if="selectedResources && selectedResources.length > 0 && isManager"
      >
        <span>Nimi</span>
        <span>Kogus</span>
        <span>Kulu</span>
        <span>Hind</span>
      </div>
      <div
        class="gwd-table-row grid-cols-4 w-full"
        v-for="resourceAssignment in selectedResources"
        :key="`res${resourceAssignment.id}`"
      >
        <span>{{ resourceAssignment.resource.name }}</span>
        <span
          >{{
            resourceAssignment.assigned_amount -
            resourceAssignment.returned_amount
          }}
          {{ resourceAssignment.resource.unit }}</span
        >
        <span>{{ resourceAssignment.resource.cost }}</span>
        <span>{{ resourceAssignment.resource.price }}</span>
      </div>

      <div
        class="gwd-table-bottom-bar"
        v-if="selectedResources && selectedResources.length > 0 && isManager"
      >
        <button class="btn-primary" @click="createResourceCosts">
          <span class="label">Salvesta kuludeks</span>
        </button>
      </div>
      <div class="gwd-table-header mt-2">Ressurssi kulud</div>
      <div
        v-if="taskResourceCosts && isManager"
        class="gwd-table-header resource-assignment-table"
      >
        <span>Ressurssi kulu nimi</span>
        <span>Kogus</span>
        <span>Kulu</span>
        <span>Hind</span>
        <span>Kokku </span>
      </div>
      <div
        class="gwd-table-row resource-assignment-table"
        v-for="resourceCost in taskResourceCosts"
        :key="resourceCost.id"
      >
        <span>{{ resourceCost.name }}</span>
        <span>{{ resourceCost.amount }} {{ resourceCost.unit }}</span>
        <span>{{ resourceCost.cost }} €</span>
        <span>{{ resourceCost.price }} €</span>
        <span> {{ round2(resourceCost.amount * resourceCost.price) }} €</span>
      </div>
      <div
        v-if="taskResourceCosts && taskResourceCosts.length === 0 && isManager"
        class="p-2"
      >
        Kulud puuduvad
      </div>
    </div>

    <div class="gwd-table-bottom-bar" v-if="selectedResources.length === 0">
      <button
        class="btn-primary mr-2"
        @click="
          $store.commit('modals/taskModal/openAddResourceAssignmentModal')
        "
      >
        <span class="label">{{
          isManager ? "Planeeri ressurss" : "Lisa ressurss"
        }}</span>
      </button>
      <button
        class="btn-primary mr-2"
        @click="$store.commit('modals/taskModal/openAddResourceCostModal')"
        v-if="isManager"
      >
        <span class="label">Lisa kulu</span>
      </button>
      <button
        class="btn-primary"
        v-if="resourcesChanged"
        @click="updateResourceAssignments"
      >
        <span class="label">Salvesta</span>
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { isEqual } from "lodash";
import { EventBus } from "../../../assets/mixins/GewodoMixins";
import { round2 } from "@/assets/utils/commonMath";

export default {
  name: "TaskResourceAssignments",
  props: {
    taskId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      taskResources: [],
      taskResourcesCopy: [],
      taskResourceCosts: [],
      taskResourcesLoaded: false,
      newResourceAssignment: false,
      selectedResources: [],
      newResourceCost: false,
    };
  },
  methods: {
    round2,
    loadTaskResources() {
      this.taskResourcesLoaded = false;
      this.taskResources = [];
      this.taskResourcesCopy = [];
      this.apiRequest(
        `template/${this.companyId}/resources/task/${this.taskId}/`,
        "get",
        true
      ).then((res) => {
        this.taskResources = res.data;
        this.taskResourcesCopy = JSON.parse(JSON.stringify(res.data));
        this.taskResourcesLoaded = true;
      });
    },
    loadTaskResourceCosts() {
      if (this.isManager) {
        this.apiRequest(
          `costs/${this.companyId}/resource/costs/?task=${this.taskId}`,
          "get",
          true
        ).then((res) => {
          this.taskResourceCosts = res.data;
        });
      }
    },
    updateResourceAssignments() {
      this.apiRequest(
        `template/${this.companyId}/task/${this.taskId}/resources/edit/`,
        "patch",
        true,
        this.taskResources
      ).then((res) => {
        if (res.status === 200) {
          this.loadTaskResources();
        }
      });
    },
    toggleResource(e) {
      if (
        this.selectedResources.find((item) => item.id === e.id) !== undefined
      ) {
        this.selectedResources.splice(
          this.selectedResources.indexOf(
            this.selectedResources.find((item) => item.id === e.id)
          ),
          1
        );
      } else {
        this.selectedResources.push(e);
      }
    },
    createResourceCosts() {
      let data = {
        task: this.taskId,
        assignments: this.selectedResources.map((x) => {
          return {
            resource: x.resource.id,
            id: x.id,
            info: x.info,
            task: this.taskId,
          };
        }),
      };
      this.apiRequest(
        `costs/${this.companyId}/resources/costs/create/`,
        "post",
        true,
        data
      ).then((res) => {
        if (res.status === 201) {
          this.loadTaskResources();
          this.loadTaskResourceCosts();
          this.selectedResources = [];
        }
      });
    },
  },
  mounted() {
    this.loadTaskResources();
    this.loadTaskResourceCosts();
    EventBus.$on("resourcesAssigned", () => this.loadTaskResources());
    EventBus.$on("costsUpdated", () => this.loadTaskResourceCosts());
  },
  beforeDestroy() {
    EventBus.$off("resourcesAssigned");
    EventBus.$off("costsUpdated");
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      isAdmin: "companyData/isAdmin",
      isManager: "companyData/isManager",
    }),
    resourcesChanged() {
      return (
        !isEqual(this.taskResources, this.taskResourcesCopy) &&
        this.taskResources.filter((x) => x.returned_amount > x.assigned_amount)
          .length === 0
      );
    },
  },
};
</script>
<style scoped lang="scss">
.resource-assignment-table {
  //                     name amount ret   info  confirm
  grid-template-columns: 0.5fr 0.3fr 0.3fr 0.3fr 0.3fr;
}
.resource-assignment-table-worker {
  //                     name amount ret   info  confirm
  grid-template-columns: 0.5fr 0.3fr 0.3fr 0.3fr;
}
input {
  width: 95%;
}
</style>
