export const filterProviders = (provider, query) => {
  return (
    provider.name?.toLowerCase().includes(query.toLowerCase()) ||
    provider.reg_code?.toLowerCase().includes(query.toLowerCase()) ||
    provider.alias?.toLowerCase().includes(query.toLowerCase())
  );
};

export const filterClients = (client, query) => {
  return (
    client.name.toLowerCase().includes(query.toLowerCase()) ||
    (client.register_code &&
      client.register_code.toLowerCase().includes(query.toLowerCase()))
  );
};
