import RegisterWorkerWithTokenView from "@/views/core/RegisterWorkerWithTokenView";

const accountRoutes = [
  {
    path: "/register/worker/",
    name: "ActivateAccount",
    component: RegisterWorkerWithTokenView,
    meta: {
      public: true,
      sidebar: false,
      title: "Aktiveeri konto | AVA töörakendus",
    },
  },
];

export default accountRoutes;
