import StoreRequestHandler from "../../../assets/mixins/StoreRequestHandler";

export const templates = {
  state: {
    templates: null,
  },
  namespaced: true,
  mutations: {
    setTemplates(state, data) {
      state.templates = data;
    },
  },
  actions: {
    retrieveInvoiceTemplates({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        StoreRequestHandler.apiRequest(
          `invoice/${rootState.companyData.activeCompany.uuid}/invoice/templates/`,
          "get",
          true
        )
          .then((res) => {
            commit("setTemplates", res.data);
            resolve();
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
    },
  },
  getters: {
    templates: (state) => state.templates,
  },
};
