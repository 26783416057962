<template>
  <div class="modal">
    <div class="modal-body" v-on-clickaway:mousedown="closeModal">
      <div class="flex modal-content">
        <l-map
          class="rounded-xl flex flex-grow h-64"
          :options="mapOptions"
          :interactive="true"
          ref="map"
          :bounds="bounds"
          @click="addMarker"
        >
          <l-tile-layer
            v-if="mapInited"
            :url="tileUrl"
            :attribution="'© OpenStreetMap contributors'"
            :position="'bottomleft'"
          ></l-tile-layer>
          <l-marker
            v-for="(marker, index) in markers"
            :key="index"
            :lat-lng="marker"
            @click="removeMarker(index)"
          >
            <l-tooltip :content="'Asukoht'"></l-tooltip>
            <l-icon icon-url="/bc21/placeholder.svg"></l-icon>
          </l-marker>
        </l-map>
      </div>
      <div class="modal-bottom-bar">
        <button
          class="mr-2"
          :disabled="markers.length === 0"
          :class="markers.length === 0 ? 'new-button-disabled' : 'btn-primary'"
          @click="selectLocation"
        >
          Salvesta
        </button>
        <button class="btn-danger ml-2" @click="closeModal">
          <span class="typcn typcn-times icon"></span
          ><span class="label">Sulge</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LTooltip, LIcon } from "vue2-leaflet";
import { latLng, latLngBounds } from "leaflet";

export default {
  name: "MapLocationSelector",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LIcon,
  },
  data() {
    return {
      tileUrl: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      mapInited: false,
      mapOptions: {
        zoom: 6,
        center: latLng(58.5953, 25.0136),
        interactive: true,
      },
      markers: [],
      bounds: latLngBounds([
        [58.280146, 20.528655],
        [59.907877, 29.484372],
      ]),
    };
  },
  methods: {
    selectLocation() {
      if (this.markers.length > 0) {
        this.$emit("locationSelected", this.markers[0]);
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("close");
    },
    initLocation() {
      this.mapInited = true;
    },
    removeMarker(index) {
      this.markers.splice(index, 1);
    },
    addMarker(e) {
      this.markers = [];
      this.markers.push(e.latlng);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initLocation();
    });
  },
};
</script>

<style lang="scss" scoped>
.map-container {
  min-height: 325px;
}
@media screen and (max-width: 992px) {
  .map-container {
    min-height: auto;
  }
}
</style>
