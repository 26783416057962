var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal relative"},[_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway:mousedown",value:(_vm.closeModal),expression:"closeModal",arg:"mousedown"}],staticClass:"modal-body modal-large mobile:p-0 relative z-10 max-h-full",attrs:{"id":"file-viewer"}},[_c('div',{staticClass:"modal-header p-3"},[_c('h2',[_vm._v(_vm._s(_vm.file.file_name))])]),(_vm.fileLoaded)?_c('div',{staticClass:"modal-content flex-row mobile:flex-col"},[_c('div',{staticClass:"flex flex-col w-10/12 restricted-height mobile:w-full"},[(
            ['image/png', 'image/jpeg', 'image/svg+xml'].includes(
              _vm.file.file_type
            )
          )?_c('div',{staticClass:"flex w-full h-full"},[_c('img',{staticClass:"flex rounded mobile:w-full mobile:h-auto mobile:m-0",class:!_vm.showImage ? 'hidden' : '',attrs:{"src":_vm.mediaPath,"alt":'fail'},on:{"load":function($event){_vm.showImage = true}}})]):_vm._e(),(['application/pdf', 'pdf'].includes(_vm.file.file_type))?_c('div',{staticClass:"flex flex-col items-center justify-center"},[(_vm.totalPages > 1)?_c('div',{staticClass:"flex flex-row items-center justify-center mb-3"},[_c('div',{staticClass:"mr-1 cursor-pointer",on:{"click":function($event){_vm.currentPage === 1 ? '' : (_vm.currentPage -= 1)}}},[_vm._m(0)]),_c('span',[_vm._v(" "+_vm._s(_vm.currentPage)+" / "+_vm._s(_vm.totalPages)+" ")]),_c('div',{staticClass:"mx-1 cursor-pointer",on:{"click":function($event){_vm.currentPage === _vm.totalPages ? '' : (_vm.currentPage += 1)}}},[_vm._m(1)])]):_vm._e(),_c('div',{staticClass:"flex border"},[_c('vue-pdf-embed',{ref:"pdfRef",attrs:{"source":_vm.mediaPath,"page":_vm.currentPage,"width":_vm.pdfSize},on:{"rendered":_vm.handleDocumentRender}})],1)]):_vm._e(),(['video/webm', 'video/mp4'].includes(_vm.file.file_type))?_c('div',[_c('video-player',{attrs:{"options":_vm.videoOptions,"source":_vm.mediaPath}})],1):_vm._e(),(
            ![
              'image/png',
              'image/jpeg',
              'image/svg+xml',
              'video/webm',
              'video/mp4',
              'application/pdf',
              'pdf' ].includes(_vm.file.file_type)
          )?_c('div',[_vm._m(2)]):_vm._e()]),(_vm.fileInfoLoaded && _vm.canEdit)?_c('div',{staticClass:"flex flex-col bg-offwhite rounded-xl pr-2 w-2/12 mobile:w-full shadow-sm"},[_c('span',{staticClass:"text-sm mb-2"},[_vm._v(" Üleslaadija: "+_vm._s(_vm.editables.uploaded_by))]),_c('span',{staticClass:"text-sm mb-2"},[_vm._v(" Kuupäev: "+_vm._s(_vm.createdAtFormatted(_vm.editables.created_at)))]),_c('span',{staticClass:"text-sm mb-2"},[_vm._v(" Suurus: "+_vm._s(_vm.editables.size))]),(_vm.canEdit)?_c('div',{staticClass:"flex flex-row items-center justify-between"},[_c('span',{staticClass:"text-sm mr-2"},[_vm._v("Nähtav töötajale")]),_c('gwd-checkbox',{staticClass:"flex",model:{value:(_vm.editables.visible_to_workers),callback:function ($$v) {_vm.$set(_vm.editables, "visible_to_workers", $$v)},expression:"editables.visible_to_workers"}})],1):_vm._e(),(
            (_vm.draft ||
              (_vm.editables.related_object &&
                Object.keys(_vm.editables.related_object).length > 0 &&
                _vm.editables.related_object.type === 'Projekt')) &&
            _vm.canEdit
          )?_c('div',{staticClass:"flex flex-row items-center justify-between"},[_c('span',{staticClass:"text-sm mr-2"},[_vm._v("Nähtav kliendile")]),_c('gwd-checkbox',{staticClass:"flex",model:{value:(_vm.editables.visible_to_client),callback:function ($$v) {_vm.$set(_vm.editables, "visible_to_client", $$v)},expression:"editables.visible_to_client"}})],1):_vm._e(),(_vm.canEdit)?_c('div',{staticClass:"flex flex-row items-center justify-between"},[_c('span',{staticClass:"text-sm mr-2"},[_vm._v("Nähtav halduritele")]),_c('gwd-checkbox',{staticClass:"flex",model:{value:(_vm.editables.visible_to_managers),callback:function ($$v) {_vm.$set(_vm.editables, "visible_to_managers", $$v)},expression:"editables.visible_to_managers"}})],1):_vm._e()]):_vm._e()]):_vm._e(),_c('div',{staticClass:"modal-bottom-bar relative"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.settingsDropupActive)?_c('div',{staticClass:"settings-dropup"},[_c('div',{staticClass:"settings-dropup-item",on:{"click":function () {
                _vm.downloadFile();
                _vm.settingsDropupActive = false;
              }}},[_c('div',{staticClass:"settings-dropup-icon bg-primary"},[_c('span',{staticClass:"typcn typcn-download"})]),_c('div',{staticClass:"settings-dropup-title"},[_vm._v("Lae alla")])]),_c('div',{staticClass:"settings-dropup-item",on:{"click":function () {
                _vm.deleteFileModalActive = true;
                _vm.settingsDropupActive = false;
              }}},[_c('div',{staticClass:"settings-dropup-icon bg-danger"},[_c('span',{},[_c('img',{staticClass:"filter-to-white",attrs:{"src":"/bc21/trash.svg","alt":"Delete icon"}})])]),_c('div',{staticClass:"settings-dropup-title"},[_vm._v("Kustuta fail")])])]):_vm._e()]),_c('div',{staticClass:"flex"},[_c('button',{staticClass:"meatball-menu-button hidden mobile:flex mr-3",class:_vm.settingsDropupActive ? 'open' : '',on:{"click":function($event){_vm.settingsDropupActive = !_vm.settingsDropupActive}}},[_c('div',{staticClass:"meatball-menu-icon"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.settingsDropupActive)?_c('div',{staticClass:"meatball-menu-icon"},[_c('div',{staticClass:"circle"}),_c('div',{staticClass:"circle"}),_c('div',{staticClass:"circle"})]):_vm._e(),(_vm.settingsDropupActive)?_c('div',{staticClass:"close typcn typcn-times"}):_vm._e()])],1),_c('span',{staticClass:"label"},[_vm._v("Valikud")])]),_c('button',{staticClass:"btn-danger mr-3",on:{"click":_vm.closeModal}},[_c('span',{staticClass:"icon typcn typcn-times"}),_c('span',{staticClass:"label"},[_vm._v("Sulge")])]),_c('button',{staticClass:"btn-primary mr-3 mobile:hidden",class:_vm.canEdit ? 'mr-3' : '',on:{"click":_vm.downloadFile}},[_c('span',{staticClass:"typcn typcn-download icon"}),_c('span',{staticClass:"label"},[_vm._v("Lae alla")])]),(_vm.canEdit)?_c('button',{staticClass:"btn-danger mobile:hidden",on:{"click":function($event){_vm.deleteFileModalActive = true}}},[_vm._m(3),_c('span',{staticClass:"label"},[_vm._v("Kustuta")])]):_vm._e()])],1)]),(_vm.deleteFileModalActive)?_c('confirm-modal',{attrs:{"text":"Olete kindel, et soovite faili kustutada?"},on:{"confirm":_vm.deleteFile,"closeModal":function($event){_vm.deleteFileModalActive = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"p-2 rounded-full bg-offwhite"},[_c('span',{staticClass:"typcn typcn-chevron-left icon h-5 text-black"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"p-2 rounded-full bg-offwhite"},[_c('span',{staticClass:"typcn typcn-chevron-right icon h-5 text-black"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col rounded shadow bg-offwhite mt-3 py-3 px-5"},[_c('span',[_vm._v("Ei saa faili automaatselt kuvada")]),_c('span',[_vm._v("faili nägemiseks laadige see alla")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{staticClass:"filter-to-white h-5 w-5",attrs:{"src":"/bc21/trash.svg","alt":"Delete icon"}})])}]

export { render, staticRenderFns }