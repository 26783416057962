<template>
  <div class="flex w-full flex-col gap-y-2" v-if="templatesLoaded">
    <div class="flex gap-x-4">
      <div class="flex w-2/5 gap-x-2">
        <button
          class="btn-danger"
          @click="$router.push(`/projects/${$route.params.projectId}/?view=4`)"
        >
          <span class="typcn typcn-chevron-left icon"></span>
          <span class="label">Tagasi</span>
        </button>
        <button class="btn-primary" @click="reload">
          <span class="typcn typcn-refresh icon"></span>
          <span class="label">Lae uuesti</span>
        </button>
        <select
          v-model="statusFilter"
          class="p-1 border border-primary border-1 w-2/12"
        >
          <option :value="null">-</option>
          <option
            v-for="status in [
              { key: 0, description: 'Planeeritud' },
              {
                key: 1,
                description: 'Loodud',
              },
              {
                key: 2,
                description: 'Arvel (maksmata)',
              },
              {
                key: 3,
                description: 'Tasutud',
              },
              {
                key: 4,
                description: 'Omakulu',
              },
            ]"
            :key="status.key"
            :value="status.key"
          >
            {{ status.description }}
          </option>
        </select>
        <button
          class="btn-primary ml-auto"
          @click="plannedActualModalActive = true"
        >
          <span>Planeeritud kulud</span>
        </button>
      </div>
      <div class="flex w-3/5 items-center gap-x-3">
        <button class="btn-primary" @click="loadInvoiceRevenues">
          <span class="label">Lae arvete tuluread</span>
        </button>
        <button class="btn-primary" @click="addAnotherInvoice">
          <span class="label">Lisa eraldi arve</span>
        </button>
        <button class="btn-primary ml-auto" @click="goToInvoice">
          Edasi arve koostamisse
        </button>
      </div>
    </div>
    <div class="flex gap-x-4 text-sm">
      <div class="flex w-2/5 flex-col mb-auto">
        <div class="tab-tray mb-2">
          <div
            class="tab-tray-item"
            :class="subPage === 1 ? 'tab-tray-item-active' : ''"
            @click="changePage(1)"
          >
            <span class="tab-tray-label"> Kõik </span>
          </div>
          <div
            class="tab-tray-item"
            :class="subPage === 2 ? 'tab-tray-item-active' : ''"
            @click="changePage(2)"
          >
            <span class="tab-tray-label"> Töödega </span>
          </div>
          <div
            class="tab-tray-item"
            :class="subPage === 3 ? 'tab-tray-item-active' : ''"
            @click="changePage(3)"
          >
            <span class="tab-tray-label"> Töödeta </span>
          </div>
        </div>
        <div class="flex flex-row mb-4 justify-start items-center w-full">
          <date-range-selector
            @stateChanged="handleStateChange"
            class="w-full"
          />
        </div>
        <div class="flex flex-row mb-4 items-center w-full">
          <button
            class="btn-primary mr-2"
            v-if="!combineCostsActive"
            @click="toggleMarkPaid"
          >
            <span class="label">
              {{ markPayActive ? "Tühista" : "Märgi makstuks" }}
            </span>
          </button>
          <button
            class="btn-primary ml-2"
            @click="toggleCombineCosts"
            v-if="!markPayActive && subPage === 1"
          >
            <span class="label">
              {{
                combineCostsActive
                  ? "Tühista kombineerimine"
                  : "Kombineeri kulud"
              }}
            </span>
          </button>
          <div class="flex items-center ml-auto self-end" v-if="markPayActive">
            <span class="mr-2">Valitud: {{ markedAsPaid.length }}</span>
            <button class="btn-primary" @click="markPaidModalActive = true">
              Märgi makstuks
            </button>
          </div>
          <div
            class="flex items-center ml-auto self-end"
            v-if="combineCostsActive && subPage === 1"
          >
            <span class="mr-2"> Valitud: {{ combinedCosts.length }}</span>
            <button
              class="btn-primary mr-2"
              @click="$refs.filteredCosts.combineAll()"
            >
              Vali kõik
            </button>
            <button class="btn-primary" @click="combineCostsModalActive = true">
              Kombineeri
            </button>
          </div>
        </div>
        <div class="gwd-table mb-2">
          <template-item
            v-for="template in templates"
            :key="template.id"
            :template="template"
            @selectTemplate="
              (template) => {
                if (template.type === 7) addTotalCostActive = true;
                else selectTemplate(template, null);
              }
            "
          />
        </div>
        <filtered-costs
          v-if="subPage === 1 && taskCosts && noTaskCosts"
          :costs="taskCosts"
          :no-task-costs="noTaskCosts"
          :added-cost-ids="addedCostIds"
          :combine-costs-active="combineCostsActive"
          :combined-costs="combinedCosts"
          :mark-as-paid="markPayActive"
          :marked-as-paid="markedAsPaid"
          :status="statusFilter"
          @addItem="addItem"
          @addToCombinedCosts="addToCombinedCosts"
          @addToMarkedAsPaid="addToMarkedAsPaid"
          @toggleShowAdded="
            () => {
              this.showAdded = !this.showAdded;
            }
          "
          ref="filteredCosts"
        />
        <task-costs
          v-if="subPage === 2"
          :show-added="showAdded"
          :total-costs="taskCosts"
          :templates="templates"
          :added-cost-ids="addedCostIds"
          :combine-costs-active="combineCostsActive"
          :combined-costs="combinedCosts"
          :mark-as-paid="markPayActive"
          :marked-as-paid="markedAsPaid"
          :status="statusFilter"
          @addItem="addItem"
          @selectTemplate="selectTemplate"
          @addToCombinedCosts="addToCombinedCosts"
          @addToMarkedAsPaid="addToMarkedAsPaid"
          @addAnotherInvoice="addAnotherInvoice"
          @toggleShowAdded="
            () => {
              this.showAdded = !this.showAdded;
            }
          "
          @toggleTaskPlannedActual="
            (e) => {
              this.plannedActualModalTask = e.id;
              this.plannedActualModalActive = true;
            }
          "
          ref="taskCosts"
        />
        <no-task-costs
          v-if="subPage === 3"
          :show-added="showAdded"
          :no-task-costs="noTaskCosts"
          :templates="templates"
          :added-cost-ids="addedCostIds"
          :combine-costs-active="combineCostsActive"
          :combined-costs="combinedCosts"
          :mark-as-paid="markPayActive"
          :marked-as-paid="markedAsPaid"
          :status="statusFilter"
          @addItem="addItem"
          @selectTemplate="selectTemplateNoTask"
          @addToCombinedCosts="addToCombinedCosts"
          @addToMarkedAsPaid="addToMarkedAsPaid"
          @toggleShowAdded="
            () => {
              this.showAdded = !this.showAdded;
            }
          "
        />
      </div>
      <div class="flex flex-col w-3/5 mb-auto" v-if="showRevenueList">
        <template>
          <revenue-list
            class="mb-2"
            v-for="revenue in revenues"
            :payer="revenue.payer"
            :save-button="false"
            :key="revenue.revenueListKey"
            :id="revenue.revenueListKey"
            ref="revenueList"
            :revenue-id="revenue.id"
            :revenues-prop="revenue.revenues"
            :invoice="revenue.invoice"
            :show-payer="true"
            :delete-button="revenuesArray.length > 1"
            @removeRevenueArray="removeRevenueArray"
            @revenueChanged="handleRevenuesChanged"
            @togglePayer="handleRevenueListPayerChanged"
          />
        </template>
      </div>
    </div>
    <revenue-list-selector
      :revenue-lists="revenuesArray"
      v-if="revenueArraySelectorActive"
      @reset="
        () => {
          this.revenueArraySelectorActive = false;
          this.revenueArrayItem = null;
        }
      "
      @revenueSelectorSelected="revenueSelectorSelected"
    />
    <total-cost-template-revenue-generator-modal
      v-if="addTotalCostActive"
      :template="templates.find((x) => x.type === 7)"
      :total-costs="taskCosts"
      :no-task-costs="noTaskCosts"
      @addItems="revenueSaved"
      @closeModal="addTotalCostActive = false"
    />
    <cost-template-revenue-generator-modal
      v-if="selectedTemplate"
      :costs="templateCosts"
      :template="selectedTemplate"
      :task="selectedTask"
      @removeCostFromTemplateCosts="removeCostFromTemplateCosts"
      @closeModal="closeModal"
      @revenueSaved="revenueSaved"
    />
    <combined-cost-modal
      v-if="combineCostsModalActive"
      :combined-costs="combinedCosts"
      :revenue-lists="revenuesArray"
      @revenueSaved="revenueSaved"
      @splitRevenue="splitRevenue"
      @closeModal="closeModal"
    />
    <mark-costs-as-paid-modal
      v-if="markPaidModalActive"
      :mark-as-paid="markedAsPaid"
      @closeModal="markPaidModalActive = false"
      @removeCost="addToMarkedAsPaid"
      @markCostsAsPaid="markCostsAsPaid"
    />
    <existing-invoice-revenue-list-selector
      v-if="selectExistingInvoiceActive"
      :invoices="existingInvoices"
      @activateSelectedExistingInvoices="activateSelectedExistingInvoices"
      @close="
        () => {
          this.showRevenueList = true;
          this.existingInvoices = [];
          this.selectExistingInvoiceActive = false;
        }
      "
    />
    <planned-actual-cost-modal
      v-if="plannedActualModalActive"
      :project="$route.params.projectId"
      :task="plannedActualModalTask"
      :added-cost-ids="addedCostIds"
      @close="
        () => {
          this.plannedActualModalActive = false;
          this.plannedActualModalTask = null;
        }
      "
      @addItem="addItem"
      @openCombinedCosts="openCombinedCosts"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RevenueList from "@/components/invoices/RevenueList";
import moment from "moment";
import CostTemplateRevenueGeneratorModal from "@/components/invoices/CostTemplateRevenueGeneratorModal";
import { Revenue } from "@/assets/types/Revenue";
import FilteredCosts from "@/components/invoices/FilteredCosts";
import DateRangeSelector from "@/components/reusable/DateRangeSelector";
import TotalCostTemplateRevenueGeneratorModal from "@/components/invoices/TotalCostTemplateRevenueGeneratorModal";
import CombinedCostModal from "@/components/invoices/CombinedCostModal.vue";
import TaskCosts from "@/components/invoices/TaskCosts.vue";
import NoTaskCosts from "@/components/invoices/NoTaskCosts.vue";
import { generateParamString } from "@/assets/utils/commonTransforms";
import MarkCostsAsPaidModal from "@/components/invoices/MarkCostsAsPaidModal.vue";
import RevenueListSelector from "@/components/invoices/RevenueListSelector.vue";
import ExistingInvoiceRevenueListSelector from "@/components/invoices/ExistingInvoiceRevenueListSelector.vue";
import PlannedActualCostModal from "@/components/invoices/PlannedActualCostModal.vue";
import set from "lodash/set";
import CostTypeParser from "@/assets/mixins/CostTypeParser";
import TemplateItem from "@/components/invoices/TemplateItem.vue";

export default {
  name: "RevenueGeneratorView",
  components: {
    TemplateItem,
    PlannedActualCostModal,
    ExistingInvoiceRevenueListSelector,
    RevenueListSelector,
    MarkCostsAsPaidModal,
    NoTaskCosts,
    TaskCosts,
    CombinedCostModal,
    TotalCostTemplateRevenueGeneratorModal,
    DateRangeSelector,
    FilteredCosts,
    CostTemplateRevenueGeneratorModal,
    RevenueList,
  },
  mixins: [CostTypeParser],
  data() {
    return {
      taskCosts: [],
      noTaskCosts: {},
      templatesLoaded: false,
      templates: [],
      moment: moment,
      selectedTemplate: null,
      templateCosts: [],
      selectedTask: null,
      addedCostIds: [],
      subPage: 1,
      dateRangeState: null,
      addTotalCostActive: false,
      client: null,
      payer: null,
      showAdded: false,
      combineCostsActive: false,
      combinedCosts: [],
      combineCostsModalActive: false,
      markPayActive: false,
      markedAsPaid: [],
      markPaidModalActive: false,
      statusFilter: null,
      invoiceAmount: 1,
      revenuesArray: [],
      showRevenueList: true,
      revenueArraySelectorActive: false,
      revenueArrayItem: null,
      selectExistingInvoiceActive: false,
      existingInvoices: [],
      plannedActualModalActive: false,
      plannedActualModalTask: null,
    };
  },
  methods: {
    changePage(e) {
      if (this.subPage !== e) {
        this.combinedCosts = [];
        this.markedAsPaid = [];
        this.combineCostsActive = false;
        this.markPayActive = false;
      }
      this.subPage = e;
    },
    markCostsAsPaid() {
      this.apiRequest(
        `costs/${this.companyId}/project/${this.$route.params.projectId}/paid/`,
        "post",
        true,
        this.markedAsPaid.map((x) => `${x.type}${x.id}`)
      )
        .then((res) => {
          if (res.status === 200) this.retrieveProjectsTotalCosts();
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("messageHandler/throwMessage", {
            text: "Midagi läks valesti",
            type: "error",
            ttl: 15,
          });
        });
    },
    removeRevenueArray(revenueId) {
      if (this.revenuesArray.find((x) => x.id === revenueId)) {
        let revenue = this.revenuesArray.find((x) => x.id === revenueId);
        if (revenue.task) {
          this.$refs.taskCosts.changeTaskToNotChecked(revenue.task);
        }
        this.revenuesArray.splice(this.revenuesArray.indexOf(revenue), 1);
        this.$nextTick(() => this.handleRevenuesChanged());
      }
    },
    toggleMarkPaid() {
      this.markPayActive = !this.markPayActive;
    },
    toggleCombineCosts() {
      if (this.combineCostsActive) {
        this.combinedCosts = [];
        this.combineCostsActive = false;
      } else {
        this.combineCostsActive = true;
      }
    },
    handleStateChange(e) {
      this.dateRangeState = e;
    },
    reload(resetAdded = false) {
      if (resetAdded) {
        this.addedCostIds = [];
      }
      this.retrieveProjectsTotalCosts();
    },
    revenueSelectorSelected(e) {
      this.$refs[`revenueList`]
        .find((x) => x.revenueId === e)
        .addRevenueLine(this.revenueArrayItem);
      this.revenueArraySelectorActive = false;
      this.revenueArrayItem = null;
    },
    addItem(e) {
      let revenueTypeArray;
      if (e.revenueTypeArray) {
        revenueTypeArray = e.revenueTypeArray;
      } else {
        revenueTypeArray = [`${e.type}${e.id}`];
      }
      const vatType = this.taxTypes.find((x) => x.accounting_code === e.vat);
      if (this.$refs["revenueList"].length === 1) {
        this.$refs["revenueList"][0].addRevenueLine(
          new Revenue(
            null,
            null,
            e.amount,
            e.unit,
            vatType,
            e.price,
            revenueTypeArray,
            e.description
          )
        );
      } else {
        this.revenueArrayItem = new Revenue(
          null,
          null,
          e.amount,
          e.unit,
          vatType,
          e.price,
          [`${e.type}${e.id}`],
          e.description
        );
        this.revenueArraySelectorActive = true;
      }
    },
    revenueSaved(revenueItem) {
      this.combinedCosts = [];
      this.combineCostsActive = false;
      if (this.$refs["revenueList"].length === 1) {
        this.$refs[`revenueList`][0].addRevenueLine(revenueItem);
      } else {
        this.revenueArrayItem = revenueItem;
        this.revenueArraySelectorActive = true;
      }
    },
    splitRevenue(e) {
      e.forEach((revList) => {
        if (revList.itemObj.percentage > 0)
          this.$refs[`revenueList`]
            .find((x) => x.revenueId === revList.id)
            .addRevenueLine(revList.itemObj);
      });
      this.combinedCosts = [];
      this.combineCostsActive = false;
      this.markPayActive = false;
    },
    handleRevenueListPayerChanged(revenueId, payer) {
      let revObj = this.revenuesArray.find((x) => x.id === revenueId);
      set(revObj, "payer", payer);
    },
    selectTemplate(template, task) {
      let values = [];
      this.taskCosts.forEach((x) => {
        values.push(...x.costs);
      });
      values = [...values, ...[...this.noTaskCosts.costs]];
      if (task) {
        values = values.filter((x) => x.task_id === task.id);
      }
      values = values.filter(
        (x) => !this.addedCostIds.includes(`${x.type}${x.id}`)
      );

      let templateTypeToCostMap = {
        1: values.filter(
          (x) => x.type === "workerCost" || x.type === "equipmentCost"
        ),
        2: values.filter((x) => x.type === "workerCost"),
        3: values.filter((x) => x.type === "resourceCost"),
        4: values.filter((x) => x.type === "equipmentCost"),
        5: values.filter((x) => x.type === "supplierCost"),
        6: values.filter((x) => x.type === "extraCost"),
        7: values,
      };
      this.selectedTemplate = template;
      this.templateCosts = templateTypeToCostMap[template.type];
      this.selectedTask = task;
    },
    selectTemplateNoTask(template, task) {
      task = this.noTaskCosts;
      const costTypeMapping = {
        1: task.costs.filter((x) => x.type === "equipmentCost"),
        2: task.costs.filter((x) => x.type === "workerCost"),
        3: task.costs.filter((x) => x.type === "resourceCost"),
        4: task.costs.filter((x) => x.type === "equipmentCost"),
        5: task.costs.filter((x) => x.type === "supplierCost"),
        6: task.costs.filter((x) => x.type === "extraCost"),
      };
      this.selectedTemplate = template;
      this.templateCosts = costTypeMapping[template.type] || [];
      this.selectedTask = task;
    },
    removeCostFromTemplateCosts(e) {
      this.templateCosts.splice(this.templateCosts.indexOf(e), 1);
    },
    closeModal() {
      this.selectedTemplate = null;
      this.selectedTask = null;
      this.templateCosts = [];
      this.combinedCosts = [];
      this.combineCostsModalActive = false;
    },
    handleRevenuesChanged() {
      this.addedCostIds = this.$refs["revenueList"]
        .map((y) => y.newRevenues.map((x) => x.revenueTypeArray).flat(2))
        .flat(2);
    },
    loadProjectCostTemplates() {
      this.apiRequest(
        `template/${this.companyId}/costs/templates/?type=project&object_id=${this.$route.params.projectId}`,
        "get",
        true
      )
        .then((res) => {
          this.templates = res.data;
          this.retrieveProjectsTotalCosts();
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("messageHandler/throwMessage", {
            text: "Midagi läks valesti",
            type: "error",
            ttl: 15,
          });
        });
    },
    retrieveProjectsTotalCosts() {
      let params = {};
      this.revenuesArray = [];
      if (
        this.dateRangeState &&
        this.dateRangeState.filter &&
        this.dateRangeState.startDate !== null
      )
        params.start_time = moment(this.dateRangeState.startDate).format(
          "YYYY-MM-DD"
        );
      if (
        this.dateRangeState &&
        this.dateRangeState.filter &&
        this.dateRangeState.endDate !== null
      )
        params.end_time = moment(this.dateRangeState.endDate).format(
          "YYYY-MM-DD"
        );
      let url = `costs/${this.companyId}/project/${
        this.$route.params.projectId
      }/total/${generateParamString(params)}`;
      this.taskCosts = null;
      this.noTaskCosts = null;
      this.apiRequest(url, "get", true)
        .then((res) => {
          this.taskCosts = res.data.tasks;
          this.noTaskCosts = res.data.no_tasks;
          this.payer = res.data.payer;
          this.client = res.data.client;
          this.templatesLoaded = true;
          this.revenuesArray.push({
            id: 0,
            client: res.data.client,
            payer: res.data.payer,
            invoice: null,
          });
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("messageHandler/throwMessage", {
            text: "Midagi läks valesti",
            type: "error",
            ttl: 15,
          });
        });
    },
    goToInvoice: async function () {
      if (this.revenuesArray.length > 1) {
        this.revenuesArray.map((x) => {
          const revObj = this.$refs[`revenueList`].find(
            (y) => y.revenueId === x.id
          );
          x.revenues = [...revObj.newRevenues, ...revObj.revenuesEditable];
          delete x.itemObj;
        });
        await this.$store.dispatch("invoice/setMultipleData", {
          projectId: this.$route.params.projectId,
          revenuesArray: this.revenuesArray,
          addedCosts: this.addedCostIds,
        });
        await this.$router.push(`/invoices/outgoing/multiple/add/`);
      } else {
        await this.$store.dispatch("invoice/setData", {
          revenues: [
            ...this.$refs["revenueList"][0].newRevenues,
            ...this.$refs["revenueList"][0].revenuesEditable,
          ],
          addedCosts: this.addedCostIds,
          projectId: this.$route.params.projectId,
          client: this.client.id,
          payer: this.payer ? this.payer.id : null,
          invoice: this.revenuesArray[0].invoice
            ? this.revenuesArray[0].invoice
            : null,
        });
        await this.$router.push(`/invoices/outgoing/add/`);
      }
    },
    loadInvoiceRevenues() {
      this.revenuesArray = [];
      this.showRevenueList = false;
      this.apiRequest(
        `invoice/${this.companyId}/outgoing/?project_id=${this.$route.params.projectId}&manually_checked=False`,
        "get",
        true
      )
        .then((res) => {
          this.existingInvoices = res.data.results;
          this.selectExistingInvoiceActive = true;
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("messageHandler/throwMessage", {
            text: "Midagi läks valesti",
            type: "error",
            ttl: 15,
          });
        });
    },
    activateSelectedExistingInvoices(invoices) {
      Promise.all(
        invoices.map(async (x, index) => {
          this.invoiceAmount++;
          let invoiceObject = {
            id: index,
            client: x.client,
            payer: x.payer ? x.payer : this.payer,
            invoice: x,
          };
          await this.apiRequest(
            `invoice/${this.companyId}/outgoing/${x.id}/revenues/`,
            "get",
            true
          ).then((res2) => {
            invoiceObject.revenues = res2.data.map((revenue) => {
              return new Revenue(
                revenue.id,
                null,
                revenue.amount,
                revenue.unit,
                this.taxTypes.find(
                  (y) => parseInt(y.accounting_code) === revenue.vat
                ),
                revenue.price,
                null,
                revenue.service_name
              );
            });
          });
          this.revenuesArray.push(invoiceObject);
        })
      ).then(() => {
        this.selectExistingInvoiceActive = false;
        this.existingInvoices = [];
        this.showRevenueList = true;
      });
    },
    openCombinedCosts(e) {
      for (let i = 0; i < e.length; i++) {
        const el = e[i];
        this.combinedCosts.push(el);
      }

      this.combineCostsModalActive = true;
    },
    addToCombinedCosts(e) {
      if (!this.combinedCosts.includes(e)) {
        this.combinedCosts.push(e);
      } else {
        this.combinedCosts.splice(this.combinedCosts.indexOf(e), 1);
      }
    },
    addToMarkedAsPaid(e) {
      if (!this.markedAsPaid.includes(e)) {
        this.markedAsPaid.push(e);
      } else {
        this.markedAsPaid.splice(this.markedAsPaid.indexOf(e), 1);
      }
    },
    addAnotherInvoice(taskData) {
      let data = {
        id: this.invoiceAmount++,
        client: this.client,
        invoice: null,
      };
      if (taskData.payer) {
        data.payer = taskData.payer;
        data.task = taskData.task;
      }
      this.revenuesArray.push(data);
    },
  },
  mounted() {
    if (this.taxTypes)
      this.$store.dispatch("companyVariables/retrieveTaxTypes");
    if (this.serviceObjects)
      this.$store.dispatch("companyVariables/retrieveServiceObjects");
    this.loadProjectCostTemplates();
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      serviceObjects: "companyVariables/serviceObjects",
      taxTypes: "companyVariables/taxTypes",
    }),
    revenues() {
      return this.revenuesArray.map((revenue) => ({
        ...revenue,
        revenueListKey: `revenueList${revenue.id}`,
      }));
    },
  },
};
</script>
<style lang="scss">
.narrow-cost-list {
  grid-template-columns: 0.3fr 1.2fr 0.3fr 0.3fr 0.3fr;
}
.narrow-task-list {
  grid-template-columns: 0.3fr 0.2fr 0.5fr;
}
.narrow-template-list {
  grid-template-columns: 0.3fr 0.8fr 0.2fr 0.2fr;
}
</style>
