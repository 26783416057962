<template>
  <div class="modal">
    <div class="modal-body modal-large" v-on-clickaway:mousedown="closeModal">
      <div class="modal-header p-3 items-center">
        <h3>Planeeritud ja reaalsed kulud</h3>
        <div class="ml-auto flex px-2 items-center">
          <label class="mr-2">Töötaja palgad</label>
          <gwd-checkbox size="6" v-model="filter.orkerCost" />
        </div>
        <div class="flex px-2 items-center">
          <label class="mr-2">Tehnika</label>
          <gwd-checkbox v-model="filter.quipmentCost" size="6" />
        </div>
        <div class="flex px-2 items-center">
          <label class="mr-2">Ressurssid</label>
          <gwd-checkbox v-model="filter.esourceCost" size="6" />
        </div>
        <div class="flex px-2 items-center">
          <label class="mr-2">Lisatööd</label>
          <gwd-checkbox v-model="filter.xtraCost" size="6" />
        </div>
        <div class="flex px-2 items-center">
          <label class="mr-2">Hankijad</label>
          <gwd-checkbox v-model="filter.upplierCost" size="6" />
        </div>
      </div>
      <div class="modal-content flex-col w-full">
        <div class="gwd-table nested">
          <div class="gwd-table-header grid-cols-12">
            <span class="col-span-2">Nimi</span>
            <div class="col-span-4 wider-cost-list">
              <span />
              <span>Kulude kogus</span>
              <span>Kogus</span>
              <span>Hind</span>
              <span>Summa</span>
            </div>
            <div class="col-span-4 wider-cost-list">
              <span />
              <span>Kulude kogus</span>
              <span>Kogus</span>
              <span>Hind</span>
              <span>Summa</span>
            </div>
          </div>
          <div v-for="dictKey in allKeys" :key="dictKey">
            <combined-element-cost
              :planned-costs="
                plannedKeys.includes(dictKey) ? plannedCosts[dictKey] : []
              "
              :actual-costs="
                actualKeys.includes(dictKey) ? actualCosts[dictKey] : []
              "
              :filter="filter"
              :added-cost-ids="addedCostIds"
              :combined-costs="localCombinedCosts"
              :display-mode="displayMode"
              @addItem="addItem"
              @addToLocalCombinedCosts="addToLocalCombinedCosts"
            />
          </div>
        </div>
        <div class="grid grid-cols-12 w-full" v-if="allKeys.length > 0">
          <div class="col-span-2" />
          <div class="flex col-span-4 spec">
            <div class="flex"></div>
            <span class="flex">{{ round2(plannedTotalPrice) }} € </span>
          </div>
          <div class="flex col-span-4 spec">
            <div class="flex"></div>
            <span class="flex">{{ round2(actualTotalPrice) }} € </span>
          </div>
          <div class="col-span-2" />
        </div>
      </div>
      <div class="modal-bottom-bar">
        <button
          class="btn-primary"
          v-if="localCombinedCosts.length > 0"
          @click="openCombinedCosts"
        >
          Kombineeri, Valitud {{ localCombinedCosts.length }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CostTypeParser from "@/assets/mixins/CostTypeParser";
import { round2 } from "@/assets/utils/commonMath";
import CombinedElementCost from "@/components/invoices/CombinedElementCost.vue";
export default {
  name: "PlannedActualCostModal",
  components: { CombinedElementCost },
  props: {
    project: {
      type: String,
      default: null,
    },
    task: {
      type: Number,
      default: null,
    },
    addedCostIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
    displayMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      plannedCosts: [],
      actualCosts: [],
      allKeys: [],
      plannedKeys: [],
      actualKeys: [],
      diffStatusPrice: 0,
      filter: {
        orkerCost: true,
        quipmentCost: true,
        esourceCost: true,
        xtraCost: true,
        upplierCost: true,
      },
      localCombinedCosts: [],
    };
  },
  mixins: [CostTypeParser],
  methods: {
    round2,
    closeModal() {
      this.$emit("close");
    },
    addItem(e) {
      this.$emit("addItem", e);
    },
    openCombinedCosts() {
      this.$emit("close");
      this.$emit("openCombinedCosts", this.localCombinedCosts);
    },
    addToLocalCombinedCosts(e) {
      for (let i = 0; i < e.length; i++) {
        this.localCombinedCosts.push(e[i]);
      }
    },
    loadPlannedAndActualCosts() {
      let url;
      if (this.task) {
        url = `costs/${this.companyId}/task/${this.task}/combined/`;
      } else {
        url = `costs/${this.companyId}/project/${this.project}/combined/`;
      }
      this.apiRequest(url, "get", true).then((res) => {
        this.plannedCosts = res.data.planned_costs;
        this.actualCosts = res.data.actual_costs;
        this.plannedKeys = Object.keys(this.plannedCosts);
        this.actualKeys = Object.keys(this.actualCosts);

        this.allKeys = this.plannedKeys
          .concat(this.actualKeys)
          .filter(function (e, i, c) {
            // extra step to remove duplicates
            return c.indexOf(e) === i;
          });
      });
    },
  },
  mounted() {
    this.loadPlannedAndActualCosts();
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
    }),
    plannedTotalPrice() {
      if (!this.plannedCosts) return 0;
      let total = 0;
      for (const values of Object.values(this.plannedCosts)) {
        total += values.reduce(
          (prev, curr) => prev + curr.amount * curr.price,
          0
        );
      }
      return total;
    },
    actualTotalPrice() {
      if (!this.actualCosts) return 0;
      let total = 0;
      for (const values of Object.values(this.actualCosts)) {
        total += values.reduce(
          (prev, curr) => prev + curr.amount * curr.price,
          0
        );
      }
      return total;
    },
  },
};
</script>

<style scoped lang="scss">
.spec {
  @apply grid items-center w-full py-1;
  grid-template-columns: 0.8fr 0.2fr;
}
.wider-cost-list {
  @apply grid items-center w-full py-1;
  grid-template-columns: 0.3fr 0.3fr 0.2fr 0.2fr 0.2fr;
}
</style>
