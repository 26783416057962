<template>
  <div class="flex flex-col">
    <div class="flex flex-row items-center mb-4">
      <div class="border-r px-2">
        <span class="font-medium">Filter</span>
      </div>
      <div class="flex px-2 items-center">
        <label class="mr-2">Töötaja palgad</label>
        <gwd-checkbox v-model="filter.workerCost" size="6" />
      </div>
      <div class="flex px-2 items-center">
        <label class="mr-2">Tehnika</label>
        <gwd-checkbox v-model="filter.equipmentCost" size="6" />
      </div>
      <div class="flex px-2 items-center">
        <label class="mr-2">Ressurssid</label>
        <gwd-checkbox v-model="filter.resourceCost" size="6" />
      </div>
      <div class="flex px-2 items-center">
        <label class="mr-2">Lisatööd</label>
        <gwd-checkbox v-model="filter.extraCost" size="6" />
      </div>
      <div class="flex px-2 items-center">
        <label class="mr-2">Hankijad</label>
        <gwd-checkbox v-model="filter.supplierCost" size="6" />
      </div>
    </div>
    <div class="gwd-table" v-if="allCostsLoaded">
      <div class="gwd-table-header narrow-cost-list">
        <span>Tüüp</span>
        <span>Kirjeldus</span>
        <span>Kogus</span>
        <span>Hind</span>
        <span class="flex justify-end">
          <button class="btn-primary" @click="showAdded = !showAdded">
            {{ showAdded ? "Peida" : "Näita" }}
          </button>
        </span>
      </div>
      <div
        v-for="cost in showAdded
          ? allCosts
          : allCosts.filter((y) => !addedCostIds.includes(`${y.type}${y.id}`))"
        :key="`${cost.type}${cost.id}`"
        class="gwd-table-row nopad narrow-cost-list items-center"
      >
        <div
          v-html="parseCostColorAndName(cost.type)"
          class="flex items-center h-full gap-x-1"
        />
        <span>{{ cost.description }}</span>
        <div class="flex py-2">
          <span class="mr-1"> {{ cost.amount }}</span>
          <span> {{ cost.unit }}</span>
        </div>
        <span class="flex py-2">{{ cost.price }}</span>
        <div class="flex justify-end py-2 pr-2">
          <gwd-checkbox
            v-if="markAsPaid || combineCostsActive"
            :value="
              combineCostsActive
                ? combinedCosts.includes(cost)
                : markedAsPaid.includes(cost)
            "
            @input="
              combineCostsActive
                ? addToCombinedCosts(cost)
                : addToMarkedAsPaid(cost)
            "
          />
          <button
            @click="addItem(cost)"
            class="btn-primary"
            v-if="
              !(combineCostsActive || markAsPaid) &&
              (!showAdded ||
                (showAdded && !addedCostIds.includes(`${cost.type}${cost.id}`)))
            "
          >
            <span class="label">Lisa </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CostTypeParser from "@/assets/mixins/CostTypeParser";

export default {
  name: "FilteredCosts",
  props: {
    costs: {
      type: Array,
      default: () => {
        return [];
      },
    },
    noTaskCosts: {
      type: Object,
      default: () => {},
    },
    addedCostIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
    combinedCosts: {
      type: Array,
      default: () => {
        return [];
      },
    },
    combineCostsActive: {
      type: Boolean,
      default: false,
    },
    markedAsPaid: {
      type: Array,
      default: () => [],
    },
    markAsPaid: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      filter: {
        workerCost: true,
        equipmentCost: true,
        resourceCost: true,
        extraCost: true,
        supplierCost: true,
      },
      allCosts: [],
      allCostsLoaded: false,
      allCostsCopy: [],
      showAdded: false,
    };
  },
  mixins: [CostTypeParser],
  methods: {
    addItem(e) {
      this.$emit("addItem", e);
    },
    initCosts() {
      let values = [];
      this.costs.forEach((x) => {
        values.push(...x.costs);
      });
      values = [...values, ...[...this.noTaskCosts.costs]];
      this.allCosts = values;
      this.allCostsCopy = JSON.parse(JSON.stringify(values));
      this.filterCosts();
      this.allCostsLoaded = true;
    },
    filterCosts() {
      this.allCosts = this.allCostsCopy
        .filter((x) => this.filter[x.type])
        .filter((x) => {
          if (this.status || this.status === 0) return x.status === this.status;
          return true;
        })
        .sort((a, b) => a.type < b.type);
    },
    addToCombinedCosts(e) {
      this.$emit("addToCombinedCosts", e);
    },
    addToMarkedAsPaid(e) {
      this.$emit("addToMarkedAsPaid", e);
    },
    combineAll() {
      this.allCosts.forEach((e) => {
        this.addToCombinedCosts(e);
      });
    },
  },
  mounted() {
    this.initCosts();
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.filterCosts();
      },
    },
    status: {
      handler() {
        this.filterCosts();
      },
    },
  },
};
</script>
