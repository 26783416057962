var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-container-content"},[_c('div',{staticClass:"flex flex-wrap gap-x-2 gap-y-2"},_vm._l((_vm.taskData.sub_tasks),function(assignment){return _c('div',{key:assignment.worker.id,staticClass:"w-1/3 bg-white rounded shadow flex flex-col gap-y-2 p-2"},[_c('div',{staticClass:"flex items-center justify-between bg-offwhite rounded-t -mx-2 -mt-2 p-2"},[_c('h3',[_vm._v(_vm._s(assignment.worker.worker_name))]),_c('div',{staticClass:"flex items-center gap-x-2"},[(_vm.workerHasActiveTimer(assignment.worker.profile))?_c('span',{staticClass:"h-4 w-4 bg-green rounded-full",attrs:{"title":"Töötajal on aktiivne taimer"}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.getWorkerTimeTotal(assignment.worker.profile)))])])]),_c('div',{staticClass:"flex items-center gap-x-2"},[_c('div',{staticClass:"flex flex-1 items-center gap-x-2"},[_c('h4',[_vm._v("Algus")]),(assignment.start_time)?_c('time-selector',{attrs:{"width":"8"},model:{value:(assignment.start_time),callback:function ($$v) {_vm.$set(assignment, "start_time", $$v)},expression:"assignment.start_time"}}):_c('span',{staticClass:"w-full h-full hover:bg-offwhite hover:p-1 rounded duration-200 cursor-pointer",on:{"click":function () { return (assignment.start_time = {
                  hour: _vm.moment(_vm.taskData.start_time).hour(),
                  minute: _vm.moment(_vm.taskData.start_time).minute(),
                }); }}},[_vm._v(" "+_vm._s(("" + (_vm.moment(_vm.taskData.start_time).format("HH:mm"))))+" ")]),(assignment.start_time)?_c('button',{staticClass:"btn-danger w-5",on:{"click":function($event){assignment.start_time = null}}},[_c('span',{staticClass:"typcn typcn-times leading-none"})]):_vm._e()],1),_c('div',{staticClass:"flex flex-1 items-center gap-x-2"},[_c('h4',[_vm._v("Lõpp")]),(assignment.end_time)?_c('time-selector',{attrs:{"width":"10"},model:{value:(assignment.end_time),callback:function ($$v) {_vm.$set(assignment, "end_time", $$v)},expression:"assignment.end_time"}}):_c('span',{staticClass:"w-full h-full hover:bg-offwhite p-1 rounded duration-200 cursor-pointer",on:{"click":function () { return (assignment.end_time = {
                  hour: _vm.moment(_vm.taskData.end_time).hour(),
                  minute: _vm.moment(_vm.taskData.end_time).minute(),
                }); }}},[_vm._v(" "+_vm._s(("" + (_vm.moment(_vm.taskData.end_time).format("HH:mm"))))+" ")]),(assignment.end_time)?_c('button',{staticClass:"btn-danger w-5",on:{"click":function($event){assignment.end_time = null}}},[_c('span',{staticClass:"typcn typcn-times leading-none"})]):_vm._e()],1)]),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex gap-x-2"},[_c('h4',[_vm._v("Lähteaadress")]),(assignment.location)?_c('button',{staticClass:"btn-danger",on:{"click":function($event){assignment.location = null}}},[_vm._v(" x ")]):_vm._e()]),_c('div',{staticClass:"flex"},[(assignment.location)?_c('location-inaddress',{staticClass:"w-full",attrs:{"emit-standardized-object":true},model:{value:(assignment.location),callback:function ($$v) {_vm.$set(assignment, "location", $$v)},expression:"assignment.location"}}):_c('span',{staticClass:"flex-grow flex truncate py-1 hover:px-2 hover:bg-offwhite duration-200 rounded h-full cursor-pointer",on:{"click":function () { return (assignment.location = {}); }}},[_vm._v(" "+_vm._s(_vm.taskData.location ? _vm.taskData.location.long_address : "-")+" ")])],1)]),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex gap-x-2"},[_c('h4',[_vm._v("Sihtaadress")]),(assignment.end_location)?_c('button',{staticClass:"btn-danger",on:{"click":function($event){assignment.end_location = null}}},[_vm._v(" x ")]):_vm._e()]),_c('div',{staticClass:"flex"},[(assignment.end_location)?_c('location-inaddress',{staticClass:"w-full",attrs:{"emit-standardized-object":true},model:{value:(assignment.end_location),callback:function ($$v) {_vm.$set(assignment, "end_location", $$v)},expression:"assignment.end_location"}}):_c('span',{staticClass:"w-full truncate py-1 hover:bg-offwhite hover:px-2 duration-200 rounded h-full cursor-pointer",on:{"click":function () { return (assignment.end_location = _vm.taskData.end_location
                  ? _vm.taskData.end_location
                  : {}); }}},[_vm._v(" "+_vm._s(_vm.taskData.end_location ? _vm.taskData.end_location.long_address : "-")+" ")])],1)]),_c('div',{staticClass:"flex"},[_c('gwd-textarea',{attrs:{"placeholder":"Kirjeldus"},model:{value:(assignment.description),callback:function ($$v) {_vm.$set(assignment, "description", $$v)},expression:"assignment.description"}})],1),_c('card-view-assignment-equipment',{staticClass:"flex flex-col",attrs:{"equipment":assignment.worker && assignment.worker.equipment
            ? assignment.worker.equipment
            : []}})],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }