<template>
  <div class="w-full items-center flex flex-col add-task-modal">
    <div class="flex flex-col w-full">
      <div class="flex flex-col w-full mobile:flex-col gap-y-4 flex-1">
        <div class="flex w-full gap-x-2 mobile:flex-col gap-y-2">
          <div class="flex flex-col w-1/2 gap-y-2">
            <div class="data-container">
              <div class="data-container-header">
                <h4>Üldinfo</h4>
              </div>
              <div class="data-container-content mobile:gap-y-4 text-sm">
                <div class="flex gap-x-2 mobile:flex-col mobile:gap-y-4">
                  <div class="flex flex-col gwd-form w-1/2 mobile:w-full">
                    <div class="form-group">
                      <gwd-formlabel
                        title="Pealkiri"
                        :required="true"
                        :valid="!$v.fields.title.$invalid"
                        :error="$v.fields.title.$error"
                        :error-text="
                          fields.title && $v.fields.title.$params.maxLength
                            ? 'Pealkiri on liiga pikk'
                            : 'Kohustuslik'
                        "
                      />
                      <gwd-textarea
                        class="shadow focus:shadow-outline"
                        :class="
                          $v.$error && $v.fields.title.$invalid
                            ? 'shadow-dangeroutline'
                            : ''
                        "
                        v-model="fields.title"
                        :max-length="128"
                        :background="true"
                      />
                    </div>
                    <div class="gwd-form-hor-group gap-x-2">
                      <gwd-formlabel title="Tellimus" />
                      <div class="flex items-center gap-x-2">
                        <button
                          class="btn-primary"
                          @click="
                            $store.commit(
                              'modals/addTaskModal/setShowProjectSelector',
                              true
                            )
                          "
                          v-if="!selectedProject && !relatedProject"
                        >
                          <span class="label">Vali</span>
                        </button>
                        <button
                          class="btn-primary"
                          @click="
                            $store.commit(
                              'modals/addTaskModal/setShowNewProjectModal',
                              true
                            )
                          "
                          v-if="!selectedProject && !relatedProject"
                        >
                          <span class="label">Lisa uus</span>
                        </button>
                        <span>{{
                          relatedProject
                            ? relatedProject.title
                            : selectedProject
                            ? selectedProject.title
                            : ""
                        }}</span>
                        <button
                          v-if="!relatedProject && selectedProject"
                          @click="
                            $store.dispatch(
                              'modals/addTaskModal/setShowProjectSelector',
                              true
                            )
                          "
                          class="btn-primary"
                        >
                          <span class="icon typcn typcn-plus"></span>
                          <span class="label">Vali uus</span>
                        </button>
                        <button
                          v-if="!relatedProject && selectedProject"
                          @click="setProject(null)"
                          class="new-button-danger"
                        >
                          <span class="icon typcn typcn-times"></span>
                          <span class="label">Tühjenda</span>
                        </button>
                      </div>
                    </div>
                    <div v-if="selectedProject" class="flex gap-x-2">
                      <h4>Klient</h4>
                      <router-link
                        :to="`/clients/${selectedProject.client_id}`"
                        target="_blank"
                      >
                        <span>
                          {{
                            selectedProject.client_name
                              ? selectedProject.client_name
                              : selectedProject.client.name
                              ? selectedProject.client.name
                              : "?"
                          }}
                        </span>
                      </router-link>
                    </div>
                    <div class="gwd-form-hor-group gap-x-2">
                      <gwd-formlabel title="Maksja" />
                      <button
                        class="btn-primary"
                        v-if="!fields.payer"
                        @click="selectSeparatePayer = true"
                      >
                        <span class="label">Vali</span>
                      </button>
                      <div
                        v-if="
                          fields.payer && Object.keys(fields.payer).length > 0
                        "
                        class="flex items-center w-full gap-x-2"
                      >
                        <span>{{ fields.payer.name }}</span>
                        <button
                          class="btn-danger"
                          @click="() => (fields.payer = null)"
                        >
                          <span class="label">Muuda</span>
                        </button>
                      </div>
                    </div>
                    <div class="flex items-center mobile:justify-center">
                      <gwd-checkbox
                        class="mr-2"
                        v-model="fields.is_public"
                        size="6"
                      />
                      <span>Nähtav kõikidele töötajatele</span>
                    </div>
                    <div class="flex items-center mobile:justify-center">
                      <gwd-checkbox
                        class="mr-2"
                        v-model="fields.publish"
                        size="6"
                      />
                      <span>Salvesta aktiivsena</span>
                    </div>
                    <div
                      class="flex items-center mobile:justify-center"
                      v-if="relatedProject"
                    >
                      <gwd-checkbox
                        class="mr-2"
                        v-model="fields.visible_to_client"
                        size="6"
                      />
                      <span>Nähtav kliendile</span>
                    </div>
                  </div>
                  <div class="flex w-1/2">
                    <add-task-time-selector ref="timeSelector" />
                  </div>
                </div>
              </div>
            </div>
            <div class="data-container">
              <div class="data-container-header">
                <h4>Kirjeldus</h4>
              </div>
              <div class="data-container-content text-sm">
                <div class="form-group">
                  <gwd-textarea
                    v-model="fields.description"
                    :background="true"
                    :max-length="1024"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-y-2 w-1/2 mobile:w-full">
            <div class="data-container">
              <div class="data-container-header">
                <h4>Asukoht</h4>
              </div>
              <div class="data-container-content text-sm">
                <add-task-location-selector />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-full mx-auto error-box"
        ref="errorBox"
        v-if="$v.$error && $v.$invalid"
      >
        <div class="flex w-1/12 justify-center items-center">
          <span class="typcn typcn-times text-4xl leading-none"></span>
        </div>
        <div class="flex w-11/12 items-center">
          <span v-if="$v.fields.title.$invalid">Palun sisestage pealkiri</span>
          <span v-if="$v.fields.description.$invalid"
            >Kirjeldus võib olla kuni 1024 tähemärki</span
          >
        </div>
      </div>
    </div>
    <client-selector-modal
      v-if="selectSeparatePayer"
      :select-client="false"
      @closeModal="selectSeparatePayer = false"
      @clientSelected="
        (e) => {
          fields.payer = e;
          selectSeparatePayer = false;
        }
      "
    />
  </div>
</template>

<script>
import { RequestHandler } from "@/assets/mixins/GewodoMixins";
import { ee } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import { maxLength, required } from "vuelidate/lib/validators";
import "moment/locale/et";
import AddTaskTimeSelector from "./AddTaskTimeSelector.vue";
import { mapGetters, mapState } from "vuex";
import ClientSelectorModal from "@/components/project/ClientSelectorModal.vue";
import AddTaskLocationSelector from "@/components/tasks/AddTaskLocationSelector";
export default {
  props: {
    hasPresetData: Boolean,
    presetData: {
      type: [Object, Array, Date],
      default: () => {
        return {};
      },
    },
    relatedProject: {
      type: String,
      default: null,
    },
    projectLocation: {
      type: Object,
      default: () => {
        return {};
      },
    },
    displaySaveButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      ee: ee,
      topCalendarShowing: false,
      bottomCalendarShowing: false,
      selectSeparatePayer: false,
    };
  },
  mixins: [RequestHandler],
  components: {
    ClientSelectorModal,
    AddTaskTimeSelector,
    AddTaskLocationSelector,
  },
  mounted() {
    if (this.inputData) {
      if (this.inputData.project) this.setProject(this.inputData.project);
      if (this.inputData.location)
        this.fields.location = this.inputData.location;
      if (this.inputData.end_location)
        this.fields.endLocation = this.inputData.end_location;
    }
    if (Object.keys(this.projectLocation).length > 0)
      this.fields.location = this.projectLocation;
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    setProject(project) {
      this.$store.dispatch("modals/addTaskModal/setProject", project);
    },
    processLocationForEmit(inputLocation) {
      let location = JSON.parse(JSON.stringify(inputLocation));
      delete location["uuid"];
      return location;
    },
  },
  computed: {
    ...mapState({
      fields: (state) => state.modals.addTaskModal.fields,
    }),
    ...mapGetters({
      inputData: "modals/addTaskModal/inputData",
      selectedProject: "modals/addTaskModal/project",
      extraLocations: "modals/addTaskModal/extraLocations",
    }),
  },
  validations() {
    return {
      fields: {
        title: {
          required,
          maxLength: maxLength(128),
        },
        description: {
          maxLength: maxLength(1024),
        },
      },
    };
  },
};
</script>

<style lang="scss">
.add-task-modal {
  max-height: 85vh;
}
</style>
