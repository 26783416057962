var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex w-full items-center"},[_c('div',{staticClass:"w-4/12 mobile:w-1/2 flex justify-start items-center truncate"},[_vm._m(0),_c('span',{staticClass:"truncate w-1/2"},[_vm._v(_vm._s(_vm.data.worker_name))])]),_c('div',{staticClass:"w-7/12 flex flex-wrap gap-x-2 gap-y-1"},[_vm._l((_vm.data.equipment),function(equipment){return _c('span',{key:equipment.id,staticClass:"flex bg-primary text-white rounded text-sm font-medium p-1 gap-x-1 items-center"},[_c('img',{staticClass:"w-5 h-5 filter-to-white",attrs:{"src":"/icons/tehnika.svg","alt":"Equipment icon"}}),_vm._v(" "+_vm._s(equipment.name)+" ")])}),_vm._l((_vm.data.attributes),function(attribute){return _c('span',{key:attribute.id,staticClass:"flex bg-primary text-white rounded text-sm font-medium p-1"},[_vm._v(" "+_vm._s(attribute.name)+" ")])})],2),_c('div',{staticClass:"w-1/12 items-center flex mobile:order-4",class:[
      _vm.isBusy
        ? _vm.cautionHovered
          ? _vm.buttonFaded
            ? 'justify-start'
            : 'justify-between'
          : 'justify-between'
        : 'justify-end' ]},[(_vm.isBusy)?_c('div',{staticClass:"flex items-center justify-start duration-150 w-auto"},[(!_vm.cautionHovered && _vm.busyTextFaded)?_c('div',{staticClass:"text-white rounded-xl leading-none w-8 h-8 flex justify-center items-center font-extrabold cursor-default mr-3",class:_vm.cautionHovered ? 'bg-danger-dark' : 'bg-danger',on:{"mouseenter":function($event){_vm.cautionHovered = true},"mouseleave":function($event){_vm.cautionHovered = false}}},[_vm._v(" ! ")]):_vm._e(),_c('transition',{attrs:{"name":"fade","duration":{ enter: 300, leave: 100 }},on:{"before-enter":function($event){_vm.busyTextFaded = false},"after-leave":function($event){_vm.busyTextFaded = true}}},[(_vm.cautionHovered && _vm.buttonFaded)?_c('span',{staticClass:"text-sm py-1",on:{"mouseenter":function($event){_vm.cautionHovered = true},"mouseleave":function($event){_vm.cautionHovered = false}}},[_vm._v("Töötaja on valitud ajal teisele tööle määratud")]):_vm._e()])],1):_vm._e(),_c('transition',{attrs:{"name":"fade","duration":{ enter: 300, leave: 100 }},on:{"before-enter":function($event){_vm.buttonFaded = false},"after-leave":function($event){_vm.buttonFaded = true}}},[(!_vm.cautionHovered && _vm.busyTextFaded)?_c('button',{staticClass:"h-8",class:_vm.isSelected ? 'new-button-danger' : 'btn-primary',on:{"click":_vm.toggleStatus,"mouseover":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}},[_c('div',{staticClass:"transform duration-150 rounded-gwdfull icon items-center justify-center flex",class:_vm.isSelected ? 'rotate-45' : ''},[_c('span',{staticClass:"typcn typcn-plus text-2xl leading-none transform text-white"})])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mr-3"},[_c('span',{staticClass:"typcn typcn-user table-icon"})])}]

export { render, staticRenderFns }