<template>
  <div
    class="user-menu gap-y-2"
    :class="active ? 'user-menu-open' : 'user-menu-closed'"
  >
    <transition-group
      name="fade"
      :duration="{ enter: 500, leave: 50 }"
      class="mobile:w-full"
    >
      <ul v-if="active" key="ul1">
        <li key="1">
          <input
            v-if="fileView"
            ref="profileImage"
            type="file"
            accept="image/*"
            class="mb-2"
          />
          <button
            v-if="fileView"
            @click="saveProfileImage"
            data-cy="logout"
            class="btn-primary navbar-logout bg-white mb-2"
          >
            <span class="label py-1">Salvesta</span>
          </button>
          <button
            v-if="!fileView"
            @click="fileView = !fileView"
            data-cy="logout"
            class="btn-primary navbar-logout bg-white mb-2"
          >
            <span class="label py-1">Lisa pilt</span>
          </button>
        </li>

        <button
          @click="activateNotifications"
          data-cy="logout"
          class="btn-primary navbar-logout bg-white mb-2"
        >
          <span class="label py-1">Notifikatsioonid</span>
        </button>
        <button
          @click="logout"
          data-cy="logout"
          class="btn-danger navbar-logout bg-white"
        >
          <span class="label py-1">Logi välja</span>
        </button>
      </ul>
    </transition-group>
  </div>
</template>

<script>
export default {
  props: {
    active: Boolean,
  },
  data() {
    return {
      fileView: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    saveProfileImage() {
      if (this.$refs.profileImage.files.length > 0) {
        const formData = new FormData();
        const file = this.$refs.profileImage.files[0];
        const fileToAppend = new File([file], file.name, { type: file.type });
        formData.append("image", fileToAppend, file.name);
        this.apiRequest(
          `profiles/${this.$store.state.userData.user.id}/image/`,
          "post",
          true,
          formData
        ).then((res) => {
          console.log(res);
        });
      }
    },
    urlBase64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/");

      const rawData = window.atob(base64);
      let outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
    async activateNotifications() {
      if ("serviceWorker" in navigator) {
        await navigator.serviceWorker
          .register("/service-worker.js")
          .catch((err) => {
            console.log(err);
          });
      }
      navigator.serviceWorker.ready
        .then(async (serviceWorkerReg) => {
          const sub = await serviceWorkerReg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: this.urlBase64ToUint8Array(
              process.env.VUE_APP_VAPID_PUBLIC_KEY
            ),
          });
          this.apiRequest(`notifications/webpush/create/`, "post", true, {
            browser: navigator.userAgent
              .match(/(firefox|msie|chrome|safari|trident)/gi)[0]
              .toUpperCase(),
            p256dh: btoa(
              String.fromCharCode.apply(
                null,
                new Uint8Array(sub.getKey("p256dh"))
              )
            ),
            auth: btoa(
              String.fromCharCode.apply(
                null,
                new Uint8Array(sub.getKey("auth"))
              )
            ),
            registration_id: sub.endpoint,
          }).then(() => {
            if (!("Notification" in window)) {
              console.log("Notifications unsupported");
            } else if (Notification) {
              if (
                Notification.permission !== "denied" ||
                Notification.permission === "default"
              ) {
                Notification.requestPermission();
              }
            }
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>
