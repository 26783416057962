export const fileModal = {
  namespaced: true,
  state: {
    data: null,
    displayFileModal: false,
    displayFileType: "companyFile",
    draft: null,
  },
  mutations: {
    open(state, data) {
      state.fileToDisplay = data.file;
      state.displayFileModal = true;
      state.displayFileType = data.type;
      if (data.draft) {
        state.draft = data.draft;
      }
    },
    close(state) {
      state.fileToDisplay = null;
      state.displayFileModal = false;
      state.displayFileType = "companyFile";
    },
  },
  actions: {
    open({ commit }, data) {
      commit("open", data);
    },
    close({ commit }) {
      commit("close");
    },
  },
};
