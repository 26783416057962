export const addInvoiceModal = {
  state: {
    show: false,
    data: null,
  },
  mutations: {
    open(state, data) {
      state.show = true;
      state.data = data;
    },
    close(state) {
      state.show = false;
      state.data = null;
    },
  },
  actions: {
    open({ commit }, data) {
      commit("open", data);
    },
    close({ commit }) {
      commit("close");
    },
  },
};
