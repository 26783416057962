<template>
  <div class="flex flex-col p-2 gap-y-2 h-full">
    <div class="flex flex-initial">
      <search-input v-model="searchQuery" @input="debounceSearchQuery" />
    </div>

    <div class="flex flex-col h-full" v-if="clientsLoaded">
      <div class="flex flex-col flex-grow overflow-y-scroll">
        <div
          v-for="client in clients.results"
          :key="client.id"
          @click="selectClient(client)"
          class="w-full flex hover:bg-offwhite p-2 duration-100 cursor-pointer items-center gap-x-2"
          :class="selectedClient === client ? 'bg-primary bg-opacity-10' : ''"
        >
          <h4>{{ client.name }}</h4>
          <img
            src="/icons/briefcase.svg"
            class="h-6"
            v-if="client.is_company"
            alt="Company icon"
          />
          <span v-if="client.is_company">
            {{ client.register_code ? client.register_code : "-" }}</span
          >
        </div>
      </div>

      <div class="flex justify-center" v-if="clients.count > 1">
        <page-changer
          :page="page"
          :count="clients.count"
          @pageChange="changePage"
        />
      </div>
    </div>
    <div v-else>
      <clip-loader />
    </div>
  </div>
</template>

<script>
import { RequestHandler } from "@/assets/mixins/GewodoMixins";
import PageChanger from "@/components/reusable/PageChanger";
import debounce from "lodash/debounce";
import { mapGetters } from "vuex";
import { generateParamString } from "@/assets/utils/commonTransforms";
import SearchInput from "../reusable/SearchInput.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  name: "ExistingClientSelector",
  components: { PageChanger, SearchInput, ClipLoader },
  mixins: [RequestHandler],
  props: {
    mustBeAbleToPay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedClient: null,
      clients: [],
      clientsLoaded: false,
      menuActive: false,
      searchQuery: "",
      page: 1,
      pageCount: null,
    };
  },
  methods: {
    loadCompanyClients() {
      let params = { page: this.page, payable: this.mustBeAbleToPay };
      if (this.searchQuery.length > 0) params.filter = this.searchQuery;

      let url = `company/${this.companyId}/clients/${generateParamString(
        params
      )}`;
      this.apiRequest(url, "get", true).then((res) => {
        this.clients = res.data;
        this.clientsLoaded = true;
        if (this.$route.query.client) {
          this.selectClient(
            res.data.results.find(
              (x) => x.id === parseInt(this.$route.query.client)
            )
          );
          this.selectExistingClient = true;
        }
      });
    },
    toggleMenu() {
      this.menuActive = !this.menuActive;
    },
    findClientById(id) {
      this.client = this.clients.results.find((x) => x.id === id);
      this.client.existing = true;
      this.$emit("clientUpdated", this.client);
    },
    selectClient(item) {
      this.client = item;
      this.selectedClient = item;
      this.client.existing = true;
      this.$emit("clientUpdated", this.client);
    },
    clearSelection() {
      this.client = null;
      this.searchBox = "";
      this.$emit("clientUnselected");
    },
    closeDropdown() {
      this.menuActive = false;
      this.searchBox = "";
    },
    changePage(e) {
      this.page = e;
      this.loadCompanyClients();
    },
    debounceSearchQuery: debounce(function () {
      this.loadCompanyClients();
    }, 500),
  },
  mounted() {
    this.loadCompanyClients();
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
    }),
  },
  watch: {
    selectedClient(val) {
      if (val !== this.client) {
        this.client = this.selectedClient;
      }
    },
    searchQuery() {
      this.debounceSearchQuery();
    },
  },
};
</script>

<style scoped lang="scss">
.client-selector-menu {
  @apply flex flex-col;
}
</style>
