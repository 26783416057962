import StoreRequestHandler from "../../../assets/mixins/StoreRequestHandler";
import { countryList } from "./countryList";
export const companyVariables = {
  state: {
    taxTypes: null,
    costAccounts: null,
    objects: null,
    currencyList: null,
    workerAttributes: null,
    serviceObjects: null,
    templateTypes: [
      {
        type: 1,
        description: "Tehnika + töötajad",
        color: "rgba(216,205,2,0.5)",
      },
      {
        type: 2,
        description: "Töötajad",
        color: "rgba(61,52,139,0.5)",
      },
      {
        type: 3,
        description: "Ressurssid",
        color: "rgba(111,143,114,0.5)",
      },
      {
        type: 4,
        description: "Tehnika",
        color: "rgba(131,53,132,0.5)",
      },
      {
        type: 5,
        description: "Hankija",
        color: "rgba(217,131,36,0.5)",
      },
      {
        type: 6,
        description: "Lisatöö",
        color: "rgba(164,22,35,0.5)",
      },
      {
        type: 7,
        description: "Koguhind",
        color: "rgba(192,0,219,0.5)",
      },
      {
        type: 8,
        description: "Muu",
        color: "rgba(18,33,18,0.5)",
      },
    ],
    units: ["tk", "h", "m", "km", "l", "m²", "m³", "kg", "t", "paev", "kuu"],
    countryList: countryList,
    supplierGroups: null,
    equipmentStatuses: [
      {
        id: 1,
        description: "Aktiivne",
      },
      {
        id: 2,
        description: "Vigadega",
      },
      {
        id: 3,
        description: "Katki",
      },
      {
        id: 4,
        description: "Paranduses",
      },
      {
        id: 5,
        description: "Mitte aktiivne",
      },
    ],
    savedLocations: null,
    workers: null,
  },
  namespaced: true,
  mutations: {
    setTaxTypes(state, data) {
      state.taxTypes = data;
    },
    setCostAccounts(state, data) {
      state.costAccounts = data;
    },
    setObjects(state, data) {
      state.objects = data;
    },
    setCurrencyList(state, data) {
      state.currencyList = data;
    },
    setWorkerAttributes(state, data) {
      state.workerAttributes = data;
    },
    setServiceObjects(state, data) {
      state.serviceObjects = data;
    },
    setSupplierGroups(state, data) {
      state.supplierGroups = data;
    },
    setSavedLocations(state, data) {
      state.savedLocations = data;
    },
    setWorkers(state, data) {
      state.workers = data;
    },
  },
  actions: {
    retrieveTaxTypes({ commit, rootState }) {
      return new Promise((resolve) => {
        StoreRequestHandler.apiRequest(
          `invoice/${rootState.companyData.activeCompany.uuid}/ava/taxes/`,
          "get",
          true
        ).then((res) => {
          commit("setTaxTypes", res.data);
          resolve();
        });
      });
    },
    retrieveCostAccounts({ commit, rootState }) {
      return new Promise((resolve) => {
        StoreRequestHandler.apiRequest(
          `invoice/${rootState.companyData.activeCompany.uuid}/ava/cost_accounts/`,
          "get",
          true
        ).then((res) => {
          commit("setCostAccounts", res.data);
          resolve();
        });
      });
    },
    retrieveObjects({ commit, rootState }) {
      return new Promise((resolve) => {
        StoreRequestHandler.apiRequest(
          `invoice/${rootState.companyData.activeCompany.uuid}/ava/objects/`,
          "get",
          true
        ).then((res) => {
          commit("setObjects", res.data);
          resolve();
        });
      });
    },
    retrieveCurrencies({ rootState, commit }) {
      return new Promise((resolve) => {
        StoreRequestHandler.apiRequest(
          `invoice/${rootState.companyData.activeCompany.uuid}/currencies/`,
          "get",
          true
        ).then((res) => {
          commit("setCurrencyList", res.data);
          resolve();
        });
      });
    },
    retrieveWorkerAttributes({ rootState, commit }) {
      return new Promise((resolve, reject) => {
        StoreRequestHandler.apiRequest(
          `company/${rootState.companyData.activeCompany.uuid}/attributes/`,
          "get",
          true
        )
          .then((res) => {
            commit("setWorkerAttributes", res.data);
            resolve();
          })
          .catch((err) => {
            commit("setWorkerAttributes", []);
            console.error(err);
            reject(err);
          });
      });
    },
    retrieveServiceObjects({ rootState, commit }) {
      return new Promise((resolve) => {
        StoreRequestHandler.apiRequest(
          `invoice/${rootState.companyData.activeCompany.uuid}/service_objects/`,
          "get",
          true
        ).then((res) => {
          commit("setServiceObjects", res.data);
          resolve();
        });
      });
    },
    retrieveCurrencyRate({ state }, currencyCode, date) {
      return new Promise((resolve) => {
        StoreRequestHandler.apiRequest(
          `core/currency/?currency=${currencyCode}&date=${date}`
        ).then((res) => {
          state.currencyList.find((x) => x.code === currencyCode).rate =
            res.data;
          resolve();
        });
      });
    },
    retrieveSupplierGroups({ rootState, commit }) {
      return new Promise((resolve) =>
        StoreRequestHandler.apiRequest(
          `suppliers/${rootState.companyData.activeCompany.uuid}/groups/`,
          "get",
          true
        ).then((res) => {
          commit("setSupplierGroups", res.data);
          resolve();
        })
      );
    },
    retrieveSavedLocations({ rootState, commit }) {
      return new Promise((resolve) => {
        StoreRequestHandler.apiRequest(
          `settings/${rootState.companyData.activeCompany.uuid}/locations/`,
          "get",
          true
        ).then((res) => {
          commit("setSavedLocations", res.data);
          resolve();
        });
      });
    },
    retrieveWorkers({ rootState, commit }) {
      return new Promise((resolve) => {
        StoreRequestHandler.apiRequest(
          `company/${rootState.companyData.activeCompany.uuid}/workers/all/`,
          "get",
          true
        ).then((res) => {
          commit("setWorkers", res.data);
          resolve();
        });
      });
    },
  },
  getters: {
    taxTypes: (state) => state.taxTypes,
    costAccounts: (state) => state.costAccounts,
    objects: (state) => state.objects,
    currencyList: (state) => state.currencyList,
    workerAttributes: (state) => state.workerAttributes,
    serviceObjects: (state) => state.serviceObjects,
    templateTypes: (state) => state.templateTypes,
    units: (state) => state.units,
    countryList: (state) => state.countryList,
    supplierGroups: (state) => state.supplierGroups,
    equipmentStatuses: (state) => state.equipmentStatuses,
    savedLocations: (state) => state.savedLocations,
    companyWorkers: (state) => state.workers,
  },
};
