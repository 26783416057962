<template>
  <div
    @click="documentView ? activateProjectImage() : activateImage()"
    class="cursor-pointer"
  >
    <img
      v-if="imageLoaded"
      class="flex m-2 border border-primary rounded-md h-48"
      :src="imagePath"
      alt="Project image"
    />
  </div>
</template>

<script>
import { RequestHandler } from "@/assets/mixins/GewodoMixins";

export default {
  name: "ProjectImage",
  mixins: [RequestHandler],
  props: {
    image: {
      type: Object,
      default: () => {
        return {};
      },
    },
    type: {
      type: String,
      default: () => {
        return "image";
      },
    },
    documentView: {
      type: Boolean,
      default: false,
    },
    companyUuid: {
      type: String,
      default: "",
    },
    resize: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageLoaded: false,
      imagePath: "",
    };
  },
  methods: {
    activateImage() {
      this.$emit("active", this.imagePath);
    },
    activateProjectImage() {
      this.$emit("activate", this.image, this.imagePath);
    },
    loadImage() {
      this.apiRequest(
        "files/private/" +
          (this.companyUuid !== null &&
          this.companyUuid !== "" &&
          this.companyUuid !== undefined
            ? this.companyUuid
            : this.$store.state.companyData.activeCompany.uuid) +
          "/" +
          this.image.id +
          "/?type=" +
          this.type +
          (this.$store.state.companyData.isClient
            ? "&project_uuid=" + this.$route.params.projectId
            : ""),
        "get",
        true
      ).then((res) => {
        this.imagePath = `data:image/png;base64,${res.data}`;
        this.imageLoaded = true;
      });
    },
  },
  mounted() {
    this.loadImage();
  },
};
</script>

<style lang="scss">
.file-modal {
  min-height: 60vh;
  max-height: 75vh;
}
.mobile-file-modal {
  min-height: 20vh;
  max-height: 75vh;
}
</style>
