<template>
  <div
    class="flex justify-start items-center relative w-auto flex-grow max-w-full"
    :class="`w-${width}`"
    ref="wrapper"
    :data-is-time-input="key"
  >
    <div
      class="flex items-center w-full max-w-full bg-offwhite rounded h-8 shadow focus-within:ring-2 ring-primary ring-opacity-25 duration-150"
      v-if="(hour || hour === 0) && (minute || minute === 0)"
      :data-is-time-input="key"
    >
      <input
        ref="timeInput"
        v-model="timeInput"
        v-maska
        data-maska="SS:TS"
        data-maska-tokens="S:[0-9]|T:[0-5]"
        :data-is-time-input="key"
        class="h-full focus:ring-0 duration-100 shadow-none p-0 w-full max-w-full time-selector-input px-1"
        @focus="
          () => {
            showMenu = true;
            selectMinute = false;
          }
        "
        @keydown.tab="showMenu = false"
      />
    </div>
    <transition name="fade" mode="out-in" :duration="150">
      <div
        class="time-selector-menu"
        :style="posComputed"
        v-if="showMenu"
        v-on-clickaway="handleClickAway"
        :data-is-time-input="key"
      >
        <div class="flex">
          <button
            class="btn-danger"
            @click="
              () => {
                showMenu = false;
                selectMinute = false;
              }
            "
          >
            x
          </button>
        </div>

        <div
          class="time-selector-element mb-4"
          v-if="selectMinute"
          key="minute"
        >
          <div class="time-selector-foreground">
            <div
              v-for="item in minuteSelector"
              :key="`minute-${item}`"
              class="outer"
              @click="
                () => {
                  setTimeFromProp(hour, item);
                  showMenu = false;
                }
              "
              :class="minute === item ? 'selected' : ''"
            >
              {{ formatTimeUnit(item) }}
            </div>
          </div>
          <div class="time-selector-background">
            <div class="center"></div>
          </div>
        </div>
        <div class="time-selector-element mb-4" v-else key="hour">
          <div class="time-selector-foreground">
            <div
              v-for="item in hourSelectorOuter"
              :key="`hour-${item}`"
              @click="
                () => {
                  blockCloseMenu = true;
                  setTimeFromProp(item, minute);
                  selectMinute = true;
                }
              "
              class="outer"
              :class="hour === item ? 'selected' : ''"
            >
              {{ formatTimeUnit(item) }}
            </div>
            <div
              v-for="item in hourSelectorInner"
              :key="`hour-${item}`"
              @click="
                () => {
                  blockCloseMenu = true;
                  setTimeFromProp(item, minute);
                  selectMinute = true;
                }
              "
              class="inner"
              :class="hour === item ? 'selected' : ''"
            >
              {{ formatTimeUnit(item) }}
            </div>
          </div>
          <div class="time-selector-background">
            <div class="center"></div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import { formatTimeUnit } from "@/assets/utils/commonTransforms";
import { vMaska } from "maska";
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    isMoment: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: null,
    },
    pos: {
      type: String,
      default: "br",
    },
    inline: {
      type: Boolean,
      default: false,
    },
    offset: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      minuteSelector: [20, 25, 30, 35, 40, 45, 50, 55, 0, 5, 10, 15],
      hourSelectorOuter: [4, 5, 6, 7, 8, 9, 10, 11, 0, 1, 2, 3],
      hourSelectorInner: [15, 16, 17, 18, 19, 20, 21, 22, 23, 12, 13, 14],
      timeInput: null,
      hour: null,
      minute: null,
      selectMinute: false,
      showMenu: false,
      blockCloseMenu: false,
      key: null,
    };
  },
  directives: {
    maska: vMaska,
  },
  mounted() {
    this.key = this.generateKey();
    if (this.value?.hour) this.setHour(this.value.hour);
    if (this.value?.minute) this.setMinute(this.value.minute);
    if (this.isMoment) {
      this.setTimeFromMoment(this.value);
    } else {
      if (!this.value) {
        this.setTimeFromMoment(moment());
      } else {
        this.setTimeFromProp(this.value.hour, this.value.minute);
      }
    }
  },
  methods: {
    formatTimeUnit,
    setTimeFromMoment(time) {
      this.hour = time.hour();
      this.minute = time.minute();
      this.timeInput = `${formatTimeUnit(time.hour())}:${formatTimeUnit(
        time.minute()
      )}`;
      this.$emit("input", { hour: this.hour, minute: this.minute });
    },
    setTimeFromProp(hour, minute) {
      this.hour = hour;
      this.minute = minute;
      this.timeInput = `${formatTimeUnit(hour)}:${formatTimeUnit(minute)}`;
      setTimeout(() => (this.blockCloseMenu = false), 1000);
      this.$emit("input", { hour: this.hour, minute: this.minute });
    },
    forceTimeUpdate() {
      this.setTimeFromProp(this.value.hour, this.value.minute);
    },
    setHour(hour, fromClick = true) {
      this.hour = hour;
      if (fromClick) this.selectMinute = true;
      this.$emit("input", { hour: this.hour, minute: this.minute });
    },
    setMinute(minute) {
      this.minute = minute;
      this.$emit("input", { hour: this.hour, minute: this.minute });
    },
    initValue() {
      this.setHour(moment().hour(), false);
      this.setMinute(moment().minute());
    },
    handleClickAway(event) {
      if (event.target.dataset.isTimeInput !== this.key) {
        this.showMenu = false;
      }
    },
    generateKey() {
      return String(Math.random() * 10000000);
    },
  },
  computed: {
    posComputed() {
      let elPos = this.$refs.wrapper.getBoundingClientRect();
      if (this.pos === "t")
        return {
          bottom:
            window.innerHeight -
            elPos.bottom +
            elPos.height +
            this.offset +
            "px",
          left: elPos.left + "px",
        };
      if (this.pos === "r")
        return {
          top: elPos.top + "px",
          left: elPos.right + this.offset + "px",
        };

      return {
        top: elPos.bottom + this.offset + "px",
        left: elPos.left + "px",
      };
    },
  },
  watch: {
    timeInput(val) {
      if (val.length === 1 && val.match(/[3-9]/g)) this.timeInput = "0" + val;
      if (val.length === 5) {
        let timeSplit = val.split(":");
        this.setHour(timeSplit[0]);
        this.setMinute(timeSplit[1]);
        if (!this.blockCloseMenu) {
          this.showMenu = false;
        }
      }
    },
    showMenu(val) {
      if (val)
        this.$nextTick(() => {
          this.$refs.timeInput.focus();
        });
    },
  },
};
</script>

<style lang="scss">
.time-selector-menu {
  @apply fixed bg-offwhite shadow rounded flex flex-col p-2 isolate;
  z-index: 999;
  .time-selector-element {
    @apply bg-white rounded-full w-52 h-52 relative z-50 shadow border border-offwhite-dark;
    .time-selector-background {
      @apply absolute w-52 h-52 z-40;
      top: 0;
      left: 0;
      .center {
        @apply bg-offwhite-dark rounded-full w-3 h-3 absolute;
        left: calc(50% - 0.375rem);
        top: calc(50% - 0.375rem);
      }
    }
    .time-selector-foreground {
      @apply relative w-52 h-52 rounded-full z-50;
      $angle: calc(360 / 12);
      $rotation: 0;
      @for $i from 0 through 12 {
        .outer:nth-child(#{$i}) {
          @apply w-6 h-6 flex items-center justify-center cursor-pointer;
          position: absolute;
          left: 5.75rem;
          top: 5.75rem;
          transform: rotate($rotation * 1deg)
            translate(calc(11rem / 2))
            rotate($rotation * -1deg);
        }
        $rotation: $rotation + $angle;
      }
      $rotation: 0;
      @for $i from 13 through 24 {
        .inner:nth-child(#{$i}) {
          @apply w-6 h-6 flex items-center justify-center cursor-pointer;
          position: absolute;
          left: 5.75rem;
          top: 5.75rem;

          transform: rotate($rotation * 1deg)
            translate(calc(7.5rem / 2))
            rotate($rotation * -1deg);
        }
        $rotation: $rotation + $angle;
      }
      div {
        &:hover {
          @apply bg-primary text-white rounded-full;
        }
        &.selected {
          @apply bg-primary text-white rounded-full;
        }
      }
    }
  }
}
.time-selector-input {
  width: calc(100% - 1.5rem);
}
.time-selector {
  @apply shadow text-center;
  &:focus {
    @apply shadow-outline;
  }
}
.ui-select {
  @apply shadow-lg;
}
/* This is to remove the arrow of select element in IE */
select::-ms-expand {
  display: none;
}
select {
  -webkit-appearance: none;
  appearance: none;
}
</style>
