<template>
  <div class="modal relative">
    <div
      class="modal-body modal-large mobile:p-0 relative z-10 max-h-full"
      id="file-viewer"
      v-on-clickaway:mousedown="closeModal"
    >
      <div class="modal-header p-3">
        <h2>{{ file.file_name }}</h2>
      </div>
      <div class="modal-content flex-row mobile:flex-col" v-if="fileLoaded">
        <div class="flex flex-col w-10/12 restricted-height mobile:w-full">
          <div
            v-if="
              ['image/png', 'image/jpeg', 'image/svg+xml'].includes(
                file.file_type
              )
            "
            class="flex w-full h-full"
          >
            <img
              class="flex rounded mobile:w-full mobile:h-auto mobile:m-0"
              :src="mediaPath"
              @load="showImage = true"
              :class="!showImage ? 'hidden' : ''"
              :alt="'fail'"
            />
          </div>
          <div
            v-if="['application/pdf', 'pdf'].includes(file.file_type)"
            class="flex flex-col items-center justify-center"
          >
            <div
              class="flex flex-row items-center justify-center mb-3"
              v-if="totalPages > 1"
            >
              <div
                class="mr-1 cursor-pointer"
                @click="currentPage === 1 ? '' : (currentPage -= 1)"
              >
                <span class="p-2 rounded-full bg-offwhite">
                  <span
                    class="typcn typcn-chevron-left icon h-5 text-black"
                  ></span>
                </span>
              </div>
              <span> {{ currentPage }} / {{ totalPages }} </span>
              <div
                class="mx-1 cursor-pointer"
                @click="currentPage === totalPages ? '' : (currentPage += 1)"
              >
                <span class="p-2 rounded-full bg-offwhite">
                  <span
                    class="typcn typcn-chevron-right icon h-5 text-black"
                  ></span>
                </span>
              </div>
            </div>
            <div class="flex border">
              <vue-pdf-embed
                ref="pdfRef"
                :source="mediaPath"
                :page="currentPage"
                :width="pdfSize"
                @rendered="handleDocumentRender"
              />
            </div>
          </div>

          <div v-if="['video/webm', 'video/mp4'].includes(file.file_type)">
            <video-player :options="videoOptions" :source="mediaPath" />
          </div>
          <div
            v-if="
              ![
                'image/png',
                'image/jpeg',
                'image/svg+xml',
                'video/webm',
                'video/mp4',
                'application/pdf',
                'pdf',
              ].includes(file.file_type)
            "
          >
            <div
              class="flex flex-col rounded shadow bg-offwhite mt-3 py-3 px-5"
            >
              <span>Ei saa faili automaatselt kuvada</span>
              <span>faili nägemiseks laadige see alla</span>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col bg-offwhite rounded-xl pr-2 w-2/12 mobile:w-full shadow-sm"
          v-if="fileInfoLoaded && canEdit"
        >
          <span class="text-sm mb-2">
            Üleslaadija: {{ editables.uploaded_by }}</span
          >
          <span class="text-sm mb-2">
            Kuupäev: {{ createdAtFormatted(editables.created_at) }}</span
          >
          <span class="text-sm mb-2"> Suurus: {{ editables.size }}</span>
          <div
            class="flex flex-row items-center justify-between"
            v-if="canEdit"
          >
            <span class="text-sm mr-2">Nähtav töötajale</span>
            <gwd-checkbox class="flex" v-model="editables.visible_to_workers" />
          </div>
          <div
            class="flex flex-row items-center justify-between"
            v-if="
              (draft ||
                (editables.related_object &&
                  Object.keys(editables.related_object).length > 0 &&
                  editables.related_object.type === 'Projekt')) &&
              canEdit
            "
          >
            <span class="text-sm mr-2">Nähtav kliendile</span>
            <gwd-checkbox class="flex" v-model="editables.visible_to_client" />
          </div>
          <div
            class="flex flex-row items-center justify-between"
            v-if="canEdit"
          >
            <span class="text-sm mr-2">Nähtav halduritele</span>
            <gwd-checkbox
              class="flex"
              v-model="editables.visible_to_managers"
            />
          </div>
        </div>
      </div>
      <div class="modal-bottom-bar relative">
        <transition name="fade">
          <div class="settings-dropup" v-if="settingsDropupActive">
            <div
              class="settings-dropup-item"
              @click="
                () => {
                  downloadFile();
                  settingsDropupActive = false;
                }
              "
            >
              <div class="settings-dropup-icon bg-primary">
                <span class="typcn typcn-download"></span>
              </div>
              <div class="settings-dropup-title">Lae alla</div>
            </div>
            <div
              class="settings-dropup-item"
              @click="
                () => {
                  deleteFileModalActive = true;
                  settingsDropupActive = false;
                }
              "
            >
              <div class="settings-dropup-icon bg-danger">
                <span class="">
                  <img
                    src="/bc21/trash.svg"
                    class="filter-to-white"
                    alt="Delete icon"
                  />
                </span>
              </div>
              <div class="settings-dropup-title">Kustuta fail</div>
            </div>
          </div>
        </transition>
        <div class="flex">
          <button
            class="meatball-menu-button hidden mobile:flex mr-3"
            :class="settingsDropupActive ? 'open' : ''"
            @click="settingsDropupActive = !settingsDropupActive"
          >
            <div class="meatball-menu-icon">
              <transition name="fade" mode="out-in">
                <div class="meatball-menu-icon" v-if="!settingsDropupActive">
                  <div class="circle"></div>
                  <div class="circle"></div>
                  <div class="circle"></div>
                </div>
                <div
                  class="close typcn typcn-times"
                  v-if="settingsDropupActive"
                ></div>
              </transition>
            </div>
            <span class="label">Valikud</span>
          </button>
          <button class="btn-danger mr-3" @click="closeModal">
            <span class="icon typcn typcn-times"></span>
            <span class="label">Sulge</span>
          </button>
          <button
            class="btn-primary mr-3 mobile:hidden"
            @click="downloadFile"
            :class="canEdit ? 'mr-3' : ''"
          >
            <span class="typcn typcn-download icon"></span>
            <span class="label">Lae alla</span>
          </button>
          <button
            class="btn-danger mobile:hidden"
            @click="deleteFileModalActive = true"
            v-if="canEdit"
          >
            <span class="icon">
              <img
                src="/bc21/trash.svg"
                class="filter-to-white h-5 w-5"
                alt="Delete icon"
              />
            </span>
            <span class="label">Kustuta</span>
          </button>
        </div>
      </div>
    </div>
    <confirm-modal
      v-if="deleteFileModalActive"
      text="Olete kindel, et soovite faili kustutada?"
      @confirm="deleteFile"
      @closeModal="deleteFileModalActive = false"
    />
  </div>
</template>

<script>
import { RequestHandler } from "@/assets/mixins/GewodoMixins";
import moment from "moment";
import ConfirmModal from "@/components/reusable/ConfirmModal";
import { mapGetters } from "vuex";
import VideoPlayer from "@/components/reusable/VideoPlayer.vue";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import { EventBus } from "../../assets/mixins/GewodoMixins";
export default {
  name: "FileModal",
  components: { VideoPlayer, ConfirmModal, VuePdfEmbed },
  props: {
    file: {
      type: Object,
      default: () => {
        return {};
      },
    },
    type: {
      type: String,
      default: "file",
    },
    draft: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fileLoaded: false,
      totalPages: 1,
      currentPage: 1,
      editables: null,
      showImage: false,
      deleteFileModalActive: false,
      fileInfoLoaded: false,
      settingsDropupActive: false,
      base64String: null,
      mediaPath: null,
      videoOptions: {
        autoplay: false,
        controls: true,
      },
    };
  },
  mixins: [RequestHandler],
  methods: {
    editFile() {
      this.apiRequest(`company/${this.companyId}/files/edit/`, "patch", true, {
        id: this.file.id,
        visible_to_workers: this.editables.visible_to_workers,
        visible_to_managers: this.editables.visible_to_managers,
        visible_to_client: this.editables.visible_to_client,
      }).then((res) => {
        if (res) {
          this.retrieveFileData();
        }
      });
    },
    handleDocumentRender() {
      this.totalPages = this.$refs.pdfRef.pageCount;
    },
    handleKeyDown(e) {
      if (e.keyCode === 37 && this.currentPage > 1) {
        this.currentPage -= 1;
      } else if (e.keyCode === 39 && this.currentPage < this.totalPages) {
        this.currentPage += 1;
      } else if (e.keyCode === 27) {
        this.closeModal();
      }
    },
    initPages(e) {
      this.totalPages = e;
    },
    deleteFile() {
      this.$store.state.loading = true;
      this.apiRequest(
        `company/${this.companyId}/files/delete/`,
        "delete",
        true,
        {
          document_id_list: [this.file.id],
        }
      )
        .then((res) => {
          if (res.status === 200) {
            this.$store.state.loading = false;
            this.$store.dispatch("modals/fileModal/close");
            EventBus.$emit("fileDeleted");
          } else {
            this.$store.state.loading = false;
          }
        })
        .catch((err) => {
          this.$store.state.loading = false;
          console.error(err);
          this.$store.dispatch("messageHandler/throwMessage", {
            text: "Midagi läks valesti",
            type: "error",
            ttl: 15,
          });
        });
    },
    retrieveFileInfo() {
      this.fileInfoLoaded = false;
      if (!this.editables) {
        let url;
        if (!this.$store.state.companyData.isClient) {
          url = `company/${this.companyId}/file/${this.file.id}/?info=True`;
        } else {
          url = `projects/${this.draft}/file/${this.file.id}/?info=True`;
        }
        this.apiRequest(url, "get", true).then((res) => {
          console.log(res)
          this.editables = res.data;
          this.fileInfoLoaded = true;
        });
        this.$store.state.loading = false;
      }
    },
    retrieveFileData() {
      let url;
      if (!this.$store.state.companyData.isClient) {
        url = `company/${this.companyId}/file/${this.file.id}/`;
      } else {
        url = `projects/${this.draft}/file/${this.file.id}/`;
      }
      this.apiRequest(url, "get", true)
        .then(
          (res) => {
            this.mediaPath = `data:${this.file.file_type};base64,${res.data}`;
            this.base64String = res.data;
            this.$store.state.loading = false;
            this.fileLoaded = true;
          },
          (err) => {
            console.error(err);
            this.$store.state.loading = false;
          }
        )
        .catch(() => (this.$store.state.loading = false));
    },
    closeModal(e) {
      if (
        e.target.id !== "message-close-button" &&
        !this.deleteFileModalActive
      ) {
        this.$store.dispatch("modals/fileModal/close");
      }
    },
    downloadFile() {
      if (this.file && this.mediaPath) {
        const link = document.createElement("a");
        link.href = this.mediaPath;
        link.target = "_blank";
        link.setAttribute("download", this.file.file_name);
        document.body.appendChild(link);
        link.click();
      }
    },
    createdAtFormatted(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
  },
  mounted() {
    this.$store.state.loading = true;
    this.retrieveFileData();
    document.addEventListener("keydown", this.handleKeyDown);

    if (this.canEdit) {
      this.retrieveFileInfo();
    }
    document.body.classList.add("modal-open");
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  watch: {
    editables: {
      handler() {
        if (this.fileLoaded) {
          this.editFile();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
    }),
    screenSize() {
      const screen = window.innerWidth;
      if (screen < 700) {
        return screen + "px";
      } else if (screen <= 992) {
        return "40vw";
      } else {
        return "38vw";
      }
    },
    screenHeight() {
      const screen = window.innerHeight;
      if (screen >= 1280) {
        return "60vh";
      } else {
        return "50vh";
      }
    },
    pdfSize() {
      const screen = window.innerWidth;
      if (screen < 700) {
        return screen;
      }
      return 1080;
    },
    canEdit() {
      return (
        !this.$store.state.companyData.isClient &&
        ["R0", "R1", "R2"].includes(
          this.$store.state.companyData.activeCompany.worker_permission
        )
      );
    },
  },
};
</script>

<style lang="scss">
#file-viewer {
  opacity: 1;
  z-index: 1;
}
#imageDisplay {
  opacity: 100;
}
.restricted-height {
  min-height: 80vh;
  max-height: 80vh;
}
</style>
