<template>
  <div class="data-container w-full mb-4" v-if="parameters">
    <div class="data-container-header">
      <h3>Parameetrid</h3>
    </div>
    <div class="data-container-content data-container-content-hor">
      <div class="flex flex-col w-1/2 pr-2">
        <planning-location-selector
          class="mb-4"
          v-model="parameters.startLocation"
          ref="startLocation"
        >
          Lähtepunkt
        </planning-location-selector>
        <div
          class="flex w-full text-sm mt-1"
          v-if="data && data.project && data.project.location"
        >
          <button
            class="mr-2"
            @click="
              () =>
                $refs.startLocation.selectLocation(
                  processLocationForEmit(data.project.location)
                )
            "
            :class="data.project.location ? 'btn-primary' : 'btn-disabled'"
            :disabled="!data.project.location"
          >
            <span class="label">Tellimusega sama</span>
          </button>
          <button
            class="mr-2"
            :class="
              data.project &&
              data.project.client &&
              data.project.client.location
                ? 'btn-primary'
                : 'btn-disabled'
            "
            @click="
              () =>
                $refs.startLocation.selectLocation(
                  processLocationForEmit(data.project.client.location)
                )
            "
          >
            <span class="label">Tellimuse kliendiga sama</span>
          </button>
        </div>
        <planning-location-selector
          class="mb-4"
          v-model="parameters.endLocation"
          ref="endLocation"
        >
          Sihtpunkt
        </planning-location-selector>
        <div
          class="flex w-full text-sm mt-1"
          v-if="data && data.project && data.project.location"
        >
          <button
            class="mr-2"
            @click="
              () =>
                $refs.endLocation.selectLocation(
                  processLocationForEmit(data.project.location)
                )
            "
            :class="data.project.location ? 'btn-primary' : 'btn-disabled'"
            :disabled="!data.project.location"
          >
            <span class="label">Tellimusega sama</span>
          </button>
          <button
            class="mr-2"
            :class="
              data.project &&
              data.project.client &&
              data.project.client.location
                ? 'btn-primary'
                : 'btn-disabled'
            "
            @click="
              () =>
                $refs.endLocation.selectLocation(
                  processLocationForEmit(data.project.client.location)
                )
            "
          >
            <span class="label">Tellimuse kliendiga sama</span>
          </button>
        </div>
        <div class="flex">
          <div class="flex flex-col w-1/2 pr-2">
            <h4>Kestvus</h4>
            <input
              type="number"
              v-model.number="parameters.duration"
              class="mb-2"
            />
          </div>
          <div class="flex flex-col w-1/2 pl-2">
            <h4>Töötajate arv</h4>
            <input
              type="number"
              v-model.number="parameters.worker_amount"
              class="mb-2"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col w-1/2 pl-2">
        <div
          v-for="(item, workerIndex) in parameters.attributes"
          :key="workerIndex"
          class="mb-2"
        >
          <div class="flex bg-offwhite rounded shadow">
            <div class="w-1/2 border-r flex flex-col border-offwhite-dark">
              <div class="flex bg-offwhite-dark rounded-tl px-2 py-1">
                <h4>Töötaja #{{ workerIndex + 1 }}</h4>
              </div>
              <div
                class="flex flex-wrap p-2 gap-2"
                v-if="parameters.attributes[workerIndex].length > 0"
              >
                <div
                  v-for="(selectedAttribute, index) in parameters.attributes[
                    workerIndex
                  ]"
                  :key="index"
                  class="rounded bg-white font-medium py-1 px-2 flex items-center shadow hover:bg-danger hover:text-white cursor-pointer"
                  @click="parameters.attributes[workerIndex].splice(index, 1)"
                >
                  {{ selectedAttribute.name }}
                </div>
              </div>
            </div>
            <div class="w-1/2 flex flex-col">
              <div class="flex bg-offwhite-dark rounded-tr px-2 py-1">
                <h4>Oskused</h4>
              </div>
              <div class="flex flex-wrap p-2 gap-2">
                <div
                  v-for="(attribute, index) in workerAttributes.filter(
                    (x) =>
                      !parameters.attributes[workerIndex]
                        .map((y) => y.id)
                        .includes(x.id)
                  )"
                  :key="index"
                  class="rounded bg-white flex items-center shadow duration-100"
                  :class="
                    attribute.workers.length > 0
                      ? 'hover:bg-primary hover:text-white cursor-pointer'
                      : ''
                  "
                  @click="
                    attribute.workers.length > 0
                      ? parameters.attributes[workerIndex].push(attribute)
                      : ''
                  "
                >
                  <span class="p-1 font-medium">{{ attribute.name }}</span>
                  <div
                    class="rounded-r text-white px-2 py-1 font-medium"
                    :class="
                      attribute.workers.length > 0 ? 'bg-primary' : 'bg-danger'
                    "
                  >
                    {{ attribute.workers.length }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PlanningLocationSelector from "./PlanningLocationSelector.vue";
import { mapGetters, mapState } from "vuex";
import { cloneDeep } from "lodash";
export default {
  components: {
    PlanningLocationSelector,
  },
  data() {
    return {
      workers: [],
      endLocationMapSearch: false,
      startLocationMapSearch: false,
      startLocationSearch: false,
      endLocationSearch: false,
    };
  },
  async mounted() {
    if (!this.workerAttributes)
      await this.$store.dispatch("companyVariables/retrieveWorkerAttributes");
    this.generateWorkerArray(this.parameters.worker_amount);
  },
  methods: {
    processLocationForEmit(inputLocation) {
      let location = JSON.parse(JSON.stringify(inputLocation));
      delete location["uuid"];
      return location;
    },
    generateWorkerArray(num) {
      const defaultAttributes = this.workerAttributes.filter(
        (x) => x.set_default
      );
      if (num >= this.parameters.attributes.length) {
        const arrToConcat = Array.from(
          { length: num - this.parameters.attributes.length },
          () => cloneDeep(defaultAttributes) ?? []
        );
        this.parameters.attributes =
          this.parameters.attributes.concat(arrToConcat);
      } else if (num < this.parameters.attributes.length)
        this.parameters.attributes.length = Number(num);
    },
  },
  computed: {
    ...mapState({
      parameters: (state) => state.modals.taskPlanningModal.parameters,
      data: (state) => state.modals.taskPlanningModal.data,
    }),
    ...mapGetters({
      workerAttributes: "companyVariables/workerAttributes",
    }),
  },
  watch: {
    "parameters.worker_amount"(v) {
      if (!v && v !== 0) return;
      this.generateWorkerArray(v);
    },
  },
};
</script>
