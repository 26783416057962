export default {
  methods: {
    trimTitle(title, len) {
      if (title.length < len) return title;
      let titleStr = title.slice(0, len);
      titleStr.replace(" ", "");
      titleStr += "...";
      return titleStr;
    },
  },
};
