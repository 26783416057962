var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"location-coordinate-selector"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.location.address),expression:"location.address"}],attrs:{"type":"text","placeholder":"Aadress"},domProps:{"value":(_vm.location.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.location, "address", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.location.latitude),expression:"location.latitude"}],attrs:{"type":"text","placeholder":"Laius"},domProps:{"value":(_vm.location.latitude)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.location, "latitude", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.location.longitude),expression:"location.longitude"}],attrs:{"type":"text","placeholder":"Pikkus"},domProps:{"value":(_vm.location.longitude)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.location, "longitude", $event.target.value)}}}),_c('div',{staticClass:"btn-primary",on:{"click":function($event){_vm.showMap = true}}},[_c('span',{staticClass:"typcn typcn-map icon"})]),_c('div',{staticClass:"btn-danger",on:{"click":function () {
        _vm.location.address = '';
        _vm.location.latitude = '';
        _vm.location.longitude = '';
        _vm.location.info = '';
      }}},[_c('span',{staticClass:"typcn typcn-trash icon"})]),(_vm.showMap)?_c('map-location-selector',{on:{"locationSelected":function (e) {
        _vm.location.latitude = e.lat;
        _vm.location.longitude = e.lng;
        _vm.showMap = false;
      },"close":function () { return (_vm.showMap = false); }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }