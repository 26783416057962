<template>
  <div class="data-container">
    <div class="data-container-header">
      <h3>Töö</h3>
      <button class="btn-danger ml-3" @click="resetSelectedSlot">Tagasi</button>
      <button class="btn-primary ml-3" @click="prefillTaskData">Loo töö</button>
    </div>
    <div class="data-container-content data-container-content-hor">
      <div class="flex flex-col w-1/2 mr-2">
        <worker-card
          v-for="worker in selectedSlot.workers"
          :key="worker.id"
          :data="worker"
          :is-selected="true"
        >
        </worker-card>
      </div>
      <div class="flex flex-col w-1/2 ml-2">
        <h4>Kuupäev</h4>
        <span>{{ moment(parameters.startDate).format("DD.MM.YYYY") }}</span>
        <h4>Aeg</h4>
        <span>{{
          `${moment(selectedSlot.start).format("HH:mm")}-${moment(
            selectedSlot.end
          ).format("HH:mm")}`
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import WorkerCard from "../WorkerCard.vue";
export default {
  data() {
    return {
      moment: moment,
    };
  },
  components: {
    WorkerCard,
  },
  methods: {
    resetSelectedSlot() {
      this.$store.dispatch("modals/taskPlanningModal/resetSelectedSlot");
    },
    prefillTaskData() {
      console.log(this.parameters);
      let data = {
        workers: this.selectedSlot.workers,
        date: this.selectedSlot.start.format("YYYY-MM-DD"),
        startTime: {
          hour: this.selectedSlot.start.hour(),
          minute: this.selectedSlot.start.minute(),
        },
        endTime: {
          hour: this.selectedSlot.end.hour(),
          minute: this.selectedSlot.end.minute(),
        },
        location: this.parameters.startLocation,
        end_location: this.parameters.endLocation,
      };

      this.$store.dispatch("modals/taskPlanningModal/close");
      this.$store.dispatch("modals/addTaskModal/open", data);
    },
  },
  computed: {
    ...mapGetters({
      selectedSlot: "modals/taskPlanningModal/selectedSlot",
      parameters: "modals/taskPlanningModal/parameters",
    }),
  },
};
</script>
