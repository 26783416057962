<template>
  <div class="flex w-3/4 flex-row">
    <div class="flex flex-col w-full mr-2">
      <div class="flex flex-col mb-2" v-if="!selectedProvider">
        <gwd-formlabel title="Vali hankija"></gwd-formlabel>
        <select-search
          :dataset="providers"
          :label="(item) => item.name"
          class="flex-grow"
          v-model="selectedProvider"
          v-if="!selectedProvider"
          :tabindex="1"
          :focus="true"
          :filter-func="filterProviders"
          ref="providerSelect"
          @input="handleProviderSelection"
        />
      </div>
      <div class="flex flex-col mb-2" v-if="selectedProvider">
        <gwd-formlabel title="Valitud hankija" />
        <div class="flex flex-row justify-between">
          <span>{{ selectedProvider.name }}</span>
          <button
            @click="
              () => {
                selectedProvider = null;
              }
            "
            class="btn-primary ml-2"
            tabindex="1"
          >
            <span class="label">Muuda</span>
          </button>
        </div>
      </div>
      <div class="flex flex-col mb-2">
        <gwd-formlabel
          :title="'Teenuse nimi'"
          :required="true"
          :valid="$v.supplier_cost.name.required"
        />
        <select-search
          v-if="
            !planningCost &&
            selectedProvider &&
            supplierRelatedItems.previous_costs &&
            supplierRelatedItems.previous_costs.length > 0 &&
            !costSelected
          "
          :dataset="supplierRelatedItems.previous_costs"
          :label="(item) => item.name"
          class="flex-grow mb-2"
          v-model="relatedProviderCost"
          :tabindex="1"
          :focus="true"
          :filter-func="filterCosts"
          ref="providerSelect"
          @input="handleCostSelected"
          :placeholder="'Otsi eelnevate kulude seast'"
        />
        <input v-model="supplier_cost.name" placeholder="Alusta kirjutamist" />
      </div>

      <div class="flex flex-col">
        <gwd-formlabel :title="'Kogus'" />
        <decimal-input v-model="supplier_cost.amount" class="mb-2" />
        <gwd-formlabel :title="'Ühik'" />
        <select class="add-offer-input mb-2" v-model="supplier_cost.unit">
          <option v-for="unit in units" :value="unit" :key="unit">
            {{ unit }}
          </option>
        </select>
        <gwd-formlabel :title="'Kulu'" />
        <decimal-input v-model="supplier_cost.cost" class="mb-2" />
        <gwd-formlabel :title="'Müügi hind'" />
        <decimal-input v-model="supplier_cost.price" class="mb-2" />
        <div
          class="flex flex-col"
          v-if="
            !planningCost &&
            isAdmin &&
            selectedProvider &&
            supplierRelatedItemsLoaded &&
            supplierRelatedItems.related_invoices.length > 0
          "
        >
          <gwd-formlabel v-if="selectedProvider" :title="'Arve (valikuline)'" />
          <div
            v-for="invoice in supplierRelatedItems.related_invoices"
            :key="invoice.id"
            class="flex flex-col items-center mb-3"
          >
            <div
              class="flex flex-row justify-between items-center mb-2 w-full cursor-pointer"
              @click="selectInvoice(invoice)"
            >
              <span
                class="typcn"
                :class="
                  selectedInvoice && selectedInvoice.id === invoice.id
                    ? 'typcn-plus'
                    : 'typcn-times'
                "
              ></span>
              <span> {{ invoice.invoice_nr }}</span>
              <span> {{ invoice.seller_name }}</span>
              <span> {{ invoice.total_sum }}</span>
              <span> {{ invoice.invoice_date }}</span>
            </div>
            <div class="flex flex-col w-full">
              <div
                v-for="cost in invoice.costs"
                :key="cost.id"
                class="flex flex-row items-center w-full justify-between mb-2 cursor-pointer"
                @click="selectInvoiceCost(cost)"
              >
                <span> {{ cost.program_name }}</span>
                <span> {{ cost.sum }}</span>
                <span> {{ cost.tax_code }}</span>
                <span> {{ cost.accounting_description }}</span>
              </div>
            </div>
          </div>
          <div>
            {{ selectedInvoice }}
            {{ selectedInvoiceCost }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SelectSearch from "@/components/reusable/SelectSearch.vue";
import { filterProviders } from "@/assets/utils/commonFilters";
import DecimalInput from "../../reusable/DecimalInput.vue";
import { required } from "vuelidate/lib/validators";
export default {
  name: "AddSupplierCost",
  components: { SelectSearch, DecimalInput },
  props: {
    project: {
      type: String,
      default: null,
    },
    supplier: {
      type: Object,
      default: () => {
        return null;
      },
    },
    task: {
      type: Number,
      default: null,
    },
    planningCost: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedProvider: null,
      relatedProviderCost: "",
      costSelected: false,
      supplier_cost: {
        invoice: null,
        invoice_cost_item: null,
        name: "",
        amount: 0,
        unit: "",
        cost: 0.0,
        price: 0.0,
        vat: 0,
        project: null,
        task: null,
      },
      supplierRelatedItemsLoaded: false,
      supplierRelatedItems: [],
      selectedInvoice: null,
      selectedInvoiceCost: null,
    };
  },
  methods: {
    filterProviders,
    filterCosts(cost, query) {
      return cost.name.toLowerCase().includes(query.toLowerCase());
    },
    handleProviderSelection(e) {
      this.selectedProvider = e;
      if (e) {
        this.loadSupplierRelatedItems();
      } else {
        this.supplierRelatedItemsLoaded = false;
      }
    },
    handleCostSelected(e) {
      this.supplier_cost.name = e.name;
      this.supplier_cost.cost = e.cost;
      this.supplier_cost.price = e.price;
      this.supplier_cost.amount = e.amount;
      this.supplier_cost.unit = e.unit;
      this.costSelected = true;
    },
    saveSupplierCost() {
      if (
        !this.$v.$invalid &&
        this.selectedProvider &&
        Object.keys(this.selectedProvider).length > 0
      ) {
        this.supplier_cost.supplier = this.selectedProvider.id;
        this.supplier_cost.project = this.project;
        this.supplier_cost.task = this.task;
        if (!this.supplier_cost.name || this.supplier_cost.name.length === 0) {
          this.supplier_cost.name = this.relatedProviderCost;
        }
        if (this.selectedInvoice) {
          this.supplier_cost.invoice = this.selectedInvoice.id;
          if (this.selectedInvoiceCost) {
            this.supplier_cost.invoice_cost_item = this.selectedInvoiceCost.id;
          }
        }
        let url;
        if (this.planningCost) {
          url = `template/${this.companyId}/plannings/supplier/create/`;
        } else {
          url = `costs/${this.companyId}/supplier/costs/add/`;
        }
        this.apiRequest(url, "post", true, this.supplier_cost).then((res) => {
          if (res.status === 201) {
            this.$emit("supplierCostAdded");
          }
        });
      }
    },
    loadSupplierRelatedItems() {
      if (
        this.selectedProvider &&
        Object.keys(this.selectedProvider).length > 0
      ) {
        this.apiRequest(
          `suppliers/${this.companyId}/${this.selectedProvider.id}/related/`,
          "get",
          true
        ).then((res) => {
          this.supplierRelatedItems = res.data;
          this.supplierRelatedItemsLoaded = true;
        });
      }
    },
    selectInvoice(e) {
      this.selectedInvoice = e;
    },
    selectInvoiceCost(e) {
      this.selectedInvoiceCost = e;
    },
  },
  mounted() {
    if (!this.providers || this.providers.length === 0) {
      this.$store.dispatch("providers/retrieveProviders");
    }
    if (this.supplier) {
      this.selectedProvider = this.supplier;
      this.loadSupplierRelatedItems();
    }
  },
  watch: {
    supplier_cost: {
      deep: true,
      handler() {
        this.$emit("formUpdated", !this.$v.$invalid);
      },
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      providers: "providers/providers",
      units: "companyVariables/units",
      isAdmin: "companyData/isAdmin",
    }),
  },
  validations: {
    supplier_cost: {
      name: { required },
    },
  },
};
</script>
