<template>
  <div class="modal">
    <div
      class="modal-body modal-narrow add-task-modal relative"
      v-on-clickaway:mousedown="closeModal"
    >
      <div class="modal-header p-2">
        <gwd-formlabel title="Lisa tehnika kulu" :heading-level="3" />
      </div>
      <div class="modal-content flex-col gwd-form">
        <equipment-selector
          v-if="!selectedEquipment"
          @equipmentSelected="toggleEquipment"
        />

        <div class="gwd-form-group">
          <gwd-formlabel title="Nimetus" />
          <input type="text" v-model="equipmentCost.name" />
        </div>
        <div class="gwd-form-group">
          <gwd-formlabel title="Kogus" />
          <decimal-input v-model.number="equipmentCost.amount" />
        </div>
        <div class="gwd-form-group">
          <gwd-formlabel title="Kulu" />
          <decimal-input v-model.number="equipmentCost.cost" />
        </div>
        <div class="gwd-form-group">
          <gwd-formlabel title="Müügihind" />
          <decimal-input v-model.number="equipmentCost.price" />
        </div>
        <div class="gwd-form-group">
          <gwd-formlabel title="Ühik" />
          <input type="text" v-model="equipmentCost.unit" />
        </div>
        <div class="gwd-form-hor-group gap-x-2">
          <div class="flex-1">
            <gwd-formlabel title="Kulu kokku" />
            <span>
              {{
                `${round2(
                  equipmentCost.cost * equipmentCost.amount
                )}${currency}`
              }}
            </span>
          </div>
          <div class="flex-1">
            <gwd-formlabel title="Müügihind kokku" />
            <span>
              {{
                `${round2(
                  equipmentCost.price * equipmentCost.amount
                )}${currency}`
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="modal-bottom-bar">
        <button @click="saveEquipmentCost" class="btn-primary mr-3">
          <span class="icon typcn typcn-tick" />
          <span class="label">Salvesta</span>
        </button>
        <button class="new-button-danger" @click="closeModal(false)">
          <span class="typcn typcn-times icon" />
          <span class="label">Sulge</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EquipmentSelector from "@/components/company/equipment/EquipmentSelector.vue";
import { round2 } from "@/assets/utils/commonMath";
import DecimalInput from "../../reusable/DecimalInput.vue";
export default {
  name: "AddProjectEquipmentCostModal",
  components: { EquipmentSelector, DecimalInput },
  props: {
    equipmentAssignment: {
      type: Object,
      default: () => {},
    },
    task: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectedAssignment: null,
      selectedEquipment: null,
      equipmentCost: {
        equipment: null,
        task: null,
        project: null,
        name: "",
        amount: 0,
        unit: "",
        price: 0,
        cost: 0,
      },
    };
  },
  methods: {
    round2,
    priceType(e) {
      let data = {
        1: "Tükihind",
        2: "Tunnihind",
        3: "Kilomeetrihind",
      };
      return data[e];
    },
    closeModal() {
      this.$emit("closeModal");
    },
    handleSaveResource() {
      this.$emit("costAdded");
      this.closeModal();
    },
    toggleEquipment(e) {
      this.selectedEquipment = e;
      let localEquipment;
      if (this.equipmentAssignment) {
        localEquipment = this.equipmentAssignment.equipment;
        this.equipmentCost.amount = e.amount ? parseFloat(e.amount) : 0;
      } else {
        localEquipment = e;
      }
      this.equipmentCost.equipment = localEquipment.id;
      this.equipmentCost.name = localEquipment.name;
      this.equipmentCost.cost = e.cost ? parseFloat(e.cost) : 0;
      this.equipmentCost.price = e.price ? parseFloat(e.price) : 0;
      switch (e.type) {
        case 1:
          this.equipmentCost.unit = "tk";
          break;
        case 2:
          this.equipmentCost.unit = "km";
          break;
        case 3:
          this.equipmentCost.unit = "h";
          break;
        default:
          this.equipmentCost.unit = "tk";
          break;
      }

      this.equipmentCost.task = e.task ? e.task.id : null;
      if (this.task) {
        this.equipmentCost.task = this.task;
      }
    },
    saveEquipmentCost() {
      if (this.equipmentCost.equipment) {
        this.apiRequest(
          `costs/${this.companyId}/equipment/${this.equipmentCost.equipment}/costs/add/`,
          "post",
          true,
          this.equipmentCost
        ).then((res) => {
          if (res.status === 201) {
            this.handleSaveResource();
          }
        });
      }
    },
  },
  mounted() {
    document.body.classList.add("modal-open");
    this.equipmentCost.project = this.$route.params.projectId;
    this.equipmentCost.task = this.task;
    if (this.equipmentAssignment) {
      this.toggleEquipment(this.equipmentAssignment);
    }
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      currency: "userData/currency",
    }),
  },
};
</script>
