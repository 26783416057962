var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.parameters)?_c('div',{staticClass:"data-container w-full mb-4"},[_vm._m(0),_c('div',{staticClass:"data-container-content data-container-content-hor"},[_c('div',{staticClass:"flex flex-col w-1/2 pr-2"},[_c('planning-location-selector',{ref:"startLocation",staticClass:"mb-4",model:{value:(_vm.parameters.startLocation),callback:function ($$v) {_vm.$set(_vm.parameters, "startLocation", $$v)},expression:"parameters.startLocation"}},[_vm._v(" Lähtepunkt ")]),(_vm.data && _vm.data.project && _vm.data.project.location)?_c('div',{staticClass:"flex w-full text-sm mt-1"},[_c('button',{staticClass:"mr-2",class:_vm.data.project.location ? 'btn-primary' : 'btn-disabled',attrs:{"disabled":!_vm.data.project.location},on:{"click":function () { return _vm.$refs.startLocation.selectLocation(
                _vm.processLocationForEmit(_vm.data.project.location)
              ); }}},[_c('span',{staticClass:"label"},[_vm._v("Tellimusega sama")])]),_c('button',{staticClass:"mr-2",class:_vm.data.project &&
            _vm.data.project.client &&
            _vm.data.project.client.location
              ? 'btn-primary'
              : 'btn-disabled',on:{"click":function () { return _vm.$refs.startLocation.selectLocation(
                _vm.processLocationForEmit(_vm.data.project.client.location)
              ); }}},[_c('span',{staticClass:"label"},[_vm._v("Tellimuse kliendiga sama")])])]):_vm._e(),_c('planning-location-selector',{ref:"endLocation",staticClass:"mb-4",model:{value:(_vm.parameters.endLocation),callback:function ($$v) {_vm.$set(_vm.parameters, "endLocation", $$v)},expression:"parameters.endLocation"}},[_vm._v(" Sihtpunkt ")]),(_vm.data && _vm.data.project && _vm.data.project.location)?_c('div',{staticClass:"flex w-full text-sm mt-1"},[_c('button',{staticClass:"mr-2",class:_vm.data.project.location ? 'btn-primary' : 'btn-disabled',attrs:{"disabled":!_vm.data.project.location},on:{"click":function () { return _vm.$refs.endLocation.selectLocation(
                _vm.processLocationForEmit(_vm.data.project.location)
              ); }}},[_c('span',{staticClass:"label"},[_vm._v("Tellimusega sama")])]),_c('button',{staticClass:"mr-2",class:_vm.data.project &&
            _vm.data.project.client &&
            _vm.data.project.client.location
              ? 'btn-primary'
              : 'btn-disabled',on:{"click":function () { return _vm.$refs.endLocation.selectLocation(
                _vm.processLocationForEmit(_vm.data.project.client.location)
              ); }}},[_c('span',{staticClass:"label"},[_vm._v("Tellimuse kliendiga sama")])])]):_vm._e(),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex flex-col w-1/2 pr-2"},[_c('h4',[_vm._v("Kestvus")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.parameters.duration),expression:"parameters.duration",modifiers:{"number":true}}],staticClass:"mb-2",attrs:{"type":"number"},domProps:{"value":(_vm.parameters.duration)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.parameters, "duration", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"flex flex-col w-1/2 pl-2"},[_c('h4',[_vm._v("Töötajate arv")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.parameters.worker_amount),expression:"parameters.worker_amount",modifiers:{"number":true}}],staticClass:"mb-2",attrs:{"type":"number"},domProps:{"value":(_vm.parameters.worker_amount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.parameters, "worker_amount", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])])],1),_c('div',{staticClass:"flex flex-col w-1/2 pl-2"},_vm._l((_vm.parameters.attributes),function(item,workerIndex){return _c('div',{key:workerIndex,staticClass:"mb-2"},[_c('div',{staticClass:"flex bg-offwhite rounded shadow"},[_c('div',{staticClass:"w-1/2 border-r flex flex-col border-offwhite-dark"},[_c('div',{staticClass:"flex bg-offwhite-dark rounded-tl px-2 py-1"},[_c('h4',[_vm._v("Töötaja #"+_vm._s(workerIndex + 1))])]),(_vm.parameters.attributes[workerIndex].length > 0)?_c('div',{staticClass:"flex flex-wrap p-2 gap-2"},_vm._l((_vm.parameters.attributes[
                  workerIndex
                ]),function(selectedAttribute,index){return _c('div',{key:index,staticClass:"rounded bg-white font-medium py-1 px-2 flex items-center shadow hover:bg-danger hover:text-white cursor-pointer",on:{"click":function($event){return _vm.parameters.attributes[workerIndex].splice(index, 1)}}},[_vm._v(" "+_vm._s(selectedAttribute.name)+" ")])}),0):_vm._e()]),_c('div',{staticClass:"w-1/2 flex flex-col"},[_vm._m(1,true),_c('div',{staticClass:"flex flex-wrap p-2 gap-2"},_vm._l((_vm.workerAttributes.filter(
                  function (x) { return !_vm.parameters.attributes[workerIndex]
                      .map(function (y) { return y.id; })
                      .includes(x.id); }
                )),function(attribute,index){return _c('div',{key:index,staticClass:"rounded bg-white flex items-center shadow duration-100",class:attribute.workers.length > 0
                    ? 'hover:bg-primary hover:text-white cursor-pointer'
                    : '',on:{"click":function($event){attribute.workers.length > 0
                    ? _vm.parameters.attributes[workerIndex].push(attribute)
                    : ''}}},[_c('span',{staticClass:"p-1 font-medium"},[_vm._v(_vm._s(attribute.name))]),_c('div',{staticClass:"rounded-r text-white px-2 py-1 font-medium",class:attribute.workers.length > 0 ? 'bg-primary' : 'bg-danger'},[_vm._v(" "+_vm._s(attribute.workers.length)+" ")])])}),0)])])])}),0)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-container-header"},[_c('h3',[_vm._v("Parameetrid")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex bg-offwhite-dark rounded-tr px-2 py-1"},[_c('h4',[_vm._v("Oskused")])])}]

export { render, staticRenderFns }