var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col shadow chat-modal bg-white z-5 rounded-lg"},[_c('div',{staticClass:"flex w-full items-center justify-between bg-offwhite-dark p-2 rounded-t-lg"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"mr-2 bg-offwhite-dark h-10 w-10 rounded-full flex items-center justify-center hover:text-white hover:bg-primary"},[_c('span',{staticClass:"font-medium"},[_vm._v(_vm._s(_vm.chatParty.id))])]),_c('span',[_vm._v(_vm._s(_vm.chatParty.title))])]),_c('div',{staticClass:"flex"},[_c('span',{staticClass:"typcn typcn-times icon cursor-pointer text-xl text-white bg-danger rounded-full leading-none items-center justify-center flex h-6 w-6",on:{"click":_vm.closeParty}})])]),_c('div',{staticClass:"flex flex-col overflow-y-scroll p-2 bg-offwhite gap-y-2 flex-grow"},[(_vm.next)?_c('div',{staticClass:"flex w-full justify-center"},[_c('button',{staticClass:"flex self-center bg-primary text-white w-full justify-center rounded lg-",on:{"click":_vm.loadMoreMessages}},[_c('span',{staticClass:"typcn typcn-arrow-up icon"}),_c('span',[_vm._v("Lae rohkem sõnumeid")])])]):_vm._e(),_vm._l((_vm.messages),function(message,index){return _c('chat-comment',{key:message.uuid,staticClass:"flex w-full rounded",class:message.author.id === _vm.$store.state.userData.user.id
          ? 'justify-end'
          : 'justify-start',attrs:{"show-header":index > 0
          ? _vm.messages[index - 1].author.id !== message.author.id ||
            Date.parse(message.created_at) -
              Date.parse(_vm.messages[index - 1].created_at) <
              300
          : true,"comment":message,"id":("comment-" + (message.uuid))}})})],2),_c('div',{staticClass:"flex bg-offwhite-dark justify-center items-center relative w-full p-1.5 rounded-b-lg gap-x-2"},[_c('div',{staticClass:"flex h-full relative flex-grow"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],staticClass:"w-full h-full relative p-2 focus:shadow-outline bg-white rounded-sm",attrs:{"data-cy":"commentField","rows":"1"},domProps:{"value":(_vm.content)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return (function () {
            if (!_vm.sending) { _vm.saveComment(); }
          }).apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.content=$event.target.value}}})]),_c('div',{staticClass:"flex w-10 h-full justify-center items-center"},[(!_vm.sending)?_c('span',{staticClass:"icon typcn typcn-arrow-right-thick text-white w-full h-full rounded justify-center items-center flex leading-none duration-150",class:_vm.content.length > 0 ? 'bg-primary' : 'bg-offwhite',on:{"click":_vm.saveComment,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return (function () {
            if (!_vm.sending) { _vm.saveComment(); }
          }).apply(null, arguments)}}}):_c('ClipLoader',{attrs:{"size":"24px"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }