const types = {
  workerCost: { color: "#3D348B", name: "Palk" },
  plannedWorkerCost: { color: "rgba(61,52,139,0.75)", name: "Palk" },
  extraCost: { color: "#A41623", name: "Lisatöö" },
  plannedExtraCost: { color: "rgba(164,22,35,0.75)", name: "Lisatöö" },
  resourceCost: { color: "#6F8F72", name: "Ressurss" },
  plannedResourceCost: { color: "rgba(111,143,114,0.75)", name: "Ressurss" },
  equipmentCost: { color: "#833584", name: "Tehnika" },
  plannedEquipmentCost: { color: "rgba(131,53,132,0.75)", name: "Tehnika" },
  supplierCost: { color: "#D98324", name: "Hankija" },
  plannedSupplierCost: { color: "rgba(217,131,36,0.75)", name: "Hankija" },
  totalCost: { color: "#C000DB", name: "Koguhind" },
};
export default {
  methods: {
    parseCostColorAndName(type) {
      return `<div class='flex h-full w-1' style='background-color: ${types[type].color};'></div> <span> ${types[type].name}</span>`;
    },
    parseCostColor(type) {
      return `background-color: ${types[type].color}`;
    },
    getCostTypeObject(type) {
      return types[type];
    },
  },
};
