<template>
  <div class="modal">
    <div class="modal-body modal-medium" v-on-clickaway:mousedown="closeModal">
      <div class="modal-content flex-col">
        <div class="flex justify-between items-center mobile:flex-col mb-4">
          <gwd-formlabel title="Vali tehnika" :heading-level="3" />
        </div>
        <equipment-selector
          v-if="!selectedEquipment"
          :task="task"
          @equipmentSelected="equipmentSelected"
        />
        <div v-else>
          <div class="flex flex-row mb-4 items-center w-full">
            <div class="flex flex-col mr-2 w-1/3">
              <gwd-formlabel title="Tehnika nimetus" class="mb-2" />
              <span> {{ selectedEquipment.name }} </span>
            </div>
            <div class="flex flex-col mx-2 w-1/3">
              <gwd-formlabel title="Määratud töötaja" class="mb-2" />
              <span>
                {{
                  selectedEquipment.worker
                    ? selectedEquipment.worker.worker_name
                    : "-"
                }}</span
              >
            </div>
            <div class="flex flex-row ml-2 w-1/3 justify-end">
              <span class="items-center flex leading-none rounded-xl">
                <button class="btn-danger" @click="clearSelected">
                  <span class="label">Eemalda valikust</span>
                </button>
              </span>
            </div>
          </div>
          <div class="flex flex-row w-full px-2 mb-4">
            <div class="flex flex-col mr-2 w-1/2">
              <gwd-formlabel
                title="Hinna tüüp"
                :required="true"
                :error="$v.equipmentAssignment.type.$error"
                :valid="!$v.equipmentAssignment.type.$error"
                :error-text="'Hinna tüüp on kohustuslik'"
              />
              <select v-model="equipmentAssignment.type">
                <option value="1">Tükihind</option>
                <option value="2">Kilomeetrihind</option>
                <option value="3">Tunnihind</option>
              </select>
            </div>
            <div class="flex flex-col ml-2 w-1/2">
              <gwd-formlabel title="Kogus" />
              <decimal-input v-model.number="equipmentAssignment.amount" />
            </div>
          </div>
          <div class="flex flex-row w-full px-2">
            <div class="flex flex-col mr-2 w-1/2">
              <gwd-formlabel title="Kulu" />
              <decimal-input v-model.number="equipmentAssignment.cost" />
            </div>
            <div class="flex flex-col ml-2 w-1/2">
              <gwd-formlabel title="Hind" />
              <decimal-input v-model.number="equipmentAssignment.price" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-bottom-bar">
        <button class="btn-primary mr-3" @click="saveEquipmentAssignment">
          Salvesta
        </button>
        <button class="btn-danger" @click="closeModal">Sulge</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EquipmentSelector from "@/components/company/equipment/EquipmentSelector";
import { required } from "vuelidate/lib/validators";
import DecimalInput from "../../reusable/DecimalInput.vue";
export default {
  name: "AddEquipmentAssignmentModal",
  components: { EquipmentSelector, DecimalInput },
  props: {
    task: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      equipmentLoaded: false,
      equipment: [],
      selectedEquipment: null,
      equipmentAssignment: {
        type: 1,
        price: 0,
        cost: 0,
        amount: 0,
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    equipmentSelected(e) {
      this.selectedEquipment = e;
      this.equipmentAssignment.type = e.preferred_payment;
      switch (e.preferred_payment) {
        case 1:
          this.equipmentAssignment.cost = e.price;
          this.equipmentAssignment.price = e.price * e.cost_coefficient;
          break;
        case 2:
          this.equipmentAssignment.cost = e.price_per_km;
          this.equipmentAssignment.price = e.price_per_km * e.cost_coefficient;
          break;
        case 3:
          this.equipmentAssignment.cost = e.price_per_hour;
          this.equipmentAssignment.price =
            e.price_per_hour * e.cost_coefficient;
          break;
        default:
          this.equipmentAssignment.cost = 0;
          this.equipmentAssignment.price = 0;
      }
    },
    clearSelected() {
      this.selectedEquipment = null;
      this.equipmentAssignment = {
        type: 1,
        price: 0,
        cost: 0,
        amount: 0,
      };
    },
    saveEquipmentAssignment() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.apiRequest(
          `template/${this.companyId}/equipment/${this.selectedEquipment.id}/assign/`,
          "post",
          true,
          { ...this.equipmentAssignment, task: this.task }
        ).then((res) => {
          if (res.status === 201) {
            this.$emit("equipmentAssigned");
            this.closeModal();
          }
        });
      }
    },
  },
  mounted() {
    document.body.classList.add("modal-open");
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
  validations: {
    equipmentAssignment: {
      type: {
        required,
      },
      price: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
    }),
  },
};
</script>
