<template>
  <div class="flex self-end items-center px-2 saving-loader mobile:px-0">
    <transition name="fade" mode="out-in">
      <clip-loader
        class="items-center leading-none"
        v-if="saving && !savedActive"
      ></clip-loader>
      <span
        class="typcn typcn-tick p-2 bg-primary rounded-full text-white leading-none"
        v-if="savingDone"
      ></span>
    </transition>
    <transition
      name="fade"
      :duration="{ enter: 100, leave: 100 }"
      mode="out-in"
    >
      <span
        v-if="saving"
        class="mx-2 font-medium label"
        @enter="savingActive = true"
        @after-leave="savingActive = false"
        >{{ text.saving }}</span
      >
      <span v-if="savingDone" class="mx-2 font-semibold label">{{
        text.saved
      }}</span>
    </transition>
    <transition
      name="fade"
      :duration="{ enter: 100, leave: 100 }"
      @enter="savedActive = true"
      @after-leave="savedActive = false"
    >
    </transition>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  props: {
    saving: Boolean,
    saved: Boolean,
    text: {
      type: Object,
      default: () => {
        return {
          saving: "Salvestan...",
          saved: "Salvestatud",
        };
      },
    },
  },
  data() {
    return {
      savingDelayComplete: false,
      savedActive: false,
      savingActive: false,
    };
  },
  components: {
    ClipLoader,
  },
  watch: {
    saved() {
      this.savingDelayComplete = false;
      if (this.saved) {
        let self = this;
        setTimeout(function () {
          self.savingDelayComplete = true;
        }, 100);
      }
    },
  },
  computed: {
    savingDone() {
      return this.saved && this.savingDelayComplete;
    },
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 992px) {
  .saving-loader {
    @apply bg-offwhite rounded-full shadow z-10;
    top: 93%;
    left: 5%;
    position: fixed;
    .label {
      @apply px-3;
    }
  }
}
</style>
