import { round2 } from "../utils/commonMath";

export default {
  methods: {
    humanizeSize(input) {
      if (typeof input !== "number") return "-";
      let num = input;
      const neg = num < 0;
      const units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      if (neg) num = -num;
      if (num === 0) return `${num} B`;

      let exponent = Math.min(
        Math.floor(Math.log(num) / Math.log(1024)),
        units.length - 1
      );
      num = num / Math.pow(1024, exponent);

      return `${neg ? "-" : ""}${round2(num)} ${units[exponent]}`;
    },
  },
};
