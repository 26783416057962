export const messageHandler = {
  namespaced: true,
  state: {
    messages: [],
  },
  mutations: {
    throwMessage(state, data) {
      state.messages.push(data);
    },
    clearMessage(state, id) {
      state.messages.splice(
        state.messages.find((x) => x.id === id),
        1
      );
    },
    clearMessages(state) {
      state.messages = [];
    },
  },
  actions: {
    throwMessage({ commit }, data) {
      let messageData = generateMessage(
        data,
        data.type ? data.type : "primary"
      );
      commit("throwMessage", messageData);
      if (messageData.ttl)
        setTimeout(
          () => commit("clearMessage", messageData.id),
          messageData.ttl
        );
    },
    clearMessage({ commit }, id) {
      commit("clearMessage", id);
    },
    clearMessages({ commit }) {
      commit("clearMessages");
    },
  },
  getters: {
    messages: (state) => state.messages,
  },
};

const generateMessage = (data, type) => {
  return {
    id: Math.floor(Date.now() * Math.random()),
    text: data.text,
    error: data.error ?? null,
    ttl: data.ttl ? data.ttl * 1000 : null,
    type: type,
    payload: data.payload ?? null,
  };
};
