<template>
  <div class="modal">
    <div
      class="modal-body modal-narrow relative"
      v-on-clickaway:mousedown="closeModal"
    >
      <div class="modal-header p-2">
        <h3>Lisa tehnikale hõivatus</h3>
      </div>
      <div class="modal-content flex-col">
        <add-equipment-unavailability-form
          ref="addEquipmentUnavailablityForm"
          @close="closeModal"
          @unavailabilityAdded="handleSaveUnavailability"
          :data="data"
        />
      </div>
      <div class="modal-bottom-bar">
        <button @click="saveEquipmentUnavailability" class="btn-primary mr-3">
          <span class="icon typcn typcn-tick"></span>
          <span class="label">Salvesta</span>
        </button>
        <button class="new-button-danger" @click="closeModal(false)">
          <span class="typcn typcn-times icon"></span>
          <span class="label">Sulge</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AddEquipmentUnavailabilityForm from "@/components/company/equipment/AddEquipmentUnavailabilityForm";
import { EventBus } from "@/assets/mixins/GewodoMixins";
import { mapGetters } from "vuex";

export default {
  name: "AddEquipmentUnavailabilityModal",
  components: { AddEquipmentUnavailabilityForm },
  methods: {
    closeModal() {
      this.$store.dispatch("modals/addEquipmentUnavailabilityModal/close");
    },
    handleSaveUnavailability() {
      EventBus.$emit("updateSchedule");
      this.closeModal();
    },
    saveEquipmentUnavailability() {
      this.$refs.addEquipmentUnavailablityForm.saveUnavailability();
    },
  },
  computed: {
    ...mapGetters({
      data: "modals/addEquipmentUnavailabilityModal/data",
    }),
  },
  mounted() {
    document.body.classList.add("modal-open");
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
};
</script>
