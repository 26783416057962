<template>
  <div
    class="flex cursor-pointer gap-x-2 overflow-x-hidden max-w-full group hover:bg-offwhite duration-150 px-4 py-2"
    :class="notificationTarget ? 'bg-offwhite rounded-lg' : ''"
    @click="activateChat"
  >
    <div class="flex w-10 h-10 items-center justify-center">
      <div
        class="bg-offwhite h-9 w-9 rounded-full flex items-center justify-center duration-150"
      >
        <span class="font-medium">{{ party.id }}</span>
      </div>
    </div>
    <div class="flex flex-grow flex-col title-container justify-center">
      <span class="font-medium text-sm">{{ party.title }}</span>
      <span class="truncate text-sm text-grey-light">
        {{ party.last_message.author }}: {{ party.last_message.message }}</span
      >
    </div>
    <div
      class="flex w-4 justify-end items-center cursor-pointer"
      @click.stop.prevent="markRead"
    >
      <span
        class="typcn typcn-media-record icon"
        v-if="notificationTarget"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatCard",
  props: {
    party: {
      type: Object,
      default: null,
    },
    notificationTarget: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    markRead() {
      this.$emit("markRead", this.party.id);
    },
    activateChat() {
      this.$emit("toggleChatTray", this.party);
    },
  },
};
</script>

<style lang="scss" scoped>
.title-container {
  max-width: calc(100% - 5rem);
}
</style>
