var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-offwhite relative",attrs:{"id":"app"}},[_c('div',{class:_vm.$route.meta.sidebar ? '' : 'sticky top-0 z-50'},[_c('page-navbar')],1),_c('div',{staticClass:"wrapper flex mobile:flex-col w-full flex-grow",class:_vm.$store.state.modals.taskModal.show ? 'overflow-y-hidden' : ''},[(
        _vm.$route.meta.sidebar &&
        ((_vm.authStatus.loggedIn && _vm.$store.state.companyData.retrieved) ||
          !_vm.authStatus.loggedIn)
      )?_c('sidebar-overlord',{staticClass:"w-36 mobile:w-full",on:{"activeCompanyChanged":_vm.handleActiveCompanyChange}}):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in","duration":50}},[(
          _vm.dataRetrieved &&
          ((_vm.authStatus.loggedIn && _vm.$store.state.companyData.retrieved) ||
            !_vm.authStatus.loggedIn)
        )?_c('router-view',{staticClass:"overflow-y-auto",class:[
          [
            'Home',
            'Landing',
            'AboutUs',
            'Product',
            'Pricing',
            'Demo' ].includes(_vm.$route.name)
            ? 'p-0'
            : 'px-6 py-6 mobile:px-0 mobile:py-0',
          _vm.$route.meta.sidebar
            ? 'flex-grow mobile:w-full medium:w-3/4 sidebar-width-limiter'
            : 'w-full',
          _vm.anyModalOpen ? 'overflow-y-hidden' : '' ]}):_vm._e()],1)],1),_c('message-handler'),_c('transition',{attrs:{"name":"fade","duration":100}},[(_vm.$store.state.loading)?_c('loading-modal'):_vm._e()],1),_c('modal-controller'),(
      _vm.config.features.chat && _vm.$store.state.chat.activeChatParties.length > 0
    )?_c('chat-controller'):_vm._e(),(_vm.$store.state.timetracking.hasActiveTimer && _vm.screenW < 993)?_c('timer-bar'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }