<template>
  <div class="modal overflow-x-hidden" v-on-clickaway="closeModal">
    <div class="image-modal overflow-x-hidden">
      <img
        :src="currImageUrl"
        class="modal-image border-2 border-primary"
        alt="Image"
      />
      <div
        @click="closeModal"
        class="close-button leading-none bg-danger flex justify-center items-center"
      >
        <span class="text-4xl typcn typcn-times leading-none"></span>
      </div>
      <div
        class="image-modal-prev-button mobile:hidden"
        @click="previousImage"
        v-if="imgArray.length > 1"
      >
        <span class="typcn typcn-media-play-reverse"></span>
      </div>
      <div
        class="image-modal-next-button mobile:hidden"
        @click="nextImage"
        v-if="imgArray.length > 1"
      >
        <span class="typcn typcn-media-play"></span>
      </div>
      <div
        class="flex self-end items-center py-3 carousel-container w-full justify-center mobile:h-32 overflow-y-hidden overflow-x-scroll mobile:px-8 mobile:w-auto mobile:block mobile:whitespace-nowrap"
        v-if="imgArray.length > 1"
      >
        <div
          class="flex mobile:inline-block mobile:px-1 mobile:max-h-full w-auto mx-2"
          v-for="item in imgArray"
          :key="item.id"
        >
          <img
            :src="item"
            class="h-32 w-auto mobile:h-auto mx-3 carousel-image mobile:max-h-full"
            @click="currImageUrl = item"
            alt="Thumbnail"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      default: () => {
        return "";
      },
    },
    imgArray: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      currImageUrl: null,
    };
  },
  mounted() {
    this.currImageUrl = this.imageUrl;
    window.addEventListener("keydown", this.handleInput);
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
      window.removeEventListener("keydown", this.handleInput);
    },
    thumbnailUrl(imgUrl) {
      return imgUrl;
    },
    nextImage() {
      if (this.currImageIndex + 1 < this.imgArray.length) {
        this.currImageUrl = this.thumbnailUrl(
          this.imgArray[this.currImageIndex + 1]
        );
      } else {
        this.currImageUrl = this.thumbnailUrl(this.imgArray[0]);
      }
    },
    previousImage() {
      if (this.currImageIndex - 1 >= 0) {
        this.currImageUrl = this.thumbnailUrl(
          this.imgArray[this.currImageIndex - 1]
        );
      } else {
        this.currImageUrl = this.thumbnailUrl(
          this.imgArray[this.imgArray.length - 1]
        );
      }
    },
    handleInput(e) {
      if (e.keyCode === 37) {
        this.previousImage();
      } else if (e.keyCode === 39) {
        this.nextImage();
      } else if (e.keyCode === 27) {
        this.closeModal();
      }
    },
  },
  computed: {
    currImageIndex() {
      return this.imgArray.indexOf(this.currImageUrl);
    },
  },
};
</script>

<style lang="scss">
.image-modal {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 60;
  overflow-x: scroll;

  .modal-image {
    display: flex;
    position: absolute;
    justify-self: center;
    align-self: center;
    @apply bg-white rounded-md;
    max-width: 65%;
    max-height: 65%;
  }
  .close-button {
    @apply h-16 w-16 bg-danger rounded-full text-white cursor-pointer;
    position: absolute;
    left: 80%;
    top: 10%;
  }
}

.carousel-container {
  background-color: rgba(0, 0, 0, 0.5);
  max-height: 12rem;
}
.image-modal-prev-button {
  @apply text-white;
  position: absolute;
  display: flex;
  align-self: center;
  left: 5%;
  font-size: 124px;
}
.image-modal-next-button {
  @apply text-white;
  position: absolute;
  display: flex;
  align-self: center;
  left: 90%;
  font-size: 124px;
}
@media (max-width: 992px) {
  .carousel-container {
    .carousel-image {
      max-height: 4rem;
    }
  }
  .close-button {
    left: 55%;
  }
  .image-modal {
    .modal-image {
      max-width: 95vw;
    }
  }
}
@media (max-height: 972px) {
  .image-modal {
    .modal-image {
      max-height: 50vh;
    }
  }
}
</style>
