<template>
  <div class="gwd-table nested h-full" v-if="taskExtraCostsLoaded">
    <div
      class="gwd-table-header"
      :class="
        isAdmin || isManager ? 'extra-cost-table' : 'extra-cost-table-worker'
      "
    >
      <span class="mobile:hidden">Töötaja</span>
      <span>Kirjeldus</span>
      <span>{{ isManager ? "Kulu" : "Ühiku hind" }}</span>
      <span v-if="isAdmin || isManager">Müügihind</span>
      <span>Kogus</span>
      <span>Kokku</span>
      <span>Lisainfo</span>
      <span>Kinnitatud</span>
    </div>
    <div class="gwd-table-row-wrapper">
      <div
        class="gwd-table-row"
        :class="
          isAdmin || isManager ? 'extra-cost-table' : 'extra-cost-table-worker'
        "
        v-for="extraWorkPlanning in extraWorkPlannings"
        :key="extraWorkPlanning.id"
      >
        <span class="mobile:hidden">Planeeritud töö</span>
        <span>
          <span class="desktop:hidden">Planeeritud töö:</span>
          {{ extraWorkPlanning.name }}
        </span>
        <span v-if="isAdmin || isManager">{{ extraWorkPlanning.cost }} €</span>
        <span v-else>-</span>
        <span v-if="isAdmin || isManager">{{ extraWorkPlanning.price }} €</span>
        <span>
          {{ extraWorkPlanning.amount }} {{ extraWorkPlanning.unit }}</span
        >
        <span v-if="isAdmin || isManager">
          {{ round2(extraWorkPlanning.amount * extraWorkPlanning.price) }} €
        </span>
        <span />
        <span />
      </div>
      <extra-cost
        v-for="extraCost in taskExtraCosts"
        :key="extraCost.id"
        :extra-cost="extraCost"
        @confirmExtraCost="confirmWorkerExtraCost"
        @editCost="editCost"
        @deleteCost="deleteCost"
        :is-admin="isAdmin"
        :is-manager="isManager"
      />
      <span
        v-if="taskExtraCosts.length === 0 && extraWorkPlannings.length === 0"
        class="p-2"
        >Määratud lisatöid pole.</span
      >
    </div>

    <div class="gwd-table-bottom-bar">
      <button
        class="btn-primary mr-3"
        @click="$store.commit('modals/taskModal/openAddExtraCostModal', false)"
      >
        <span class="label">Lisa</span>
      </button>
      <button
        class="btn-primary"
        v-if="['R0', 'R1', 'R2'].includes(userPermissions)"
        @click="$store.commit('modals/taskModal/openAddExtraCostModal', true)"
      >
        Planeeri lisatöö
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { EventBus } from "../../../assets/mixins/GewodoMixins";
import { round2 } from "@/assets/utils/commonMath";
import ExtraCost from "@/components/tasks/costs/ExtraCost.vue";

export default {
  name: "TaskExtraCostAssignments",
  components: { ExtraCost },
  props: {
    task: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      taskExtraCosts: [],
      taskExtraCostsLoaded: false,
      extraWorkPlannings: [],
    };
  },
  methods: {
    round2,
    loadTaskExtraCosts() {
      this.apiRequest(
        `template/${this.companyId}/extras/?task=${this.task.id}`,
        "get",
        true
      ).then((res) => {
        this.extraWorkPlannings = res.data;
      });
      this.apiRequest(
        `costs/${this.companyId}/workers/costs/?task=${this.task.id}`,
        "get",
        true
      ).then((res) => {
        this.taskExtraCosts = res.data;
        this.taskExtraCostsLoaded = true;
      });
    },
    confirmWorkerExtraCost(cost) {
      this.apiRequest(
        `costs/${this.companyId}/worker/${cost.worker.id}/extra/${cost.id}/confirm/`,
        "post",
        true
      ).then((res) => {
        if (res.status === 200) {
          this.loadTaskExtraCosts();
        }
      });
    },
    editCost(e) {
      this.apiRequest(
        `costs/${this.companyId}/costs/extraCost/${e.id}/edit/`,
        "patch",
        true,
        e
      )
        .then((res) => {
          if (res.status === 200) {
            this.loadTaskExtraCosts();
          }
        })
        .catch((err) =>
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            error: err,
            text: "Midagi läks valesti",
            ttl: 5,
          })
        );
    },
    deleteCost(e) {
      this.apiRequest(
        `costs/${this.companyId}/costs/extraCost/${e.id}/delete/`,
        "delete",
        true
      )
        .then((res) => {
          if (res.status === 200) {
            this.loadTaskExtraCosts();
          }
        })
        .catch((err) =>
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            error: err,
            text: "Kulu kustutamine ebaõnnestus!",
            ttl: 5,
          })
        );
    },
  },
  mounted() {
    this.loadTaskExtraCosts();
    EventBus.$on("costSaved", () => this.loadTaskExtraCosts());
  },
  beforeDestroy() {
    EventBus.$off("costSaved");
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      isAdmin: "companyData/isAdmin",
      isManager: "companyData/isManager",
      userPermissions: "companyData/userPermission",
    }),
  },
};
</script>
<style lang="scss">
.extra-cost-table {
  //                     worker name cost price amount sum info confirm
  grid-template-columns: 0.3fr 0.6fr 0.3fr 0.3fr 0.3fr 0.3fr 0.6fr 0.3fr;
}
.extra-cost-table-worker {
  //                     worker name price amount sum info   confirmed
  grid-template-columns: 0.3fr 0.4fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr;

  @media screen and (max-width: 992px) {
    //                     name amount price sum info confirmed
    grid-template-columns: 0.4fr 0.1fr 0.1fr 0.15fr 0.2fr 0.1fr;
  }
}
</style>
