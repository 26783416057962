<template>
  <div
    class="flex flex-col w-full"
    :class="isOwnComment ? 'items-end' : 'items-start'"
  >
    <div v-if="showHeader" class="flex gap-x-1 w-auto items-center">
      <span
        class="flex text-center text-bordergrey"
        :class="isOwnComment ? 'order-2' : null"
      >
        {{ parseTime }}
      </span>
      <span class="text-left font-semibold">
        {{ comment.author.display_username }}
      </span>
    </div>
    <div
      class="flex flex-shrink rounded-lg p-2 text-white w-min-content"
      :class="[comment.author.id === selfId ? 'bg-primary' : 'bg-grey-light']"
    >
      <span
        class="break-words"
        :class="comment.author.id === selfId ? '' : ''"
        v-if="comment.status === 1"
      >
        {{ comment.content }}
      </span>
      <span v-if="comment.status === 2">Kommentaar kustutatud</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment/moment";

export default {
  name: "ChatComment",
  props: {
    comment: {
      type: Object,
      default: null,
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      selfId: "userData/userId",
    }),
    parseTime() {
      return moment(this.comment.created_at).format("HH:mm");
    },
    isOwnComment() {
      if (!this.selfId || !this.comment.author.id) return false;
      return this.comment.author.id === this.selfId;
    },
  },
};
</script>
