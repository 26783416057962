export const formatTimeUnit = (item) => {
  if (item.toString().length > 1) return item;
  else return `0${item}`;
};

export const generateParamString = (params) => {
  if (!params) return "";
  let paramString = "?";
  if (Array.isArray(params)) {
    if (params.length === 0) return "";
    for (let i = 0; i < params.length; i++) {
      paramString += `${params[i].toString()}${
        i !== params.length - 1 ? "&" : ""
      }`;
    }
  } else if (typeof params === "object") {
    if (Object.keys(params).length === 0) return "";
    for (const key in params) {
      paramString += `${key}=${params[key]}&`;
    }
    paramString = paramString.slice(0, -1);
  } else {
    console.error("Invalid input!");
    return "";
  }
  return paramString;
};

export const generateCoordinateLocationObject = (address, lat, long) => {
  return {
    ads_oid: "",
    long_address: address,
    latitude: lat,
    longitude: long,
    street: null,
    locality: null,
    city: null,
    country: null,
    full_object: null,
  };
};

export const normalizeLocationObject = (locationObj) => {
  if (locationObj.long_address) return locationObj;
  return {
    ads_oid: locationObj.ads_oid ?? null,
    long_address: locationObj.ipikkaadress ?? null,
    latitude: locationObj.viitepunkt_b ?? null,
    longitude: locationObj.viitepunkt_l ?? null,
    street: locationObj.aadresstekst ?? null,
    locality: locationObj.asustusyksus ?? null,
    city: locationObj.omavalitsus ?? null,
    country: "Eesti" ?? null,
    full_object: locationObj ?? null,
  };
};

export const normalizeAbbreviatedLocationObject = (location) => {
  return {
    ads_oid: null,
    long_address: location,
    latitude: null,
    longitude: null,
    street: null,
    locality: null,
    city: null,
    country: "Eesti",
    full_object: null,
  };
};

import moment from "moment";
export const hmObjectToString = (obj) => {
  return `${formatTimeUnit(obj.hour)}:${formatTimeUnit(obj.minute)}`;
};
export const timeSelectorObjectToString = (timeObj, date, format) => {
  return moment(date).hour(timeObj.hour).minute(timeObj.minute).format(format);
};

export const parseTimerData = (timerData, workerUUID) => {
  return moment
    .utc(
      timerData
        .filter((x) => x.worker === workerUUID)
        .map((x) =>
          moment
            .duration(
              moment(x.stopper_end ? moment(x.stopper_end) : moment()).diff(
                moment(x.stopper_start)
              )
            )
            .as("milliseconds")
        )
        .reduce((len, sum) => len + sum, 0)
    )
    .format("HH:mm:ss");
};
