var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.$store.state.companyData.retrieved ||
    !_vm.$store.state.userData.user.is_email_verified
  )?_c('div',{staticClass:"flex mobile:shadow-none shadow z-20 flex-col bg-white duration-100 mobile:sticky"},[(!_vm.$store.state.companyData.isClient)?_c('sidebar-company',{class:_vm.sidebarExpanded ? 'mobile:pb-4 open' : ' closed',attrs:{"expanded":_vm.sidebarExpanded},on:{"activeCompanyChanged":_vm.handleActiveCompanyChange}}):_vm._e(),(
      (_vm.$store.state.companyData.isClient ||
        _vm.$store.state.companyData.companies.length === 0) &&
      _vm.sidebarExpanded
    )?_c('sidebar-client'):_vm._e(),_c('button',{staticClass:"rounded-b rounded-t-none font-normal text-xl hidden mobile:flex justify-center items-center p-1 mobile:shadow shadow-none",on:{"click":function($event){_vm.sidebarExpanded = !_vm.sidebarExpanded}}},[_c('span',{staticClass:"typcn typcn-th-menu"})])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }