<template>
  <div
    class="flex mobile:shadow-none shadow z-20 flex-col bg-white duration-100 mobile:sticky"
    v-if="
      $store.state.companyData.retrieved ||
      !$store.state.userData.user.is_email_verified
    "
  >
    <sidebar-company
      @activeCompanyChanged="handleActiveCompanyChange"
      v-if="!$store.state.companyData.isClient"
      :class="sidebarExpanded ? 'mobile:pb-4 open' : ' closed'"
      :expanded="sidebarExpanded"
    />
    <sidebar-client
      v-if="
        ($store.state.companyData.isClient ||
          $store.state.companyData.companies.length === 0) &&
        sidebarExpanded
      "
    />

    <button
      class="rounded-b rounded-t-none font-normal text-xl hidden mobile:flex justify-center items-center p-1 mobile:shadow shadow-none"
      @click="sidebarExpanded = !sidebarExpanded"
    >
      <span class="typcn typcn-th-menu"></span>
    </button>
  </div>
</template>

<script>
import SidebarCompany from "@/components/company/Sidebar";
import SidebarClient from "@/components/client/Sidebar";
export default {
  components: {
    SidebarCompany,
    SidebarClient,
  },
  data() {
    return {
      sidebarExpanded: false,
    };
  },
  mounted() {
    this.sidebarExpanded = window.innerWidth > 992;
  },
  watch: {
    screenW() {
      if (this.screenW > 992) {
        this.sidebarExpanded = true;
      }
    },
  },
  methods: {
    handleActiveCompanyChange() {
      this.$emit("activeCompanyChanged");
    },
  },
};
</script>
