<template>
  <div class="file-display gap-y-2">
    <div ref="fileArea" class="file-area grid gap-2">
      <div
        class="bg-offwhite hover:bg-offwhite-dark duration-100 rounded p-2 flex flex-col cursor-pointer"
        v-for="(file, index) in files"
        @click.stop.prevent="openFileModal(file)"
        :key="index"
      >
        <span class="text-wrap break-words">{{ file.file_name }}</span>
      </div>
      <span v-if="files.length === 0 && !fileAreaDragHovered">
        Faile pole
      </span>
    </div>
    <input
      type="file"
      multiple
      class="bg-white"
      ref="files"
      :name="`fields[${this.identifier}][]`"
      :id="`${this.identifier}`"
      @input="updateFiles"
    />
    <div
      class="file-upload-area"
      ref="uploadArea"
      @drop="handleDrop"
      @dragover="dragover"
      @dragleave="dragleave"
    >
      <div
        class="flex gap-x-2 items-center py-2"
        @click="handleUploadAreaClick"
      >
        <span class="upload-icon typcn typcn-upload text-xl leading-none" />
        <span>Kliki või lohista failid siia</span>
      </div>
      <input
        type="file"
        multiple
        class="bg-white"
        ref="files"
        :name="`fields[${this.identifier}][]`"
        :id="`${this.identifier}`"
        @input="updateFiles"
      />
      <div
        class="flex flex-col gap-y-2 border-t border-offwhite-dark py-2"
        v-if="filesToUpload.length > 0"
      >
        <h4>Üleslaetavad failid</h4>
        <div class="grid grid-cols-6 mobile:grid-cols-1 gap-x-2">
          <div
            class="flex flex-col bg-white rounded p-2 shadow"
            v-for="(file, index) in filesToUpload"
            :key="index"
          >
            <span class="truncate">
              {{ file.name }}
            </span>
            <div class="flex justify-between">
              <span>{{ humanizeSize(file.size) }}</span>
              <button class="btn-danger" @click="removeFile(file)">
                Eemalda
              </button>
            </div>
          </div>
        </div>
        <div class="flex gap-x-2">
          <button class="btn-primary" @click="callUpload">Lae üles</button>
          <button class="btn-danger" @click="reset">Tühjenda</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { FileSizeHumanizer } from "@/assets/mixins/GewodoMixins";
export default {
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [FileSizeHumanizer],
  data() {
    return {
      filesToUpload: [],
      fileAreaDragHovered: false,
    };
  },
  methods: {
    handleDrop(e) {
      e.preventDefault();
      this.$refs.files.files = e.dataTransfer.files;
      this.updateFiles();
      this.$refs.uploadArea.classList.remove("drag-hovered");
    },
    handleUploadAreaClick() {
      this.$refs.files.click();
    },
    dragover(e) {
      e.preventDefault();
      this.$refs.uploadArea.classList.add("drag-hovered");
    },
    dragleave() {
      if (this.$refs.uploadArea.classList.contains("drag-hovered")) {
        this.$refs.uploadArea.classList.remove("drag-hovered");
      }
    },
    updateFiles() {
      if (!this.multiple) {
        this.filesToUpload = [];
        this.filesToUpload.push(this.$refs.files.files[0]);
      } else {
        for (let file of this.$refs.files.files) {
          this.filesToUpload.push(file);
        }
      }

      this.$emit("filesUpdated", this.filesToUpload);
    },
    getFilesToUpload() {
      return this.filesToUpload;
    },
    callUpload() {
      this.$emit("uploadFiles");
    },
    reset() {
      this.filesToUpload = [];
    },
    openFileModal(file) {
      this.$store.dispatch("modals/fileModal/open", {
        file: file,
        type: "companyFile",
        company: this.$store.state.companyData.activeCompany.uuid,
      });
    },
    fileDeleted() {
      this.$emit("fileDeleted");
    },
    removeFile(file) {
      this.filesToUpload.splice(
        this.filesToUpload.findIndex((x) => x.name === file.name),
        1
      );
    },
  },
  computed: {
    identifier() {
      return this.$vnode.key ?? "assetsFieldHandle";
    },
  },
};
</script>
<style lang="scss" scoped>
.file-display {
  @apply rounded flex relative flex-col z-50;
  .file-area {
    @apply rounded;
    .upload-icon {
      @apply text-4xl self-center justify-self-center;
    }
  }
  .file-upload-area {
    @apply bg-offwhite rounded p-2 duration-100 gap-y-2 cursor-pointer;
    &.drag-hovered {
      @apply bg-primary text-white;
    }
  }
  input {
    @apply p-0 absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 0.1px;
    width: 0.1px;
  }
}
</style>
