<template>
  <div class="modal">
    <div
      class="modal-body modal-medium add-task-modal relative"
      v-on-clickaway:mousedown="closeModal"
    >
      <div class="modal-header">
        <h3 class="modal-title">Lisa tellimusele ressurss</h3>
        <div class="modal-tabs" v-if="false">
          <div
            class="modal-tab w-1/4"
            @click="selectExisting = true"
            :class="selectExisting ? 'resource-selector-tab-active' : ''"
          >
            <span class="typcn typcn-th-list mr-2"></span>
            <span>Vali olemasolev</span>
          </div>
          <div
            class="modal-tab"
            @click="selectExisting = false"
            :class="!selectExisting ? 'resource-selector-tab-active' : ''"
          >
            <span class="typcn typcn-plus mr-2"></span>
            <span>Lisa uus</span>
          </div>
        </div>
      </div>
      <div class="modal-content flex-row mb-2 w-full">
        <div class="flex flex-col w-1/2 mr-2">
          <div
            v-for="(newAssignment, index) in selectedResources"
            :key="index"
            class="flex p-1 mb-1 items-center"
            :class="
              newAssignment.assigned_amount > newAssignment.remaining_amount ||
              newAssignment.assigned_amount < 0
                ? 'ring-2 rounded ring-danger'
                : ''
            "
          >
            <div class="flex flex-row w-1/2">
              <button
                class="btn-danger"
                @click="toggleAssignedResource(newAssignment)"
              >
                <span class="label">X</span>
              </button>
              <span class="mx-2"> {{ newAssignment.name }}</span>
              <span class="ml-auto">{{ newAssignment.remaining_amount }}</span>
            </div>
            <decimal-input
              class="ml-auto"
              v-model.number="newAssignment.assigned_amount"
            />
          </div>
        </div>
        <div class="flex flex-col w-1/2 ml-2">
          <existing-resource-selector
            @resourceSelected="toggleAssignedResource"
            :toggled-resources="selectedResources.map((x) => x.id)"
          />
        </div>
      </div>
      <div class="modal-bottom-bar">
        <button @click="saveResource" class="btn-primary mr-3">
          <span class="icon typcn typcn-tick"></span>
          <span class="label">Salvesta</span>
        </button>
        <button class="new-button-danger" @click="closeModal(false)">
          <span class="typcn typcn-times icon"></span>
          <span class="label">Sulge</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/assets/mixins/GewodoMixins";
import { mapGetters } from "vuex";
import ExistingResourceSelector from "@/components/company/resources/ExistingResourceSelector.vue";
import DecimalInput from "../../reusable/DecimalInput.vue";
export default {
  name: "AddProjectResourceModal",
  components: { ExistingResourceSelector, DecimalInput },
  data() {
    return {
      selectExisting: true,
      selectedResources: [],
    };
  },
  methods: {
    closeModal() {
      this.$store.dispatch("modals/addResourceModal/close");
    },
    saveResource() {
      if (this.selectExisting) this.$refs.resourceSelector.saveResource();
      else {
        const resource = this.$refs.addResourceForm.getResource();

        this.apiRequest(
          `template/${this.companyId}/resources/add/`,
          "post",
          true,
          resource
        ).then(() => {
          this.selectExisting = true;
        });
      }
    },
    toggleAssignedResource(e) {
      if (this.selectedResources.includes(e)) {
        this.selectedResources.splice(
          this.selectedResources.indexOf(
            this.selectedResources.find((x) => x === e)
          ),
          1
        );
        e.assigned_amount = null;
      } else {
        this.selectedResources.push(e);
      }
    },
    handleSaveResource() {
      EventBus.$emit("updateResources");
      this.closeModal();
    },
  },
  computed: {
    ...mapGetters({
      modalData: "modals/addResourceModal/data",
      companyId: "companyData/activeCompanyUuid",
    }),
  },
  mounted() {
    document.body.classList.add("modal-open");
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
};
</script>
