<template>
  <div class="notification-card" @click="goToNotificationTarget">
    <span class="font-medium">{{ notification.description }}</span>
    <span v-if="notification.details !== ''"> {{ notification.details }}</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    notification: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    goToNotificationTarget() {
      console.log(this.notification.company_id);
      console.log(this.companyId);
      if (
        this.notification.company_id &&
        this.notification.company_id !== this.companyId
      ) {
        this.$store
          .dispatch("companyData/activateCompany", this.notification.company_id)
          .then(() => {
            this.$store.dispatch(
              "notifications/readNotification",
              this.notification.id
            );
            this.$emit("close");
            this.$store.dispatch("modals/taskModal/open", {
              task: this.notification.object_id,
            });
          });
      } else {
        this.$store.dispatch(
          "notifications/readNotification",
          this.notification.id
        );
        this.$emit("close");
        this.$store.dispatch("modals/taskModal/open", {
          task: this.notification.object_id,
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
    }),
  },
};
</script>
