<template>
  <div class="flex flex-col gap-y-1">
    <div
      v-for="(brigade, index) in workerBrigades"
      :key="brigade.id"
      class="flex flex-col"
    >
      <h4 class="flex px-1">
        {{ brigade.name }}
      </h4>
      <div class="flex w-full bg-offwhite rounded shadow p-1 gap-x-1">
        <div class="flex flex-wrap flex-1 gap-x-2">
          <span
            v-for="worker in brigade.workers"
            :key="`res${index}w${worker.id}`"
          >
            {{ worker.worker_name }}
          </span>
        </div>
        <button
          :class="
            freeAmount >= brigade.workers.length
              ? 'btn-primary'
              : 'btn-disabled'
          "
          @click="
            freeAmount >= brigade.workers.length
              ? selectBrigade(brigade, true)
              : ''
          "
        >
          Töötajad
        </button>
        <button class="btn-primary" @click="selectBrigade(brigade)">
          Brigaad
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "WorkerBrigades",
  props: {
    freeAmount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      workerBrigades: [],
    };
  },
  methods: {
    loadWorkerBrigades() {
      this.apiRequest(
        `company/${this.companyId}/brigades/?task=true`,
        "get",
        true
      ).then((res) => {
        this.workerBrigades = res.data;
      });
    },
    selectBrigade(brigade, append = false) {
      this.$emit("selectBrigade", brigade, append);
    },
  },
  mounted() {
    this.loadWorkerBrigades();
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
    }),
  },
};
</script>
