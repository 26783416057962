<template>
  <div class="flex flex-col gap-y-2 text-sm">
    <div class="flex gap-x-2">
      <div class="flex w-4 self-center">
        <span class="w-4 h-4 bg-primary rounded-full"></span>
      </div>
      <div class="flex flex-col w-full">
        <planning-location-selector
          v-model="fields.location"
          :emit-standardized-object="true"
          ref="startLocation"
          class="text-sm"
        >
          Lähtekoht
        </planning-location-selector>
        <div
          class="flex w-full text-sm mt-1"
          v-if="
            selectedProject &&
            Object.keys(selectedProject).length > 0 &&
            !fields.location
          "
        >
          <button
            class="mr-2"
            @click="
              () =>
                $refs.startLocation.selectLocation(
                  processLocationForEmit(selectedProject.location)
                )
            "
            :class="selectedProject.location ? 'btn-primary' : 'btn-disabled'"
            :disabled="!selectedProject.location"
          >
            <span class="label">Tellimusega sama</span>
          </button>
          <button
            class="mr-2"
            :class="
              selectedProject &&
              selectedProject.client &&
              selectedProject.client.location
                ? 'btn-primary'
                : 'btn-disabled'
            "
            @click="
              () =>
                $refs.startLocation.selectLocation(
                  processLocationForEmit(selectedProject.client.location)
                )
            "
          >
            <span class="label">Tellimuse kliendiga sama</span>
          </button>
          <button
            class="mr-2"
            :class="fields.endLocation ? 'btn-primary' : 'btn-disabled'"
            @click="
              () =>
                $refs.startLocation.selectLocation(
                  processLocationForEmit(fields.endLocation)
                )
            "
            :disabled="!fields.endLocation"
          >
            <span class="label">Sama mis lõpp</span>
          </button>
        </div>
      </div>
    </div>
    <div v-if="extraLocations.length > 0" class="flex flex-col gap-y-1">
      <div
        v-for="(location, index) in extraLocations"
        :key="`l-${location.key}`"
        class="flex w-full gap-x-2"
      >
        <div class="flex flex-col w-full">
          <planning-location-selector
            :emit-standardized-object="true"
            :extra-location="true"
            ref="extraLocations"
            v-model="location.location"
            @delete="
              $store.dispatch('modals/addTaskModal/deleteExtraLocation', index)
            "
          >
            Vahesihtkoht
          </planning-location-selector>
        </div>
      </div>
    </div>
    <div
      v-if="fields.location && fields.endLocation && isManager"
      class="flex gap-x-2 cursor-pointer rounded-l-full rounded-r hover:bg-primary hover:bg-opacity-10 duration-100"
      @click="$store.dispatch('modals/addTaskModal/addExtraLocation')"
    >
      <div class="w-4 self-center">
        <button
          class="bg-primary h-4 w-4 rounded-full leading-none flex justify-center items-center text-white"
        >
          <span class="typcn typcn-plus leading-none"></span>
        </button>
      </div>
      <div class="flex font-medium">
        <span>Lisa vahesihtkoht</span>
      </div>
    </div>
    <div class="flex gap-x-2">
      <div class="flex w-4 relative self-center">
        <span class="w-4 h-4 bg-primary rounded-full"></span>
      </div>
      <div class="flex flex-col flex-1">
        <planning-location-selector
          v-model="fields.endLocation"
          :emit-standardized-object="true"
          ref="endLocation"
        >
          Sihtkoht
        </planning-location-selector>
        <div class="flex w-full text-sm mt-1" v-if="!fields.endLocation">
          <button
            v-if="selectedProject && Object.keys(selectedProject).length > 0"
            class="mr-2"
            @click="
              () =>
                $refs.endLocation.selectLocation(
                  processLocationForEmit(selectedProject.location)
                )
            "
            :class="selectedProject.location ? 'btn-primary' : 'btn-disabled'"
          >
            <span class="label">Tellimusega sama</span>
          </button>
          <button
            v-if="selectedProject && Object.keys(selectedProject).length > 0"
            class="mr-2"
            :class="
              selectedProject &&
              selectedProject.client &&
              selectedProject.client.location
                ? 'btn-primary'
                : 'btn-disabled'
            "
            @click="
              () =>
                $refs.endLocation.selectLocation(
                  processLocationForEmit(selectedProject.client.location)
                )
            "
          >
            <span class="label">Tellimuse kliendiga sama</span>
          </button>
          <button
            v-if="fields.location"
            class="btn-primary mr-2"
            @click="
              () =>
                $refs.endLocation.selectLocation(
                  processLocationForEmit(fields.location)
                )
            "
          >
            <span class="label">Sama mis algus</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PlanningLocationSelector from "@/components/tasks/planning/PlanningLocationSelector";
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    PlanningLocationSelector,
  },
  methods: {
    processLocationForEmit(inputLocation) {
      let location = JSON.parse(JSON.stringify(inputLocation));
      delete location["uuid"];
      return location;
    },
  },
  computed: {
    ...mapState({
      fields: (state) => state.modals.addTaskModal.fields,
      extraLocations: (state) => state.modals.addTaskModal.extraLocations,
    }),
    ...mapGetters({
      isManager: "companyData/isManager",
      isAdmin: "companyData/isAdmin",
      selectedProject: "modals/addTaskModal/project",
    }),
  },
};
</script>
<style lang="scss"></style>
