<template>
  <div class="flex flex-col">
    <div class="flex flex-col" v-if="!addTaskActModalActive">
      <div class="flex">{{ taskActs }}</div>
      <div class="flex mt-1">
        <button class="btn-primary" @click="addTaskActModalActive = true">
          Lisa akt
        </button>
      </div>
    </div>
    <add-task-act
      v-if="addTaskActModalActive"
      @closeModal="addTaskActModalActive = false"
      @save="saveTaskAct"
    >
    </add-task-act>
  </div>
</template>

<script>
import AddTaskAct from "@/components/tasks/costs/AddTaskAct";
import { mapGetters } from "vuex";
export default {
  name: "TaskActs",
  components: { AddTaskAct },
  data() {
    return {
      addTaskActModalActive: false,
      taskActs: [],
    };
  },
  methods: {
    loadTaskActs() {
      this.apiRequest(`tasks/${this.task.task}/acts/`, "get", true).then(
        (res) => {
          this.taskActs = res.data;
        }
      );
    },
    saveTaskAct(data) {
      this.apiRequest(
        `tasks/${this.task.task}/acts/create/`,
        "post",
        true,
        data
      ).then((res) => {
        console.log(res);
      });
    },
  },
  mounted() {
    this.loadTaskActs();
  },
  computed: {
    ...mapGetters({
      task: "modals/taskModal/data",
    }),
  },
};
</script>

<style scoped></style>
