<template>
  <div class="gwd-table nested h-full">
    <div class="gwd-table-header supplier-cost">
      <span>Hankija</span>
      <span>Kirjeldus</span>
      <span>Kogus</span>
      <span>Kulu</span>
      <span>Hind</span>
      <span>Kokku</span>
      <span />
    </div>
    <div class="gwd-table-row-wrapper">
      <div
        v-for="planningCost in supplierCostPlannings"
        :key="planningCost.id"
        class="gwd-table-row supplier-cost"
      >
        <span>Planeeritud: {{ planningCost.supplier.name }}</span>
        <span>{{ planningCost.name }}</span>
        <span>{{ planningCost.amount }} {{ planningCost.unit }}</span>
        <span>{{ planningCost.cost }} €</span>
        <span>{{ planningCost.price }} €</span>
        <span>{{ round2(planningCost.price * planningCost.amount) }} €</span>
        <span />
      </div>
      <compact-cost
        v-for="supplierCost in taskSupplierCosts"
        :key="supplierCost.id"
        :cost="supplierCost"
        @editCost="editCost"
        @deleteCost="deleteCost"
      >
        {{ supplierCost.supplier.name }}
      </compact-cost>
      <span
        v-if="
          taskSupplierCosts.length === 0 && supplierCostPlannings.length === 0
        "
        class="p-2"
      >
        Hankijakulusid pole.
      </span>
    </div>

    <div class="gwd-table-bottom-bar">
      <button
        class="btn-primary mr-3"
        @click="
          $store.commit('modals/taskModal/openAddSupplierCostModal', false)
        "
      >
        <span class="label">Lisa</span>
      </button>
      <button
        class="btn-primary"
        v-if="isAdmin"
        @click="
          $store.commit('modals/taskModal/openAddSupplierCostModal', true)
        "
      >
        Planeeri hankija kulu
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { EventBus } from "../../../assets/mixins/GewodoMixins";
import CompactCost from "@/components/tasks/costs/CompactCost.vue";
import { round2 } from "@/assets/utils/commonMath";

export default {
  name: "TaskSupplierAssignments",
  components: { CompactCost },
  props: {
    taskId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      taskSupplierCostsLoaded: false,
      taskSupplierCosts: [],
      addSupplierCost: false,
      supplierCostPlannings: [],
      addSupplierCostPlanning: false,
    };
  },
  methods: {
    round2,
    loadTaskSupplierCosts() {
      this.apiRequest(
        `template/${this.companyId}/plannings/supplier/?task=${this.taskId}`,
        "get",
        true
      ).then((res) => {
        this.supplierCostPlannings = res.data;
      });
      this.apiRequest(
        `costs/${this.companyId}/supplier/costs/?task=${this.taskId}`,
        "get",
        true
      ).then((res) => {
        this.taskSupplierCosts = res.data;
        this.taskSupplierCostsLoaded = true;
      });
    },
    editCost(e) {
      this.apiRequest(
        `costs/${this.companyId}/costs/supplierCost/${e.id}/edit/`,
        "patch",
        true,
        e
      )
        .then((res) => {
          if (res.status === 200) {
            this.loadTaskSupplierCosts();
          }
        })
        .catch((err) =>
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            error: err,
            text: "Midagi läks valesti",
            ttl: 5,
          })
        );
    },
    deleteCost(e) {
      this.apiRequest(
        `costs/${this.companyId}/costs/supplierCost/${e.id}/delete/`,
        "delete",
        true
      )
        .then((res) => {
          if (res.status === 200) {
            this.loadTaskSupplierCosts();
          }
        })
        .catch((err) =>
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            error: err,
            text: "Kulu kustutamine ebaõnnestus!",
            ttl: 5,
          })
        );
    },
  },
  mounted() {
    this.loadTaskSupplierCosts();
    EventBus.$on("supplierCostAdded", () => this.loadTaskSupplierCosts());
  },
  beforeDestroy() {
    EventBus.$off("supplierCostAdded");
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      isAdmin: "companyData/isAdmin",
    }),
  },
};
</script>
<style lang="scss">
.supplier-cost {
  grid-template-columns: 0.25fr 0.25fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr;
}
</style>
