<template>
  <div class="modal">
    <div
      class="modal-body modal-narrow"
      v-on-clickaway="close"
      v-if="schedule && scheduleEditable"
    >
      <div class="modal-header p-2">
        <h3>Hõivatus {{ schedule.reqData.id }}</h3>
      </div>
      <div class="modal-content flex-col gap-y-2">
        <div class="flex">
          <div class="flex flex-col">
            <h4>Algus</h4>
            <div class="flex gap-x-4" v-if="canEdit">
              <div class="w-1/2 flex">
                <date-selector
                  pos="r"
                  v-model="scheduleEditable.start_date"
                  background-color="offwhite"
                  :padded="true"
                  :shadow="true"
                />
              </div>
              <div class="w-1/2 flex">
                <time-selector v-model="scheduleEditable.start_time" pos="r" />
              </div>
            </div>
            <span v-else>{{
              moment(scheduleEditable.start_date)
                .hour(scheduleEditable.start_date.hour)
                .minute(scheduleEditable.start_date.minute)
                .format("DD.MM.YYYY HH:mm")
            }}</span>
          </div>
        </div>
        <div class="flex">
          <div class="flex flex-col">
            <h4>Lõpp</h4>
            <div class="flex gap-x-4" v-if="canEdit">
              <div class="w-1/2 flex">
                <date-selector
                  pos="r"
                  v-model="scheduleEditable.end_date"
                  background-color="offwhite"
                  :padded="true"
                  :shadow="true"
                />
              </div>
              <div class="flex w-1/2">
                <time-selector v-model="scheduleEditable.end_time" pos="r" />
              </div>
            </div>
            <span v-else>{{
              moment(scheduleEditable.end_date)
                .hour(scheduleEditable.end_time.hour)
                .minute(scheduleEditable.end_time.minute)
                .format("DD.MM.YYYY HH:mm")
            }}</span>
          </div>
        </div>
        <div class="flex flex-col">
          <h4>Kirjeldus</h4>
          <gwd-textarea v-model="scheduleEditable.reason" v-if="canEdit" />
          <span v-else>
            {{ schedule.reqData.reason ? schedule.reqData.reason : "-" }}
          </span>
        </div>
      </div>
      <div class="modal-bottom-bar gap-x-2">
        <button
          :class="hasChanges && !retrieving ? 'btn-primary' : 'btn-disabled'"
          :disabled="!hasChanges || retrieving"
          @click="editUnavailability"
        >
          <span v-if="!retrieving">Salvesta</span>
          <ClipLoader size="16px" v-else />
        </button>
        <button class="btn-danger" @click="deleteUnavailability">
          Kustuta
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { RequestHandler, EventBus } from "@/assets/mixins/GewodoMixins";
import DateSelector from "../../globals/DateSelector.vue";
import TimeSelector from "../../reusable/TimeSelector.vue";
import moment from "moment";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
export default {
  props: {
    schedule: {
      type: Object,
      default: null,
    },
  },
  components: { DateSelector, TimeSelector, ClipLoader },
  mixins: [RequestHandler],
  data() {
    return {
      moment: moment,
      scheduleEditable: null,
    };
  },
  mounted() {
    let data = JSON.parse(JSON.stringify(this.schedule.reqData));
    this.scheduleEditable = {
      id: data.id,
      reason: data.reason,
      start_date: moment(data.start_time),
      start_time: {
        hour: moment(data.start_time).hour(),
        minute: moment(data.start_time).minute(),
      },
      end_date: data.end_time,
      end_time: {
        hour: moment(data.end_time).hour(),
        minute: moment(data.end_time).minute(),
      },
    };
  },
  methods: {
    close() {
      this.$store.dispatch("modals/equipmentUnavailabilityModal/close");
    },
    deleteUnavailability() {
      this.$store.dispatch(
        "modals/equipmentUnavailabilityModal/setRetrieving",
        true
      );
      this.apiRequest(
        `template/${this.companyId}/equipment/${this.schedule.equipmentId}/unavailability/${this.schedule.reqData.id}/delete/`,
        "delete",
        true
      )
        .then(() => {
          this.$store.dispatch(
            "modals/equipmentUnavailabilityModal/setRetrieving",
            false
          );
          EventBus.$emit("updateSchedule");
          this.close();
        })
        .catch((err) => {
          this.$store.dispatch(
            "modals/equipmentUnavailabilityModal/setRetrieving",
            false
          );
          console.error(err);
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            text: "Kustutamine ebaõnnestus",
            ttl: 15,
          });
        });
    },
    editUnavailability() {
      this.$store.dispatch(
        "modals/equipmentUnavailabilityModal/setRetrieving",
        false
      );
      this.apiRequest(
        `template/${this.companyId}/equipment/${this.schedule.equipmentId}/unavailability/${this.schedule.reqData.id}/edit/`,
        "patch",
        true,
        this.databaseObject
      )
        .then(() => {
          this.$store.dispatch("modals/equipmentUnavailabilityModal/open", {
            equipmentId: this.schedule.equipmentId,
            unavailabilityId: this.schedule.reqData.id,
          });
          EventBus.$emit("updateSchedule");
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch(
            "modals/equipmentUnavailabilityModal/setRetrieving",
            false
          );
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            text: "Muutmine ebaõnnestus",
            ttl: 15,
          });
        });
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      retrieving: "modals/equipmentUnavailabilityModal/retrieving",
    }),
    canEdit() {
      return true;
    },
    databaseObject() {
      return {
        end_time: moment(this.scheduleEditable.end_date)
          .hour(this.scheduleEditable.end_time.hour)
          .minute(this.scheduleEditable.end_time.minute)
          .format("YYYY-MM-DD[T]HH:mm:ssZ"),
        id: this.scheduleEditable.id,
        reason: this.scheduleEditable.reason,
        start_time: moment(this.scheduleEditable.start_date)
          .hour(this.scheduleEditable.start_time.hour)
          .minute(this.scheduleEditable.start_time.minute)
          .format("YYYY-MM-DD[T]HH:mm:ssZ"),
      };
    },
    hasChanges() {
      return (
        !moment(this.databaseObject.start_time).isSame(
          moment(this.schedule.reqData.start_time),
          "minute"
        ) ||
        !moment(this.databaseObject.end_time).isSame(
          moment(this.schedule.reqData.end_time),
          "minute"
        ) ||
        this.databaseObject.id !== this.schedule.reqData.id ||
        this.databaseObject.reason !== this.schedule.reqData.reason
      );
    },
  },
};
</script>
<style lang="scss"></style>
