import StoreRequestHandler from "../../../assets/mixins/StoreRequestHandler";

export const providers = {
  namespaced: true,
  state: {
    providers: null,
  },
  mutations: {
    setProviders(state, data) {
      state.providers = data;
    },
  },
  actions: {
    retrieveProviders({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        StoreRequestHandler.apiRequest(
          `suppliers/${rootState.companyData.activeCompany.uuid}/?all=True`,
          "get",
          true
        )
          .then((res) => {
            commit("setProviders", res.data);
            resolve();
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
    },
    addProvider({ rootState }, data) {
      return StoreRequestHandler.apiRequest(
        `suppliers/${rootState.companyData.activeCompany.uuid}/new/`,
        "post",
        true,
        data
      );
    },
  },
  getters: {
    providers: (state) => state.providers,
  },
};
