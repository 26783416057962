<template>
  <div class="modal">
    <div
      class="modal-body modal-large relative z-10"
      v-on-clickaway:mousedown="closeModal"
    >
      <div class="modal-header items-center p-2">
        <h4>Planeeri töö</h4>
      </div>
      <div class="modal-content flex-col">
        <PlanningDateSelector v-if="!results" class="mb-4" />
        <PlanningParameters v-if="!results" />
        <PlanningResults v-if="results && !selectedSlot" />
        <PlanningTaskData v-if="selectedSlot" />
      </div>
      <div class="modal-bottom-bar gap-x-3">
        <button
          v-if="!results && !loading"
          class="btn-primary"
          @click="
            !loading
              ? $store.dispatch(
                  'modals/taskPlanningModal/retrieveAvailabilityData'
                )
              : () => {}
          "
        >
          <span class="label">Genereeri</span>
        </button>
        <saving-loader
          v-if="loading"
          :saving="loading"
          :text="{
            saving: 'Genereerin',
            saved: 'Genereeritud',
          }"
        />
        <button
          class="btn-danger"
          @click="$store.dispatch('modals/taskPlanningModal/close')"
        >
          Sulge
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

import PlanningTaskData from "./planning/PlanningTaskData.vue";
import PlanningParameters from "./planning/PlanningParameters.vue";
import PlanningResults from "./planning/PlanningResults.vue";
import PlanningDateSelector from "./planning/PlanningDateSelector.vue";
import SavingLoader from "@/components/reusable/SavingLoader.vue";
export default {
  name: "PlanningModal",
  components: {
    SavingLoader,
    PlanningTaskData,
    PlanningParameters,
    PlanningResults,
    PlanningDateSelector,
  },
  data() {
    return {
      attributes: [],
      duration: 1,
      startLocation: {
        long_address: "",
        longitude: "",
        latitude: "",
      },
      endLocation: {
        long_address: "",
        longitude: "",
        latitude: "",
      },
      worker_amount: 2,
      moment: moment,
      startTime: null,
    };
  },
  methods: {
    closeModal() {
      this.$store.dispatch("modals/taskPlanningModal/close");
    },
  },
  mounted() {
    document.body.classList.add("modal-open");
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      selectedSlot: "modals/taskPlanningModal/selectedSlot",
      results: "modals/taskPlanningModal/results",
      loading: "modals/taskPlanningModal/loading",
    }),
  },
};
</script>
