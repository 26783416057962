var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"w-full flex h-navbar z-40 bg-white shadow mobile:px-0 relative font-body",class:!_vm.$store.state.userData.user.is_email_verified &&
    _vm.$store.state.auth.status.loggedIn &&
    this.$route.name !== 'ResendActivation'
      ? 'mb-16'
      : ''},[_c('div',{staticClass:"w-full flex mx-auto flex-row mobile:py-3 mobile:mx-0 relative"},[_c('div',{staticClass:"w-36 mobile:hidden flex mobile:justify-between justify-center items-center mobile:px-3",class:_vm.$store.state.auth.status.loggedIn ? 'mobile:w-1/2' : 'mobile:w-full'},[_c('router-link',{class:_vm.logoLink === _vm.$route.path ? 'cursor-default' : 'cursor-pointer',attrs:{"to":_vm.logoLink}},[_c('img',{staticClass:"mobile:h-12 h-16 p-3 mobile:p-1 mobile:hidden",attrs:{"src":"/logo.png","alt":"Gewodo logo"}})]),(
          !this.$store.state.auth.status.loggedIn && !this.$route.meta.isProto
        )?_c('button',{staticClass:"h-10 w-10 duration-100 hidden mobile:flex",class:_vm.hamburgerActive
            ? 'bg-white shadow-dangeroutline focus:bg-white text-danger'
            : 'bg-primary text-white focus:text-primary focus:bg-white focus:shadow-outline',on:{"click":function($event){_vm.hamburgerActive = !_vm.hamburgerActive}}},[_c('span',{staticClass:"typcn",class:_vm.hamburgerActive ? 'text-3xl typcn-times' : 'typcn-th-menu text-xl'})]):_vm._e()],1),_c('div',{staticClass:"flex"}),_c('div',{staticClass:"flex justify-start flex-grow mobile:hidden medium:self-center items-center gap-x-4"},[(
          _vm.$store.state.auth.status.loggedIn &&
          _vm.config.features.tasks &&
          ['R0', 'R1', 'R2'].includes(_vm.userRole)
        )?_c('div',{staticClass:"flex"},[_c('button',{staticClass:"btn-primary",attrs:{"tabindex":"1"},on:{"click":function () {
              _vm.$store.dispatch('modals/addTaskModal/open', _vm.addTaskDataInput);
            }}},[_vm._v(" Uus töö ")])]):_vm._e(),(
          _vm.$store.state.auth.status.loggedIn &&
          _vm.config.features.tasks &&
          ['R0', 'R1', 'R2'].includes(_vm.userRole)
        )?_c('div',{staticClass:"flex"},[_c('button',{staticClass:"btn-primary",attrs:{"tabindex":"2"},on:{"click":function () {
              _vm.$store.dispatch('modals/taskPlanningModal/open', {});
            }}},[_vm._v(" Planeeri töö ")])]):_vm._e(),(
          _vm.$store.state.auth.status.loggedIn &&
          _vm.config.features.projects &&
          ['R0', 'R1', 'R2'].includes(_vm.userRole)
        )?_c('div',{staticClass:"flex"},[_c('router-link',{attrs:{"to":"/projects/new"}},[_c('button',{staticClass:"btn-primary",attrs:{"tabindex":"3"}},[_vm._v("Uus tellimus")])])],1):_vm._e(),_c('transition',{attrs:{"name":"fade","duration":100,"mode":"out-in"}},[(
            _vm.$store.state.timetracking.hasActiveTimer &&
            !_vm.$store.state.timetracking.loading
          )?_c('timer-component'):_vm._e()],1)],1),(
        _vm.$store.state.auth.status.loggedIn && _vm.$store.state.userData.retrieved
      )?_c('div',{staticClass:"w-4/12 mobile:w-full flex justify-end items-center flex-row mobile:px-3 pr-6"},[_c('div',{staticClass:"flex mr-3 flex-grow"},[(_vm.activeCompany)?_c('div',{staticClass:"flex bg-offwhite w-full py-1 px-3 justify-between relative shadow cursor-pointer",class:_vm.showCompanySelector ? 'rounded-t' : 'rounded',on:{"click":function($event){_vm.showCompanySelector = !_vm.showCompanySelector}}},[_c('div',{staticClass:"truncate max-w-full flex"},[_c('span',[_vm._v(_vm._s(_vm.activeCompany.company_name))])]),_c('span',{staticClass:"typcn typcn-arrow-sorted-down transform duration-100",class:_vm.showCompanySelector ? 'rotate-180' : ''}),(_vm.showCompanySelector)?_c('div',{staticClass:"company-selector shadow"},_vm._l((_vm.companies),function(item,index){return _c('div',{key:item.uuid,staticClass:"flex justify-between items-center hover:bg-offwhite-dark",class:index === _vm.companies.length - 1 ? 'rounded-b' : '',on:{"click":function($event){return _vm.changeActiveCompany(item.uuid)}}},[_c('span',[_vm._v(_vm._s(item.company_name))]),_c('span',{staticClass:"rounded-full h-4 w-4 border-2 border-primary flex items-center justify-center"},[(item.uuid === _vm.activeCompany.uuid)?_c('span',{staticClass:"bg-primary h-2 w-2 rounded-full"}):_vm._e()])])}),0):_vm._e()]):_vm._e()]),_c('div',{staticClass:"flex items-center justify-end relative"},[(_vm.config.features.chat && _vm.$route.name !== 'ChatsView')?_c('div',{staticClass:"mr-2 group navbar-button",attrs:{"tabindex":"4"},on:{"click":function($event){_vm.chatTrayActive ? _vm.setChatTrayState(false) : _vm.setChatTrayState(true)}}},[_c('img',{staticClass:"h-6 w-6 group-hover:filter-to-white cursor-pointer",attrs:{"src":"/icons/messages.svg","alt":"Messages icon"}})]):_vm._e(),(
            _vm.config.features.chat &&
            _vm.$store.state.chat.chatNotifications.length > 0 &&
            _vm.$route.name !== 'ChatsView'
          )?_c('span',{staticClass:"absolute notification-number flex justify-center items-center text-white font-semibold cursor-pointer",attrs:{"role":"button"}},[_vm._v(_vm._s(_vm.$store.state.chat.chatNotifications.length))]):_vm._e()]),_c('div',{staticClass:"flex items-center justify-end relative"},[_c('div',{staticClass:"mr-2 group navbar-button",attrs:{"tabindex":"5"},on:{"click":function($event){_vm.notificationTrayActive
              ? _vm.setNotificationTrayState(false)
              : _vm.setNotificationTrayState(true)}}},[_c('img',{staticClass:"h-6 w-6 group-hover:filter-to-white cursor-pointer",attrs:{"src":"/bc21/bell.svg","alt":"Messages icon"}})]),(
            _vm.$store.state.notifications.notificationsLoaded &&
            _vm.$store.state.notifications.unread.length > 0
          )?_c('span',{staticClass:"absolute notification-number flex justify-center items-center text-white font-semibold cursor-pointer",attrs:{"role":"button"}},[_vm._v(_vm._s(_vm.$store.state.notifications.unread.length))]):_vm._e()]),(
          _vm.activeCompany &&
          _vm.config.features.settings &&
          ['R0', 'R1'].includes(_vm.userRole)
        )?_c('router-link',{staticClass:"mr-2 group navbar-button",attrs:{"to":"/settings","tabindex":"6"}},[_c('img',{staticClass:"h-6 w-6 group-hover:filter-to-white",attrs:{"src":"/icons/setting.svg","alt":"Settings icon"}})]):_vm._e(),_c('div',{staticClass:"flex items-center justify-end"},[_c('div',{staticClass:"flex relative cursor-pointer duration-100",attrs:{"tabindex":"7"},on:{"click":_vm.toggleUserMenu}},[(_vm.profileImage)?_c('img',{staticClass:"rounded-full cursor-pointer h-10 w-10 p-1 hover:shadow-outline navbar-profile-image",attrs:{"src":_vm.profileImage,"alt":"User profile image","data-cy":"usermenu"}}):_vm._e(),(!_vm.profileImage)?_c('div',{staticClass:"bg-offwhite h-10 w-10 rounded-full flex items-center justify-center hover:text-white hover:bg-primary"},[_c('span',{staticClass:"font-medium"},[_vm._v(_vm._s(_vm.getInitials()))])]):_vm._e()])])],1):_vm._e(),(
        !this.$store.state.auth.status.loggedIn && !this.$route.meta.isProto
      )?_c('div',{staticClass:"flex-auto self-center flex justify-end items-center flex-row mobile:hidden pr-6"},[_c('router-link',{staticClass:"mobile:hidden",attrs:{"to":"/login"}},[_c('button',{staticClass:"my-3 mx-2 self-end mobile:text-sm btn-primary p-2",class:_vm.$route.name === 'Login' ? 'bg-white' : ''},[_c('span',{staticClass:"label text-lg py-1"},[_vm._v("Logi sisse")])])])],1):_vm._e(),_c('div',{staticClass:"navbar-menu-container"},[(_vm.notificationTrayActive)?_c('notification-tray',{ref:"notificationTray",attrs:{"active":_vm.notificationTrayActive},on:{"closeTray":function($event){return _vm.setNotificationTrayState(false)}}}):_vm._e(),_c('user-menu',{attrs:{"active":_vm.showUserMenu}}),(_vm.config.features.chat && _vm.chatTrayActive)?_c('chat-tray',{attrs:{"active":_vm.chatTrayActive},on:{"closeTray":function($event){return _vm.setChatTrayState(false)}}}):_vm._e()],1)]),(
      _vm.$store.state.auth.status.loggedIn &&
      !_vm.$store.state.userData.user.is_email_verified &&
      _vm.$store.state.userData.retrieved &&
      this.$route.name !== 'ResendActivation'
    )?_c('div',{staticClass:"verify-email-notification"},[_c('p',[_vm._v(" Et kasutada lehe funktsioone, palun kinnitage oma meilikonto. Kui te ei ole vastavat e-kirja saanud, vajutage "),_c('router-link',{staticClass:"cursor-pointer text-highlightblue",attrs:{"to":"/resend-activation"}},[_vm._v("siia")]),_vm._v(". ")],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }