<template>
  <div
    class="grid col-span-4 narrow-cost-list"
    :class="!planned && item.status !== 1 ? 'bg-offwhite' : ''"
  >
    <div class="flex flex-row h-full">
      <div
        class="flex h-full"
        :style="parseCostColor(item.type)"
        style="min-width: 7px"
      />
      <span>{{ item.description }}</span>
    </div>
    <div class="flex">
      <span class="mr-1"> {{ item.amount }}</span>
      <span> {{ item.unit }}</span>
    </div>
    <span>{{ round2(item.price) }} €</span>
    <span>{{ round2(item.amount * item.price) }} €</span>
  </div>
</template>
<script>
import CostTypeParser from "@/assets/mixins/CostTypeParser";
import { round2 } from "@/assets/utils/commonMath";

export default {
  name: "CostItem",
  methods: { round2 },
  props: {
    item: {
      type: Object,
      default: null,
    },
    planned: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [CostTypeParser],
};
</script>

<style lang="scss" scoped>
.narrow-cost-list {
  @apply grid items-center w-full py-1;
  grid-template-columns: 0.6fr 0.2fr 0.2fr 0.2fr;
}
</style>
