<template>
  <div class="modal">
    <div
      class="modal-body modal-full relative"
      v-on-clickaway:mousedown="closeModal"
    >
      <div class="modal-header">
        <div class="modal-title">
          <h3 class="self-center">Lisa töö</h3>
        </div>
      </div>
      <div class="modal-content flex-col gap-y-4">
        <add-task-form @taskSaved="handleSaveTask" ref="addTaskForm" />
        <worker-selector
          v-if="selectedWorkers.length === 0 || showWorkerSelector"
          :start-time="startTime"
          :end-time="endTime"
          :start-location="fields.location"
          :end-location="fields.endLocation"
          :selected-workers="selectedWorkers.map((x) => x.worker)"
          :full-width="true"
          @setWorkers="handleSetWorkers"
          @removeWorker="handleRemoveWorker"
          @workerAmountChanged="handleWorkerCountChanged"
          @attributeStringChanged="handleAttributeStringChanged"
          ref="workerSelector"
          class="text-sm"
        />
        <assignment-manager
          v-if="selectedWorkers.length > 0 && !showWorkerSelector"
          @removeWorker="handleRemoveWorker"
          @clearWorkers="() => handleSetWorkers([])"
          @showWorkerSelector="showWorkerSelector = true"
        />
      </div>
      <div class="modal-bottom-bar">
        <div class="flex gap-x-3">
          <button
            @click="saveTask()"
            :disabled="!fields.title || saving"
            :class="fields.title && !saving ? 'btn-primary' : 'btn-disabled'"
          >
            <span v-if="!saving">Salvesta</span>
            <ClipLoader size="16px" v-else />
          </button>
          <button class="btn-danger" @click="closeModal(false)">Sulge</button>
        </div>
      </div>
      <project-selector
        v-if="showProjectSelector"
        :convert="false"
        @closeModal="
          $store.commit('modals/addTaskModal/setShowProjectSelector', false)
        "
        @projectAdded="setProject"
        class="z-999"
      />
      <new-project-modal
        v-if="showNewProjectModal"
        :task-data="fields"
        @closeModal="
          $store.commit('modals/addTaskModal/setShowNewProjectModal', false)
        "
        @projectAdded="setProject"
        class="z-999"
      />
    </div>
  </div>
</template>

<script>
import AddTaskForm from "./AddTaskForm";
import WorkerSelector from "./WorkerSelector.vue";
import AssignmentManager from "./assignmentmanager/AssignmentManager";
import ProjectSelector from "@/components/tasks/ProjectSelector";
import { EventBus } from "@/assets/mixins/GewodoMixins";
import { mapGetters } from "vuex";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import NewProjectModal from "@/components/project/NewProjectModal.vue";
export default {
  components: {
    NewProjectModal,
    AddTaskForm,
    WorkerSelector,
    AssignmentManager,
    ProjectSelector,
    ClipLoader,
  },
  data() {
    return {
      showWorkerSelector: false,
    };
  },
  mounted() {
    document.body.classList.add("modal-open");
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
  methods: {
    closeModal(event) {
      if (event.target === undefined || !event.target.dataset.modalId)
        this.$store.dispatch("modals/addTaskModal/close");
    },
    setProject(project) {
      this.$store.dispatch("modals/addTaskModal/setProject", project);
    },
    saveTask() {
      this.saving = true;
      if (this.$refs.workerSelector && this.$refs.workerSelector.hasOneWorker)
        this.$refs.workerSelector.confirmSelection();
      this.$store.dispatch("modals/addTaskModal/saveTask");
    },
    handleSaveTask() {
      EventBus.$emit("updateTasks");
      this.closeModal();
    },
    handleRemoveWorker(index) {
      this.$store.commit("modals/addTaskModal/removeWorker", index);
    },
    handleSetWorkers(arr) {
      this.$store.commit("modals/addTaskModal/setWorkers", arr);
      this.showWorkerSelector = false;
    },
    handleAttributeStringChanged(attrString) {
      this.$store.commit("modals/addTaskModal/setAttributeString", attrString);
    },
    handleWorkerCountChanged(count) {
      this.$store.commit("modals/addTaskModal/setWorkerCount", count);
    },
  },
  computed: {
    ...mapGetters({
      startTime: "modals/addTaskModal/startTimeDb",
      endTime: "modals/addTaskModal/endTimeDb",
      selectedWorkers: "modals/addTaskModal/selectedWorkers",
      showProjectSelector: "modals/addTaskModal/showProjectSelector",
      showNewProjectModal: "modals/addTaskModal/showNewProjectModal",
      fields: "modals/addTaskModal/fields",
      saving: "modals/addTaskModal/saving",
    }),
  },
};
</script>

<style lang="scss">
.z-999 {
  z-index: 999 !important;
}
</style>
