import StoreRequestHandler from "@/assets/mixins/StoreRequestHandler";
import { EventBus } from "../../../assets/mixins/GewodoMixins";
import { isEqual } from "lodash";
import { timeSelectorObjectToString } from "@/assets/utils/commonTransforms";
import moment from "moment";

const generateAssigment = (worker) => {
  return {
    worker: worker,
    start_time: null,
    end_time: null,
    location: null,
    end_location: null,
    description: null,
    attributes: [],
  };
};

const compareUploadData = (data, compareData) => {
  let dataToProcess = JSON.parse(JSON.stringify(data));
  delete dataToProcess.workers;
  Object.keys(dataToProcess).forEach((k) =>
    ["start_time", "end_time"].includes(k)
      ? moment(dataToProcess[k]).isSame(moment(compareData[k]), "minute") &&
        delete dataToProcess[k]
      : isEqual(dataToProcess[k], compareData[k]) && delete dataToProcess[k]
  );
  return dataToProcess;
};

const generateMovingData = (data, taskData) => {
  return {
    start_time: moment(
      data.start_time ? data.start_time : taskData.start_time
    ).format("YYYY-MM-DD[T]HH:mm:ssZ"),
    end_time: moment(data.end_time ? data.end_time : taskData.end_time).format(
      "YYYY-MM-DD[T]HH:mm:ssZ"
    ),
    start_location: data.location ? data.location : taskData.location,
    end_location: data.end_location ? data.end_location : taskData.end_location,
    worker_ids: data.sub_tasks
      ? data.sub_tasks.map((x) => x.worker)
      : taskData.workers.map((x) => x.id),
    task_id: taskData.id,
  };
};

const initialState = {
  show: false,
  data: null,
  saving: false,
  taskData: null,
  taskDataClone: null,
  timerData: null,
  todos: null,
  showAddResourceAssignmentModal: false,
  extraCostModal: { show: false, planning: false },
  supplierCostModal: { show: false, planning: false },
  showNewProjectModal: false,
  resourceCostModal: false,
  extraLocations: [],
  internalSelectedWorkers: [],
  equipmentList: [],
  timerMapModal: { show: false, data: null },
  locationModal: { show: false, data: null },
  lastOpenTaskId: null,
};

export const taskModal = {
  state: JSON.parse(JSON.stringify(initialState)),
  namespaced: true,
  mutations: {
    retrieveTasks(state, data) {
      state.tasks = data;
    },
    setTimerData(state, data) {
      state.timerData = data;
    },
    setTodos(state, data) {
      state.todos = data;
    },
    open(state, data) {
      state.data = data;
      state.show = true;
      state.lastOpenTaskId = data.task;
    },
    close(state) {
      state.show = false;
      state.taskData = null;
      state.taskDataClone = null;
      state.timerData = null;
      state.todos = null;
      state.data = null;
      state.internalSelectedWorkers = [];
      state.extraLocations = [];
      state.equipmentList = [];
      state.lastOpenTaskId = null;
    },
    setSaving(state, data) {
      state.saving = data;
    },
    setTaskData(state, data) {
      if (data.sub_tasks) {
        data.sub_tasks.forEach((x) => {
          if (x.start_time)
            x.start_time = {
              hour: moment(x.start_time).hour(),
              minute: moment(x.start_time).minute(),
            };
          if (x.end_time)
            x.end_time = {
              hour: moment(x.end_time).hour(),
              minute: moment(x.end_time).minute(),
            };
        });
      }
      state.taskData = JSON.parse(JSON.stringify(data));
      state.taskDataClone = JSON.parse(JSON.stringify(data));
    },
    setAssignments(state, data) {
      let boundWorkers = state.taskData.sub_tasks.map((x) => x.worker);
      for (const worker of data.filter(
        (w) => !boundWorkers.map((x) => x.id).includes(w.id)
      )) {
        state.taskData.workers.push(worker);
        state.taskData.sub_tasks.push(generateAssigment(worker));
      }
    },
    removeAssignment(state, assignment) {
      state.taskData.sub_tasks.splice(
        state.taskData.sub_tasks.findIndex(
          (x) => x.worker.id === assignment.worker.id
        ),
        1
      );
    },
    removeWorker(state, id) {
      state.taskData.workers.splice(
        state.taskData.workers.findIndex((x) => x.id === id),
        1
      );
    },
    openAddResourceAssignmentModal(state) {
      state.showAddResourceAssignmentModal = true;
    },
    closeAddResourceAssignmentModal(state) {
      state.showAddResourceAssignmentModal = false;
    },
    openAddExtraCostModal(state, isPlanning) {
      state.extraCostModal.show = true;
      state.extraCostModal.isPlanning = isPlanning;
    },
    closeAddExtraCostModal(state) {
      state.extraCostModal.show = false;
      state.extraCostModal.isPlanning = false;
    },
    openAddSupplierCostModal(state, isPlanning) {
      state.supplierCostModal.show = true;
      state.supplierCostModal.isPlanning = isPlanning;
    },
    closeAddSupplierCostModal(state) {
      state.supplierCostModal.show = false;
      state.supplierCostModal.isPlanning = false;
    },
    openAddResourceCostModal(state) {
      state.resourceCostModal = true;
    },
    closeAddResourceCostModal(state) {
      state.resourceCostModal = false;
    },
    addExtraLocation(state) {
      state.extraLocations.push(null);
    },
    deleteNotSavedExtraLocation(state, data) {
      state.extraLocations.splice(data.index, 1);
    },
    updateExtraLocation(state, data) {
      state.extraLocations[data.index] = data.newLocation;
    },
    setShowNewProjectModal(state, data) {
      state.showNewProjectModal = data;
    },
    updateSelectedWorkers(state, data) {
      state.internalSelectedWorkers = data;
    },
    updateSelectedEquipment(state, data) {
      state.equipmentList = data;
    },
    openTimerMap(state, data) {
      state.timerMapModal = {
        show: true,
        data: data,
      };
    },
    closeTimerMap(state) {
      state.timerMapModal = { show: false, data: null };
    },
    openLocationModal(state, data) {
      state.locationModal = {
        show: true,
        data: data,
      };
    },
    closeLocationModal(state) {
      state.locationModal = {
        show: false,
        data: null,
      };
    },
  },
  actions: {
    retrieveTasks({ commit }, data) {
      commit("retrieveTasks", data);
    },
    setShowNewProjectModal({ commit }, data) {
      commit("setShowNewProjectModal", data);
    },
    removeAssignment({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        if (data.id)
          StoreRequestHandler.apiRequest(
            `tasks/${state.taskData.id}/sub_tasks/${data.id}/delete/`,
            "delete",
            true
          )
            .then(() => {
              commit("removeAssignment", data);
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        else {
          commit("removeAssignment", data);
          resolve();
        }
      });
    },
    open({ commit }, data) {
      commit("open", data);
    },
    updateSelectedWorkers({ commit }, data) {
      commit("updateSelectedWorkers", data);
    },
    openAddResourceAssignmentModal({ commit }) {
      commit("openAddResourceAssignmentModal");
    },
    close({ commit }) {
      commit("close");
    },
    retrieveTask({ commit, dispatch }, id, project = null) {
      commit("setSaving", true);
      StoreRequestHandler.apiRequest(
        project ? `tasks/${project}/${id}/` : `tasks/${id}/`,
        "get",
        true
      )
        .then((res) => {
          commit("setTaskData", res.data);
          dispatch("retrieveTimerData", res.data.id);
          // dispatch("retrieveTaskTodos", res.data.id);
          commit("setSaving", false);
        })
        .catch((err) => {
          console.error(err);
          if (err.response.status === 404) {
            dispatch(
              "messageHandler/throwMessage",
              {
                text: "Ülesannet ei leitud, võta ühendust administraatoriga.",
                type: "error",
                ttl: 5,
              },
              { root: true }
            );
            commit("close");
          }
          commit("setSaving", false);
        });
    },
    retrieveTimerData({ commit }, taskId) {
      StoreRequestHandler.apiRequest(
        `tasks/${taskId}/timers/`,
        "get",
        true
      ).then((res) => {
        commit("setTimerData", res.data);
      });
    },
    retrieveTaskTodos({ commit }, taskId) {
      StoreRequestHandler.apiRequest(
        `tasks/${taskId}/todos/`,
        "get",
        true
      ).then((res) => commit("setTodos", res.data));
    },
    async saveTask({ state, dispatch, commit, rootState }) {
      commit("setSaving", true);

      if (
        state.internalSelectedWorkers &&
        state.internalSelectedWorkers.length > 0
      ) {
        const e = state.internalSelectedWorkers.filter((x) => !!x);
        let removePromises = [];
        let assignmentsToRemove = state.taskData.sub_tasks.filter(
          (x) => !e.map((y) => y.id).includes(x.worker.id)
        );
        assignmentsToRemove.forEach((x) =>
          removePromises.push(dispatch("removeAssignment", x))
        );
        await Promise.all(removePromises).then(() => {
          commit("setAssignments", e);
        });
      }

      let data = compareUploadData(state.taskData, state.taskDataClone);

      if (data.sub_tasks) {
        data.sub_tasks.forEach((subtask) => {
          subtask.worker = subtask.worker.id;
          if (subtask.start_time)
            subtask.start_time = timeSelectorObjectToString(
              subtask.start_time,
              state.taskData.start_time,
              "YYYY-MM-DD[T]HH:mm:ssZ"
            );
          if (subtask.end_time)
            subtask.end_time = timeSelectorObjectToString(
              subtask.end_time,
              state.taskData.end_time,
              "YYYY-MM-DD[T]HH:mm:ssZ"
            );
        });
      }
      if (state.equipmentList && state.equipmentList.length > 0) {
        data.equipmentList = state.equipmentList;
      }

      let breakFunction = false;
      if (
        data?.start_time ||
        data?.end_time ||
        (data?.sub_tasks && data.sub_tasks.length > 0)
      ) {
        await StoreRequestHandler.apiRequest(
          `calendar/${rootState.companyData.activeCompany.uuid}/suitable/moving/`,
          "post",
          true,
          generateMovingData(data, state.taskData)
        )
          .then((res) => {
            if (res.data?.overlaps && res.data.overlaps.length > 0) {
              breakFunction = true;
              dispatch(
                "messageHandler/throwMessage",
                {
                  text: `Salvestamine ebaõnnestus! Töötajad: ${res.data.overlaps.join(
                    ", "
                  )} pole sellel hetkel vabad!`,
                  type: "warning",
                  ttl: 5,
                },
                { root: true }
              );
            }
          })
          .catch((err) => {
            commit("setSaving", false);
            console.error(err);
            breakFunction = true;
            dispatch(
              "messageHandler/throwMessage",
              {
                text: "Ülekattuvuse kontroll ebaõnnestus.",
                type: "error",
                ttl: 5,
              },
              { root: true }
            );
          });
      }
      if (breakFunction) {
        commit("setSaving", false);
        return;
      }

      StoreRequestHandler.apiRequest(
        `tasks/${state.data.task}/edit/`,
        "patch",
        true,
        data
      )
        .then((res) => {
          if (res.status === 200)
            if (state.taskData.workers !== state.taskDataClone.workers)
              dispatch("saveWorkers");
            else {
              dispatch("retrieveTask", state.taskData.id);
              EventBus.$emit("updateTasks");
            }
        })
        .catch((err) => {
          commit("setSaving", false);
          console.error(err);
          dispatch(
            "messageHandler/throwMessage",
            { text: "Midagi läks valesti", type: "error", ttl: 10 },
            { root: true }
          );
        });
      if (data.extra_locations && data.extra_locations.length > 0) {
        StoreRequestHandler.apiRequest(
          `tasks/${state.data.task}/extras/edit/`,
          "patch",
          true,
          data.extra_locations
        );
      }
      if (state.extraLocations && state.extraLocations.length > 0) {
        StoreRequestHandler.apiRequest(
          `tasks/${state.data.task}/extras/create/`,
          "post",
          true,
          state.extraLocations
        ).then(() => {
          state.extraLocations = [];
        });
      }
      if (
        state.internalSelectedWorkers &&
        state.internalSelectedWorkers.length > 0
      ) {
        state.internalSelectedWorkers = [];
        EventBus.$emit("closeEditMenu");
      }
    },
    saveWorkers({ state, dispatch, commit }) {
      commit("setSaving", true);
      const cloneIdList = state.taskDataClone.workers.map((x) => x.id);
      const mainIdList = state.taskData.workers.map((x) => x.id);
      let workersToAdd = cloneIdList.filter((x) => !mainIdList.includes(x));
      let workersToRemove = mainIdList.filter(
        (x) => !cloneIdList.includes(x) && !workersToAdd.includes(x)
      );
      StoreRequestHandler.apiRequest(
        `tasks/${state.data.task}/workers/`,
        "post",
        true,
        {
          add_workers: workersToAdd,
          remove_workers: workersToRemove,
        }
      ).then((res) => {
        if (res.status === 200) {
          dispatch("retrieveTask", state.data.task);
          EventBus.$emit("updateTasks");
        }
      });
    },
    addExtraLocation({ commit }) {
      commit("addExtraLocation");
    },
    updateExtraLocation({ commit }, data) {
      commit("updateExtraLocation", data);
    },
    deleteNotSavedExtraLocation({ commit }, data) {
      commit("deleteNotSavedExtraLocation", data);
    },
    deleteExtraLocation({ state, dispatch }, data) {
      StoreRequestHandler.apiRequest(
        `tasks/${state.data.task}/extras/${data.id}/delete/`,
        "delete",
        true
      ).then((res) => {
        if (res.status === 200) {
          dispatch("retrieveTask", state.data.task);
          EventBus.$emit("updateTasks");
        }
      });
    },
    convertToProject({ state, dispatch }, data) {
      StoreRequestHandler.apiRequest(
        `tasks/${state.data.task}/convert/${data.uuid}/`,
        "post",
        true
      ).then((res) => {
        if (res.status === 200) {
          dispatch("retrieveTask", state.data.task);
          EventBus.$emit("updateTasks");
        }
      });
    },
    updateSelectedEquipment({ commit }, data) {
      commit("updateSelectedEquipment", data);
    },
    openTimerMap({ commit }, data) {
      commit("openTimerMap", data);
    },
    closeTimerMap({ commit }) {
      commit("closeTimerMap");
    },
    openLocationModal({ commit }, data) {
      commit("openLocationModal", data);
    },
    closeLocationModal({ commit }) {
      commit("closeLocationModal");
    },
  },
  getters: {
    data: (state) => state.data,
    taskDataChanged: (state) => {
      if (!state.taskData || !state.taskDataClone) return false;

      let comparisonObject = JSON.parse(JSON.stringify(state.taskData));
      let comparisonObjectClone = JSON.parse(
        JSON.stringify(state.taskDataClone)
      );

      delete comparisonObject.start_time;
      delete comparisonObjectClone.start_time;
      delete comparisonObject.end_time;
      delete comparisonObjectClone.end_time;

      let startDateChanged = moment(state.taskData.start_time).isSame(
        moment(state.taskDataClone.start_time),
        "minute"
      );
      let endDateChanged = moment(state.taskData.end_time).isSame(
        moment(state.taskDataClone.end_time),
        "minute"
      );
      return [
        !isEqual(comparisonObject, comparisonObjectClone),
        !startDateChanged,
        !endDateChanged,
        state.internalSelectedWorkers &&
        state.internalSelectedWorkers.length > 0
          ? !isEqual(
              state.internalSelectedWorkers.filter((x) => !!x).map((x) => x.id),
              state.taskData.sub_tasks.map((x) => x.worker.id)
            )
          : undefined,
        state.extraLocations.length > 0,
        state.equipmentList.length > 0
          ? !isEqual(
              state.equipmentList.map((x) => x.id).sort(),
              state.taskData.assigned_equipment_list
                .map((x) => x.equipment.id)
                .sort()
            )
          : undefined,
      ].some((x) => !!x);
    },
    timerData: (state) => state.timerData,
    todos: (state) => state.todos,
    taskData: (state) => state.taskData,
    taskDataClone: (state) => state.taskDataClone,
    showAddResourceAssignmentModal: (state) =>
      state.showAddResourceAssignmentModal,
    showNewProjectModal: (state) => state.showNewProjectModal,
    extraCostModal: (state) => state.extraCostModal,
    supplierCostModal: (state) => state.supplierCostModal,
    resourceCostModal: (state) => state.resourceCostModal,
    timerMapModal: (state) => state.timerMapModal,
    locationModal: (state) => state.locationModal,
    workers: (state) =>
      state.taskData.sub_tasks
        ? state.taskData.sub_tasks.map((x) => x.worker) ?? null
        : null,
    saving: (state) => state.saving,
    extraLocations: (state) => state.extraLocations,
    lastOpenTaskId: (state) => state.lastOpenTaskId,
    exportData: (state) => {
      let tempObject = JSON.parse(JSON.stringify(state.taskData));
      tempObject.sub_tasks.forEach((subTask) => {
        if (subTask.start_time)
          subTask.start_time = timeSelectorObjectToString(
            subTask.start_time,
            state.taskData.start_time,
            "YYYY-MM-DD[T]HH:mm:ssZ"
          );
        if (subTask.end_time)
          subTask.end_time = timeSelectorObjectToString(
            subTask.end_time,
            state.taskData.end_time,
            "YYYY-MM-DD[T]HH:mm:ssZ"
          );
      });

      return tempObject;
    },
  },
};
