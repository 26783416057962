var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex border-2 justify-center items-center rounded duration-100 cursor-pointer",class:[
    _vm.value && !_vm.hovered ? 'bg-primary border-primary' : '',
    _vm.value && _vm.hovered ? 'bg-danger border-danger' : '',
    !_vm.value && _vm.hovered ? 'bg-offwhite border-primary mobile:bg-white' : '',
    !_vm.value && !_vm.hovered ? 'border-primary' : '',
    ("h-" + _vm.size + " w-" + _vm.size) ],on:{"click":_vm.toggle,"mouseenter":function($event){_vm.hovered = true},"mouseleave":function($event){_vm.hovered = false}}},[_c('span',{staticClass:"typcn text-xl leading-none",class:_vm.value
        ? 'text-white typcn-tick'
        : _vm.hovered
        ? 'text-primary typcn-tick'
        : 'text-white'})])}
var staticRenderFns = []

export { render, staticRenderFns }