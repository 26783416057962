<template>
  <div class="modal">
    <div class="modal-body modal-large" v-on-clickaway="closeModal">
      <div class="modal-header px-2 py-1">
        <h3>
          {{
            timerMapModal.data.length === 1
              ? `Töötaja "${timerMapModal.data[0].worker_name}" töö alustamise/lõpetamise kohad`
              : `Töö "${taskData.title}" asukoha ja töötajate kaart`
          }}
        </h3>
      </div>
      <div
        class="flex h-full w-full shadow rounded-b-xl mobile:hidden"
        v-if="mapInited"
      >
        <l-map
          class="h-full w-full z-10 rounded-b-xl"
          :options="mapOptions"
          :center="mapOptions.center"
          :interactive="false"
          @update:zoom="resizeCircles"
          ref="map"
        >
          <l-tile-layer
            :url="tileUrl"
            :attribution="'© OpenStreetMap contributors'"
            :position="'bottomleft'"
            class="h-full w-full justify-start flex"
          />
          <div v-for="(timerData, index) in timerMapModal.data" :key="index">
            <l-circle
              v-if="timerData.start_location && zoomLevel >= 14"
              :lat-lng="[
                timerData.start_location.latitude,
                timerData.start_location.longitude,
              ]"
              :radius="parseFloat(timerData.start_location.accuracy)"
              color="#550055"
            >
              <l-tooltip>
                Töötaja {{ timerData.worker_name }} algus koht
              </l-tooltip>
            </l-circle>
            <l-marker
              v-if="timerData.start_location && zoomLevel < 14"
              :lat-lng="[
                timerData.start_location.latitude,
                timerData.start_location.longitude,
              ]"
              color="#550055"
            >
              <l-icon
                icon-url="/bc21/placeholder.svg"
                :icon-size="dynamicSize"
              />
              <l-tooltip>
                Töötaja {{ timerData.worker_name }} algus koht
              </l-tooltip>
            </l-marker>
            <l-circle
              v-if="timerData.end_location && zoomLevel >= 14"
              :lat-lng="[
                timerData.end_location.latitude,
                timerData.end_location.longitude,
              ]"
              :radius="parseFloat(timerData.end_location.accuracy)"
            >
              <l-tooltip>
                Töötaja {{ timerData.worker_name }} lõppkoht
              </l-tooltip>
            </l-circle>
            <l-marker
              v-if="timerData.end_location && zoomLevel < 14"
              :lat-lng="[
                timerData.end_location.latitude,
                timerData.end_location.longitude,
              ]"
              color="#550055"
            >
              <l-icon
                icon-url="/bc21/placeholder.svg"
                :icon-size="dynamicSize"
              />
              <l-tooltip>
                Töötaja {{ timerData.worker_name }} lõppkoht
              </l-tooltip>
            </l-marker>
          </div>

          <l-marker
            v-if="
              taskData &&
              taskData.location &&
              taskData.location.latitude &&
              taskData.location.longitude
            "
            :lat-lng="[taskData.location.latitude, taskData.location.longitude]"
          >
            <l-icon icon-url="/bc21/placeholder.svg" :icon-size="dynamicSize" />
            <l-tooltip> Töö alguskoht </l-tooltip>
          </l-marker>
          <l-marker
            v-for="(extraLocation, index) in taskData
              ? taskData.extra_locations.filter(
                  (x) =>
                    x.location && x.location.latitude && x.location.longitude
                )
              : []"
            :key="index"
            :lat-lng="[
              extraLocation.location.latitude,
              extraLocation.location.longitude,
            ]"
          >
            <l-icon icon-url="/bc21/placeholder.svg" :icon-size="dynamicSize" />
            <l-tooltip> Vahekoht {{ index }} </l-tooltip>
          </l-marker>
          <l-marker
            v-if="
              taskData &&
              taskData.end_location &&
              taskData.end_location.latitude &&
              taskData.end_location.longitude
            "
            :lat-lng="[
              taskData.end_location.latitude,
              taskData.end_location.longitude,
            ]"
          >
            <l-icon icon-url="/bc21/placeholder.svg" :icon-size="dynamicSize" />
            <l-tooltip> Töö lõppkoht </l-tooltip>
          </l-marker>
        </l-map>
      </div>
      <div class="modal-bottom-bar">
        <button class="btn-danger" @click="closeModal">Sulge</button>
      </div>
    </div>
  </div>
</template>

<script>
import { latLng } from "leaflet";
import { mapGetters } from "vuex";
import {
  LIcon,
  LMap,
  LMarker,
  LTileLayer,
  LTooltip,
  LCircle,
} from "vue2-leaflet";

export default {
  name: "TimerMapModal",
  components: {
    LMap,
    LMarker,
    LIcon,
    LTileLayer,
    LTooltip,
    LCircle,
  },
  props: {
    taskData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tileUrl: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      mapOptions: {
        zoom: 10,
        center: latLng(58.6953, 25.5136),
        interactive: false,
      },
      zoomLevel: 0,
      mapInited: false,
    };
  },
  methods: {
    initLocations() {
      let totalLongitude = 0;
      let totalLatitude = 0;
      let num = 0;
      if (
        this.taskData.location &&
        this.taskData.location.latitude &&
        this.taskData.location.longitude
      ) {
        totalLongitude += parseFloat(this.taskData.location.longitude);
        totalLatitude += parseFloat(this.taskData.location.latitude);
        num++;
      }
      if (
        this.taskData.end_location &&
        this.taskData.end_location.latitude &&
        this.taskData.end_location.longitude
      ) {
        totalLongitude += parseFloat(this.taskData.end_location.longitude);
        totalLatitude += parseFloat(this.taskData.end_location.latitude);
        num++;
      }
      for (const index in this.timerMapModal.data) {
        const location = this.timerMapModal.data[index];
        if (location.start_location) {
          totalLongitude += parseFloat(location.start_location.longitude);
          totalLatitude += parseFloat(location.start_location.latitude);
          num++;
        }
        if (location.end_location) {
          totalLongitude += parseFloat(location.end_location.longitude);
          totalLatitude += parseFloat(location.end_location.latitude);
          num++;
        }
      }
      this.mapOptions.center = latLng(
        totalLatitude / num,
        totalLongitude / num
      );

      this.mapInited = true;
    },
    resizeCircles(zoomLevel) {
      this.zoomLevel = zoomLevel;
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
  mounted() {
    this.initLocations();
  },
  computed: {
    ...mapGetters({
      timerMapModal: "modals/taskModal/timerMapModal",
    }),
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
  },
};
</script>

<style scoped lang="scss"></style>
