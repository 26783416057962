import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./modules/auth.module";
import { userData } from "./modules/userdata.module";
import { notifications } from "./modules/notifications.module";
import { companyData } from "./modules/companyData.module";
import { modals } from "./modules/modals.module";
import { timetracking } from "./modules/timetracking.module";
import { companyVariables } from "./modules/ava/companyVariables.module";
import { templates } from "./modules/ava/templates.module";
import { providers } from "./modules/ava/providers.module";
import { clients } from "./modules/ava/clients.module";
import { pages } from "./modules/pages.module";
import { calendar } from "./modules/calendar.module";
import { invoice } from "./modules/invoice.module";
import { messageHandler } from "./modules/messageHandler.module";
import { chat } from "./modules/chat.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
  },
  modules: {
    auth,
    userData,
    notifications,
    companyData,
    modals,
    timetracking,
    companyVariables,
    templates,
    providers,
    clients,
    pages,
    calendar,
    invoice,
    chat,
    messageHandler,
  },
  getters: {
    anyModalOpen: (state) => {
      return (
        state.modals.fileModal.displayFileModal ||
        state.modals.invoiceModal.show ||
        state.modals.addInvoiceModal.show ||
        state.modals.offerModal.show ||
        state.modals.addOfferModal.show ||
        state.modals.addTaskModal.show ||
        state.modals.taskModal.show ||
        state.modals.addResourceModal.show ||
        state.modals.supplierCostModal.show ||
        state.modals.equipmentUnavailabilityModal.show ||
        state.modals.taskPlanningModal.show
      );
    },
  },
});
