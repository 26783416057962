<template>
  <div class="dashboard-sidebar-cont">
    <div class="dashboard-sidebar pt-5">
      <router-link class="dashboard-sidebar-item" to="/company/new">
        <img
          src="/bc21/clipboard.svg"
          class="mr-2 mobile:mr-0"
          alt="Create company icon"
        />
        <span>Loo ettevõte</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyNavbar",
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.dashboard-sidebar {
  .company-selector-button {
    @apply transform cursor-pointer duration-100 absolute;
    right: 0.2rem;
    &:hover {
      @apply text-primary-dark;
    }
  }
  .company-selector-button-active {
    @apply rotate-180 text-primary;
  }
  .company-selector {
    @apply bg-white flex flex-col justify-center pb-2;
  }
}
</style>
