var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return ((_vm.filter && _vm.filter[_vm.type.substring(8)]) || _vm.filter[_vm.type.substring(1)])?_c('div',{staticClass:"w-full gwd-table"},[_c('div',{staticClass:"gwd-table-row cursor-pointer grid-cols-12",class:_vm.actualCostsFiltered.some(function (x) { return x.status !== 1; })
        ? _vm.actualCostsFiltered.some(function (x) { return x.status === 1; })
          ? 'bg-offwhite-dark'
          : 'bg-bordergrey opacity-50'
        : 'bg-offwhite',on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.expandTable.apply(null, arguments)}}},[_c('div',{staticClass:"col-span-2 flex items-center h-full"},[_c('div',{staticClass:"flex flex-row h-full mr-3 items-center",staticStyle:{"min-width":"7px"},domProps:{"innerHTML":_vm._s(_vm.parseCostColorAndName(_vm.type))}}),_c('span',[_vm._v(_vm._s(_vm.name))])]),_c('div',{staticClass:"col-span-4 wider-cost-list"},[_c('span'),_c('span',[_vm._v(" "+_vm._s(_vm.plannedCostsFiltered.length))]),_c('span',[_vm._v(_vm._s(_vm.round2(_vm.totalPlannedAmount))+" "+_vm._s(_vm.unit))]),_c('span',[_vm._v(_vm._s(_vm.round2(_vm.totalPlannedSum))+" €")]),_c('span',[_vm._v(_vm._s(_vm.round2(_vm.totalPlannedSum))+" €")])]),_c('div',{staticClass:"col-span-4 wider-cost-list"},[_c('span'),_c('span',[_vm._v(" "+_vm._s(_vm.actualCostsFiltered.length))]),_c('span',[_vm._v(_vm._s(_vm.round2(_vm.totalActualAmount))+" "+_vm._s(_vm.unit))]),_c('span',[_vm._v(_vm._s(_vm.round2(_vm.totalActualSum))+" €")]),_c('span',[_vm._v(_vm._s(_vm.round2(_vm.totalActualSum))+" €")])]),(!_vm.displayMode)?_c('div',{staticClass:"col-span-2 flex flex-row justify-between items-center text-sm"},[_c('button',{class:!_vm.combined && _vm.plannedCostsFiltered.length > 0
            ? 'btn-primary'
            : 'btn-disabled',attrs:{"disabled":!_vm.combined && _vm.plannedCostsFiltered.length === 0},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();!_vm.combined && _vm.plannedCostsFiltered.length > 0 ? _vm.addPlannedCost() : ''}}},[_vm._v(" Lisa plan. ")]),_c('button',{class:!_vm.combined && _vm.actualCostsFiltered.length > 0
            ? 'btn-primary'
            : 'btn-disabled',attrs:{"disabled":!_vm.combined && _vm.actualCostsFiltered.length === 0},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();!_vm.combined && _vm.actualCostsFiltered.length > 0 ? _vm.addActualCost() : ''}}},[_vm._v(" Lisa tegelik ")]),_c('button',{class:!_vm.combined &&
          (_vm.plannedCostsFiltered.length > 0 || _vm.actualCostsFiltered.length > 0)
            ? 'btn-primary'
            : 'btn-disabled',attrs:{"disabled":!_vm.combined &&
          _vm.plannedCostsFiltered.length === 0 &&
          _vm.actualCostsFiltered.length === 0},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();!_vm.combined &&
          (_vm.plannedCostsFiltered.length > 0 || _vm.actualCostsFiltered.length > 0)
            ? _vm.addToLocalCombinedCosts()
            : ''}}},[_vm._v(" Kombineeri ")])]):_vm._e()]),(_vm.expanded)?_c('div',{staticClass:"flex flex-col"},_vm._l((Math.max(
        _vm.plannedCostsFiltered.length,
        _vm.actualCostsFiltered.length
      )),function(index){return _c('div',{key:index,staticClass:"grid grid-cols-12"},[_c('span',{staticClass:"col-span-2"}),(_vm.plannedCostsFiltered.length >= index)?_c('cost-item',{attrs:{"item":_vm.plannedCostsFiltered[index - 1]}}):_c('div',{staticClass:"col-span-4 narrow-cost-list"}),(_vm.actualCostsFiltered.length >= index)?_c('cost-item',{attrs:{"item":_vm.actualCostsFiltered[index - 1],"planned":false}}):_c('div',{staticClass:"col-span-4 narrow-cost-list"}),_c('div',{staticClass:"col-span-2"})],1)}),0):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }